import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import './RemoveTagModal.scss'

const RemoveTagModal = ({
    isForceSuccessClose = false,
    isSkipEnsure = false,
    tag,
    onRemove = () => {},
    onClose = () => {},
}) => (
    <ConfirmationDialog
        isForceSuccessClose={isForceSuccessClose}
        isShowEnsure={!isSkipEnsure && tag.isSystem}
        ensureText="This is system tag. You have to understand that this action has the potential to disrupt the operation of the application. Confirm that you are confident in your actions"
        onClose={() => onClose()}
        onAction={async () => await onRemove(tag)}
        successText="Tag removed successfully!"
        headText="Please, confirm"
        noteText={`Are you sure you want to remove this${tag.isSystem ? ' system' : ''} tag?`}
        targetText={tag.name}
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveTagModal
