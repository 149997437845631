import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'
import FormModal from 'components/Modal/FormModal/FormModal'

const EditTextModal = ({ value = '', label, onAction = () => {}, onClose = () => {} }) => {
    const [_value, setValue] = useState(value)

    const handleSubmit = async () => {
        try {
            await onAction(_value.trim())

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Update user"
            successText={`User ${label.toLowerCase()} changed successfully!`}
            actionText="Change"
            actionVariant="primary"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label={label}>
                <Input value={_value} onChange={value => setValue(value)} />
            </FormBlock>
        </FormModal>
    )
}

export default EditTextModal
