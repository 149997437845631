import HttpClient from './instance'

export const GET_PRODUCTS = async () =>
    HttpClient({
        method: 'get',
        url: `/admin/products`,
    })

export const UPDATE_PRODUCT_RUB_PRICE = async (id, { productCode, newPrice }) =>
    HttpClient({
        method: 'patch',
        url: `/admin/products/${id}/rubPrice`,
        data: {
            productCode,
            newPriceInRub: newPrice,
        },
    })
