import API from 'api'

import { LOCALES_DETAILS } from 'constants/locales'

import { getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'

export const findTranslation = (translations = [], languageCode = 'en', fallbackValue = null) => {
    const res = translations.find(el => el.languageCode === languageCode.toUpperCase())
    return res ? res.text : fallbackValue
}

export const getLocaleDependentTextFieldsStateObject = (fields, obj = {}) => {
    const result = {}
    for (const field of Object.values(fields)) {
        const translations =
            field.translationsFieldKey && !!obj[field.translationsFieldKey]
                ? obj[field.translationsFieldKey].translations
                : null
        result[field.fieldName] = {}
        Object.values(LOCALES_DETAILS)
            .filter(locale => locale.isSupported)
            .forEach(locale => {
                result[field.fieldName][locale.code] = translations
                    ? findTranslation(translations, locale.code, '')
                    : ''
            })
    }
    return result
}
export const addLocaleDependentTextFieldsToValidator = (
    validator,
    { fields, values, excludeFields = {}, requiredLocales },
) => {
    for (const { fieldName } of Object.values(fields)) {
        if (excludeFields[fieldName]) continue
        Object.values(LOCALES_DETAILS)
            .filter(locale => locale.isSupported)
            .forEach(({ code, isRequired }) => {
                const isReq = requiredLocales ? requiredLocales.includes(code) : isRequired
                validator.addFields([
                    validator.createField(
                        [fieldName, code],
                        values[fieldName][code],
                        isReq ? [getIsValueRule(), getIsNotEmptyStringRule()] : [],
                    ),
                ])
            })
    }
}

export const createTranslation = async (fields, isReturnFullInfo = false) => {
    let parentTranslation
    for (const [locale, text] of Object.entries(fields)) {
        if (!text) continue
        if (!parentTranslation) {
            parentTranslation = await API.TRANSLATION.CREATE_TRANSLATION({
                text,
                languageCode: locale,
            })
            continue
        }
        await API.TRANSLATION.UPDATE_TRANSLATION(parentTranslation.id, {
            text,
            languageCode: locale,
        })
    }
    return isReturnFullInfo ? parentTranslation : parentTranslation.id
}
export const updateTranslation = async ({ initialFields, fields, id }) => {
    for (const [locale, oldText] of Object.entries(initialFields)) {
        const newText = fields[locale]
        if (oldText === newText) continue
        if (!newText) {
            await API.TRANSLATION.REMOVE_LOCALE_FROM_TRANSLATION(id, locale)
            continue
        }
        await API.TRANSLATION.UPDATE_TRANSLATION(id, {
            text: newText,
            languageCode: locale,
        })
    }
}
export const getTranslation = async translationId => await API.TRANSLATION.GET_TRANSLATION(translationId)

export function convertToList(translations) {
    const listOfTranslations = []
    for (const [locale, text] of Object.entries(translations)) {
        if (!text) continue

        listOfTranslations.push({
            text,
            languageCode: locale,
        })
    }
    return listOfTranslations
}
