import React, { useEffect, useState } from 'react'

import Modal from 'components/Modal/Modal'
import Button from 'components/Forms/Button/Button'
import Preloader from 'components/Preloader/Preloader'

import styles from './FormModal.module.scss'

const FormModal = ({
    headText = 'Form',
    subHeadText,
    successText = 'Success!',
    actionText = 'Accept',
    cancelText = 'Cancel',
    isForceSuccessClose = false,
    isSuccessAutoClose = true,
    successAutoCloseTime = 1500,
    isDisabledAction = false,
    isCloseOnOverlayClick = false,
    showCancelButton = true,
    showActionButton = true,
    onAction,
    onClose,
    width = 640,
    children,
    isLoading = false,
}) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        let timer = null
        if (isSuccess) {
            if (isForceSuccessClose) {
                onClose()
                return
            }
            if (isSuccessAutoClose) timer = setTimeout(() => onClose(), successAutoCloseTime)
        }
        return () => clearTimeout(timer)
    }, [isSuccess])

    return (
        <Modal isCloseOnOverlayClick={isCloseOnOverlayClick} onClose={onClose}>
            <div className={styles.formModal} style={{ width }}>
                <div className={styles.closeIcon} onClick={() => onClose()}></div>
                {isSuccess ? (
                    <>
                        <div className={styles.successText}>{successText}</div>
                    </>
                ) : (
                    <>
                        <div className={styles.title}>
                            {headText}
                            {subHeadText ? <p>{subHeadText}</p> : null}
                        </div>
                        <div className={styles.body}>{isLoading ? <Preloader /> : children}</div>
                        <div className={styles.footer}>
                            {showCancelButton && (
                                <Button content={cancelText} variant="secondary" onClick={() => onClose()} />
                            )}
                            {showActionButton && (
                                <Button
                                    content={actionText}
                                    variant="primary"
                                    isInverse={true}
                                    isDisabled={isDisabledAction || isProcessing || isLoading}
                                    isLoading={isProcessing}
                                    onClick={async () => {
                                        try {
                                            setIsProcessing(true)
                                            setIsSuccess(false)
                                            const _isSuccess = await onAction()
                                            if (_isSuccess) setIsSuccess(true)
                                        } catch (err) {
                                            console.error(err)
                                        } finally {
                                            setIsProcessing(false)
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default FormModal
