import React from 'react'

import Button from 'components/Forms/Button/Button'

import { MODALS } from '../../constants'

import './TagsList.scss'

const TagsList = ({ list, openModal }) =>
    list.length ? (
        <ul className="tags__list">
            {list.map(tag => (
                <li key={tag.id}>
                    <div className="name">{tag.name}</div>
                    <ul className="actions">
                        <li>
                            <Button
                                content="Edit"
                                variant="primary"
                                isInverse={true}
                                onClick={() => openModal(MODALS.EDIT_TAG, tag)}
                            />
                        </li>
                        <li>
                            <Button
                                content="Remove"
                                variant="danger"
                                isInverse={true}
                                onClick={() => openModal(MODALS.REMOVE_TAG, tag)}
                            />
                        </li>
                    </ul>
                </li>
            ))}
        </ul>
    ) : (
        <div className="tags__empty">Nothing was found</div>
    )

export default TagsList
