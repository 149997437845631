import React, { /*useEffect,*/ useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Upload from 'components/Forms/Upload/Upload'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import ColorPicker from 'components/Forms/ColorPicker/ColorPicker'
import FormBlock from 'components/Forms/FormBlock'
import LocaleFormBlock, { CONTROL_TYPES } from 'components/Forms/components/LocaleFormBlock/LocaleFormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Tags from 'components/Tags/Tags'
// import Select from 'components/Forms/Select/Select'

import {
    fieldsValidator,
    getIsValueRule,
    getIsIntStringRule,
    getIsNotEmptyStringRule,
    getIsHexRule,
} from 'utils/fieldsValidator'
import { setKeyToObject, removeKeyFromObject } from 'utils/common'
import {
    updateTranslation,
    getLocaleDependentTextFieldsStateObject,
    addLocaleDependentTextFieldsToValidator,
    createTranslation,
} from 'utils/translations'
// import { getOptionByTag, getSpecialTagsData, getTagByOption, ROLE_TAGS } from 'utils/tag'

import { DEFAULT_LOCALE } from 'constants/locales'

const LOCALE_DEPENDENT_FIELDS = {
    NAME: {
        fieldName: 'name',
        translationsFieldKey: 'nameTranslation',
    },
    DESCRIPTION: {
        fieldName: 'description',
        translationsFieldKey: 'descriptionTranslation',
    },
}

const EditSectionModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => {
    const { section } = data

    const [isLoading] = useState(false)
    // const [isLoading, setIsLoading] = useState(true)

    const [activeNameLocale, setActiveNameLocale] = useState(DEFAULT_LOCALE)
    const [activeDescriptionLocale, setActiveDescriptionLocale] = useState(DEFAULT_LOCALE)

    const initialLocaleDependentFields = getLocaleDependentTextFieldsStateObject(LOCALE_DEPENDENT_FIELDS, section)

    const [localeDependentFields, setLocaleDependentFields] = useState(initialLocaleDependentFields)

    const [isShowDescription, setIsShowDescription] = useState(!!section.descriptionTranslation)

    const [isEnabled, setIsEnabled] = useState(section.enabled)

    const [icon, setIcon] = useState(null)
    const [iconUrl, setIconUrl] = useState('')
    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [collectionImage, setCollectionImage] = useState(null)
    const [collectionImageUrl, setCollectionImageUrl] = useState('')

    const [color, setColor] = useState(section.color || '')

    const [customId, setCustomId] = useState(section.customId)

    // const [tagsData, setTagsData] = useState({})

    // const [role, setRole] = useState(null)
    const [tags, setTags] = useState(section.tags)

    const [orderNumber, setOrderNumber] = useState(section.orderNumber)

    const [errors, setErrors] = useState({})

    // useEffect(() => {
    //     getSpecialTagsData({ isAddRoleUnsetOption: true })
    //         .then(data => {
    //             setTagsData(data)
    //             const currentRoleTags = section.tags.filter(tag =>
    //                 [ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(tag.name),
    //             )
    //             if (currentRoleTags.length === 0) {
    //                 setRole(data.role.options[0])
    //             } else if (currentRoleTags.length === 1) {
    //                 setRole(getOptionByTag(data.role.options, currentRoleTags[0]))
    //             } else {
    //                 setRole(data.role.options[1])
    //             }
    //             setIsLoading(false)
    //         })
    //         .catch(err => console.error(err))
    // }, [])

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            addLocaleDependentTextFieldsToValidator(validator, {
                fields: LOCALE_DEPENDENT_FIELDS,
                values: localeDependentFields,
                excludeFields: {
                    [LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName]: !isShowDescription,
                },
            })
            validator.addFields([
                validator.createField('orderNumber', String(orderNumber), [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsIntStringRule(),
                ]),
                // validator.createField('role', role, [getIsValueRule()]),
            ])

            if (color) {
                validator.addFields([
                    validator.createField('color', color, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('Field is required'),
                        getIsHexRule(),
                    ]),
                ])
            }

            if (icon) {
                validator.addFields([
                    validator.createField('icon', iconUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }
            if (image) {
                validator.addFields([
                    validator.createField('image', imageUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }
            if (collectionImage) {
                validator.addFields([
                    validator.createField('collectionImage', collectionImageUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await updateTranslation({
                initialFields: initialLocaleDependentFields[LOCALE_DEPENDENT_FIELDS.NAME.fieldName],
                fields: localeDependentFields[LOCALE_DEPENDENT_FIELDS.NAME.fieldName],
                id: section.nameTranslation.id,
            })

            let descriptionTranslation = null
            if (isShowDescription) {
                if (section.descriptionTranslation) {
                    descriptionTranslation = section.descriptionTranslation

                    await updateTranslation({
                        initialFields: initialLocaleDependentFields[LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName],
                        fields: localeDependentFields[LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName],
                        id: descriptionTranslation.id,
                    })
                } else {
                    descriptionTranslation = await createTranslation(
                        localeDependentFields[LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName],
                        true,
                    )
                }
            }

            // let roleTags = []
            // if (role.value !== ROLE_TAGS.UNSET) {
            //     roleTags = getTagByOption(tagsData.role.list, role, {
            //         fallbackValue: tagsData.role.list,
            //         isWrapInArray: true,
            //     })
            // }
            // const otherTags = tags.filter(tag => ![ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(tag.name))

            await onSubmit({
                ...section,
                nameTranslation: section.nameTranslation,
                descriptionTranslation,
                enabled: isEnabled,
                iconUrl: iconUrl || section.iconUrl,
                imageUrl: imageUrl || section.imageUrl,
                collectionImageUrl: collectionImageUrl || section.collectionImageUrl,
                customId,
                color: color || null,
                orderNumber,
                // tags: [...otherTags, ...roleTags],
                tags,
            })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit section"
            subHeadText="Note: If the file field is left blank then will be applied to the old value"
            successText="Section updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
            isLoading={isLoading}
        >
            <LocaleFormBlock
                label="Name"
                errors={errors}
                fields={localeDependentFields}
                fieldName={LOCALE_DEPENDENT_FIELDS.NAME.fieldName}
                activeTabName={activeNameLocale}
                onChangeActiveTabName={v => setActiveNameLocale(v)}
                onChange={data => {
                    setLocaleDependentFields(data.value)
                    setErrors(data.errors)
                }}
            />
            <FormBlock>
                <Toggle
                    value={isShowDescription}
                    label="Show description"
                    onChange={value => {
                        setIsShowDescription(value)
                        setErrors(removeKeyFromObject(errors, 'isShowDescription'))
                    }}
                />
            </FormBlock>
            {isShowDescription && (
                <LocaleFormBlock
                    label="Description"
                    controlType={CONTROL_TYPES.TEXTAREA}
                    errors={errors}
                    fields={localeDependentFields}
                    fieldName={LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName}
                    activeTabName={activeDescriptionLocale}
                    onChangeActiveTabName={v => setActiveDescriptionLocale(v)}
                    onChange={data => {
                        setLocaleDependentFields(data.value)
                        setErrors(data.errors)
                    }}
                />
            )}
            <br />
            <FormBlock>
                <Toggle
                    value={isEnabled}
                    label="Enabled"
                    onChange={value => {
                        setIsEnabled(value)
                        setErrors(removeKeyFromObject(errors, 'isEnabled'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Icon">
                <Upload
                    lastUrl={section.iconUrl}
                    value={icon}
                    error={errors.icon}
                    isUploaded={!!iconUrl}
                    onReset={() => {
                        setIconUrl('')
                        setIcon(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'icon', errorText))
                            return
                        }
                        setIconUrl(url)
                        setErrors(removeKeyFromObject(errors, 'icon'))
                    }}
                    onChange={file => {
                        setIconUrl('')
                        setIcon(file)
                        setErrors(removeKeyFromObject(errors, 'icon'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Image">
                <Upload
                    lastUrl={section.imageUrl}
                    value={image}
                    error={errors.image}
                    isUploaded={!!imageUrl}
                    onReset={() => {
                        setImageUrl('')
                        setImage(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'image', errorText))
                            return
                        }
                        setImageUrl(url)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                    onChange={file => {
                        setImageUrl('')
                        setImage(file)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Collection image">
                <Upload
                    lastUrl={section.collectionImageUrl}
                    value={collectionImage}
                    error={errors.collectionImage}
                    isUploaded={!!collectionImageUrl}
                    onReset={() => {
                        setCollectionImageUrl('')
                        setCollectionImage(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'collectionImage', errorText))
                            return
                        }
                        setCollectionImageUrl(url)
                        setErrors(removeKeyFromObject(errors, 'collectionImage'))
                    }}
                    onChange={file => {
                        setCollectionImageUrl('')
                        setCollectionImage(file)
                        setErrors(removeKeyFromObject(errors, 'collectionImage'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Color">
                <ColorPicker value={color} error={errors.color} onChange={color => setColor(color.hex)} />
            </FormBlock>
            {/*<FormBlock label="Users Role">*/}
            {/*    <Select*/}
            {/*        error={errors.role}*/}
            {/*        value={role}*/}
            {/*        options={tagsData.role?.options}*/}
            {/*        onChange={value => {*/}
            {/*            setRole(value)*/}
            {/*            setErrors(removeKeyFromObject(errors, 'role'))*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</FormBlock>*/}
            <FormBlock label="Tags">
                <Tags
                    isShowRoles
                    isForceSuccessClose={true}
                    selectedTagsList={tags}
                    onUpdate={tags => setTags(tags)}
                    onRemove={({ id }) => setTags(tags.filter(tag => tag.id !== id))}
                />
            </FormBlock>
            <FormBlock label="Custom ID">
                <Input
                    value={customId}
                    error={errors.customId}
                    onChange={value => {
                        setCustomId(value)
                        setErrors(removeKeyFromObject(errors, 'customId'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Order number">
                <Input
                    type="number"
                    min={1}
                    value={orderNumber}
                    error={errors.orderNumber}
                    onChange={value => {
                        setOrderNumber(value)
                        setErrors(removeKeyFromObject(errors, 'orderNumber'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default EditSectionModal
