import React from 'react'
import { Link } from 'react-router-dom'

import Table from 'components/Table/Table'
import Button from 'components/Forms/Button/Button'
import Avatar from 'components/Avatar/Avatar'

import { copyToClipboard } from 'utils/clipboard'
import { getAuthorName } from 'utils/user'
import { getSortKeys } from 'utils/table'
import { getPublicTemplateSubjectLabel, getPublicTemplateGradeLabel } from 'utils/projects'

import ROUTES from 'constants/routes'
import SORT_ORDERS from 'constants/sortOrders'

import iconCopy from 'images/icons/copy.svg'
import editIcon from 'images/icons/edit.svg'
import iconShare from 'images/icons/shareWhite.svg'
import iconProfile from 'images/icons/profile.png'

import { MODALS } from '../../constants'

import styles from './UserContentTable.module.scss'

const getColumns = ({ methods, requestParams }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            getMarks: content => [
                {
                    image: iconCopy,
                    label: 'Copy ID to clipboard',
                    onClick: () => copyToClipboard(content.id),
                },
            ],
            getTitle: content => content.id,
            getValue: content => content.id,
            width: '10%',
        },
        {
            headerLabel: 'Created by',
            getTitle: content => getAuthorName(content.profileInfo),
            getValue: content => (
                <Link
                    to={`${ROUTES.USERS.path}/${content.profileInfo.publicProfileId}`}
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <Avatar
                        initials={getAuthorName(content.profileInfo)}
                        user={content.profileInfo}
                        style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }}
                    />
                    {getAuthorName(content.profileInfo)}
                </Link>
            ),
            width: '10%',
        },
        {
            headerLabel: 'Title',
            getTitle: content => content.title,
            getValue: content => content.title,
            width: '10%',
        },
        {
            headerLabel: 'Description',
            getTitle: content => content.description,
            getValue: content => content.description,
            width: '15%',
        },
        {
            headerLabel: 'Order number',
            getTitle: content => content.orderNumber,
            getValue: content => (
                <div className={styles.orderNumber}>
                    {content.orderNumber}
                    <img
                        title="Edit"
                        src={editIcon}
                        alt="edit"
                        onClick={() => methods.openModal(MODALS.CHANGE_ORDER_NUMBER, { content })}
                    />
                </div>
            ),
            sort: {
                key: 'orderNumber',
                order: sort.key === 'orderNumber' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Views',
            getTitle: template => template.views,
            getValue: template => template.views,
            width: '5%',
        },
        {
            headerLabel: 'Subject',
            getTitle: content => getPublicTemplateSubjectLabel(content.subject),
            getValue: content => getPublicTemplateSubjectLabel(content.subject),
            width: '9%',
        },
        {
            headerLabel: 'Grade',
            getTitle: content => getPublicTemplateGradeLabel(content.grade),
            getValue: content => getPublicTemplateGradeLabel(content.grade),
            width: '8%',
        },
        {
            headerLabel: 'Image',
            getValue: content => (content.previewImageUrl ? <img src={content.previewImageUrl} alt="" /> : null),
            width: '8%',
        },
        {
            valueStyles: { justifyContent: 'flex-end' },
            getValue: content => (
                <>
                    <Button
                        content={<img style={{ width: 18 }} src={iconShare} alt="" />}
                        variant="primary"
                        isInverse={true}
                        onClick={() =>
                            window.open(`${process.env.REACT_APP_SSR_URL}/author-templates/${content.id}`, '_blank')
                        }
                        styles={{ padding: 0, marginRight: 10, width: 36 }}
                    />
                    <Button
                        content={<img style={{ width: 18 }} src={iconProfile} alt="" />}
                        variant="primary"
                        isInverse={true}
                        title="Open public profile page"
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_SSR_URL}/authors/${content.profileInfo.publicProfileId}`,
                                '_blank',
                            )
                        }
                        styles={{ padding: 0, marginRight: 10, width: 36 }}
                    />
                    <Button
                        content="Hide"
                        title="Hide from gallery"
                        variant="danger"
                        isInverse={true}
                        onClick={() => methods.openModal(MODALS.DENY_CONTENT, { content })}
                    />
                </>
            ),
            width: '15%',
        },
    ]
}

const UserContentTable = ({ list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns({ methods, requestParams })}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default UserContentTable
