import React, { useState, useEffect } from 'react'

import throttle from 'utils/throttle'

const createResizeObserver = (handler, throttleWaitTime = 300) => {
    return new ResizeObserver(throttle(handler, throttleWaitTime))
}

const useResize = (refElement, throttleWaitTime) => {
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [boxWidth, setBoxWidth] = useState(0)
    const [boxHeight, setBoxHeight] = useState(0)

    useEffect(() => {
        const resizeObserver = createResizeObserver(([entry]) => {
            setWidth(entry.contentRect.width)
            setHeight(entry.contentRect.height)
            setBoxWidth(entry.borderBoxSize[0].inlineSize)
            setBoxHeight(entry.borderBoxSize[0].blockSize)
        }, throttleWaitTime)

        if (refElement && refElement.current) {
            resizeObserver.observe(refElement.current)
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [])

    return {
        width,
        height,
        boxWidth,
        boxHeight,
    }
}

export default useResize
