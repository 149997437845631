import React, { useState } from 'react'
import classNames from 'classnames'

import { PUBLISH_DESTINATION, PUBLISH_DESTINATION_LABELS } from '../../../../constants'

import Interacty from './Tabs/Interacty/Interacty'
import TeacherContent from './Tabs/TeacherContent/TeacherContent'

import styles from './Sharing.module.scss'

const Sharing = ({ project, onUpdate }) => {
    const [destination, setDestination] = useState(PUBLISH_DESTINATION.INTERACTY)

    return (
        <div className={styles.sharing}>
            <ul className={styles.destinationTabs}>
                {Object.entries(project.system.publishSettings).map(([destinationKey, settings]) => {
                    if (settings.isDisabled || !settings.isActive) return null
                    return (
                        <li
                            onClick={() => setDestination(destinationKey)}
                            className={classNames(styles.destination, {
                                [styles.isActiveDestination]: destinationKey === destination,
                            })}
                        >
                            {PUBLISH_DESTINATION_LABELS[destinationKey]}
                        </li>
                    )
                })}
            </ul>
            {project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].isActive &&
                destination === PUBLISH_DESTINATION.INTERACTY && <Interacty project={project} onUpdate={onUpdate} />}
            {project.system.publishSettings[PUBLISH_DESTINATION.TEACHER_CONTENT].isActive &&
                destination === PUBLISH_DESTINATION.TEACHER_CONTENT && (
                    <TeacherContent project={project} onUpdate={onUpdate} />
                )}
        </div>
    )
}

export default Sharing
