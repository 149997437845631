import React, { useEffect, useState } from 'react'

import Input from 'components/Forms/Input/Input'
import Select from 'components/Forms/Select/Select'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Button from 'components/Forms/Button/Button'
import Preloader from 'components/Preloader/Preloader'
import Tags from 'components/Tags/Tags'

import { fieldsValidator, isIntegerNumber, getIsValueRule, getIsMinRule, getIsMaxRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'
import { getSpecialTagsData, getTagByOption } from 'utils/tag'

import { GPT_MODELS, DEFAULT_BLOCKS_BY_SCENARIOS, MIN_PROJECTS_COUNT, MAX_PROJECTS_COUNT } from '../../constants'

import InputBlock from '../../components/InputBlock/InputBlock'

import Blocks from './components/Blocks/Blocks'

import { generateNewBlock } from './utils'

import styles from './MainSettings.module.scss'

const MainSettings = ({ isDisabled, scenario, onAccept }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [blocks, setBlocks] = useState([])
    const [numberOfProjects, setNumberOfProjects] = useState('1')
    const [model, setModel] = useState({ value: GPT_MODELS.GPT_4, label: GPT_MODELS.GPT_4 })

    const [tagsData, setTagsData] = useState({})

    const [language, setLanguage] = useState(null)
    const [role, setRole] = useState(null)
    const [tags, setTags] = useState([])

    const [errors, setErrors] = useState({})

    useEffect(() => {
        getSpecialTagsData()
            .then(data => {
                setTagsData(data)
                setIsLoading(false)
            })
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        if (!scenario) return
        setBlocks(DEFAULT_BLOCKS_BY_SCENARIOS[scenario].map(blockType => generateNewBlock(blockType)))
    }, [scenario])

    const onAddBlock = blockType => setBlocks(prevState => [...prevState, generateNewBlock(blockType)])

    const onSubmit = () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([
                validator.createField('numberOfProjects', numberOfProjects, [
                    getIsValueRule(),
                    isIntegerNumber(),
                    getIsMinRule(MIN_PROJECTS_COUNT),
                    getIsMaxRule(MAX_PROJECTS_COUNT),
                ]),
                validator.createField('model', model, [getIsValueRule()]),
                validator.createField('language', language, [getIsValueRule()]),
                validator.createField('role', role, [getIsValueRule()]),
            ])

            const { isHasErrors, errors } = validator.validate()

            if (isHasErrors) {
                setErrors(errors)
                return
            }

            onAccept({
                numberOfProjects: Number(numberOfProjects),
                model: model.value,
                blocks: blocks.map(block => block.type),
                languageTemplateTag: getTagByOption(tagsData.language.list, language),
                roleTemplateTag: getTagByOption(tagsData.role.list, role, {
                    fallbackValue: tagsData.role.list,
                    isWrapInArray: true,
                }),
                tags,
            })
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <div className={styles.mainSettings}>
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <Blocks
                        availableBlockTypes={DEFAULT_BLOCKS_BY_SCENARIOS[scenario].filter(
                            blockType => !blocks.find(block => block.type === blockType),
                        )}
                        blocks={blocks}
                        onAddBlock={onAddBlock}
                        onUpdateBlocks={setBlocks}
                    />
                    <div className={styles.options}>
                        <InputBlock title="Number of projects">
                            <Input
                                isWide
                                error={errors.numberOfProjects}
                                value={numberOfProjects}
                                type="number"
                                min={MIN_PROJECTS_COUNT}
                                max={MAX_PROJECTS_COUNT}
                                onChange={v => {
                                    setNumberOfProjects(v)
                                    setErrors(removeKeyFromObject(errors, 'numberOfProjects'))
                                }}
                            />
                        </InputBlock>
                        <InputBlock title="AI Model">
                            <Select
                                error={errors.model}
                                isPortal={false}
                                value={model}
                                options={Object.keys(GPT_MODELS).map(key => ({
                                    value: key,
                                    label: GPT_MODELS[key],
                                }))}
                                onChange={v => {
                                    setModel(v)
                                    setErrors(removeKeyFromObject(errors, 'model'))
                                }}
                            />
                        </InputBlock>
                        <InputBlock title="Language">
                            <Select
                                error={errors.language}
                                value={language}
                                options={tagsData.language?.options}
                                onChange={value => {
                                    setLanguage(value)
                                    setErrors(removeKeyFromObject(errors, 'language'))
                                }}
                            />
                        </InputBlock>
                        <InputBlock title="Users Role">
                            <Select
                                error={errors.role}
                                value={role}
                                options={tagsData.role?.options}
                                onChange={value => {
                                    setRole(value)
                                    setErrors(removeKeyFromObject(errors, 'role'))
                                }}
                            />
                        </InputBlock>
                        <InputBlock title="Tags">
                            <Tags
                                isForceSuccessClose
                                isEnsureRemove={false}
                                selectedTagsList={tags}
                                onUpdate={tags => setTags(tags)}
                                onRemove={({ id }) => setTags(tags.filter(tag => tag.id !== id))}
                            />
                        </InputBlock>
                        {!isDisabled && (
                            <Button variant="primary" onClick={onSubmit}>
                                Next
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default MainSettings
