import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import './Tabs.scss'

const Tabs = ({ list = [], isVisibleBottomLine = true }) => (
    <div className="common__tabs">
        <ul className={`common__tabs__list${isVisibleBottomLine ? ' with-bottom-line' : ''}`}>
            {list
                .filter(item => !!item)
                .map((item, i) => (
                    <li
                        key={i}
                        className={classNames('common__tabs__item', {
                            'common__tabs__item--disabled': item.isDisabled,
                        })}
                    >
                        <NavLink exact activeClassName="is-active" to={item.to}>
                            {item.label}
                        </NavLink>
                        {item.tag && (
                            <span
                                className="common__tabs__item__tag"
                                style={{ backgroundColor: item.tag.backgroundColor }}
                                title={item.tag.title || item.tag.text}
                            >
                                {item.tag.text}
                            </span>
                        )}
                    </li>
                ))}
        </ul>
    </div>
)

export default Tabs
