import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import ROUTES from 'constants/routes'

import { copyToClipboard } from 'utils/clipboard'

import iconCopy from 'images/icons/copy.svg'

import styles from './SubscriptionBlock.module.scss'

const SubscriptionBlock = ({ title, projectId, children }) => {
    const [isVisible, setIsVisible] = useState(false)

    return (
        <div className={styles.subscriptionBlock}>
            <div
                className={classNames(styles.head, { [styles.isVisibleHead]: isVisible })}
                onClick={() => setIsVisible(!isVisible)}
            >
                {title}&nbsp;|&nbsp;
                <div className={styles.headActions}>
                    <Link
                        className={styles.projectLink}
                        title="Click to search in projects"
                        to={`${ROUTES.PROJECTS.path}?id=${projectId}`}
                    >
                        {projectId}
                    </Link>
                    <div
                        className={styles.iconCopy}
                        onClick={evt => {
                            evt.stopPropagation()
                            copyToClipboard(projectId)
                        }}
                    >
                        <img src={iconCopy} alt="copy" />
                    </div>
                </div>
            </div>
            {isVisible && <div className={styles.subscriptionDetails}>{children}</div>}
        </div>
    )
}

export default SubscriptionBlock
