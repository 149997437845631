import axios from 'axios'

const OPEN_AI_BASE_URL = 'https://api.openai.com/v1'
const OPEN_AI_KEY = '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'

const OpenAiHttpClient = axios.create({
    baseURL: OPEN_AI_BASE_URL,
    headers: {
        Authorization: `Bearer ${OPEN_AI_KEY}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
    },
    timeout: 120000,
})
OpenAiHttpClient.interceptors.request.use(
    config => {
        return config
    },
    err => Promise.reject(err),
)
OpenAiHttpClient.interceptors.response.use(
    async res => res.data,
    err => Promise.reject(err),
)

export default OpenAiHttpClient
