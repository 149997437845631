import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import styles from './HamburgerMenu.module.scss'

const HamburgerMenu = ({ list }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.hamburgerMenu}>
            <div className={classNames(styles.icon, styles.hamburgerIcon)} onClick={() => setIsOpen(true)} />

            {isOpen && (
                <div className={styles.hamburgerListRoot}>
                    <div className={styles.hamburgerListScrollContainer}>
                        <div className={classNames(styles.icon, styles.closeIcon)} onClick={() => setIsOpen(false)} />
                        <ul className={styles.list}>
                            {list.map((item, i) => (
                                <li className={styles.item} key={i}>
                                    <NavLink
                                        className={styles.link}
                                        activeClassName={styles.activeLink}
                                        exact
                                        {...item.additionalProps}
                                        to={item.to}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {item.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HamburgerMenu
