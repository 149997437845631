import OpenAiHttpClient from './instance/openai'
import ElevenLabsHttpClient from './instance/elevenlabs'

export const GENERATE_IMAGE = async (prompt = '', { n = 1, size = '1024x1024' } = {}) => {
    const { data } = await OpenAiHttpClient({
        method: 'post',
        url: '/images/generations',
        data: {
            prompt,
            n,
            size,
            response_format: 'b64_json',
        },
    })

    return data[0].b64_json
}

export const GET_CHAT_COMPLETIONS = async (prompt = '', { model = 'gpt-4', role = 'system' } = {}) => {
    const { choices } = await OpenAiHttpClient({
        method: 'post',
        url: '/chat/completions',
        data: {
            model,
            messages: [
                {
                    role,
                    content: prompt,
                },
            ],
            stream: false,
        },
    })

    return choices[0].message.content
}

export const GET_VOICES = async () =>
    ElevenLabsHttpClient({
        method: 'get',
        url: `/voices`,
    })

export const GENERATE_SPEECH = async (
    text = '',
    {
        voiceId = '21m00Tcm4TlvDq8ikWAM',
        modelId = 'eleven_monolingual_v1',
        stability = 0.5,
        similarityBoost = 0.5,
    } = {},
) =>
    ElevenLabsHttpClient({
        method: 'post',
        responseType: 'blob',
        headers: {
            Accept: 'audio/mpeg',
        },
        url: `/text-to-speech/${voiceId}`,
        data: {
            text,
            model_id: modelId,
            voice_settings: {
                stability,
                similarity_boost: similarityBoost,
            },
        },
    })
