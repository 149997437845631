import { BILLING_SYSTEM } from 'constants/subscription'

export const getStatusColor = isActive => {
    if (isActive) return '#65BB5A'
    else return '#FA655C'
}

export const getExpirationColor = isExpired => {
    if (!isExpired) return '#65BB5A'
    else return '#FA655C'
}

export const isCanManageRecurring = ({ billingSystem }) =>
    billingSystem === BILLING_SYSTEM.STRIPE || billingSystem === BILLING_SYSTEM.TWO_CHECKOUT

export const isCanManageExpirationDate = ({ billingSystem }) =>
    billingSystem === BILLING_SYSTEM.INTERNAL || billingSystem === BILLING_SYSTEM.TWO_CHECKOUT
