import React from 'react'
import classNames from 'classnames'

import plusIcon from './i/plus.svg'

import styles from './AddButton.module.scss'

const AddButton = ({ text, className, onClick }) => (
    <div className={classNames(styles.addButton, { [className]: !!className })}>
        <div className={styles.button} onClick={() => onClick()}>
            <img src={plusIcon} alt="" /> {text}
        </div>
    </div>
)

export default AddButton
