export const validate = ({ name, questions }) => {
    let isValid = true
    const errors = {
        global: {
            text: '',
        },
        name: {
            text: '',
        },
        questions: questions.map(question => ({
            text: '',
            answers: question.answers.map(() => ({ text: '' })),
        })),
    }

    if (!name.trim()) {
        errors.name.text = 'Field cannot be empty'
        isValid = false
    }

    if (!questions.length) {
        errors.global.text = 'No questions created'
        isValid = false
    }

    for (const [questionIndex, question] of questions.entries()) {
        if (!question.correctAnswersIndexes.length) {
            errors.questions[questionIndex].text = 'No correct answer provided'
            isValid = false
        } else {
            let isHasRealCorrectAnswer = false
            for (const correctAnswerIndex of question.correctAnswersIndexes) {
                if (question.answers.length > correctAnswerIndex) {
                    isHasRealCorrectAnswer = true
                    break
                }
            }
            if (!isHasRealCorrectAnswer) {
                errors.questions[questionIndex].text = 'No correct answer provided'
                isValid = false
            }
        }

        if (!question.value.trim()) {
            errors.questions[questionIndex].text = 'Field cannot be empty'
            isValid = false
        }
        for (const [answerIndex, answer] of question.answers.entries()) {
            if (!answer.trim()) {
                errors.questions[questionIndex].answers[answerIndex].text = 'Field cannot be empty'
                isValid = false
            }
        }
    }

    return {
        errors,
        isValid,
    }
}
