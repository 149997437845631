import API from 'api'

import { FILE_TYPES, FILE_TYPES_FORMATS, FILE_TYPES_MAX_SIZES } from 'constants/fileTypes'

export const getFormattedFileSizeFromBytes = (bytes = 0, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getUint8Array = async (file, allowEmpty = false) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ({ target: { result } }) => {
            if (!allowEmpty && !result.byteLength) {
                reject('File cannot be empty')
                return
            }
            resolve(new Uint8Array(result))
        }
        reader.onerror = () => reject('Reading file error')
        reader.readAsArrayBuffer(file)
    })

export const getFileDataUrl = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = ({ target }) => resolve(target.result)
        reader.onerror = () => reject('Reading file error')
        reader.readAsDataURL(file)
    })

export function getBlobFromBase64(base64Image) {
    const parts = base64Image.split(';base64,')
    const imageType = parts[0].split(':')[1]
    const decodedData = window.atob(parts[1])
    const uInt8Array = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i)
    }
    return new Blob([uInt8Array], { type: imageType })
}

export const getFileDataUrlFromUrl = url =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('get', url)
        xhr.responseType = 'blob'
        xhr.onload = function () {
            const reader = new FileReader()
            reader.onload = ({ target }) => resolve(target.result)
            reader.onerror = () => reject('Reading file error')
            reader.readAsDataURL(xhr.response)
        }
        xhr.send()
        xhr.onerror = () => reject('Fetching file error')
    })

export const uploadFile = async (
    file,
    maxSize = FILE_TYPES_MAX_SIZES[FILE_TYPES.IMAGE], // 10 MB
    formats = FILE_TYPES_FORMATS[FILE_TYPES.IMAGE],
) => {
    const getSuccessObject = url => ({
        isSuccess: true,
        url,
    })
    const getErrorObject = errorText => ({
        isSuccess: false,
        errorText,
    })

    try {
        if (maxSize && file.size > maxSize) {
            return getErrorObject(`Maximum file size is ${getFormattedFileSizeFromBytes(maxSize)}`)
        }
        if (formats && !formats.includes(file.type)) {
            return getErrorObject('Invalid file type')
        }

        const uint8Array = await getUint8Array(file)

        try {
            const url = await API.PROJECTS.UPLOAD_FILE(`file.${file.type.split('/')[1]}`, file.type, uint8Array)
            return getSuccessObject(url)
        } catch (err) {
            console.error(err)
            return getErrorObject('Cannot upload file to server')
        }
    } catch (err) {
        console.error(err)
        return getErrorObject(String(err))
    }
}
