import React from 'react'
import { cloneDeep } from 'lodash'

import { getUniqueId } from 'utils/common'

import { onGenerateImage } from '../../../../../../api'

import Image from '../../components/Blocks/Image/Image'
import AudioStory from '../../components/Blocks/AudioStory/AudioStory'
import TriviaQuiz from '../../components/Blocks/TriviaQuiz/TriviaQuiz'
import FlipCards from '../../components/Blocks/FlipCards/FlipCards'

const AudioTasks = ({ project, onUpdate }) => (
    <>
        <Image
            imageUrl={project.main.imageUrl}
            onUpdate={v => onUpdate('main.imageUrl', v)}
            onRefresh={async () => {
                const image = await onGenerateImage(project.main.topic)
                onUpdate('main.imageUrl', image)
            }}
        />
        <AudioStory
            story={project.main.story}
            voice={project.main.voice}
            onUpdateStory={v => onUpdate('main.story', v)}
            onUpdateVoice={v => onUpdate('main.voice', v)}
        />
        <TriviaQuiz
            questions={project.main.quiz.questions}
            results={project.main.quiz.results}
            onUpdate={(path, value) => {
                if (!path) return
                onUpdate(`main.quiz.${path}`, value)
            }}
        />
        <FlipCards
            title="Vocabulary"
            items={project.main.vocabulary}
            onAdd={() => {
                const _items = cloneDeep(project.main.vocabulary)
                _items.push({
                    id: getUniqueId(),
                    learning: '',
                    native: '',
                })
                onUpdate('main.vocabulary', _items)
            }}
            onUpdate={(path, value) => {
                if (!path) onUpdate(`main.vocabulary`, value)
                else onUpdate(`main.vocabulary.${path}`, value)
            }}
        />
    </>
)

export default AudioTasks
