import React from 'react'
import classNames from 'classnames'

import crossIcon from 'images/icons/cross.svg'
import arrowLeftIcon from 'images/icons/arrow-left.svg'

import styles from './FormBlockInner.module.scss'

const ActionItem = ({ onClick, title, icon, color = '#2990FB', isSplit = false, imageClassName }) => (
    <div
        className={classNames(styles.actionItem, { [styles.isSplitActionItem]: isSplit })}
        onClick={onClick}
        title={title}
        style={{ backgroundColor: color }}
    >
        <img className={classNames({ [imageClassName]: !!imageClassName })} src={icon} alt={title} />
    </div>
)

const FormBlockInner = ({ children, isShowRemove, isShowUp, isShowDown, onRemove, onUp, onDown }) => (
    <div className={styles.formBlockInner}>
        <div className={styles.children}>{children}</div>
        {(isShowRemove || isShowUp || isShowDown) && (
            <ul className={styles.actions}>
                <li className={classNames(styles.action, styles.splitActions)}>
                    {isShowUp && (
                        <ActionItem
                            title="Up"
                            icon={arrowLeftIcon}
                            onClick={() => onUp()}
                            isSplit={isShowDown}
                            imageClassName={styles.actionUpIcon}
                        />
                    )}
                    {isShowDown && (
                        <ActionItem
                            title="Down"
                            icon={arrowLeftIcon}
                            onClick={() => onDown()}
                            isSplit={isShowUp}
                            imageClassName={styles.actionDownIcon}
                        />
                    )}
                </li>
                {isShowRemove && (
                    <li className={styles.action}>
                        <ActionItem title="Remove" icon={crossIcon} color={'#FA655C'} onClick={() => onRemove()} />
                    </li>
                )}
            </ul>
        )}
    </div>
)

export default FormBlockInner
