import React from 'react'
import ReactJson from 'react-json-view'

import './JsonView.scss'

const JsonView = ({ value, name = null, collapsed = true, theme = 'rjv-default', enableClipboard = true }) => (
    <ReactJson src={value} name={name} collapsed={collapsed} theme={theme} enableClipboard={enableClipboard} />
)

export default JsonView
