import React, { useState } from 'react'
import classNames from 'classnames'
import axios from 'axios'

import Button from 'components/Forms/Button/Button'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import iconArrow from './i/arrow-right.png'

import styles from './SecretMenu.module.scss'

const SecretMenu = ({ isOpen, onClose, user }) => {
    const [isSitemapUpdating, setIsSitemapUpdating] = useState(false)

    const updateSitemap = async () => {
        try {
            setIsSitemapUpdating(true)
            await axios.get(`${process.env.REACT_APP_SSR_URL}/api-proxy/sitemap/update`)
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            setIsSitemapUpdating(false)
        }
    }

    return (
        <div
            className={classNames({
                [styles.secretMenu]: true,
                [styles.secretMenuIsOpened]: isOpen,
            })}
        >
            <div className={styles.closeButton} onClick={() => onClose()}>
                <img src={iconArrow} alt="close" />
            </div>
            <div className={styles.content}>
                <ul>
                    <li className={styles.section}>
                        <div className={styles.sectionTitle}>User info</div>
                        <ul>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>ID</div>
                                <div className={styles.value}>{user.user.id}</div>
                            </li>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>Login</div>
                                <div className={styles.value}>{user.user.login}</div>
                            </li>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>Name</div>
                                <div className={styles.value}>
                                    {user.user.firstName} {user.user.lastName}
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className={styles.section}>
                        <div className={styles.sectionTitle}>Environment variables</div>
                        <ul>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>BACKEND_URL</div>
                                <div className={styles.value}>{process.env.REACT_APP_BACKEND_URL}</div>
                            </li>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>SSR_URL</div>
                                <div className={styles.value}>{process.env.REACT_APP_SSR_URL}</div>
                            </li>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>ACCOUNT_URL</div>
                                <div className={styles.value}>{process.env.REACT_APP_ACCOUNT_URL}</div>
                            </li>
                            <li className={styles.sectionListItem}>
                                <div className={styles.key}>CDN_URL</div>
                                <div className={styles.value}>{process.env.REACT_APP_CDN_URL}</div>
                            </li>
                        </ul>
                    </li>
                    <li className={styles.section}>
                        <div className={styles.sectionTitle}>Actions</div>
                        <ul>
                            <li className={styles.sectionListItem}>
                                <Button
                                    content={
                                        <>
                                            Update <span style={{ fontWeight: 'bold' }}>sitemap.xml</span>
                                        </>
                                    }
                                    isWide
                                    isLoading={isSitemapUpdating}
                                    onClick={() => updateSitemap()}
                                />
                                <p className={styles.buttonActionText}>
                                    This action will cause the SSR site (
                                    <a href={process.env.REACT_APP_SSR_URL}>{process.env.REACT_APP_SSR_URL}</a>) to
                                    forcibly update its sitemap.xlm file
                                </p>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SecretMenu
