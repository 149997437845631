import React, { useEffect, useState } from 'react'

import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'

import { CURRENCIES } from 'constants/currencies'

import { fieldsValidator, getIsIntStringRule, getIsNotEmptyStringRule, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'
import { getCurrencySymbol, getMonthlyPrice, getYearlyFormattedPrice } from 'utils/payments'

import styles from './UpdatePriceModal.module.scss'

const PriceNote = ({ price, currency, billingCycle }) => {
    if (billingCycle !== 12) return null

    return (
        <div className={styles.priceNote}>
            <span className={styles.price}>{getYearlyFormattedPrice(price, currency, 1)}</span>/ year
        </div>
    )
}

const UpdatePriceModal = ({ product, currency, onSubmit, onClose }) => {
    const [initialPrice, setInitialPrice] = useState('')
    const [newPrice, setNewPrice] = useState('')

    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (currency === CURRENCIES.RUB)
            setInitialPrice(getMonthlyPrice(product.purchaseRussianStorePriceInRub, product.billingCycleValue))
        else setInitialPrice(getMonthlyPrice(product.purchasePrice, product.billingCycleValue))
    }, [])

    useEffect(() => {
        setNewPrice(initialPrice)
    }, [initialPrice])

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            validator.addFields([
                validator.createField('newPrice', String(newPrice), [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsIntStringRule(),
                ]),
            ])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const price = product.billingCycleValue === 12 ? newPrice * 12 : newPrice
            await onSubmit(product, currency, price)

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Update Price"
            subHeadText={`${product.name} (${product.productCode})`}
            successText="Price updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label={`Old Price (${getCurrencySymbol(currency)} / Month)`}>
                <PriceNote price={initialPrice} billingCycle={product.billingCycleValue} currency={currency} />
                <Input value={initialPrice} isDisabled isReadOnly />
            </FormBlock>
            <FormBlock label={`New Price (${getCurrencySymbol(currency)} / Month)`}>
                <PriceNote price={newPrice} billingCycle={product.billingCycleValue} currency={currency} />
                <Input
                    value={newPrice}
                    type="number"
                    min={1}
                    error={errors.newPrice}
                    onChange={value => {
                        setNewPrice(value)
                        setErrors(removeKeyFromObject(errors, 'newPrice'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default UpdatePriceModal
