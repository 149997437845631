import HttpClient from './instance'

export const ADD_USER_CONTENT = async id =>
    HttpClient({
        method: 'post',
        url: `/admin/user-content`,
        data: {
            publicContentId: id,
        },
    })
export const UPDATE_USER_CONTENT_ORDER_NUMBER = async (id, orderNumber) =>
    HttpClient({
        method: 'put',
        url: `/admin/user-content/${id}/order`,
        data: {
            orderNumber,
        },
    })
export const DELETE_USER_CONTENT = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/user-content/${id}`,
        data: {},
    })
export const GET_USER_CONTENT = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/user-content`,
        params,
    })
