import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

const AddBlockModal = ({ onSubmit = () => {}, onClose = () => {}, blocks }) => {
    const [block, setBlock] = useState(false)

    const handleSubmit = async () => {
        try {
            await onSubmit(block.value)

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Select block"
            isForceSuccessClose={true}
            actionText="Add"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Block type">
                <Select value={block} options={blocks} onChange={b => setBlock(b)} />
            </FormBlock>
        </FormModal>
    )
}

export default AddBlockModal
