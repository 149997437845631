import React from 'react'
import classNames from 'classnames'

import './Input.scss'

const Input = ({
    type = 'text',
    minLength,
    maxLength,
    min,
    max,
    step,
    value = '',
    error = '',
    notice,
    maxWidth,
    isPaintedBackground = false,
    placeholder = '',
    isDisabled = false,
    isReadOnly = false,
    isClearable = false,
    onClick = () => {},
    onChange = () => {},
    className,
    inputClassName,
    isWide = false,
}) => (
    <div
        className={classNames('common__input', { [className]: !!className })}
        style={{ maxWidth: maxWidth || '100%', width: isWide ? '100%' : 'auto' }}
    >
        <input
            onClick={evt => onClick(evt)}
            className={classNames({
                'is-error': error && error.length,
                'is-clearable': isClearable && value.length,
                'is-painted-background': isPaintedBackground,
                [inputClassName]: !!inputClassName,
            })}
            type={type}
            minLength={minLength}
            maxLength={maxLength}
            min={min}
            max={max}
            step={step}
            disabled={isDisabled}
            readOnly={isReadOnly}
            placeholder={placeholder}
            value={value}
            onChange={evt => onChange(evt.target.value)}
        />
        {isClearable && value.length ? <span className="clear" onClick={() => onChange('')}></span> : null}
        {error && error.length ? <p className="error">{error}</p> : ''}
        {notice ? <p className="notice">{notice}</p> : ''}
    </div>
)

export default Input
