import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `id,${SORT_ORDERS.desc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 30,
        getValue: v => Number(v),
    },
    id: {
        getValue: v => Number(v),
    },
}
