import HttpClient from './instance'

export const GET_USERS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/users',
        params,
    })
export const CREATE_USER = async data =>
    HttpClient({
        method: 'post',
        url: `/admin/users`,
        data,
    })
export const GET_USERS_COUNT = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/users/count`,
        params,
    })
export const GET_PROVIDERS = async () =>
    HttpClient({
        method: 'get',
        url: `/admin/users/providers`,
    })
export const DELETE_ACCOUNT = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/users/${id}`,
    })
export const GET_MOST_ACTIVE_USERS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/users/most-active',
        params,
    })
export const GET_SLEEPING_USERS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/users/sleeping',
        params,
    })
export const GET_ZOMBIE_USERS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/users/zombie',
        params,
    })
export const SET_IS_REAL = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/real`,
        data: {},
    })
export const SET_IS_TEST = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/test`,
        data: {},
    })
export const GET_USER = async id =>
    HttpClient({
        method: 'get',
        url: `/admin/users/${id}`,
    })
export const UPDATE_USER = async (data = {}) =>
    HttpClient({
        method: 'put',
        url: `/admin/users/${data.id}`,
        data,
    })
export const BLOCK_ACCOUNT = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/block`,
        data: {},
    })
export const UNBLOCK_ACCOUNT = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/unblock`,
        data: {},
    })
export const ACTIVATE_ACCOUNT = async id =>
    HttpClient({
        method: 'post',
        url: `/admin/users/${id}/activate`,
    })
export const GET_PAYMENTS = async (userId, params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/users/${userId}/payments`,
        params,
    })
export const CHANGE_SUBSCRIPTION = async (subscriptionId, productCode) =>
    HttpClient({
        method: 'patch',
        url: `/admin/subscriptions/${subscriptionId}/product/${productCode}`,
        data: {},
    })
export const UPDATE_SUBSCRIPTION = async (subscriptionId, updatedProps = {}, isInternalOnly = false) =>
    HttpClient({
        method: 'patch',
        url: `/admin/subscriptions/${subscriptionId}`,
        data: {
            internalChangesOnly: isInternalOnly,
            id: subscriptionId,
            ...updatedProps,
        },
    })
export const UPDATE_CONSTRAINT = async (subscriptionId, featureProductId, data = {}) =>
    HttpClient({
        method: 'put',
        url: `/admin/subscriptions/${subscriptionId}/constraints/${featureProductId}`,
        data,
    })
export const GET_ROLES = async () =>
    HttpClient({
        method: 'get',
        url: `users/roles-in-project`,
    })
export const GET_USER_PUBLIC_PROFILE = userId =>
    HttpClient({
        method: 'get',
        url: `/public-profile/${userId}`,
    })
export const LOCK_ENTERPRISE_PLAN = async ({ userId, productCode }) =>
    HttpClient({
        method: 'post',
        url: `/admin/products/enterprise/lock`,
        data: {
            userId,
            productCode,
        },
    })
export const UNLOCK_ENTERPRISE_PLAN = async ({ userId, productCode }) =>
    HttpClient({
        method: 'post',
        url: `/admin/products/enterprise/unlock`,
        data: {
            userId,
            productCode,
        },
    })

export const GET_ORGANIZATION_LIST = async () =>
    HttpClient({
        method: 'get',
        url: `/organizations`,
    })

export const REFUND_PAYMENT = async (userId, payment) =>
    HttpClient({
        method: 'post',
        url: `/admin/users/${userId}/payments/refund`,
        data: payment,
    })
