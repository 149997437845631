import React from 'react'
import classNames from 'classnames'

import styles from './InputBlock.module.scss'

const InputBlock = ({ title, children, isColumn = false, className }) => (
    <div className={styles.inputBlock}>
        {!!title && <p className={styles.title}>{title}</p>}
        <div className={classNames(styles.input, { [styles.isColumnInput]: isColumn, [className]: !!className })}>
            {children}
        </div>
    </div>
)

export default InputBlock
