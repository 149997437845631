import copy from 'copy-to-clipboard'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

export const copyToClipboard = (value, label = null) => {
    try {
        copy(value)
        Toast(TOAST_TYPE.SUCCESS, { title: label || 'Copied to clipboard' })
    } catch (err) {
        console.error(err)
        Toast(TOAST_TYPE.ERROR)
    }
}
