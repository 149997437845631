import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from 'constants/routes'

import { getUserName } from 'utils/user'

import UsersModal from './components/UsersModal/UsersModal'

import './UserBox.scss'

const UserBox = ({
    title = '',
    note = '',
    data,
    modalEndpoint = () => {},
    modalEndpointRequestParams,
    modalDate,
    getValue = () => '',
}) => {
    const { list, lastResponse } = data
    const [isOpenedUsersModal, setIsOpenedUsersModal] = useState(false)

    return (
        <li className="box">
            <div className="title">
                {title} {lastResponse ? ` (${lastResponse.totalElements})` : ''}
            </div>
            <div className="note">{note}</div>
            {list.length ? (
                <ul className="list">
                    {list.map(user => {
                        return (
                            <li key={user.id} className="item">
                                <p>
                                    <Link to={`${ROUTES.USERS.path}/${user.id}`}>{getUserName(user)}</Link>
                                </p>
                                <span>{getValue(user)}</span>
                            </li>
                        )
                    })}
                </ul>
            ) : (
                <div className="is-empty">No data</div>
            )}
            {lastResponse && !lastResponse.last ? (
                <div className="show-more">
                    <span onClick={() => setIsOpenedUsersModal(true)}>Show more</span>
                </div>
            ) : null}

            {isOpenedUsersModal && (
                <UsersModal
                    title={title}
                    note={note}
                    modalEndpoint={modalEndpoint}
                    modalEndpointRequestParams={modalEndpointRequestParams}
                    modalDate={modalDate}
                    getValue={getValue}
                    onClose={() => setIsOpenedUsersModal(false)}
                />
            )}
        </li>
    )
}

export default UserBox
