export const getProjectStructureForPreview = blockStructure => ({
    app: {
        bg: '#fff',
        maxWidth: 800,
        isTransparentBackground: false,
    },
    pages: [
        {
            id: '6e90ff91',
            name: 'Home',
            blocks: [JSON.parse(blockStructure)],
        },
    ],
    integrations: {},
})
