import qs from 'qs'

import HttpClient from './instance'
import DeeplHttpClient from './instance/deepl'

export const CREATE_TRANSLATION = async ({ text, languageCode }) =>
    HttpClient({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        url: '/admin/translations',
        data: {
            id: null,
            text,
            languageCode,
        },
    })

export const GET_TRANSLATION = async id =>
    HttpClient({
        method: 'get',
        url: `/admin/translations/${id}`,
    })

export const UPDATE_TRANSLATION = async (id, { text, languageCode }) =>
    HttpClient({
        method: 'put',
        url: `/admin/translations/${id}`,
        data: {
            id,
            text,
            languageCode,
        },
    })

export const REMOVE_TRANSLATION = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/translations/${id}`,
    })

export const REMOVE_LOCALE_FROM_TRANSLATION = async (id, languageCode) =>
    HttpClient({
        method: 'delete',
        url: `/admin/translations/${id}/code/${languageCode}`,
    })

export const GET_DEEPL_USAGE_INFO = () =>
    DeeplHttpClient({
        method: 'get',
        url: '/usage',
    })

export const GET_DEEPL_TRANSLATE = async ({ sourceLocale, sourceText, targetLocale }) =>
    (
        await DeeplHttpClient({
            method: 'post',
            url: '/translate',
            data: qs.stringify({
                source_lang: sourceLocale.toUpperCase(),
                target_lang: targetLocale.toUpperCase(),
                text: sourceText,
            }),
        })
    ).translations[0].text
