import React, { useState, useEffect, useRef, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { lock, clearBodyLocks } from 'tua-body-scroll-lock'
import useKeypress from 'react-use-keypress'

import crossIcon from 'images/icons/crossGray.svg'

import './Modal.scss'

const modalListElement = document.getElementById('modal-list')

const Modal = ({ isCloseOnOverlayClick = true, onClose, isShowCross = false, children }) => {
    const [mouseDownTarget, setMouseDownTarget] = useState(null)
    const [mouseUpTarget, setMouseUpTarget] = useState(null)

    const modalRef = useRef(null)
    const modalContainerRef = useRef(null)
    const el = useMemo(() => document.createElement('li'), [])
    el.classList.add('modal-item')

    useEffect(() => {
        modalListElement.appendChild(el)
        lock(modalContainerRef.current)

        return () => {
            if (modalListElement.hasChildNodes()) {
                const _children = modalListElement.childNodes
                _children.forEach(child => {
                    if (child === el) modalListElement.removeChild(el)
                })
            }
            if (modalListElement.childElementCount === 0) clearBodyLocks()
        }
    }, [])

    useEffect(() => {
        if (!isCloseOnOverlayClick) return
        if (!mouseDownTarget || !mouseUpTarget || !modalContainerRef) return
        if (mouseDownTarget !== mouseUpTarget) {
            setMouseUpTarget(null)
            return
        }
        if (mouseUpTarget === modalContainerRef.current) {
            onClose()
        }
    }, [mouseUpTarget])

    useKeypress('Escape', () => {
        if (isCloseOnOverlayClick) onClose()
    })

    return createPortal(
        <div className="modal-wrapper" ref={modalRef}>
            <div
                className="modal-wrapper__container"
                ref={modalContainerRef}
                onMouseDown={evt => setMouseDownTarget(evt.target)}
                onMouseUp={evt => setMouseUpTarget(evt.target)}
            >
                <div className="modal-wrapper__content" onClick={evt => evt.stopPropagation()}>
                    {isShowCross && (
                        <div className="modal-wrapper__content__cross" onClick={() => onClose()}>
                            <img src={crossIcon} alt="close" />
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>,
        el,
    )
}

export default Modal
