import React from 'react'

import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import API from 'api'

import BillingHistoryTable from './components/BillingHistoryTable/BillingHistoryTable'

import { MODALS, MODAL_ACTIONS } from './constants'

class BillingHistoryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            billingHistory: {
                isLoading: false,
                lastResponse: null,
                requestParams: {
                    size: 10,
                    sort: 'saleDate,desc',
                },
                list: [],
            },

            modal: {
                isOpen: false,
                isLoading: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getBillingHistory()
        this.setState({
            isReady: true,
        })
    }

    getBillingHistory = async (page = 0, clear = true) => {
        try {
            const {
                user: { id },
            } = this.props
            const {
                billingHistory: { requestParams },
            } = this.state

            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    isLoading: true,
                },
            }))

            const response = await API.USERS.GET_PAYMENTS(id, {
                ...requestParams,
                page,
            })

            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    lastResponse: response,
                    list: clear ? response.content : [...prevState.billingHistory.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                billingHistory: {
                    ...prevState.billingHistory,
                    isLoading: false,
                },
            }))
        }
    }

    openModal = async (name, payload = {}) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        if (this.state.modal.isLoading) return
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }
    modalAction = async (actionName, payload = {}) => {
        try {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: true,
                },
            }))

            switch (actionName) {
                case MODAL_ACTIONS.REFUND_PAYMENT: {
                    await API.USERS.REFUND_PAYMENT(this.props.user.id, payload)
                    await this.getBillingHistory()
                    break
                }
                default:
                    break
            }
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isSuccess: true,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: false,
                },
            }))
        }
    }

    render() {
        const { userVisibleName } = this.props
        const { isReady, billingHistory, modal } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title={`Users / ${userVisibleName} / Billing History`} />

                {isReady ? (
                    <BillingHistoryTable
                        list={billingHistory.list}
                        pagination={{
                            currentPage: billingHistory.lastResponse.number,
                            totalPages: billingHistory.lastResponse.totalPages,
                            perPageElements: billingHistory.lastResponse.numberOfElements,
                            totalElements: billingHistory.lastResponse.totalElements,
                        }}
                        onExpand={() => this.getBillingHistory(billingHistory.lastResponse.number + 1, false)}
                        onChangePage={page => this.getBillingHistory(page)}
                        isLoading={billingHistory.isLoading}
                        methods={{
                            openModal: this.openModal,
                            modalAction: this.modalAction,
                        }}
                    />
                ) : (
                    <Preloader />
                )}

                {isOpenedModal(MODALS.CONFIRMATION) && (
                    <ConfirmationDialog
                        onClose={() => this.closeModal()}
                        onAction={() => modal.payload.onAction()}
                        {...modal.payload.data}
                    />
                )}
            </>
        )
    }
}

export default BillingHistoryPage
