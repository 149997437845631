import { SIGN_IN, SIGN_OUT } from '../actions/user'


const initialState = {
    isAuth: false,
    data: null,
}

const user = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SIGN_IN:
            state = {
                ...state,
                isAuth: true,
                data: payload,
            }
            break
        case SIGN_OUT:
            state = {
                ...state,
                isAuth: false,
                data: null,
            }
            break
        default:
            break
    }
    return state
}

export default user
