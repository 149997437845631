import React, { useState } from 'react'

import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import Divider from 'components/Forms/Divider/Divider'

import { fieldsValidator, getIsIntStringRule, getIsNotEmptyStringRule, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

const UpdateConstraintModal = ({ data: { subscription, constraint }, onSubmit, onClose }) => {
    const [isUnlimited, setIsUnlimited] = useState(constraint.customPlanLimit === -1 || constraint.planLimit === -1)
    const [isCustomizeLimit, setIsCustomizeLimit] = useState(constraint.customPlanLimit !== 0)
    const [customPlanLimit, setCustomPlanLimit] = useState(constraint.customPlanLimit)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            if (!isUnlimited) {
                validator.addFields([
                    validator.createField('customPlanLimit', String(customPlanLimit), [
                        getIsValueRule(),
                        getIsNotEmptyStringRule(),
                        getIsIntStringRule(),
                    ]),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit(subscription.id, constraint.id, {
                featureId: constraint.featureProduct.id,
                featureProduct: {
                    featureType: constraint.featureProduct.featureType,
                    hasOption: constraint.featureProduct.hasOption,
                    productOptionValue: constraint.featureProduct.productOptionValue,
                },
                currentValue: constraint.currentValue,
                isUnlimited: constraint.unlimited,
                isRefreshable: constraint.refreshable,
                planLimit: constraint.planLimit,
                customPlanLimit: isCustomizeLimit ? (isUnlimited ? -1 : customPlanLimit) : 0,
            })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Update constraint"
            successText="Constraint updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Constraint">
                <Input value={constraint.featureProduct.featureType} isDisabled />
            </FormBlock>
            <FormBlock label="Plan Limit">
                <Input value={constraint.planLimit} isDisabled />
            </FormBlock>
            <FormBlock>
                <Toggle
                    value={isCustomizeLimit}
                    label="Customize limit"
                    onChange={value => setIsCustomizeLimit(value)}
                />
            </FormBlock>
            {isCustomizeLimit && (
                <>
                    <Divider />
                    <FormBlock>
                        <Toggle
                            value={isUnlimited}
                            label="Unlimited"
                            onChange={value => {
                                setIsUnlimited(value)
                                if (!value) setCustomPlanLimit(0)
                            }}
                        />
                    </FormBlock>
                    {!isUnlimited && (
                        <FormBlock label="Custom Limit">
                            <Input
                                value={customPlanLimit}
                                type="number"
                                min={1}
                                error={errors.customPlanLimit}
                                onChange={value => {
                                    setCustomPlanLimit(value)
                                    setErrors(removeKeyFromObject(errors, 'customPlanLimit'))
                                }}
                            />
                        </FormBlock>
                    )}
                </>
            )}
        </FormModal>
    )
}

export default UpdateConstraintModal
