import { useEffect, useState } from 'react'

const useModal = () => {
    const [name, setName] = useState(null)
    const [payload, setPayload] = useState({})

    const onOpen = (_name, _payload = {}) => {
        if (!_name) return

        setPayload(_payload)
        setName(_name)
    }

    const onClose = () => {
        setName(null)
        setPayload({})
    }

    useEffect(() => {}, [name, payload])

    return {
        modalName: name,
        modalPayload: payload,
        onOpenModal: onOpen,
        onCloseModal: onClose,
    }
}

export default useModal
