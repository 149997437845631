import React, { useEffect } from 'react'

import Input from 'components/Forms/Input/Input'
import TextArea from 'components/Forms/TextArea/TextArea'

import InputBlock from '../../../../../../../../components/InputBlock/InputBlock'

import { PUBLISH_DESTINATION } from '../../../../../../constants'

import { onGenerateImage } from '../../../../../../../../api'

import Image from '../../../Blocks/Image/Image'

const Interacty = ({ project, onUpdate }) => {
    useEffect(() => {
        if (!project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].templateImageUrl) {
            onUpdate(
                `system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateImageUrl`,
                project.main.imageUrl,
            )
        }

        if (!project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].templateName) {
            onUpdate(`system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateName`, project.main.topic)
        }
    }, [])

    return (
        <>
            <InputBlock title="Template name">
                <Input
                    isWide
                    value={project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].templateName}
                    onChange={v =>
                        onUpdate(`system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateName`, v)
                    }
                />
            </InputBlock>
            <InputBlock title="Template Description">
                <TextArea
                    isWide
                    value={project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].templateDescription}
                    onChange={v =>
                        onUpdate(`system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateDescription`, v)
                    }
                />
            </InputBlock>
            <InputBlock title="Template Image">
                <Image
                    isHideBorders
                    imageUrl={project.system.publishSettings[PUBLISH_DESTINATION.INTERACTY].templateImageUrl}
                    onUpdate={v =>
                        onUpdate(`system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateImageUrl`, v)
                    }
                    onRefresh={async () => {
                        const image = await onGenerateImage(project.main.topic)
                        onUpdate(`system.publishSettings.[${PUBLISH_DESTINATION.INTERACTY}].templateImageUrl`, image)
                    }}
                />
            </InputBlock>
        </>
    )
}

export default Interacty
