import React from 'react'
import { toast } from 'react-toastify'

import './Toast.scss'

import defaultIconSuccess from './i/success.svg'
import defaultIconError from './i/error.svg'

export const TOAST_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
}

const Body = ({ title, message, icon }) => (
    <div className={`toast-box`}>
        {icon && (
            <div className="toast-box__icon">
                <img src={icon} alt="" />
            </div>
        )}
        {
            <div className={`toast-box__content${!message ? ' toast-box__content--centered' : ''}`}>
                {title && (
                    <div className={`toast-box__title${message ? ' toast-box__title--with-offset' : ''}`}>{title}</div>
                )}
                {message && <div className="toast-box__message">{message}</div>}
            </div>
        }
    </div>
)

const Toast = (type = TOAST_TYPE.SUCCESS, data = {}) => {
    let { title, message, time = 4000, position = 'top-right', icon, progressBar = true } = data

    if (!Object.values(TOAST_TYPE).includes(type)) {
        console.warn(`Invalid toast type. Received: "${type}", allowed: "${Object.values(TOAST_TYPE).join(', ')}"`)
        return
    }

    switch (type) {
        case TOAST_TYPE.SUCCESS: {
            title = title || 'Success!'
            icon = icon || defaultIconSuccess
            break
        }
        case TOAST_TYPE.ERROR: {
            title = title || 'Failed...'
            message = message || 'Looks like there was some kind of mistake. Check dev tools for details'
            icon = icon || defaultIconError
            break
        }
        default:
            break
    }

    toast[type](<Body title={title} message={message} icon={icon} />, {
        autoClose: time,
        position,
        pauseOnHover: true,
        hideProgressBar: !progressBar,
        className: 'toast',
        bodyClassName: 'toast-body',
        progressClassName: 'toast-progress',
    })
}

export default Toast
