import React, { useState } from 'react'

import { getUserName, getUserAvatar } from 'utils/user'

import styles from './Avatar.module.scss'

const Avatar = ({ url, initials, user, style }) => {
    const [_initials] = useState(() => initials || getUserName(user, true))
    const [avatarUrl] = useState(() => url || getUserAvatar(user))

    return (
        <div
            className={styles.avatar}
            style={{
                ...style,
                backgroundImage: avatarUrl ? `url(${avatarUrl}` : 'none',
                backgroundColor: avatarUrl ? '#fff' : '#2990FB',
            }}
        >
            {!avatarUrl && _initials}
        </div>
    )
}

export default Avatar
