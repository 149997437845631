import React, { useState, useEffect } from 'react'

import API from 'api'

import Tags from 'components/Tags/Tags'

const TagsFilter = ({ field, initialValue = '', onChange, isDisabled = false }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [tagsList, setTagsList] = useState([])
    const [selectedTagsList, setSelectedTagsList] = useState([])

    useEffect(() => {
        setIsLoading(true)
        API.TEMPLATES.GET_TAGS()
            .then(tags => {
                setTagsList(tags)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        if (!tagsList.length) return
        const tagNames = initialValue.split(',')
        setSelectedTagsList(tagsList.filter(tag => tagNames.includes(tag.name)))
    }, [tagsList, initialValue])

    return (
        <Tags
            isDisabled={isDisabled || isLoading}
            selectedTagsList={selectedTagsList}
            isCompactView={true}
            onUpdate={tags => {
                setIsLoading(true)
                onChange([
                    {
                        field,
                        value: tags.map(tag => tag.name).join(','),
                    },
                ])
                    .then(() => setSelectedTagsList(tags))
                    .finally(() => setIsLoading(false))
            }}
            onRemove={() => {}}
            isEnsureRemove={false}
            isForceSuccessClose={true}
        />
    )
}

export default TagsFilter
