import axios from 'axios'

const ELEVEN_LABS_BASE_URL = 'https://api.elevenlabs.io/v1'
const ELEVEN_LABS_KEY = 'e24afb4098e113416197514e3da61e4c'

const ElevenLabsHttpClient = axios.create({
    baseURL: ELEVEN_LABS_BASE_URL,
    headers: {
        'xi-api-key': ELEVEN_LABS_KEY,
    },
    timeout: 60000,
})
ElevenLabsHttpClient.interceptors.request.use(
    config => {
        return config
    },
    err => Promise.reject(err),
)
ElevenLabsHttpClient.interceptors.response.use(
    async res => res.data,
    err => Promise.reject(err),
)

export default ElevenLabsHttpClient
