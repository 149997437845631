import React, { useEffect, useState } from 'react'

import { GET_VOICES } from 'api/projectGenerator'

import Select from 'components/Forms/Select/Select'

import AudioButton from './components/AudioButton/AudioButton'

const Voice = ({ value = null, onChange, error }) => {
    const [voice, setVoice] = useState(null)
    const [voices, setVoices] = useState([])

    useEffect(() => {
        GET_VOICES()
            .then(data => setVoices(data.voices))
            .catch(err => console.error(err))
    }, [])

    useEffect(() => {
        const selectedVoice = voices.find(v => v.voice_id === value)
        setVoice(selectedVoice ? { value: selectedVoice.voice_id, label: selectedVoice.name } : null)
    }, [value, voices])

    return (
        <>
            <Select
                isPortal={false}
                value={voice}
                error={error}
                options={voices.map(voice => ({
                    value: voice.voice_id,
                    label: voice.name,
                }))}
                onChange={v => onChange(v.value)}
            />
            {!!value && <AudioButton voiceId={value} voices={voices} />}
        </>
    )
}

export default Voice
