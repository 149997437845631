import React from 'react'

import './Sections.scss'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SectionsTable from './components/SectionsTable/SectionsTable'
import CreateSectionModal from './components/CreateSectionModal/CreateSectionModal'
import EditSectionModal from './components/EditSectionModal/EditSectionModal'
import RemoveSectionModal from './components/RemoveSectionModal/RemoveSectionModal'
import Button from 'components/Forms/Button/Button'
import SEO from 'components/SEO/SEO'
import Preloader from 'components/Preloader/Preloader'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'
import { getSpecialTagsData } from 'utils/tag'

import API from 'api'

import { TABLE_PARAMS, MODALS } from './constants'

class SectionsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            sections: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },

            tagsData: [],

            modal: {
                isOpen: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        const [tagsDataResponse] = await Promise.all([getSpecialTagsData(), this.getSections()])

        this.setState({
            isReady: true,
            tagsData: tagsDataResponse,
        })
    }

    getSections = async (isClear = true) => {
        try {
            const {
                sections: { requestParams },
            } = this.state
            this.setState(prevState => ({
                sections: {
                    ...prevState.sections,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.TEMPLATES.GET_SECTIONS(requestParams)
            await this.setState(prevState => ({
                sections: {
                    ...prevState.sections,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.sections.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                sections: {
                    ...prevState.sections,
                    isLoading: false,
                },
            }))
        }
    }

    openModal = async (name, payload = null) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }

    updateSection = async section => {
        await API.TEMPLATES.UPDATE_SECTION(section)
        await this.getSections()
    }
    createSection = async section => {
        await API.TEMPLATES.CREATE_SECTION(section)
        await this.getSections()
    }
    removeSection = async sectionId => {
        await API.TEMPLATES.REMOVE_SECTION(sectionId)
        await this.getSections()
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            sections: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await this.setState(prevState => ({
            sections: {
                ...prevState.sections,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getSections(isClear)
    }

    render() {
        const { isReady, sections, modal, tagsData } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title="Template gallery / Sections" />

                {isReady ? (
                    <div className="sections">
                        <ul className="top-bar">
                            <li>
                                <Button
                                    content="+ Create section"
                                    variant="primary"
                                    onClick={() => this.openModal(MODALS.CREATE_SECTION)}
                                />
                            </li>
                        </ul>
                        <br />
                        <SectionsTable
                            list={sections.list}
                            payload={{
                                tagsData,
                            }}
                            methods={{
                                updateSection: this.updateSection,
                                openModal: this.openModal,
                                changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                            }}
                            pagination={{
                                currentPage: sections.lastResponse.number,
                                totalPages: sections.lastResponse.totalPages,
                                perPageElements: sections.lastResponse.numberOfElements,
                                totalElements: sections.lastResponse.totalElements,
                            }}
                            requestParams={sections.requestParams}
                            onExpand={() =>
                                this.changeRequestParamsAndRefresh(
                                    [
                                        {
                                            field: 'page',
                                            value: sections.lastResponse.number + 1,
                                        },
                                    ],
                                    false,
                                )
                            }
                            onChangePage={page =>
                                this.changeRequestParamsAndRefresh([
                                    {
                                        field: 'page',
                                        value: page,
                                    },
                                ])
                            }
                            isLoading={sections.isLoading}
                        />

                        {isOpenedModal(MODALS.CREATE_SECTION) && (
                            <CreateSectionModal onSubmit={this.createSection} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODALS.REMOVE_SECTION) && (
                            <RemoveSectionModal
                                data={modal.payload}
                                onSubmit={this.removeSection}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.EDIT_SECTION) && (
                            <EditSectionModal
                                data={modal.payload}
                                onSubmit={this.updateSection}
                                onClose={this.closeModal}
                            />
                        )}
                    </div>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default SectionsPage
