import React from 'react'
import ReactDatepicker from 'react-datepicker'
import moment from 'moment'

import CustomInput from './components/CustomInput/CustomInput'

import './DatePicker.scss'

const DatePicker = ({
    value = null,
    format = 'MMM D, YYYY',
    startDate,
    endDate,
    minDate,
    maxDate,
    minTime,
    maxTime,
    isSelectsStart = false,
    isSelectsEnd = false,
    placeholder = 'Click to select',
    error,
    timeFormat = 'HH:mm',
    popperPlacement = 'auto',
    timeIntervals = 5,
    onChange = () => {},
    isClearable = true,
    isShowTimeSelect = false,
    isShowTodayButton = false,
}) => (
    <div className="common__date-picker">
        <ReactDatepicker
            popperPlacement={popperPlacement}
            showTimeSelect={isShowTimeSelect}
            timeIntervals={timeIntervals}
            selected={value}
            onChange={date => {
                if (isShowTimeSelect) {
                    onChange(moment(date).valueOf())
                    return
                }
                if (isSelectsEnd) {
                    onChange(moment(date).endOf('day').valueOf())
                    return
                }
                onChange(moment(date).startOf('day').valueOf())
            }}
            dateFormat="yyyy-MM-dd HH:mm"
            timeFormat={timeFormat}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            minTime={minTime}
            maxTime={maxTime}
            selectsStart={isSelectsStart}
            selectsEnd={isSelectsEnd}
            todayButton={isShowTodayButton ? 'Today' : null}
            customInput={
                <CustomInput
                    format={format}
                    _placeholder={placeholder}
                    error={error}
                    onClear={() => onChange(null)}
                    isClearable={isClearable}
                />
            }
        />
    </div>
)

export default DatePicker
