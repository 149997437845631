import React from 'react'

import Table from 'components/Table/Table'
import Avatar from 'components/Avatar/Avatar'

import { copyToClipboard } from 'utils/clipboard'

import iconCopy from 'images/icons/copy.svg'

const getColumns = () => [
    {
        headerLabel: 'ID',
        getMarks: organization => [
            {
                image: iconCopy,
                label: 'Copy ID to clipboard',
                onClick: () => copyToClipboard(organization.id),
            },
        ],
        getTitle: organization => organization.id,
        getValue: organization => organization.id,
        width: '20%',
    },
    {
        headerLabel: 'Name',
        getTitle: organization => organization.name,
        getValue: organization => (
            <>
                {!!organization.logoURL?.length && (
                    <Avatar
                        url={organization.logoURL}
                        style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }}
                    />
                )}
                {organization.name}
            </>
        ),
        width: '40%',
    },
    {
        headerLabel: 'Members count',
        getTitle: organization => organization.membersCount,
        getValue: organization => organization.membersCount,
        width: '20%',
    },
    {
        headerLabel: 'Projects count',
        getTitle: organization => organization.projectsCount,
        getValue: organization => organization.projectsCount,
        width: '20%',
    },
]

const OrganizationsTable = ({ title, list }) => (
    <Table isShowCounter={false} columns={getColumns()} title={title} list={list} />
)

export default OrganizationsTable
