import React from 'react'

import iconView from 'images/icons/view.svg'
import iconShare from 'images/icons/share.svg'

import useModalManager from 'hooks/useModalManager'

const ActionsList = ({ project }) => {
    const { openModal, MODAL_NAMES } = useModalManager()

    return (
        <ul className="icon-actions">
            <li
                title="View project"
                onClick={() => openModal(MODAL_NAMES.PROJECT_PREVIEW, project.projectStructureJson)}
            >
                <img src={iconView} alt="" />
            </li>
            {project.url && (
                <li title="Open project in new tab">
                    <a href={project.url} target="_blank" rel="noopener noreferrer">
                        <img src={iconShare} alt="" />
                    </a>
                </li>
            )}
        </ul>
    )
}

export default ActionsList
