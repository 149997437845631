import HttpClient from './instance'

export const GET_SUBSCRIPTION_STATUSES = async () =>
    HttpClient({
        method: 'get',
        url: '/admin/subscriptions/statuses',
    })
export const GET_PRODUCTS = async userId =>
    HttpClient({
        method: 'get',
        url: `/admin/products`,
        params: {
            userId,
        },
    })
export const GET_TASK_STATUS = async taskId =>
    HttpClient({
        method: 'get',
        url: `/admin/tasks/${taskId}`,
    })
