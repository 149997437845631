import React from 'react'

import iconView from 'images/icons/view.svg'
import iconEdit from 'images/icons/edit.svg'

import useModalManager from 'hooks/useModalManager'

import { MODALS } from '../../../../constants'

const ActionsList = ({ template, methods }) => {
    const { openModal, MODAL_NAMES } = useModalManager()

    return (
        <ul className="icon-actions">
            <li
                title="View project"
                onClick={() => openModal(MODAL_NAMES.PROJECT_PREVIEW, template.projectStructureJson)}
            >
                <img src={iconView} alt="" />
            </li>
            <li title="Edit project" onClick={() => methods.openModal(MODALS.EDIT_TEMPLATE, template)}>
                <img src={iconEdit} alt="" />
            </li>
        </ul>
    )
}

export default ActionsList
