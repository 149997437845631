import { cloneDeep, set, unset, get } from 'lodash'

import countryDataset from 'datasets/country.json'
import languageDataset from 'datasets/language.json'

export const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase()

export const encodeOrDecodeJsonStructure = (obj, method) => {
    function forAllStringProperties(o, fn) {
        if (o) {
            Object.keys(o).forEach(k => {
                if (typeof o[k] === 'string') {
                    o[k] = fn(o[k])
                } else if (typeof o[k] === 'object') {
                    forAllStringProperties(o[k], fn)
                }
            })
        }
    }
    const chars = [
        `\b`,
        `\f`,
        `\n`,
        `\r`,
        `\t`,
        `\``,
        `'`,
        `"`,
        `<`,
        `>`,
        '\\\\',
        '\u0000',
        '\u0001',
        '\u0002',
        '\u0003',
        '\u000b',
    ]

    switch (method) {
        case 'encode': {
            let result = cloneDeep(obj)
            forAllStringProperties(result, str => {
                chars.forEach(char => {
                    const reg = new RegExp(char, 'g')
                    str = str.replace(reg, `U+${char.charCodeAt(0)};`)
                })
                return str
            })
            return result
        }
        case 'decode': {
            let result = cloneDeep(obj)
            forAllStringProperties(result, str => {
                chars.forEach(char => {
                    const reg = new RegExp(`U\\+${char.charCodeAt(0)};`, 'g')
                    str = str.replace(reg, char)
                })
                return str
            })
            return result
        }
        default:
            return new Error('Invalid method')
    }
}

export const setKeyToObject = (object, path, value) => {
    const result = cloneDeep(object)
    set(result, path, value)
    return result
}
export const removeKeyFromObject = (object, path) => {
    const result = cloneDeep(object)
    unset(result, path)
    return result
}
export const getKeyFromObject = (object, path, fallbackValue = null) => get(object, path, fallbackValue)

export const getUuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    )
}

export const openUrlInNewTab = url => window.open(url, '_blank')

export const getCountryByCode = code => {
    if (!code) return null
    const el = countryDataset.find(el => el.code === code)
    if (!el) return null
    return {
        value: el.code,
        label: el.name,
    }
}

export const getLanguageByCode = code => {
    if (!code) return null
    const el = languageDataset.find(el => el.code === code)
    if (!el) return null
    return {
        value: el.code,
        label: el.label,
    }
}

export const replaceArrayElementByIndex = (array = [], index = 0, newValue = undefined) => {
    if (typeof array[index] === 'undefined') return array

    const _array = cloneDeep(array)
    _array[index] = newValue

    return _array
}

export const moveInArray = (array, fromIndex, toIndex) => {
    const _array = cloneDeep(array)
    const element = _array[fromIndex]
    _array.splice(fromIndex, 1)
    _array.splice(toIndex, 0, element)
    return _array
}

export const removeFromArrayByIndex = (array, index) => {
    const _array = cloneDeep(array)
    _array.splice(index, 1)
    return _array
}

export const getUniqueId = () => {
    let firstPart = (Math.random() * 46656) | 0
    let secondPart = (Math.random() * 46656) | 0
    firstPart = ('000' + firstPart.toString(36)).slice(-3)
    secondPart = ('000' + secondPart.toString(36)).slice(-3)
    return firstPart + secondPart
}
