import React from 'react'

import { PROJECTS_BLOCK_STATUSES, PROJECTS_STATUSES } from 'constants/projects'

import { copyToClipboard } from 'utils/clipboard'

import useModalManager from 'hooks/useModalManager'

import iconView from 'images/icons/view.svg'
import iconShare from 'images/icons/share.svg'
import iconCode from 'images/icons/code.svg'
import iconEdit from 'images/icons/edit.svg'
import iconUnlock from 'images/icons/unlock.svg'
import iconLock from 'images/icons/lock.svg'
import iconRemove from 'images/icons/rubbish-bin.svg'

import { MODALS, MODAL_ACTIONS } from '../../../../constants'

const ActionsList = ({ project, methods }) => {
    const { openModal, MODAL_NAMES } = useModalManager()

    return (
        <ul className="icon-actions">
            <li
                title="View project"
                onClick={() => openModal(MODAL_NAMES.PROJECT_PREVIEW, project.projectStructureJson)}
            >
                <img src={iconView} alt="" />
            </li>
            {project.url && project.projectStatus !== PROJECTS_STATUSES.DELETED && (
                <li title="Open project in new tab">
                    <a href={project.url} target="_blank" rel="noopener noreferrer">
                        <img src={iconShare} alt="" />
                    </a>
                </li>
            )}
            <li
                title="Copy project structure to clipboard"
                onClick={() => copyToClipboard(JSON.stringify(project.projectStructureJson))}
            >
                <img src={iconCode} alt="" />
            </li>
            <li
                title="Edit project"
                className={`right-offset${project.projectStatus === PROJECTS_STATUSES.DELETED ? ' is-disabled' : ''}`}
                onClick={() => methods.openModal(MODALS.EDIT_PROJECT, project)}
            >
                <img src={iconEdit} alt="" />
            </li>
            {project.projectBlockStatus === PROJECTS_BLOCK_STATUSES.BLOCKED ? (
                <li
                    title="Unblock project"
                    onClick={() =>
                        methods.openModal(MODALS.CONFIRMATION, {
                            data: {
                                noteText: 'Are you sure you want to unblock this project?',
                                actionVariant: 'primary',
                                actionText: 'Unblock',
                                successText: 'Project is unblocked successfully!',
                            },
                            onAction: () => methods.modalAction(MODAL_ACTIONS.UNBLOCK_PROJECT, project),
                        })
                    }
                >
                    <img src={iconUnlock} alt="" />
                </li>
            ) : (
                <li
                    title="Block project"
                    onClick={() =>
                        methods.openModal(MODALS.CONFIRMATION, {
                            data: {
                                noteText: 'Are you sure you want to block this project?',
                                actionVariant: 'danger',
                                actionText: 'Block',
                                successText: 'Project is blocked successfully!',
                            },
                            onAction: () => methods.modalAction(MODAL_ACTIONS.BLOCK_PROJECT, project),
                        })
                    }
                >
                    <img src={iconLock} alt="" />
                </li>
            )}
            <li
                title="Remove project"
                className={project.projectStatus === PROJECTS_STATUSES.DELETED ? 'is-disabled' : ''}
                onClick={() =>
                    methods.openModal(MODALS.CONFIRMATION, {
                        data: {
                            noteText: 'Are you sure you want to remove this project?',
                            targetText: project.name,
                            actionVariant: 'danger',
                            actionText: 'Remove',
                            successText: 'Project is removed successfully!',
                        },
                        onAction: () => methods.modalAction(MODAL_ACTIONS.REMOVE_PROJECT, project),
                    })
                }
            >
                <img src={iconRemove} alt="" />
            </li>
        </ul>
    )
}

export default ActionsList
