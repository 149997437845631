const throttle = (func, delay = 0, isEnsure = true) => {
    let lastCall = Number.NEGATIVE_INFINITY,
        wait,
        handle
    return (...args) => {
        wait = lastCall + delay - Date.now()
        clearTimeout(handle)
        if (wait <= 0 || isEnsure) {
            handle = setTimeout(() => {
                func(...args)
                lastCall = Date.now()
            }, wait)
        }
    }
}

export default throttle
