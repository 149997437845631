import React from 'react'

import SEO from 'components/SEO/SEO'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

import Generator from './components/Generator/Generator'

class ProjectGeneratorPage extends React.Component {
    render() {
        return (
            <>
                <SEO title="Project Generator" />
                <PageTopbar title="Project Generator" />
                <Generator />
            </>
        )
    }
}

export default ProjectGeneratorPage
