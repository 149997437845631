import React from 'react'
import moment from 'moment'

import SORT_ORDERS from 'constants/sortOrders'
import ROUTES from 'constants/routes'
import { PRODUCT_GROUPS } from 'constants/products'
import { BILLING_SYSTEM } from 'constants/subscription'

import Table from 'components/Table/Table'
import InputFilter from 'components/Table/components/Filters/InputFilter'
import SelectFilter from 'components/Table/components/Filters/SelectFilter'
import DateRangeFilter from 'components/Table/components/Filters/DateRangeFilter'
import Avatar from 'components/Avatar/Avatar'

import { copyToClipboard } from 'utils/clipboard'
import { capitalize } from 'utils/common'
import { getSortKeys } from 'utils/table'

import iconCopy from 'images/icons/copy.svg'
import iconSumoLing from 'images/icons/sumoling.svg'

import { PAID_ONLY_PLAN_VALUE } from '../../constants'

import {
    getProviderIcon,
    getStatusColor,
    getStatusText,
    getUserActiveColor,
    getUserTestColor,
    isValidProjectsCount,
} from './utils'

import iconTwocheckout from './i/2checkout.ico'
import iconStripe from './i/stripe.ico'

const getColumns = ({ methods, requestParams, payload }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.id}
                    field="id"
                    type="number"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: user => [
                {
                    image: iconCopy,
                    label: 'Copy ID to clipboard',
                    onClick: () => copyToClipboard(user.id),
                },
            ],
            getTitle: user => user.id,
            getValue: user => user.id,
            width: '5%',
            sort: {
                key: 'id',
                order: sort.key === 'id' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'First Name',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.firstName}
                    field="firstName"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: user => {
                const res = []
                if (user.firstName && user.firstName.length) {
                    res.push({
                        image: iconCopy,
                        label: 'Copy First Name to clipboard',
                        onClick: () => copyToClipboard(user.firstName),
                    })
                }
                return res
            },
            getTitle: user => user.firstName,
            getValue: user => user.firstName,
            width: '6%',
            sort: {
                key: 'firstName',
                order: sort.key === 'firstName' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Last Name',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.lastName}
                    field="lastName"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: user => {
                const res = []
                if (user.lastName && user.lastName.length) {
                    res.push({
                        image: iconCopy,
                        label: 'Copy Last Name to clipboard',
                        onClick: () => copyToClipboard(user.lastName),
                    })
                }
                return res
            },
            getTitle: user => user.lastName,
            getValue: user => user.lastName,
            width: '6%',
            sort: {
                key: 'lastName',
                order: sort.key === 'lastName' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Active',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.isActive}
                    field="isActive"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        {
                            value: true,
                            label: 'Yes',
                        },
                        {
                            value: false,
                            label: 'No',
                        },
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: user => (user.isActive ? 'Yes' : 'No'),
            getValue: user => (
                <span style={{ color: getUserActiveColor(user.isActive) }}>{user.isActive ? 'Yes' : 'No'}</span>
            ),
            sort: {
                key: 'isActive',
                order: sort.key === 'isActive' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '6%',
        },
        {
            headerLabel: 'Test',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.isTest}
                    field="isTest"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        {
                            value: true,
                            label: 'Yes',
                        },
                        {
                            value: false,
                            label: 'No',
                        },
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: user => (user.isTest ? 'Yes' : 'No'),
            getValue: user => (
                <span style={{ color: getUserTestColor(user.isTest) }}>{user.isTest ? 'Yes' : 'No'}</span>
            ),
            sort: {
                key: 'isTest',
                order: sort.key === 'isTest' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '6%',
        },
        {
            headerLabel: 'Email',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.login}
                    field="login"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: user => [
                {
                    image: iconCopy,
                    label: 'Copy Email to clipboard',
                    onClick: () => copyToClipboard(user.login),
                },
                {
                    label: 'Avatar',
                    component: <Avatar user={user} style={{ fontSize: 7, width: 14, height: 14 }} />,
                },
            ],
            getTitle: user => user.login,
            getValue: user => user.login,
            width: '11%',
            sort: {
                key: 'login',
                order: sort.key === 'login' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Group',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.groupId}
                    field="groupId"
                    options={payload.groupsOptions}
                    onChange={v => {
                        methods.changeRequestParamsAndRefresh([
                            ...v,
                            {
                                field: 'roleId',
                                value: null,
                            },
                        ])
                    }}
                />
            ),
            getTitle: user =>
                user.roleInProjectDto ? user.roleInProjectDto.userGroupInProjectDto.groupTranslationDto.text : '',
            getValue: user =>
                user.roleInProjectDto ? user.roleInProjectDto.userGroupInProjectDto.groupTranslationDto.text : '',
            width: '7%',
        },
        {
            headerLabel: 'Role',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.roleId}
                    isDisabled={!requestParams.groupId}
                    field="roleId"
                    options={requestParams.groupId ? payload.rolesInGroupsOptions[requestParams.groupId] : []}
                    placeholder="All"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: user => (user.roleInProjectDto ? user.roleInProjectDto.roleTranslationDto.text : ''),
            getValue: user => (user.roleInProjectDto ? user.roleInProjectDto.roleTranslationDto.text : ''),
            width: '7%',
        },
        {
            headerLabel: 'Provider (REG)',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.initialProvider}
                    field="initialProvider"
                    options={payload.providersOptions}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: user =>
                user.initialProvider === 'local' ? 'Login / Password' : capitalize(user.initialProvider),
            getValue: user => getProviderIcon(user.initialProvider),
            width: '7%',
            sort: {
                key: 'initialProvider',
                order: sort.key === 'initialProvider' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Plan',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.isPaidOnly ? PAID_ONLY_PLAN_VALUE : requestParams.subscriptionPlanId}
                    field="subscriptionPlanId"
                    options={payload.subscriptionPlansOptions}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: user => {
                const res = []
                const selectedSub = user.selectedSubscription
                if (selectedSub && selectedSub.product.group) {
                    if (selectedSub.product.group === PRODUCT_GROUPS.APPSUMO) {
                        res.push({
                            image: iconSumoLing,
                            label: 'User is Sumo-ling',
                        })
                    }
                }
                if (selectedSub && selectedSub.billingSystem === BILLING_SYSTEM.STRIPE) {
                    res.push({
                        image: iconStripe,
                        label: '"Stripe" billing system',
                    })
                }
                if (selectedSub && selectedSub.billingSystem === BILLING_SYSTEM.TWO_CHECKOUT) {
                    res.push({
                        image: iconTwocheckout,
                        label: '"2checkout" billing system',
                    })
                }
                return res
            },
            getTitle: user => (user.selectedSubscription ? user.selectedSubscription.product.name : ''),
            getValue: user => (user.selectedSubscription ? user.selectedSubscription.product.name : ''),
            width: '8%',
        },
        {
            headerLabel: 'Status',
            getTitle: user => (user.selectedSubscription ? user.selectedSubscription.expired : ''),
            getValue: user =>
                user.selectedSubscription?.expirationDate ? (
                    <span style={{ color: getStatusColor(user.selectedSubscription.expired) }}>
                        {getStatusText(user.selectedSubscription.expired, user.selectedSubscription.expirationDate)}
                    </span>
                ) : (
                    ''
                ),
            width: '7%',
        },
        {
            headerLabel: 'Projects (#.#)',
            headerFilter: (
                <InputFilter
                    initialValue={`${requestParams.numberOfProjects ? requestParams.numberOfProjects : ''}${
                        requestParams.numberOfPublishedProjects ? '.' + requestParams.numberOfPublishedProjects : ''
                    }`}
                    validateLength={false}
                    customChange={data => {
                        const v = data[0].value
                        if (v) {
                            if (v.includes('.')) {
                                const arr = v.split('.')
                                if (
                                    arr.length !== 2 ||
                                    !isValidProjectsCount(arr[0], true) ||
                                    !isValidProjectsCount(arr[1], true)
                                )
                                    return 'Invalid format'

                                methods.changeRequestParamsAndRefresh([
                                    {
                                        field: 'numberOfProjects',
                                        value: arr[0].length ? arr[0] : null,
                                    },
                                    {
                                        field: 'numberOfPublishedProjects',
                                        value: arr[1].length ? arr[1] : null,
                                    },
                                ])
                            } else {
                                if (!isValidProjectsCount(v)) return 'Invalid format'

                                methods.changeRequestParamsAndRefresh([
                                    {
                                        field: 'numberOfProjects',
                                        value: v,
                                    },
                                    {
                                        field: 'numberOfPublishedProjects',
                                        value: null,
                                    },
                                ])
                            }
                            return
                        }
                        methods.changeRequestParamsAndRefresh([
                            {
                                field: 'numberOfProjects',
                                value: null,
                            },
                            {
                                field: 'numberOfPublishedProjects',
                                value: null,
                            },
                        ])

                        return null
                    }}
                />
            ),
            getTitle: user =>
                `Number of projects: ${user.numberOfProject || '0'}, published: ${
                    user.numberOfPublishedProject || '0'
                }`,
            getValue: user => `${user.numberOfProject || '0'} (${user.numberOfPublishedProject || '0'})`,
            width: '6%',
        },
        {
            headerLabel: 'Registration Date',
            headerFilter: (
                <DateRangeFilter
                    initialValueFrom={requestParams.from}
                    initialValueTo={requestParams.to}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: user => moment(user.created).format('MMM D, YYYY (HH:mm)'),
            getValue: user => moment(user.created).format('MMM D, YYYY (HH:mm)'),
            sort: {
                key: 'created',
                order: sort.key === 'created' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '18%',
        },
    ]
}

const UsersTable = ({
    title,
    payload,
    list,
    methods,
    requestParams,
    pagination,
    onExpand,
    onChangePage,
    isLoading,
}) => (
    <Table
        columns={getColumns({ methods, requestParams, payload })}
        title={title}
        list={list}
        pagination={pagination}
        getRowClickHref={user => `${ROUTES.USERS.path}/${user.id}`}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default UsersTable
