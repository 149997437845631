import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `created,${SORT_ORDERS.desc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 30,
        getValue: v => Number(v),
    },
    id: {
        getValue: v => Number(v),
    },
    projectName: {},
    projectStatus: {},
    numberOfUsers: {
        getValue: v => Number(v),
    },
    from: {
        getValue: v => Number(v),
    },
    to: {
        getValue: v => Number(v),
    },
}

export const MODALS = {
    CONFIRMATION: 'CONFIRMATION',
    EDIT_PROJECT: 'EDIT_PROJECT',
}

export const MODAL_ACTIONS = {
    BLOCK_PROJECT: 'BLOCK_PROJECT',
    UNBLOCK_PROJECT: 'UNBLOCK_PROJECT',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
}
