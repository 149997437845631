import React from 'react'
import ROUTES from 'constants/routes'

import Table from 'components/Table/Table'
import Avatar from 'components/Avatar/Avatar'

import { copyToClipboard } from 'utils/clipboard'

import iconCopy from 'images/icons/copy.svg'

const getColumns = ({ tableIdLabel }) => [
    {
        headerLabel: tableIdLabel,
        getMarks: user => [
            {
                image: iconCopy,
                label: 'Copy ID to clipboard',
                onClick: () => copyToClipboard(user.id),
            },
        ],
        getTitle: user => user.id,
        getValue: user => user.id,
        width: '10%',
    },
    {
        headerLabel: 'First Name',
        getMarks: user => {
            const res = []
            if (user.firstName && user.firstName.length) {
                res.push({
                    image: iconCopy,
                    label: 'Copy First Name to clipboard',
                    onClick: () => copyToClipboard(user.firstName),
                })
            }
            return res
        },
        getTitle: user => user.firstName,
        getValue: user => user.firstName,
        width: '25%',
    },
    {
        headerLabel: 'Last Name',
        getMarks: user => {
            const res = []
            if (user.lastName && user.lastName.length) {
                res.push({
                    image: iconCopy,
                    label: 'Copy Last Name to clipboard',
                    onClick: () => copyToClipboard(user.lastName),
                })
            }
            return res
        },
        getTitle: user => user.lastName,
        getValue: user => user.lastName,
        width: '25%',
    },
    {
        headerLabel: 'Email',
        getMarks: user => [
            {
                image: iconCopy,
                label: 'Copy Email to clipboard',
                onClick: () => copyToClipboard(user.login),
            },
            {
                label: 'Avatar',
                component: <Avatar user={user} style={{ fontSize: 7, width: 14, height: 14 }} />,
            },
        ],
        getTitle: user => user.login,
        getValue: user => user.login,
        width: '20%',
    },
    {
        headerLabel: 'Country',
        getTitle: ({ country }) => country,
        getValue: ({ country }) => country,
        width: '20%',
    },
]

const UsersTable = ({ title, list, isLoading, userIdKey, tableIdLabel }) => (
    <Table
        columns={getColumns({ tableIdLabel })}
        title={title}
        list={list}
        isShowCounter={false}
        getRowClickHref={user => `${ROUTES.USERS.path}/${user[userIdKey]}`}
        isLoading={isLoading}
    />
)

export default UsersTable
