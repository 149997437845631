import React, { useState } from 'react'
import classNames from 'classnames'

import './Toggle.scss'

const Toggle = ({ value = false, label = '', isDisabled = false, onChange = () => {} }) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className="common__toggle">
            <label
                title={value ? 'On' : 'Off'}
                className={classNames({
                    'is-checked': value,
                    'is-loading': isLoading,
                    'is-disabled': isDisabled,
                })}
            >
                <input
                    type="checkbox"
                    checked={value}
                    disabled={isDisabled || isLoading}
                    onChange={async evt => {
                        try {
                            setIsLoading(true)
                            await onChange(evt.target.checked)
                        } catch (err) {
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                />
                <span></span>
            </label>
            {label.length ? <p>{label}</p> : null}
        </div>
    )
}

export default Toggle
