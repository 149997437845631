import React, { useState, useEffect } from 'react'

import SEO from 'components/SEO/SEO'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import API from 'api'

import { validate } from './utils'
import { MODE, TITLE } from './constants'

import BanksList from './components/BanksList/BanksList'
import EditModal from './components/EditModal/EditModal'
import RemoveBankModal from './components/RemoveBankModal/RemoveBankModal'

const QuestionBanks = () => {
    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isShowEditModal, setIsShowEditModal] = useState(false)
    const [mode, setMode] = useState(null)
    const [bankId, setBankId] = useState(null)
    const [list, setList] = useState([])
    const [isValid, setIsValid] = useState(false)
    const [errors, setErrors] = useState({})
    const [_name, setName] = useState('')
    const [_questions, setQuestions] = useState([])
    const [removedQuestionsIds, setRemovedQuestionsIds] = useState([])
    const [bankToDelete, setBankToDelete] = useState(null)

    useEffect(() => {
        getBanks()
            .then(() => {})
            .catch(() => {})
            .finally(() => setIsReady(true))
    }, [])

    const getBanks = async () => {
        try {
            setIsLoading(true)
            const { content } = await API.QUESTION_BANKS.GET_BANKS()
            setList(content)
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const validationResult = validate({
            name: _name,
            questions: _questions,
        })
        setErrors(validationResult.errors)
        setIsValid(validationResult.isValid)
    }, [_name, _questions])

    const onOpenEditModal = (name, questions, mode, bankId = null) => {
        setIsShowEditModal(true)
        setMode(mode)
        setName(name)
        setQuestions(questions)
        setBankId(bankId)
        setRemovedQuestionsIds([])
    }
    const onCloseEditModal = () => {
        setIsShowEditModal(false)
        setMode(mode)
        setName('')
        setQuestions([])
        setBankId(null)
        setRemovedQuestionsIds([])
    }

    const onSave = async () => {
        if (mode === MODE.CREATE) await onCreate()
        if (mode === MODE.EDIT) await onEdit()
    }
    const onDelete = async () => {
        try {
            setIsLoading(true)
            await API.QUESTION_BANKS.DELETE_BANK(bankToDelete.bankId)
            await getBanks()
            setBankToDelete(null)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }
    const onParse = async file => {
        try {
            setIsLoading(true)
            const { questions } = await API.QUESTION_BANKS.PARSE_FILE(file)
            onOpenEditModal('My question bank', questions, MODE.CREATE)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onCreate = async () => {
        try {
            setIsLoading(true)
            await API.QUESTION_BANKS.CREATE_BANK(_name.trim(), _questions)
            onCloseEditModal()
            await getBanks()
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onEdit = async () => {
        try {
            setIsLoading(true)
            await API.QUESTION_BANKS.UPDATE_BANK_QUESTIONS(bankId, _name.trim(), _questions, removedQuestionsIds)
            onCloseEditModal()
            await getBanks()
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onNew = async () => {
        try {
            setIsLoading(true)
            // const { questions } = []
            onOpenEditModal('My question bank', [], MODE.CREATE)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }
    const onOpen = async bank => {
        try {
            setIsLoading(true)
            const { content } = await API.QUESTION_BANKS.GET_BANK_QUESTIONS(bank.bankId)
            onOpenEditModal(bank.name, content, MODE.EDIT, bank.bankId)
        } catch (err) {
            console.error(err)
            Toast('error', {})
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <SEO title="Assets / Question banks" />

            {isReady ? (
                <>
                    <BanksList
                        list={list}
                        onOpen={bank => onOpen(bank)}
                        onRemove={bank => setBankToDelete(bank)}
                        onNew={() => onNew()}
                        onParse={file => onParse(file)}
                        isLoading={isLoading}
                    />

                    {isShowEditModal && (
                        <EditModal
                            mode={mode}
                            title={mode === MODE.VIEW ? _name : TITLE[mode]}
                            errors={errors}
                            isValid={isValid}
                            data={{ questions: _questions, name: _name }}
                            onUpdate={(_q, removed) => {
                                setQuestions(_q)
                                if (removed) {
                                    const arr = removed.filter(el => !!el.id).map(el => el.id)
                                    if (arr.length) setRemovedQuestionsIds([...removedQuestionsIds, ...arr])
                                }
                            }}
                            onSave={onSave}
                            isLoading={isLoading}
                            onChangeName={v => setName(v)}
                            onClose={() => onCloseEditModal()}
                        />
                    )}

                    {bankToDelete && (
                        <RemoveBankModal
                            data={bankToDelete}
                            onSubmit={onDelete}
                            onClose={() => setBankToDelete(null)}
                        />
                    )}
                </>
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default QuestionBanks
