import LOCALES, { LOCALES_DETAILS } from 'constants/locales'

import { capitalize } from 'utils/common'

import API from 'api'

export const LANGUAGE_TAG_PREFIX = 'template_language_'
export const LANGUAGE_LOCALE_ANY = 'ANY'

export const ROLE_TAGS = {
    BUSINESS: 'business',
    EDUCATION: 'education',
    ALL: 'all',
    UNSET: 'unset',
}

export const getLanguageTagLocaleDetails = tag => {
    if (tag && tag.name.startsWith(LANGUAGE_TAG_PREFIX)) {
        const localeCode = tag.name.split(LANGUAGE_TAG_PREFIX)[1].toUpperCase()
        if (LOCALES[localeCode]) return LOCALES_DETAILS[localeCode]
    }

    return null
}

export const getSpecialTagsData = async ({ isAddRoleUnsetOption = false } = {}) => {
    const tags = await API.TEMPLATES.GET_TAGS()

    const languageTags = tags.filter(_tag => _tag.name.startsWith(LANGUAGE_TAG_PREFIX))
    const languageTagsOptions = []
    let languageAnyTag = null

    languageTags.forEach(_tag => {
        const locale = _tag.name.split(LANGUAGE_TAG_PREFIX)[1].toUpperCase()
        const label = LOCALES_DETAILS[locale] ? LOCALES_DETAILS[locale].engLabel : capitalize(locale)
        const icon = _tag.iconUrl || (LOCALES_DETAILS[locale] ? LOCALES_DETAILS[locale].icon : null)

        const data = {
            value: _tag.name,
            label,
            icon,
        }

        if (locale === LANGUAGE_LOCALE_ANY) {
            languageAnyTag = data
            return
        }

        languageTagsOptions.push(data)
    })
    languageTagsOptions.sort((a, b) => {
        if (a.label < b.label) return -1
        if (a.label > b.label) return 1
        return 0
    })
    if (languageAnyTag) languageTagsOptions.unshift(languageAnyTag)

    const roleTags = tags.filter(_tag => [ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(_tag.name))
    const roleTagsOptions = [
        {
            value: ROLE_TAGS.ALL,
            label: capitalize(ROLE_TAGS.ALL),
        },
        ...roleTags.map(_tag => {
            return {
                value: _tag.name,
                label: capitalize(_tag.name),
            }
        }),
    ]
    if (isAddRoleUnsetOption) {
        roleTagsOptions.unshift({
            value: ROLE_TAGS.UNSET,
            label: capitalize(ROLE_TAGS.UNSET),
        })
    }

    return {
        language: {
            list: languageTags,
            options: languageTagsOptions,
        },
        role: {
            list: roleTags,
            options: roleTagsOptions,
        },
    }
}

export const getTagByOption = (list, option, { fallbackValue = null, isWrapInArray = false } = {}) => {
    if (!option) return fallbackValue
    const tag = list.find(_tag => _tag.name === option.value)
    if (tag) return isWrapInArray ? [tag] : tag
    return fallbackValue
}

export const getOptionByTag = (options, tag) => {
    if (!tag) return null
    const option = options.find(_tag => _tag.value === tag.name)
    return option || null
}
