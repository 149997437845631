import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `orderNumber,${SORT_ORDERS.asc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 20,
        getValue: v => Number(v),
    },
    tags: {},
}

export const MODALS = {
    CREATE_SECTION: 'CREATE_SECTION',
    REMOVE_SECTION: 'REMOVE_SECTION',
    EDIT_SECTION: 'EDIT_SECTION',
}
