import React, { Fragment, useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'

import { LOCALES_DETAILS, DEFAULT_LOCALE, getLocaleDetailsByCode } from 'constants/locales'

import FormTabs from 'components/Forms/FormTabs'
import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'
import TextArea from 'components/Forms/TextArea/TextArea'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import { getKeyFromObject, removeKeyFromObject, setKeyToObject } from 'utils/common'

import useTranslator from 'hooks/useTranslator'

import TranslateButton from './components/TranslateButton/TranslateButton'

import styles from './LocaleFormBlock.module.scss'

export const CONTROL_TYPES = {
    INPUT: 'INPUT',
    TEXTAREA: 'TEXTAREA',
}

const CONTROLS = {
    [CONTROL_TYPES.INPUT]: Input,
    [CONTROL_TYPES.TEXTAREA]: TextArea,
}

const LocaleFormBlock = ({
    label,
    controlType = CONTROL_TYPES.INPUT,
    errors,
    fields,
    fieldName,
    activeTabName,
    onChangeActiveTabName,
    onChange,
    requiredLocales,
}) => {
    const { isReady, isLoading, getTranslation } = useTranslator()
    const [missingLocales, setMissingLocales] = useState([])

    useEffect(() => {
        const result = []
        for (const [locale, value] of Object.entries(fields[fieldName])) {
            if (!value) result.push(locale)
        }
        setMissingLocales(result)
    }, [fields])

    const Control = CONTROLS[controlType]

    const onUpdate = (value, locale) => {
        onChange({
            value: setKeyToObject(fields, [fieldName, locale], value),
            errors: removeKeyFromObject(errors, [fieldName, locale]),
        })
    }

    const onUpdateMany = translations => {
        const result = {
            value: cloneDeep(fields),
            errors: cloneDeep(errors),
        }
        for (const translation of translations) {
            result.value = setKeyToObject(result.value, [fieldName, translation.code], translation.value)
            result.errors = removeKeyFromObject(result.errors, [fieldName, translation.code])
        }

        onChange(result)
    }

    return (
        <FormBlock label={label}>
            <FormTabs
                items={Object.values(LOCALES_DETAILS)
                    .filter(locale => locale.isSupported)
                    .map(locale => ({
                        isRequired: requiredLocales ? requiredLocales.includes(locale.code) : locale.isRequired,
                        isFilled: !!fields[fieldName][locale.code],
                        title: locale.label,
                        value: locale.code,
                        icon: locale.icon,
                        label: <b>{locale.code}</b>,
                        isError: !!getKeyFromObject(errors, [fieldName, locale.code]),
                    }))}
                activeValue={activeTabName}
                onChangeTab={v => onChangeActiveTabName(v)}
            />
            {Object.values(LOCALES_DETAILS)
                .filter(locale => locale.isSupported)
                .map(locale => {
                    if (activeTabName !== locale.code) return null

                    const localeDetails = getLocaleDetailsByCode(locale.code)

                    return (
                        <Fragment key={locale.code}>
                            <div className={styles.localeInfo}>
                                {!!localeDetails?.icon && (
                                    <div className={styles.localeIcon}>
                                        <img src={localeDetails.icon} alt={localeDetails.label} />
                                    </div>
                                )}
                                <p className={styles.localeLabel}>{localeDetails.label}</p>
                            </div>
                            <div className={styles.localeWrapper}>
                                <div className={styles.controlWrapper}>
                                    <Control
                                        value={fields[fieldName][locale.code]}
                                        error={getKeyFromObject(errors, [fieldName, locale.code])}
                                        onChange={value => onUpdate(value, locale.code)}
                                    />
                                </div>
                                {fields[fieldName][DEFAULT_LOCALE] && activeTabName !== DEFAULT_LOCALE && isReady && (
                                    <TranslateButton
                                        title={`Translate from "EN" to "${locale.code}"`}
                                        isLoading={isLoading}
                                        onClick={async () => {
                                            try {
                                                const translation = await getTranslation({
                                                    sourceText: fields[fieldName][DEFAULT_LOCALE],
                                                    sourceLocale: DEFAULT_LOCALE,
                                                    targetLocale: locale.code,
                                                })
                                                onUpdate(translation, locale.code)
                                            } catch (err) {
                                                console.error(err)
                                                Toast(TOAST_TYPE.ERROR)
                                            }
                                        }}
                                    />
                                )}
                                {fields[fieldName][DEFAULT_LOCALE] &&
                                    activeTabName === DEFAULT_LOCALE &&
                                    !!missingLocales.length &&
                                    isReady && (
                                        <TranslateButton
                                            title={`Translate to "${missingLocales.join(', ')}"`}
                                            isLoading={isLoading}
                                            onClick={async () => {
                                                try {
                                                    const translations = []
                                                    for (const missingLocaleCode of missingLocales) {
                                                        const translation = await getTranslation({
                                                            sourceText: fields[fieldName][DEFAULT_LOCALE],
                                                            sourceLocale: DEFAULT_LOCALE,
                                                            targetLocale: missingLocaleCode,
                                                        })
                                                        translations.push({
                                                            code: missingLocaleCode,
                                                            value: translation,
                                                        })
                                                    }
                                                    onUpdateMany(translations)
                                                } catch (err) {
                                                    console.error(err)
                                                    Toast(TOAST_TYPE.ERROR)
                                                }
                                            }}
                                        />
                                    )}
                            </div>
                        </Fragment>
                    )
                })}
        </FormBlock>
    )
}

export default LocaleFormBlock
