import React from 'react'

import styles from './InterestsTags.module.scss'

const InterestsTags = ({ tags = [] }) => (
    <div className={styles.interestsTags}>
        <h3 className={styles.title}>Interests Tags</h3>
        {tags?.length ? (
            <ul className={styles.list}>
                {tags.map(tag => (
                    <li key={tag.id} className={styles.item}>
                        {!!tag.iconUrl && (
                            <div className={styles.icon} style={{ backgroundImage: `url(${tag.iconUrl})` }} />
                        )}
                        <p className={styles.label}>{tag.name}</p>
                    </li>
                ))}
            </ul>
        ) : (
            <div className={styles.tagsNotFound}>No tags specified</div>
        )}
    </div>
)

export default InterestsTags
