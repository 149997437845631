import React, { useState, useEffect } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { TYPES, PRODUCT_LIST } from 'constants/subscription'

import API from 'api'

import SubscriptionDetails from './components/SubscriptionDetails/SubscriptionDetails'
import SubscriptionBlock from './components/SubscriptionBlock/SubscriptionBlock'
import ChangeSubscriptionModal from './components/ChangeSubscriptionModal/ChangeSubscriptionModal'
import InternalChangeSubscriptionDataModal from './components/InternalChangeSubscriptionDataModal/InternalChangeSubscriptionDataModal'
import UpdateConstraintModal from './components/UpdateConstraintModal/UpdateConstraintModal'

import { MODALS } from './constants'

import './Subscription.scss'

const SubscriptionPage = ({ user, userVisibleName, methods }) => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [modalName, setModalName] = useState('')
    const [modalPayload, setModalPayload] = useState(null)

    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [projectSubscriptions, setProjectSubscriptions] = useState([])

    useEffect(() => {
        const { selectedSubscription, subscriptions } = user

        setSelectedSubscription(selectedSubscription)

        const preparedSubscriptions = subscriptions
            ? subscriptions.filter(
                  sub => (!sub.expired && sub.product.productCode !== PRODUCT_LIST.Free) || sub.selected,
              )
            : []
        const hasSeveralActiveSubs = preparedSubscriptions.length > 1
        const subscriptionsList = preparedSubscriptions.filter(
            sub =>
                (hasSeveralActiveSubs && sub.product.productCode !== PRODUCT_LIST.Trial) ||
                (!hasSeveralActiveSubs && sub.product.productCode === PRODUCT_LIST.Trial) ||
                sub.selected,
        )
        const projectSubscriptionsList = subscriptionsList
            .filter(subscription => subscription.type === TYPES.PROJECT && subscription.selected)
            .sort((a, b) => a.started - b.started)

        setProjectSubscriptions(projectSubscriptionsList)
    }, [user])

    const changeSubscription = async (subscriptionId, productCode) => {
        await API.USERS.CHANGE_SUBSCRIPTION(subscriptionId, productCode)
        const { getUser } = methods
        await getUser()
    }

    const updateSubscription = async (subscription, updatedProps, isInternalOnly) => {
        await API.USERS.UPDATE_SUBSCRIPTION(subscription.id, updatedProps, isInternalOnly)
        const { getUser } = methods
        await getUser()
        Toast(TOAST_TYPE.SUCCESS, { message: 'Subscription updated successfully' })
    }

    const updateConstraint = async (subscriptionId, featureProductId, data) => {
        await API.USERS.UPDATE_CONSTRAINT(subscriptionId, featureProductId, data)
        const { getUser } = methods
        await getUser()
        Toast(TOAST_TYPE.SUCCESS, { message: 'Constraint updated successfully' })
    }

    const openModal = async (name, payload = {}) => {
        setIsShowModal(true)
        setModalName(name)
        setModalPayload(payload)
    }
    const closeModal = async () => {
        setIsShowModal(false)
        setModalName('')
        setModalPayload(null)
    }

    const isOpenedModal = name => isShowModal && modalName === name

    return (
        <>
            <SEO title={`Users / ${userVisibleName} / Subscription`} />

            {selectedSubscription ? (
                <>
                    <SubscriptionDetails
                        title="Main subscription"
                        user={user}
                        subscription={selectedSubscription}
                        methods={{
                            openModal,
                            updateSubscription,
                            updateUser: methods.getUser,
                        }}
                    />
                </>
            ) : (
                <div className="not-have-subscription">User don't have main subscription</div>
            )}

            {!!projectSubscriptions.length ? (
                <ul className="subscriptions-list">
                    <div className="subscriptions-list__title">Projects subscriptions</div>
                    {projectSubscriptions.map(subscription => (
                        <SubscriptionBlock title={subscription.product.name} projectId={subscription.projectId}>
                            <SubscriptionDetails
                                title="Project subscription"
                                subscription={subscription}
                                methods={{
                                    openModal,
                                    updateSubscription,
                                }}
                                isHideChangeSubscription
                            />
                        </SubscriptionBlock>
                    ))}
                </ul>
            ) : (
                <div className="not-have-subscription">User don't have projects subscriptions</div>
            )}

            {isOpenedModal(MODALS.CHANGE_SUBSCRIPTION) && (
                <ChangeSubscriptionModal
                    userId={user.id}
                    subscription={modalPayload}
                    onSubmit={changeSubscription}
                    onClose={closeModal}
                />
            )}
            {isOpenedModal(MODALS.CONFIRMATION) && <ConfirmationDialog onClose={closeModal} {...modalPayload} />}
            {isOpenedModal(MODALS.INTERNAL_CHANGE_SUBSCRIPTION_DATA) && (
                <InternalChangeSubscriptionDataModal
                    subscription={modalPayload}
                    onSubmit={updateSubscription}
                    onClose={closeModal}
                />
            )}
            {isOpenedModal(MODALS.UPDATE_CONSTRAINT) && (
                <UpdateConstraintModal data={modalPayload} onSubmit={updateConstraint} onClose={closeModal} />
            )}
        </>
    )
}

export default SubscriptionPage
