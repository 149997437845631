import React, { useEffect, useState } from 'react'

import Button from 'components/Forms/Button/Button'

import Workplace from './components/Workplace/Workplace'

import styles from './SetupScenario.module.scss'

const SetupScenario = ({ isDisabled, mainSettings, schema, onAccept }) => {
    const [isReady, setIsReady] = useState(false)

    const [data, setData] = useState({})
    const [topics, setTopics] = useState([])

    useEffect(() => {
        setIsReady(!!data && topics.length === mainSettings.numberOfProjects)
    }, [data, topics])

    const onSubmit = () => {
        onAccept({
            data,
            topics,
        })
    }

    return (
        <div className={styles.createTopics}>
            <Workplace
                isTopicsFilled={topics.length >= mainSettings.numberOfProjects}
                mainSettings={mainSettings}
                schema={schema}
                onUpdateTopics={v => setTopics(v)}
                onUpdateData={v => setData(v)}
            />
            {!isDisabled && (
                <div className={styles.buttonBox}>
                    <Button variant="primary" onClick={onSubmit} isDisabled={!isReady}>
                        Generate projects
                    </Button>
                </div>
            )}
        </div>
    )
}

export default SetupScenario
