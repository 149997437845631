import React, { useState } from 'react'

import DateRangePicker from 'components/Forms/DatePicker/DateRangePicker'

const DateRangeFilter = ({
    initialValueFrom = null,
    initialValueTo = null,
    fieldFrom = 'from',
    fieldTo = 'to',
    onChange,
}) => {
    const [startDate, setStartDate] = useState(initialValueFrom)
    const [endDate, setEndDate] = useState(initialValueTo)

    const minDate = 1546300800000
    const maxDate = Date.now()

    return (
        <DateRangePicker
            valueStart={startDate}
            valueEnd={endDate}
            onChangeStart={async date => {
                setStartDate(date)
                await onChange([
                    {
                        field: fieldFrom,
                        value: date,
                    },
                    {
                        field: fieldTo,
                        value: endDate,
                    },
                ])
            }}
            onChangeEnd={async date => {
                setEndDate(date)
                await onChange([
                    {
                        field: fieldFrom,
                        value: startDate,
                    },
                    {
                        field: fieldTo,
                        value: date,
                    },
                ])
            }}
            minDate={minDate}
            maxDate={maxDate}
        />
    )
}

export default DateRangeFilter
