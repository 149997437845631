import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MODALS } from 'components/ModalManager/constants'

import { setModal } from 'store/actions/modalManager'

const useModalManager = () => {
    const dispatch = useDispatch()
    const { modalManager } = useSelector(state => state)

    const openModal = (name, payload) => dispatch(setModal(name, payload))
    const closeModal = () => dispatch(setModal())

    return { modalManager, openModal, closeModal, MODAL_NAMES: MODALS }
}

export default useModalManager
