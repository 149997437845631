import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import Table from 'components/Table/Table'

import SORT_ORDERS from 'constants/sortOrders'
import ROUTES from 'constants/routes'
import { PROJECTS_BLOCK_STATUSES } from 'constants/projects'

import { copyToClipboard } from 'utils/clipboard'
import { getSortKeys } from 'utils/table'

import { getProjectStatusColor } from './utils'

import EmbeddedList from './components/EmbeddedList/EmbeddedList'
import ActionsList from './components/ActionsList/ActionsList'

import iconPremium from 'images/icons/premium.svg'
import iconLock from 'images/icons/lock.svg'
import iconCopy from 'images/icons/copy.svg'
import iconGroup from 'images/icons/group.png'

const getColumns = ({ methods, requestParams, history }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            getMarks: project => {
                const arr = [
                    {
                        image: iconCopy,
                        label: 'Copy ID to clipboard',
                        onClick: () => copyToClipboard(project.id),
                    },
                    {
                        image: iconGroup,
                        label: 'Open organization',
                        onClick: () => history.push(`${ROUTES.ORGANIZATIONS.path}/${project.organizationId}`),
                    },
                ]
                if (project.isPremium) {
                    arr.push({
                        image: iconPremium,
                        label: 'Project created from premium template',
                    })
                }
                return arr
            },
            getTitle: project => project.id,
            getValue: project => project.id,
            width: '8%',
        },
        {
            headerLabel: 'Name',
            getTitle: project => project.name,
            getValue: project => project.name,
            width: '12%',
            sort: {
                key: 'name',
                order: sort.key === 'name' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Status',
            getMarks: project => {
                const arr = []
                if (project.projectBlockStatus === PROJECTS_BLOCK_STATUSES.BLOCKED) {
                    arr.push({
                        image: iconLock,
                        label: 'Project is blocked',
                    })
                }
                return arr
            },
            getTitle: project => `${project.projectStatus} (${project.projectBlockStatus})`,
            getValue: project => (
                <span
                    style={{
                        color: getProjectStatusColor(project.projectStatus),
                    }}
                >
                    {project.projectStatus}
                </span>
            ),
            sort: {
                key: 'projectStatus',
                order: sort.key === 'projectStatus' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Created at',
            getTitle: project => moment(project.created).format('MMM D, YYYY (HH:mm)'),
            getValue: project => moment(project.created).format('MMM D, YYYY (HH:mm)'),
            sort: {
                key: 'created',
                order: sort.key === 'created' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '15%',
        },
        {
            headerLabel: 'Published at',
            getTitle: project => (project.published ? moment(project.published).format('MMM D, YYYY (HH:mm)') : null),
            getValue: project => (project.published ? moment(project.published).format('MMM D, YYYY (HH:mm)') : null),
            sort: {
                key: 'published',
                order: sort.key === 'published' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Embedded to',
            getTitle: project => `${project.embeddedList.filter(url => !!url).length} url's`,
            getValue: project => <EmbeddedList list={project.embeddedList.filter(url => !!url)} />,
            width: '20%',
        },
        {
            headerLabel: 'Users',
            getTitle: project => project.uniqueUsersCount,
            getValue: project => project.uniqueUsersCount,
            width: '6%',
        },
        {
            headerLabel: 'Preview',
            getValue: project => (project.previewUrl ? <img src={project.previewUrl} alt="preview" /> : null),
            width: '7%',
        },
        {
            headerLabel: '',
            getValue: project => <ActionsList project={project} methods={methods} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '12%',
        },
    ]
}

const ProjectsTable = ({ title, list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => {
    const history = useHistory()

    return (
        <Table
            columns={getColumns({ methods, requestParams, history })}
            title={title}
            list={list}
            pagination={pagination}
            onExpand={onExpand}
            onChangePage={onChangePage}
            onChangeSort={({ key, order }) => {
                methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
            }}
            isLoading={isLoading}
        />
    )
}

export default ProjectsTable
