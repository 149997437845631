import React from 'react'
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'

import SORT_ORDERS from 'constants/sortOrders'

import Table from 'components/Table/Table'
import InputFilter from 'components/Table/components/Filters/InputFilter'
import SelectFilter from 'components/Table/components/Filters/SelectFilter'
import DateRangeFilter from 'components/Table/components/Filters/DateRangeFilter'
import Avatar from 'components/Avatar/Avatar'

import { PROJECTS_STATUSES, PROJECTS_BLOCK_STATUSES } from 'constants/projects'
import ROUTES from 'constants/routes'

import { getUserName } from 'utils/user'
import { capitalize } from 'utils/common'
import { copyToClipboard } from 'utils/clipboard'
import { getSortKeys } from 'utils/table'

import { getProjectStatusColor } from './utils'

import ActionsList from './components/ActionsList/ActionsList'

import iconPremium from 'images/icons/premium.svg'
import iconCopy from 'images/icons/copy.svg'
import iconLock from 'images/icons/lock.svg'
import iconGroup from 'images/icons/group.png'

const getColumns = ({ methods, requestParams, history }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.id}
                    type="number"
                    field="id"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: project => {
                const arr = [
                    {
                        image: iconCopy,
                        label: 'Copy ID to clipboard',
                        onClick: () => copyToClipboard(project.id),
                    },
                    {
                        image: iconGroup,
                        label: 'Open organization',
                        onClick: () => history.push(`${ROUTES.ORGANIZATIONS.path}/${project.organizationId}`),
                    },
                ]
                if (project.isPremium) {
                    arr.push({
                        image: iconPremium,
                        label: 'Project created from premium template',
                    })
                }
                return arr
            },
            getTitle: project => project.id,
            getValue: project => project.id,
            width: '8%',
            sort: {
                key: 'id',
                order: sort.key === 'id' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Name',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.projectName}
                    field="projectName"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: project => project.name,
            getValue: project => project.name,
            width: '15%',
            sort: {
                key: 'name',
                order: sort.key === 'name' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Created by',
            getTitle: project => getUserName(project.user),
            getValue: project => (
                <Link to={`${ROUTES.USERS.path}/${project.user.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar user={project.user} style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }} />
                    {getUserName(project.user)}
                </Link>
            ),
            width: '14%',
        },
        {
            headerLabel: 'Status',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.projectStatus}
                    field="projectStatus"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        ...Object.values(PROJECTS_STATUSES).map(el => ({
                            value: el,
                            label: capitalize(el),
                        })),
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: project => {
                const arr = []
                if (project.projectBlockStatus === PROJECTS_BLOCK_STATUSES.BLOCKED) {
                    arr.push({
                        image: iconLock,
                        label: 'Project is blocked',
                    })
                }
                return arr
            },
            getTitle: project => `${project.projectStatus} (${project.projectBlockStatus})`,
            getValue: project => (
                <span
                    style={{
                        color: getProjectStatusColor(project.projectStatus),
                    }}
                >
                    {project.projectStatus}
                </span>
            ),
            sort: {
                key: 'projectStatus',
                order: sort.key === 'projectStatus' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Users',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.numberOfUsers}
                    field="numberOfUsers"
                    type="number"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: project => project.uniqueUsersCount,
            getValue: project => project.uniqueUsersCount,
            width: '6%',
        },
        {
            headerLabel: 'Created at',
            headerFilter: (
                <DateRangeFilter
                    initialValueFrom={requestParams.from}
                    initialValueTo={requestParams.to}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: project => moment(project.created).format('MMM D, YYYY (HH:mm)'),
            getValue: project => moment(project.created).format('MMM D, YYYY (HH:mm)'),
            sort: {
                key: 'created',
                order: sort.key === 'created' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '15%',
        },
        {
            headerLabel: 'Published at',
            getTitle: project => (project.published ? moment(project.published).format('MMM D, YYYY (HH:mm)') : null),
            getValue: project => (project.published ? moment(project.published).format('MMM D, YYYY (HH:mm)') : null),
            sort: {
                key: 'published',
                order: sort.key === 'published' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Preview',
            getValue: project => (project.previewUrl ? <img src={project.previewUrl} alt="preview" /> : null),
            width: '8%',
        },
        {
            headerLabel: '',
            getValue: project => <ActionsList project={project} methods={methods} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '14%',
        },
    ]
}

const ProjectsTable = ({ title, list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => {
    const history = useHistory()

    return (
        <Table
            columns={getColumns({ methods, requestParams, history })}
            title={title}
            list={list}
            pagination={pagination}
            onExpand={onExpand}
            onChangePage={onChangePage}
            onChangeSort={({ key, order }) => {
                methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
            }}
            isLoading={isLoading}
        />
    )
}

export default ProjectsTable
