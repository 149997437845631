import React, { useEffect, useState } from 'react'

import SEO from 'components/SEO/SEO'

import OrganizationsTable from './components/OrganizationsTable/OrganizationsTable'

const Organizations = ({ user, userVisibleName }) => {
    const [userOrganization, setUserOrganization] = useState(() => user.organizations.find(item => item.owner))
    const [otherOrganizations, setOtherOrganizations] = useState([])

    useEffect(() => {
        if (!user.organizations) return
        const userOrg = user.organizations.find(item => item.owner)
        const otherOrgs = user.organizations.filter(item => !item.owner)
        if (userOrg) setUserOrganization(userOrg)
        setOtherOrganizations(otherOrgs)
    }, [user])

    return (
        <>
            <SEO title={`Users / ${userVisibleName} / Organizations`} />
            <OrganizationsTable title="User organization" list={[userOrganization]} />
            <br />
            <br />
            <br />
            <OrganizationsTable title="Other organizations" list={otherOrganizations} />
        </>
    )
}

export default Organizations
