import React, { useState } from 'react'
import moment from 'moment'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import FormBlock from 'components/Forms/FormBlock'
import DatePicker from 'components/Forms/DatePicker/DatePicker'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

const ChangeSubscriptionExpirationDateModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [expirationDate, setExpirationDate] = useState(data.expirationDate)

    const [errors, setErrors] = useState({})

    const calculateMinExpirationTime = date => {
        if (data.subscriptionReference) {
            const isToday = moment(date).isSame(moment(), 'day')
            if (isToday) {
                return moment(new Date()).add(3, 'm').startOf('minute').valueOf()
            }
        }
        return moment().startOf('day').valueOf()
    }

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('product', expirationDate, [getIsValueRule()])])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            if (data.subscriptionReference) {
                const isToday = moment(expirationDate).isSame(moment(), 'day')
                if (isToday) {
                    if (expirationDate <= moment(new Date()).add(2, 'm').startOf('m').valueOf()) {
                        setErrors({
                            expirationDate: 'Please, choose a later time',
                        })
                        return
                    }
                }
            }

            await onSubmit(expirationDate.valueOf())

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Change subscription expiration date"
            successText="Subscription expiration date changed successfully!"
            actionText="Change"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Expiration date">
                <DatePicker
                    error={errors.expirationDate}
                    value={expirationDate}
                    format={'MMM D, YYYY (HH:mm)'}
                    timeFormat={'h:mm aa'}
                    isShowTimeSelect={true}
                    minDate={moment().startOf('day').valueOf()}
                    minTime={calculateMinExpirationTime(expirationDate)}
                    maxTime={moment().endOf('day').valueOf()}
                    isShowTodayButton={true}
                    onChange={date => {
                        setExpirationDate(date)
                        setErrors(removeKeyFromObject(errors, 'expirationDate'))
                    }}
                    isClearable={false}
                />
            </FormBlock>
        </FormModal>
    )
}

export default ChangeSubscriptionExpirationDateModal
