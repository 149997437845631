import React, { useEffect, useState } from 'react'

import { LANGUAGE_TAG_PREFIX, ROLE_TAGS } from 'utils/tag'

import CompactView from './components/CompactView/CompactView'
import ListView from './components/ListView/ListView'
import TagsSelectionModal from './components/TagsSelectionModal/TagsSelectionModal'
import RemoveTagModal from './components/RemoveTagModal/RemoveTagModal'

const Tags = ({
    selectedTagsList = [],
    isCompactView = false,
    onUpdate = () => {},
    onRemove = () => {},
    isShowRoles = false,
    isEnsureRemove = true,
    isForceSuccessClose = false,
    isDisabled = false,
    isShowOnlyEditable = true,
}) => {
    const [isOpenedSelectionTagsModal, setIsOpenedSelectionTagsModal] = useState(false)
    const [tagToRemove, setTagToRemove] = useState(null)

    const [reservedTagsList, setReservedTagsList] = useState([])
    const [filteredSelectedTagsList, setFilteredSelectedTagsList] = useState([])

    useEffect(() => {
        const reservedData = []
        const filteredData = selectedTagsList.filter(tag => {
            if (!isShowOnlyEditable) return true
            if (
                (!isShowRoles && [ROLE_TAGS.BUSINESS, ROLE_TAGS.EDUCATION].includes(tag.name)) ||
                tag.name.startsWith(LANGUAGE_TAG_PREFIX)
            ) {
                reservedData.push(tag)
                return false
            }
            return true
        })
        setReservedTagsList(reservedData)
        setFilteredSelectedTagsList(filteredData)
    }, [selectedTagsList])

    return (
        <>
            {isCompactView ? (
                <CompactView
                    isDisabled={isDisabled}
                    tagsList={filteredSelectedTagsList}
                    onEdit={() => setIsOpenedSelectionTagsModal(true)}
                />
            ) : (
                <ListView
                    isDisabled={isDisabled}
                    tagsList={filteredSelectedTagsList}
                    onEdit={() => setIsOpenedSelectionTagsModal(true)}
                    onRemove={tag => (isEnsureRemove ? setTagToRemove(tag) : onRemove(tag))}
                />
            )}

            {!!tagToRemove && (
                <RemoveTagModal
                    tag={tagToRemove}
                    onRemove={tag => onRemove(tag)}
                    onClose={() => setTagToRemove(null)}
                />
            )}
            {isOpenedSelectionTagsModal && (
                <TagsSelectionModal
                    isShowRoles={isShowRoles}
                    isShowOnlyEditable={isShowOnlyEditable}
                    isEnsureRemove={isEnsureRemove}
                    isForceSuccessClose={isForceSuccessClose}
                    initialSelectedTagsList={filteredSelectedTagsList}
                    onAccept={tags => onUpdate([...tags, ...reservedTagsList])}
                    onClose={() => setIsOpenedSelectionTagsModal(false)}
                />
            )}
        </>
    )
}

export default Tags
