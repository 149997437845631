import React, { useMemo, useState } from 'react'

import Button from 'components/Forms/Button/Button'
import Input from 'components/Forms/Input/Input'
import Preloader from 'components/Preloader/Preloader'

import styles from './FolderList.module.scss'

const FolderList = ({ list, onView, onRemove, onCreate, isLoading }) => {
    const [filter, setFilter] = useState('')

    const filteredList = useMemo(() => {
        if (!list || !list.length) return []
        return list.filter(({ name }) => name.toLowerCase().includes(filter.trim().toLowerCase()))
    }, [list, filter])

    if (isLoading) return <Preloader />

    return (
        <div className={styles.folders}>
            <h2 className={styles.title}>
                Folders
                <Button content="+ Create folder" variant="primary" onClick={() => onCreate()} />
            </h2>
            {list.length ? (
                <>
                    <div className={styles.filter}>
                        <Input
                            maxWidth="300px"
                            isClearable
                            placeholder="Name filter"
                            value={filter}
                            onChange={val => setFilter(val)}
                        />
                    </div>
                    {!!filteredList.length ? (
                        <ul className={styles.folderList}>
                            {filteredList.map(item => (
                                <li key={item.id} className={styles.folder}>
                                    <div className={styles.name}>{item.name}</div>
                                    <ul className={styles.actions}>
                                        <li>
                                            <Button
                                                content="View"
                                                variant="primary"
                                                isInverse={true}
                                                onClick={() => onView(item)}
                                            />
                                        </li>
                                        <li>
                                            <Button
                                                content="Remove"
                                                variant="danger"
                                                isInverse={true}
                                                onClick={() => onRemove(item)}
                                            />
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.emptyList}>Nothing was found for name "{filter}"</div>
                    )}
                </>
            ) : (
                <div className={styles.emptyList}>Folders not found</div>
            )}
        </div>
    )
}

export default FolderList
