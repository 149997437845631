import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { PAYOUTS_STATUSES } from 'constants/payouts'

const AVAILABLE_STATUSES = [PAYOUTS_STATUSES.PENDING, PAYOUTS_STATUSES.DECLINED]

const ChangePayoutStatusModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [status, setStatus] = useState({ label: data.paymentStatus, value: data.paymentStatus })

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('status', status, [getIsValueRule()])])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit(data, status.value)

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Change payout status"
            successText="Payout status changed successfully!"
            actionText="Change"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Status">
                <Select
                    error={errors.status}
                    value={status}
                    options={AVAILABLE_STATUSES.filter(item => item !== status.value).map(item => ({
                        label: item,
                        value: item,
                    }))}
                    onChange={value => {
                        setStatus(value)
                        setErrors(removeKeyFromObject(errors, 'status'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default ChangePayoutStatusModal
