import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Upload from 'components/Forms/Upload/Upload'

import { FILE_TYPES, FILE_TYPES_FORMATS, FILE_TYPES_MAX_SIZES } from 'constants/fileTypes'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'
import { getFormattedFileSizeFromBytes, getUint8Array } from 'utils/file'

const UploadAudioModal = ({ onSubmit = () => {}, onClose = () => {} }) => {
    const [name, setName] = useState('')
    const [file, setFile] = useState(null)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([
                validator.createField('name', name, [getIsValueRule(), getIsNotEmptyStringRule()]),
                validator.createField('file', file, [getIsValueRule()]),
            ])

            const { isHasErrors, errors } = validator.validate()

            if (!errors.file && file.size > FILE_TYPES_MAX_SIZES[FILE_TYPES.AUDIO]) {
                errors.file = `Maximum file size is ${getFormattedFileSizeFromBytes(
                    FILE_TYPES_MAX_SIZES[FILE_TYPES.AUDIO],
                )}`
            }
            if (!errors.file && !FILE_TYPES_FORMATS[FILE_TYPES.AUDIO].includes(file.type)) {
                errors.file = 'Invalid file type'
            }

            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const uint8Array = await getUint8Array(file)

            await onSubmit({ name: `${name.trim()}.${file.type.split('/')[1]}`, type: file.type, bytes: uint8Array })

            return true
        } catch (err) {
            if (err.response) {
                const {
                    response: { status },
                } = err
                if (status === 400) {
                    setErrors({
                        name: 'Invalid characters',
                    })
                    return
                }
            }
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }

    return (
        <FormModal
            headText="Upload audio"
            successText="Audio uploaded successfully!"
            actionText="Upload"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Name">
                <Input
                    value={name}
                    error={errors.name}
                    onChange={value => {
                        setName(value)
                        setErrors(removeKeyFromObject(errors, 'name'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Audio file">
                <Upload
                    value={file}
                    type={FILE_TYPES.AUDIO}
                    error={errors.file}
                    onReset={() => {
                        setFile(null)
                    }}
                    onChange={file => {
                        setFile(file)
                        setErrors(removeKeyFromObject(errors, 'file'))
                    }}
                    isShowUpload={false}
                />
            </FormBlock>
        </FormModal>
    )
}

export default UploadAudioModal
