import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash'

import Pagination from './components/Pagination/Pagination'

import { getNormalizedColumns, getSortIconByOrder, getReversedOrder } from './utils'

import preloaderImage from './i/preloader.gif'

import './Table.scss'

const ShowMoreBtn = ({ isLoading, onExpand }) => (
    <div className="common__table__show-more">
        <span className={isLoading ? 'is-disabled' : ''} onClick={() => onExpand()}>
            Show more
        </span>
    </div>
)

const Counter = ({ pagination, currentElements }) => {
    if (!pagination) {
        return (
            <div className="common__table__info__counter">
                {currentElements ? 1 : 0} - {currentElements} / <b>{currentElements}</b>
            </div>
        )
    }
    const { totalElements, currentPage, perPageElements, totalPages } = pagination
    const to = currentPage + 1 === totalPages ? totalElements : perPageElements * (currentPage + 1)
    const from = totalElements ? to - currentElements + 1 : 0

    return (
        <div className="common__table__info__counter">
            {from} - {to} / <b>{totalElements}</b>
        </div>
    )
}

const Loader = () => (
    <div className="common__table__loader">
        <img src={preloaderImage} alt="" />
    </div>
)

const executeScroll = ref => {
    const scrollableElement = document.getElementById('dashboard-content')
    if (!scrollableElement) return
    scrollableElement.scrollTo({
        top: ref.current.offsetTop - 60 - 10,
        left: 0,
        behavior: 'smooth',
    })
}

const ColValue = ({ column, row, style }) => (
    <div className="common__table__content__col__value" style={style}>
        {column.getValue(row)}
    </div>
)
const ColMarks = ({ column, row }) => {
    const marksArray = column.getMarks(row)
    if (!marksArray.length) return null
    return (
        <div className="common__table__content__col__marks">
            {(() => {
                const marksWithClickHandler = marksArray.filter(mark => !!mark.onClick)
                const marksWithoutClickHandler = marksArray.filter(mark => !mark.onClick)

                return (
                    <>
                        {!!marksWithClickHandler.length && (
                            <ul>
                                {marksWithClickHandler.map((mark, i) => (
                                    <li
                                        className="is-clickable"
                                        key={i}
                                        title={mark.label}
                                        onClick={evt => {
                                            evt.preventDefault()
                                            mark.onClick()
                                        }}
                                    >
                                        {mark.image && <img src={mark.image} alt={mark.label} />}
                                        {!!mark.component && mark.component}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {!!marksWithoutClickHandler.length && (
                            <ul
                                className={classNames({
                                    'with-left-border': !!marksWithClickHandler.length,
                                })}
                            >
                                {marksWithoutClickHandler.map((mark, i) => (
                                    <li key={i} title={mark.label} onClick={evt => evt.stopPropagation()}>
                                        {mark.image && <img src={mark.image} alt={mark.label} />}
                                        {!!mark.component && mark.component}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )
            })()}
        </div>
    )
}

const RowWrapper = ({ href, target = '_self', children }) => {
    if (href)
        return (
            <Link to={href} target={target}>
                {children}
            </Link>
        )
    return children
}

const Table = ({
    title,
    columns = [],
    list = [],
    isLoading = false,
    pagination = null,
    getRowClickHref = () => null,
    getRowClickTarget = () => null,
    onColumnClick = null,
    onExpand = () => {},
    onChangePage = () => {},
    onChangeSort = () => {},
    minWidth = 1240,
    isShowCounter = true,
    isShowRowNumber = true,
    isExpandable = true,
    isPageable = true,
}) => {
    const tableRef = useRef(null)
    const [initialColumns] = useState(columns)
    const [normalizedColumns, setNormalizedColumns] = useState(() => getNormalizedColumns(initialColumns))

    useEffect(() => {
        if (!isEqual(columns, initialColumns)) setNormalizedColumns(getNormalizedColumns(columns))
    }, [columns])

    const getRowNumber = (pagination, rowIndex) => {
        if (!pagination) return rowIndex + 1

        const { totalElements, currentPage, perPageElements, totalPages } = pagination
        const currentElements = list.length

        return (
            (totalElements
                ? (currentPage + 1 === totalPages ? totalElements : perPageElements * (currentPage + 1)) -
                  currentElements +
                  1
                : 0) + rowIndex
        )
    }

    const getRowKey = (row, i) => {
        if (row.id) return row.id
        return i
    }

    return (
        <div ref={tableRef} className="common__table">
            <div className="common__table__info">
                <div className="common__table__info__title">{title || null}</div>
                {isShowCounter && <Counter currentElements={list.length} pagination={pagination} />}
            </div>
            <div className="common__table--scrollable-wrapper">
                <div style={{ minWidth }}>
                    <div className="common__table__header">
                        <ul className="common__table__header__row">
                            {normalizedColumns.map((column, columnIndex) => (
                                <li
                                    className="common__table__header__col"
                                    key={columnIndex}
                                    style={{ width: column.width, ...column.headerStyles }}
                                >
                                    <div
                                        className={classNames({
                                            common__table__header__col__label: true,
                                            'with-sort-param': column.sort,
                                        })}
                                        onClick={() => {
                                            if (!column.sort) return
                                            onChangeSort({
                                                key: column.sort.key,
                                                order: getReversedOrder(column.sort.order),
                                            })
                                        }}
                                    >
                                        <p>{column.headerLabel}</p>
                                        {column.sort && <img src={getSortIconByOrder(column.sort.order)} alt="" />}
                                    </div>
                                    {column.headerFilter && (
                                        <div className="common__table__header__col__filter">
                                            {typeof column.headerFilter === 'function'
                                                ? column.headerFilter()
                                                : column.headerFilter}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {isLoading && !list.length ? (
                        <div className="common__table__content__loading">Loading...</div>
                    ) : (
                        <>
                            <div
                                className={classNames({
                                    common__table__content: true,
                                    'is-loading': isLoading,
                                })}
                            >
                                {list.length ? (
                                    list.map((row, rowIndex) => {
                                        const isRowHasAnyMarks = !!normalizedColumns.find(
                                            item => item.getMarks(row).length !== 0,
                                        )

                                        return (
                                            <RowWrapper
                                                key={getRowKey(row, rowIndex)}
                                                href={getRowClickHref(row)}
                                                target={getRowClickTarget(row)}
                                            >
                                                <ul
                                                    className={classNames({
                                                        common__table__content__row: true,
                                                        'is-has-marks': isRowHasAnyMarks,
                                                    })}
                                                    data-row-number={
                                                        isShowRowNumber ? getRowNumber(pagination, rowIndex) : ''
                                                    }
                                                >
                                                    {normalizedColumns.map((column, columnIndex) => (
                                                        <li
                                                            key={columnIndex}
                                                            className={classNames({
                                                                common__table__content__col: true,
                                                                'is-clickable': !!onColumnClick,
                                                            })}
                                                            onClick={() =>
                                                                !!onColumnClick ? onColumnClick({ column, row }) : {}
                                                            }
                                                            style={{ width: column.width }}
                                                            title={column.getTitle(row)}
                                                        >
                                                            <ColValue
                                                                column={column}
                                                                row={row}
                                                                style={column.valueStyles}
                                                            />
                                                            <ColMarks column={column} row={row} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </RowWrapper>
                                        )
                                    })
                                ) : (
                                    <div className="common__table__content__empty">Nothing was found</div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!!list.length && pagination && (
                <>
                    {isExpandable && pagination.currentPage + 1 < pagination.totalPages && (
                        <ShowMoreBtn isLoading={isLoading} onExpand={onExpand} />
                    )}
                    {isPageable && pagination.totalPages > 1 && (
                        <Pagination
                            currentPage={pagination.currentPage}
                            totalPages={pagination.totalPages}
                            isLoading={isLoading}
                            onChange={async page => {
                                await onChangePage(page)
                                executeScroll(tableRef)
                            }}
                        />
                    )}
                </>
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default Table
