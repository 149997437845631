import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import API from 'api'

import { setUsage } from 'store/actions/translator'

import { getLocaleDetailsByCode } from 'constants/locales'

const useTranslator = () => {
    const { usage } = useSelector(store => store.translator)
    const dispatch = useDispatch()

    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const updateUsageInfo = async () => {
        try {
            const response = await API.TRANSLATION.GET_DEEPL_USAGE_INFO()
            dispatch(
                setUsage({
                    count: response.character_count,
                    limit: response.character_limit,
                }),
            )
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (usage) {
            setIsReady(true)
            return
        }
        updateUsageInfo().then(() => {
            setIsReady(true)
        })
    }, [])

    const getTranslation = async ({ sourceText, sourceLocale, targetLocale }) => {
        try {
            setIsLoading(true)
            const translatedText = await API.TRANSLATION.GET_DEEPL_TRANSLATE({
                sourceLocale: getLocaleDetailsByCode(sourceLocale).deeplSourceCode,
                sourceText,
                targetLocale: getLocaleDetailsByCode(targetLocale).deeplTargetCode,
            })
            updateUsageInfo()
            return translatedText
        } catch (err) {
            throw err
        } finally {
            setIsLoading(false)
        }
    }

    return { isReady, isLoading, getTranslation }
}

export default useTranslator
