import { set } from 'lodash'
// note: validator package accept only STRING type
import isJson from 'validator/lib/isJSON'
import isInt from 'validator/lib/isInt'
import isUrl from 'validator/lib/isURL'
import isHex from 'validator/lib/isHexColor'
import isEmail from 'validator/lib/isEmail'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

const isValue = value => value !== void 0 && value !== null
const isString = value => typeof value === 'string'
const isNotEmptyString = value => (isString(value) ? !!value.length : false)
const isStringNumber = value => !isNaN(value)
const isMin = (value, min) => !isNaN(value) && value >= min
const isMax = (value, max) => !isNaN(value) && value <= max
const isIntNum = value => isInt(String(value))

const createValidationRule = (validateFunction, errorText = 'Field is invalid', ...props) => ({
    errorText,
    validate: (...args) => {
        try {
            return validateFunction(...args, ...props)
        } catch {
            return false
        }
    },
})

export const getIsValueRule = errorText => createValidationRule(isValue, errorText || 'Field is required')
export const getIsNotEmptyStringRule = errorText =>
    createValidationRule(isNotEmptyString, errorText || 'Field cannot be empty')
export const getIsStringNumberRule = errorText =>
    createValidationRule(isStringNumber, errorText || 'Field must be number')
export const getIsJsonStringRule = errorText => createValidationRule(isJson, errorText || 'Field must be JSON')
export const getIsIntStringRule = errorText => createValidationRule(isInt, errorText || 'Field must be integer value')
export const isIntegerNumber = errorText => createValidationRule(isIntNum, errorText || 'Field must be integer number')
export const getIsUrlRule = errorText => createValidationRule(isUrl, errorText || 'Field must be URL')
export const getIsHexRule = errorText => createValidationRule(isHex, errorText || 'Field must be HEX color')
export const getIsEmailRule = errorText => createValidationRule(isEmail, errorText || 'Field must be email')
export const getIsMinRule = (min, errorText) =>
    createValidationRule(isMin, errorText || `Must be no less than ${min}`, min)
export const getIsMaxRule = (max, errorText) =>
    createValidationRule(isMax, errorText || `Must be no greater than ${max}`, max)

export class fieldsValidator {
    #fields
    #isShowToast

    constructor(isShowToast = true) {
        this.#fields = []
        this.#isShowToast = isShowToast
    }

    createField = (key, value, rules = []) => ({
        key,
        value,
        rules,
    })
    addFields = fields => {
        try {
            this.#fields = [...this.#fields, ...fields]
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }
    getFields = () => this.#fields

    validate = () => {
        try {
            const errors = {}

            for (const item of this.#fields) {
                const { key, value, rules = [] } = item
                for (const rule of rules) {
                    if (!rule.validate(value)) {
                        set(errors, key, rule.errorText)
                        break
                    }
                }
            }

            const isHasErrors = !!Object.keys(errors).length
            if (isHasErrors && this.#isShowToast)
                Toast(TOAST_TYPE.ERROR, { message: 'Error while validating fields. Check the correctness of filling' })

            return {
                isHasErrors,
                errors,
            }
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }
}
