import React, { useState, useRef } from 'react'

import Button from 'components/Forms/Button/Button'
import Preloader from 'components/Preloader/Preloader'

import { FILE_TYPES, FILE_TYPES_FORMATS } from 'constants/fileTypes'

import { uploadFile } from 'utils/file'

import BlockWrapper from '../../BlockWrapper/BlockWrapper'

import styles from './Image.module.scss'

const Image = ({ isCanRefresh = true, title = 'Image', imageUrl, onUpdate, onRefresh, isHideBorders = false }) => {
    const [isLoading, setIsLoading] = useState(false)

    const customImageRef = useRef()

    return (
        <BlockWrapper title={title} isHideBorders={isHideBorders}>
            <div className={styles.image}>
                {isLoading ? (
                    <div className={styles.preloader}>
                        <Preloader />
                    </div>
                ) : (
                    <div className={styles.imageActions}>
                        {isCanRefresh && (
                            <>
                                <Button
                                    className={styles.refreshButton}
                                    content="Refresh"
                                    variant="primary"
                                    onClick={async () => {
                                        try {
                                            setIsLoading(true)
                                            await onRefresh()
                                        } catch (err) {
                                            console.error(err)
                                        } finally {
                                            setIsLoading(false)
                                        }
                                    }}
                                    isDisabled={isLoading}
                                />
                            </>
                        )}
                        <Button
                            content="Upload"
                            variant="primary"
                            onClick={async () => {
                                customImageRef.current.click()
                            }}
                            isDisabled={isLoading}
                        />
                        <input
                            ref={customImageRef}
                            type="file"
                            className={styles.customUploadInput}
                            accept={FILE_TYPES_FORMATS[FILE_TYPES.IMAGE].join(',')}
                            onChange={async evt => {
                                try {
                                    setIsLoading(true)
                                    const file = evt.target.files.length ? evt.target.files[0] : null
                                    evt.target.value = null
                                    const { isSuccess, url } = await uploadFile(file)
                                    if (isSuccess) onUpdate(url)
                                } catch (err) {
                                    console.error(err)
                                } finally {
                                    setIsLoading(false)
                                }
                            }}
                        />
                    </div>
                )}
                {!!imageUrl && <img className={styles.imageElement} src={imageUrl} alt="image" />}
            </div>
        </BlockWrapper>
    )
}

export default Image
