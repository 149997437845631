import React from 'react'

import styles from './Tooltip.module.scss'

const Tooltip = ({
    data: {
        point: { data },
    },
}) => (
    <div className={styles.tooltip}>
        <b>Date:</b> {data.x}
        <br />
        <b>Count:</b> {data.y}
    </div>
)

export default Tooltip
