import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'

import { useDoubleClick } from 'hooks/useDoubleClick'

import ROUTES from 'constants/routes'
import { getAllowedNavList } from 'constants/navList'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { getUserName } from 'utils/user'
import { LOGOUT } from '../../api/auth'

import SecretMenu from './components/SecretMenu/SecretMenu'
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu'

import imageLogo from './i/logo.svg'
import iconLogout from './i/logout.svg'

import './Header.scss'

const Header = () => {
    const user = useSelector(store => store.user.data)
    const [isOpenedLogoutModal, setIsOpenedLogoutModal] = useState(false)
    const [isOpenedSecretMenu, setIsOpenedSecretMenu] = useState(false)
    const [navList] = useState(() => getAllowedNavList())

    return (
        <header className="header">
            <div className="header--left">
                <div className="header__logo">
                    <Link to={ROUTES.HOME.path}>
                        <img src={imageLogo} alt="" />
                    </Link>
                </div>
                <nav className="header__nav">
                    <ul className="header__nav__list">
                        {navList.map((item, i) => (
                            <li key={i}>
                                <NavLink exact {...item.additionalProps} to={item.to}>
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className="header--right">
                <div className="header__profile">
                    <div className="header__profile__info">
                        <div onClick={useDoubleClick(() => setIsOpenedSecretMenu(true))}>
                            {getUserName(user.user, true)}
                        </div>
                        <p>{getUserName(user.user)}</p>
                        <img src={iconLogout} alt="" onClick={() => setIsOpenedLogoutModal(true)} />
                    </div>
                    <HamburgerMenu list={navList} />
                    {isOpenedLogoutModal && (
                        <ConfirmationDialog
                            onClose={() => setIsOpenedLogoutModal(false)}
                            isSuccessAutoClose={false}
                            onAction={() => LOGOUT()}
                            headText="Are you sure you want to logout?"
                            actionVariant="danger"
                            actionText="Logout"
                            cancelText="Cancel"
                        />
                    )}
                </div>
            </div>
            <SecretMenu isOpen={isOpenedSecretMenu} onClose={() => setIsOpenedSecretMenu(false)} user={user} />
        </header>
    )
}

export default Header
