import React from 'react'
import classNames from 'classnames'

import Checkbox from 'components/Forms/Checkbox/Checkbox'

import { PUBLISH_DESTINATION, PUBLISH_DESTINATION_LABELS } from '../../constants'

import styles from './ProjectsMenu.module.scss'
import Button from '../../../../../../../../components/Forms/Button/Button'

const ProjectsMenu = ({ projects, activeProjectIndex, onChangeActiveProjectIndex, onUpdate, onPublish }) => (
    <div className={styles.projectsMenu}>
        <ul className={styles.list}>
            {projects.map((project, index) => (
                <li key={index} className={styles.item}>
                    <div
                        className={classNames(styles.label, { [styles.isActiveLabel]: index === activeProjectIndex })}
                        onClick={() => {
                            if (index === activeProjectIndex) return
                            onChangeActiveProjectIndex(index)
                        }}
                    >
                        {project.main.topic}
                    </div>
                    <ul className={styles.settingsList}>
                        {Object.values(PUBLISH_DESTINATION).map(destination => {
                            if (project.system.publishSettings[destination].isDisabled) return null

                            return (
                                <li key={destination} className={styles.settingsItem}>
                                    <Checkbox
                                        label={`Publish to ${PUBLISH_DESTINATION_LABELS[destination]}`}
                                        isDisabled={project.system.publishSettings[destination].isDisabled}
                                        value={project.system.publishSettings[destination].isActive}
                                        onChange={v =>
                                            onUpdate(`system.publishSettings.${destination}.isActive`, v, index)
                                        }
                                    />
                                </li>
                            )
                        })}
                    </ul>
                    <div className={styles.actions}>
                        <Button variant="primary" onClick={onPublish}>
                            Publish projects
                        </Button>
                    </div>
                </li>
            ))}
        </ul>
    </div>
)

export default ProjectsMenu
