export const MODALS = {
    REMOVE_TAG: 'REMOVE_TAG',
    CREATE_TAG: 'CREATE_TAG',
    EDIT_TAG: 'EDIT_TAG',
}

export const TABS = {
    ALL: 'ALL',
    SYSTEM: 'SYSTEM',
    INTERESTS: 'INTERESTS',
}
