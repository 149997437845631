import React from 'react'

import iconEdit from 'images/icons/edit.svg'

import { getMonthlyFormattedPrice, getYearlyFormattedPrice } from 'utils/payments'

import styles from './PriceBlock.module.scss'

const EditButton = ({ onClick }) => <img onClick={onClick} src={iconEdit} alt="edit" className={styles.editIcon} />

const PriceBlock = ({ price, isShowEditButton, onEdit, currency, billingCycle }) => (
    <div className={styles.priceBlock}>
        <div className={styles.main}>
            {getMonthlyFormattedPrice(price, currency, billingCycle)}
            {isShowEditButton && !!price && <EditButton onClick={onEdit} />}
        </div>
        {billingCycle === 12 && (
            <div className={styles.per}>
                <span className={styles.perPrice}>{getYearlyFormattedPrice(price, currency, billingCycle)}</span> / year
            </div>
        )}
    </div>
)

export default PriceBlock
