import SORT_ORDERS from 'constants/sortOrders'

import iconSortUp from './i/sort-up.svg'
import iconSortDown from './i/sort-down.svg'
import iconSortUpDown from './i/sort-up-down.svg'

const columnSchema = {
    headerLabel: {
        isRequired: false,
        defaultValue: '',
    },
    headerFilter: {
        isRequired: false,
        defaultValue: null,
    },
    headerStyles: {
        isRequired: false,
        defaultValue: {},
    },
    getMarks: {
        isRequired: false,
        defaultValue: () => [],
    },
    getValue: {
        isRequired: false,
        defaultValue: () => '',
    },
    getTitle: {
        isRequired: false,
        defaultValue: () => '',
    },
    valueStyles: {
        isRequired: false,
        defaultValue: {},
    },
    sort: {
        isRequired: false,
        defaultValue: null,
        // Example:
        // {
        //     order: see 'constants/Orders',
        //     key: 'created'
        // }
    },
    width: {
        isRequired: false,
        defaultValue: 'auto',
    },
}

export const getNormalizedColumns = (columns = []) => {
    const result = []
    for (const column of columns) {
        const item = {}
        for (const [key, rules] of Object.entries(columnSchema)) {
            if (rules.isRequired && !column[key]) {
                throw new Error(`${key} key is required for column schema`)
            }
            if (column[key]) {
                item[key] = column[key]
            } else {
                item[key] = rules.defaultValue
            }
        }
        result.push(item)
    }
    return result
}

export const getSortIconByOrder = order => {
    switch (order.toLowerCase()) {
        case SORT_ORDERS.asc:
            return iconSortUp
        case SORT_ORDERS.desc:
            return iconSortDown
        case SORT_ORDERS.undefined:
            return iconSortUpDown
        default:
            return null
    }
}

export const getReversedOrder = order => {
    switch (order.toLowerCase()) {
        case SORT_ORDERS.asc:
        case SORT_ORDERS.undefined:
            return SORT_ORDERS.desc
        case SORT_ORDERS.desc:
            return SORT_ORDERS.asc
        default:
            return order.toLowerCase()
    }
}
