import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import { copyToClipboard } from 'utils/clipboard'

import iconShareWhite from 'images/icons/shareWhite.svg'
import ROUTES from 'constants/routes'

import { MODALS, MODAL_ACTIONS } from '../../constants'

import './InfoBlocks.scss'

const InfoBlocks = ({ user, openModal, modalAction }) => {
    const history = useHistory()
    const [organizationId, setOrganizationId] = useState(null)

    useEffect(() => {
        if (!user.organizations) return
        const userOrganization = user.organizations.find(item => item.owner)
        if (userOrganization) setOrganizationId(userOrganization.id)
    }, [user])

    return (
        <ul className="user-info-blocks">
            <li>
                <h3>User info</h3>
                <ul>
                    <li>
                        <FormBlock label="First name">
                            <Input value={user.firstName || ''} isReadOnly={true} />
                        </FormBlock>
                        <Button
                            content="Edit"
                            variant="primary"
                            onClick={() =>
                                openModal(MODALS.EDIT_FIRST_NAME, {
                                    data: {
                                        label: 'First name',
                                        value: user.firstName,
                                    },
                                    onAction: value => modalAction(MODAL_ACTIONS.EDIT_FIRST_NAME, { firstName: value }),
                                })
                            }
                        />
                    </li>
                    <li>
                        <FormBlock label="Last name">
                            <Input value={user.lastName || ''} isReadOnly={true} />
                        </FormBlock>
                        <Button
                            content="Edit"
                            variant="primary"
                            onClick={() =>
                                openModal(MODALS.EDIT_LAST_NAME, {
                                    data: {
                                        label: 'Last name',
                                        value: user.lastName,
                                    },
                                    onAction: value => modalAction(MODAL_ACTIONS.EDIT_LAST_NAME, { lastName: value }),
                                })
                            }
                        />
                    </li>
                    <li>
                        <FormBlock label="Email address">
                            <Input value={user.login} isReadOnly={true} />
                        </FormBlock>
                        <Button content="Copy" variant="primary" onClick={() => copyToClipboard(user.login)} />
                    </li>
                    <li>
                        <FormBlock label="Role">
                            <Input
                                value={
                                    user.roleInProjectDto
                                        ? `${user.roleInProjectDto.roleTranslationDto.text} (${user.roleInProjectDto.userGroupInProjectDto.groupTranslationDto.text})`
                                        : ''
                                }
                                isReadOnly={true}
                            />
                        </FormBlock>
                    </li>
                </ul>
            </li>
            <li>
                <h3>Additional params</h3>
                <ul>
                    <li>
                        <FormBlock label="ID">
                            <Input value={user.id} isReadOnly={true} />
                        </FormBlock>
                        <Button content="Copy" variant="primary" onClick={() => copyToClipboard(user.id)} />
                    </li>
                    <li>
                        <FormBlock label="Organization ID">
                            <Input value={organizationId || ''} isReadOnly={true} />
                        </FormBlock>
                        <Button
                            styles={{ minWidth: 'auto' }}
                            content={<img src={iconShareWhite} alt="" style={{ width: 18 }} />}
                            isInverse
                            variant="primary"
                            onMouseDownEvt={({ button }) => {
                                if (button === 1) {
                                    window.open(`${ROUTES.ORGANIZATIONS.path}/${organizationId}`, '_blank')
                                    return
                                }
                                history.push(`${ROUTES.ORGANIZATIONS.path}/${organizationId}`)
                            }}
                        />
                        <Button
                            content="Copy"
                            variant="primary"
                            onClick={() => copyToClipboard(organizationId || '')}
                        />
                    </li>
                    <li>
                        <FormBlock label="CID">
                            <Input value={user.cid || ''} isReadOnly={true} />
                        </FormBlock>
                        {user.cid && (
                            <Button content="Copy" variant="primary" onClick={() => copyToClipboard(user.cid)} />
                        )}
                    </li>
                    <li>
                        <FormBlock label="Test account">
                            <Input value={user.isTest ? 'Yes' : 'No'} isReadOnly={true} />
                        </FormBlock>
                        {user.isActive && (
                            <Button
                                content="Change"
                                variant="primary"
                                onClick={() =>
                                    openModal(MODALS.CONFIRMATION, {
                                        data: {
                                            noteText: `Are you sure you want to set this user is ${
                                                user.isTest ? 'real' : 'test'
                                            }?`,
                                            actionVariant: 'primary',
                                            actionText: 'Accept',
                                            successText: `User set is ${user.isTest ? 'real' : 'test'} successfully!`,
                                        },
                                        onAction: () =>
                                            modalAction(
                                                user.isTest ? MODAL_ACTIONS.SET_USER_REAL : MODAL_ACTIONS.SET_USER_TEST,
                                            ),
                                    })
                                }
                            />
                        )}
                    </li>
                    <li>
                        <FormBlock label="Active provider">
                            <Input value={user.provider || ''} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="Registration provider">
                            <Input value={user.initialProvider || ''} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="Registration date">
                            <Input value={moment(user.created).format('MMM D, YYYY (HH:mm)')} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="Last login date">
                            <Input value={moment(user.lastLoggedIn).format('MMM D, YYYY (HH:mm)')} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="News subscription">
                            <Input value={user.newsSubscription ? 'Yes' : 'No'} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="Avangate customer reference">
                            <Input value={user.externalReference || ''} isReadOnly={true} />
                        </FormBlock>
                        {user.externalReference && (
                            <Button
                                content="Copy"
                                variant="primary"
                                onClick={() => copyToClipboard(user.externalReference)}
                            />
                        )}
                    </li>
                    <li>
                        <FormBlock label="Country">
                            <Input value={user.country} isReadOnly={true} />
                        </FormBlock>
                    </li>
                    <li>
                        <FormBlock label="Language">
                            <Input value={user.languageCode} isReadOnly={true} />
                        </FormBlock>
                    </li>
                </ul>
            </li>
        </ul>
    )
}

export default InfoBlocks
