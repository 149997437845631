import { SET_USAGE } from '../actions/translator'

const initialState = {
    usage: null,
}

const translator = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_USAGE:
            state = {
                ...state,
                usage: payload,
            }
            break
        default:
            break
    }
    return state
}

export default translator
