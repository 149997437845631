import { CURRENCIES } from 'constants/currencies'

export const TABLE_PARAMS = {
    size: {
        default: 20,
        getValue: v => Number(v),
    },
    from: {
        getValue: v => Number(v),
    },
    to: {
        getValue: v => Number(v),
    },
}

export const MODALS = {
    EDIT_EXCHANGE_RATE: 'EDIT_EXCHANGE_RATE',
    CHANGE_PAYOUT_STATUS: 'CHANGE_PAYOUT_STATUS',
    FORCE_PAYOUT: 'FORCE_PAYOUT',
}

export const EXCHANGE_CURRENCY_FROM = CURRENCIES.USD
export const EXCHANGE_CURRENCY_TO = CURRENCIES.EUR
