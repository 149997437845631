import React from 'react'
import classNames from 'classnames'

import styles from './FormBlock.module.scss'

const FormBlock = ({ label, children }) => (
    <div className={classNames('formBlock', styles.formBlock)}>
        {!!label && <div className={styles.title}>{label}</div>}
        <div className={styles.control}>{children}</div>
    </div>
)

export default FormBlock
