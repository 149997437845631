import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `created,${SORT_ORDERS.desc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 10,
        getValue: v => Number(v),
    },
}

export const MODALS = {
    CONFIRMATION: 'CONFIRMATION',
    EDIT_FIRST_NAME: 'EDIT_FIRST_NAME',
    EDIT_LAST_NAME: 'EDIT_LAST_NAME',
    EDIT_PROJECT: 'EDIT_PROJECT',
}

export const MODAL_ACTIONS = {
    BLOCK_PROJECT: 'BLOCK_PROJECT',
    UNBLOCK_PROJECT: 'UNBLOCK_PROJECT',
    BLOCK_ALL_PROJECTS: 'BLOCK_ALL_PROJECTS',
    UNBLOCK_ALL_PROJECTS: 'UNBLOCK_ALL_PROJECTS',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
    SET_USER_TEST: 'SET_USER_TEST',
    SET_USER_REAL: 'SET_USER_REAL',
    BLOCK_ACCOUNT: 'BLOCK_ACCOUNT',
    UNBLOCK_ACCOUNT: 'UNBLOCK_ACCOUNT',
    DELETE_ACCOUNT: 'DELETE_ACCOUNT',
    ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
    EDIT_FIRST_NAME: 'EDIT_FIRST_NAME',
    EDIT_LAST_NAME: 'EDIT_LAST_NAME',
}
