const FILE_FORMATS = {
    JPEG: 'image/jpeg',
    PNG: 'image/png',
    GIF: 'image/gif',
    PDF: 'application/pdf',
    MPEG: 'audio/mpeg',
    WAV: 'audio/wav',
    OGG: 'audio/ogg',
    CSV: '.csv',
    XLSX: '.xlsx',
}

Object.freeze(FILE_FORMATS)

export const FILE_TYPES = {
    IMAGE: 'IMAGE',
    AUDIO: 'AUDIO',
    PDF_DOCUMENT: 'PDF_DOCUMENT',
    QUESTION_BANKS: 'QUESTION_BANKS',
}

export const FILE_TYPES_FORMATS = {
    [FILE_TYPES.IMAGE]: [FILE_FORMATS.JPEG, FILE_FORMATS.PNG, FILE_FORMATS.GIF],
    [FILE_TYPES.AUDIO]: [FILE_FORMATS.MPEG, FILE_FORMATS.WAV, FILE_FORMATS.OGG],
    [FILE_TYPES.PDF_DOCUMENT]: [FILE_FORMATS.PDF],
    [FILE_TYPES.QUESTION_BANKS]: [FILE_FORMATS.CSV, FILE_FORMATS.XLSX],
}

export const FILE_TYPES_MAX_SIZES = {
    [FILE_TYPES.IMAGE]: 10485760,
    [FILE_TYPES.AUDIO]: 5242880,
    [FILE_TYPES.PDF_DOCUMENT]: 52428800,
    [FILE_TYPES.QUESTION_BANKS]: 5242880,
}
