import HttpClient from './instance'

export const GET_BANKS = async () =>
    HttpClient({
        method: 'get',
        url: `/questions-banks/public`,
        params: {
            size: 1000,
            sort: 'name,asc',
        },
    })

export const PARSE_FILE = async file => {
    const formData = new FormData()
    formData.append('file', file)

    return await HttpClient.post('/admin/questions-banks/parse', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const DELETE_BANK = async bankId =>
    HttpClient({
        method: 'delete',
        url: `/admin/questions-banks/${bankId}`,
    })

export const CREATE_BANK = async (name, questions) =>
    HttpClient({
        method: 'post',
        url: `/admin/questions-banks`,
        data: {
            name,
            questions,
        },
    })

export const GET_BANK_QUESTIONS = async bankId =>
    HttpClient({
        method: 'get',
        url: `/admin/questions-banks/${bankId}/questions`,
        params: { size: 1000, sort: 'orderNumber,asc' },
    })

export const UPDATE_BANK_QUESTIONS = (bankId, name, questions, removedQuestionsIds) =>
    HttpClient({
        method: 'patch',
        url: `/admin/questions-banks/${bankId}`,
        data: {
            bankId,
            name,
            questions,
            removedQuestionsIds,
        },
    })
