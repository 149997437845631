import axios from 'axios'

const DeeplHttpClient = axios.create({
    baseURL: process.env.REACT_APP_DEEPL_API_URL,
    timeout: 30000,
})
DeeplHttpClient.interceptors.request.use(
    config => {
        config.params = {
            ...config.params,
            auth_key: process.env.REACT_APP_DEEPL_API_KEY,
        }
        return config
    },
    err => Promise.reject(err),
)
DeeplHttpClient.interceptors.response.use(
    async res => res.data,
    err => Promise.reject(err),
)

export default DeeplHttpClient
