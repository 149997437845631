import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Table from 'components/Table/Table'
import DateRangeFilter from 'components/Table/components/Filters/DateRangeFilter'
import Avatar from 'components/Avatar/Avatar'
import Button from 'components/Forms/Button/Button'

import ROUTES from 'constants/routes'
import { PAYOUTS_STATUSES } from 'constants/payouts'

import { getUserName } from 'utils/user'
import { copyToClipboard } from 'utils/clipboard'

import { MODALS } from '../../constants'

import { getPayoutStatusColor, isCanChangeStatus } from './utils'

import iconCopy from 'images/icons/copy.svg'
import editIcon from 'images/icons/edit.svg'

import styles from './Payouts.module.scss'

const getColumns = ({ methods, requestParams, isHasExchangeRate }) => [
    {
        headerLabel: 'ID',
        getMarks: payout => [
            {
                image: iconCopy,
                label: 'Copy ID to clipboard',
                onClick: () => copyToClipboard(payout.id),
            },
        ],
        getTitle: payout => payout.id,
        getValue: payout => payout.id,
        width: '15%',
    },
    {
        headerLabel: 'Created by',
        getTitle: payout =>
            getUserName({ firstName: payout.firstName, lastName: payout.lastName, login: payout.email }),
        getValue: payout => (
            <Link to={`${ROUTES.USERS.path}/${payout.userId}`} style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar url={payout.avatar} style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }} />
                {getUserName({ firstName: payout.firstName, lastName: payout.lastName, login: payout.email })}
            </Link>
        ),
        width: '10%',
    },
    {
        headerLabel: 'Amount',
        getTitle: payout => `${payout.amount / 100} ${payout.currency}`,
        getValue: payout => `${payout.amount / 100} ${payout.currency}`,
        width: '10%',
    },
    {
        headerLabel: 'Sent amount',
        getTitle: payout => {
            if (!payout.totalAmount && !payout.transferAmountCurrency) return null
            return `${payout.totalAmount / 100} ${payout.transferAmountCurrency}`
        },
        getValue: payout => {
            if (!payout.totalAmount && !payout.transferAmountCurrency) return null
            return `${payout.totalAmount / 100} ${payout.transferAmountCurrency}`
        },
        width: '10%',
    },
    {
        headerLabel: 'Status',
        getTitle: payout => payout.paymentStatus,
        getValue: payout => (
            <div className={styles.paymentStatus}>
                <span
                    style={{
                        color: getPayoutStatusColor(payout.paymentStatus),
                    }}
                >
                    {payout.paymentStatus}
                </span>
                {isCanChangeStatus(payout.paymentStatus) && (
                    <img
                        title="Edit"
                        src={editIcon}
                        alt="edit"
                        onClick={() => methods.openModal(MODALS.CHANGE_PAYOUT_STATUS, payout)}
                    />
                )}
            </div>
        ),
        width: '10%',
    },
    {
        headerLabel: 'Note',
        getTitle: payout => payout.note,
        getValue: payout => payout.note,
        width: '15%',
    },
    {
        headerLabel: 'Created at',
        headerFilter: (
            <DateRangeFilter
                initialValueFrom={requestParams.from}
                initialValueTo={requestParams.to}
                onChange={methods.changeRequestParamsAndRefresh}
            />
        ),
        getTitle: payout => moment(payout.created).format('MMM D, YYYY (HH:mm)'),
        getValue: payout => moment(payout.created).format('MMM D, YYYY (HH:mm)'),
        width: '15%',
    },
    {
        headerLabel: '',
        getValue: payout => {
            if (!payout.isPayoutsEnabled) return <span className={styles.payoutsDisabled}>Payouts is disabled</span>
            if (payout.paymentStatus !== PAYOUTS_STATUSES.PENDING) return

            if (!isHasExchangeRate) return <span className={styles.exchangeRateNotSet}>Exchange rate is not set</span>
            return (
                <Button
                    content="Payout"
                    variant="primary"
                    isInverse={true}
                    onClick={() => methods.openModal(MODALS.FORCE_PAYOUT, payout)}
                />
            )
        },
        valueStyles: {
            justifyContent: 'flex-end',
        },
        width: '15%',
    },
]

const PayoutsTable = ({
    title,
    list,
    isHasExchangeRate,
    methods,
    requestParams,
    pagination,
    onExpand,
    onChangePage,
    isLoading,
}) => (
    <Table
        columns={getColumns({ methods, requestParams, isHasExchangeRate })}
        title={title}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default PayoutsTable
