import React from 'react'

import Table from 'components/Table/Table'
import Button from 'components/Forms/Button/Button'

import { copyToClipboard } from 'utils/clipboard'

import iconCopy from 'images/icons/copy.svg'

import { CURRENCIES } from 'constants/currencies'

import { MODALS } from '../../constants'

import PriceBlock from './components/PriceBlock/PriceBlock'

const getColumns = ({ methods }) => [
    {
        headerLabel: 'ID',
        getMarks: product => [
            {
                image: iconCopy,
                label: 'Copy to clipboard',
                onClick: () => copyToClipboard(product.id),
            },
        ],
        getTitle: product => product.id,
        getValue: product => product.id,
        width: '10%',
    },
    {
        headerLabel: 'Product Code',
        getMarks: product => [
            {
                image: iconCopy,
                label: 'Copy to clipboard',
                onClick: () => copyToClipboard(product.productCode),
            },
        ],
        getTitle: product => product.productCode,
        getValue: product => product.productCode,
        width: '10%',
    },
    {
        headerLabel: 'Product ID',
        getMarks: product => [
            {
                image: iconCopy,
                label: 'Copy to clipboard',
                onClick: () => copyToClipboard(product.productId),
            },
        ],
        getTitle: product => product.productId,
        getValue: product => product.productId,
        width: '10%',
    },
    {
        headerLabel: 'Group',
        getTitle: product => product.group,
        getValue: product => product.group,
        width: '10%',
    },
    {
        headerLabel: 'Name',
        getTitle: product => product.name,
        getValue: product => product.name,
        width: '15%',
    },
    {
        headerLabel: 'Weight',
        getTitle: product => product.weight,
        getValue: product => product.weight,
        width: '5%',
    },
    {
        headerLabel: `Billing Cycle`,
        getValue: product => {
            if (product.billingCycleValue === -1) return 'Lifetime'
            if (product.billingCycleValue === 1) return 'Monthly'
            if (product.billingCycleValue === 12) return 'Yearly'
        },
        width: '10%',
    },
    {
        headerLabel: `Price (${CURRENCIES.USD})`,
        getValue: product => (
            <PriceBlock
                price={product.purchasePrice}
                billingCycle={product.billingCycleValue}
                currency={CURRENCIES.USD}
            />
        ),
        width: '10%',
    },
    {
        headerLabel: `Price (${CURRENCIES.RUB})`,
        getValue: product => (
            <PriceBlock
                price={product.purchaseRussianStorePriceInRub}
                billingCycle={product.billingCycleValue}
                isShowEditButton
                currency={CURRENCIES.RUB}
                onEdit={() => methods.onOpenModal(MODALS.EDIT_PRICE, product)}
            />
        ),
        width: '10%',
    },
    {
        getValue: product => (
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                    content="Features"
                    variant="primary"
                    isInverse={true}
                    onClick={() => methods.onOpenModal(MODALS.FEATURES, product)}
                />
            </div>
        ),
        width: '10%',
    },
]

const ProductsTable = ({ title, list, methods, isLoading }) => (
    <Table columns={getColumns({ methods })} isShowCounter={false} title={title} list={list} isLoading={isLoading} />
)

export default ProductsTable
