import React, { useEffect } from 'react'
import ReactSelect from 'react-select'
import classNames from 'classnames'

import './Select.scss'

const Select = ({
    error = '',
    notice,
    isDisabled = false,
    value = null,
    options = [],
    placeholder = 'Select',
    onChange = () => {},
    isPortal = true,
    className,
}) => {
    const styles = {
        control: (provided, state) => ({
            ...provided,
            height: 36,
            minHeight: 36,
            borderRadius: '4px',
            borderColor: error.length ? '#FA655C' : state.isFocused ? '#B3B3B3' : '#D8D8D8',
            backgroundColor: '#FFF',
            boxShadow: state.isFocused ? 0 : 0,
            cursor: 'pointer',
            '&:hover': {
                borderColor: '#B3B3B3',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: state.isSelected ? 'default' : 'pointer',
            pointerEvents: state.isSelected ? 'none' : 'auto',
            color: state.isSelected ? '#2990FB' : '#555555',
            backgroundColor: state.isSelected ? '#F9FAFD' : '#FFFFFF',
            '&:hover': {
                color: '#2990FB',
            },
        }),
        menu: provided => ({
            ...provided,
            borderRadius: 0,
            padding: 0,
            zIndex: 10,
        }),
        menuList: provided => ({
            ...provided,
            overflow: 'auto',
            maxHeight: 300,
            padding: 0,
        }),
        singleValue: provided => ({
            ...provided,
            fontFamily: 'Roboto, sans-serif',
            fontSize: 13,
            fontWeight: 'normal',
            color: '#555555',
            margin: '0',
            padding: '4px 0',
        }),
        menuPortal: base => ({ ...base, zIndex: 99999999999999 }),
        placeholder: provided => ({
            ...provided,
            fontFamily: 'Roboto, sans-serif',
            fontSize: 13,
            fontWeight: 'normal',
            color: '#878787',
            margin: '0',
            padding: '4px 0',
        }),
        input: provided => ({
            ...provided,
            margin: '0',
        }),
        dropdownIndicator: provided => ({
            ...provided,
            padding: 4,
        }),
        container: provided => ({
            ...provided,
            width: '100%',
        }),
    }

    useEffect(() => {
        if (options.length !== 1) return
        onChange(options[0])
    }, [])

    return (
        <div className={classNames('common__select', { 'is-disabled': isDisabled, [className]: !!className })}>
            <ReactSelect
                menuPortalTarget={isPortal ? document.body : undefined}
                menuPosition="absolute"
                isDisabled={isDisabled}
                options={options}
                value={value}
                placeholder={placeholder}
                styles={styles}
                onChange={value => onChange(value)}
                getOptionLabel={props => {
                    const { icon, label } = props
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {icon && <img src={icon} alt="" style={{ paddingRight: 10 }} />}
                            <span>{label}</span>
                        </div>
                    )
                }}
            />
            {error && error.length ? <p className="error">{error}</p> : ''}
            {notice ? <p className="notice">{notice}</p> : ''}
        </div>
    )
}

export default Select
