import React, { useState } from 'react'
import classNames from 'classnames'

import { ROLE_TAGS } from 'utils/tag'

import Business from './svg/Business'
import Education from './svg/Education'

import styles from './RoleMarks.module.scss'

const RoleMarks = ({ roleTags, activeTags = [], onChange, style = {} }) => {
    const [isLoading, setIsLoading] = useState(false)

    if (!roleTags?.length) return null

    const handleClick = async tag => {
        setIsLoading(true)
        try {
            await onChange(tag)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <ul className={classNames(styles.roleMarks, { [styles.isDisabledRoleMarks]: isLoading })} style={style}>
            {roleTags
                .filter(tag => [ROLE_TAGS.EDUCATION, ROLE_TAGS.BUSINESS].includes(tag.name))
                .map(tag => (
                    <li
                        key={tag.name}
                        className={classNames(styles.role, { [styles.isClickableRole]: !!onChange })}
                        onClick={() => (onChange ? handleClick(tag) : {})}
                    >
                        {tag.name === ROLE_TAGS.BUSINESS && (
                            <div className={styles.iconBox} title="Business">
                                <Business isActive={activeTags.includes(tag.name)} />
                            </div>
                        )}
                        {tag.name === ROLE_TAGS.EDUCATION && (
                            <div className={styles.iconBox} title="Education">
                                <Education isActive={activeTags.includes(tag.name)} />
                            </div>
                        )}
                    </li>
                ))}
        </ul>
    )
}

export default RoleMarks
