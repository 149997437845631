import React from 'react'

import styles from './Progress.module.scss'

const Progress = ({ count, totalCount, successCount, failedCount }) => {
    return (
        <div className={styles.progress}>
            <div className={styles.main}>
                {count} / {totalCount}
            </div>
            <div className={styles.system}>
                <span className={styles.systemSuccess}>Success:</span> {successCount}{' '}
                <span className={styles.systemDivider}>|</span> <span className={styles.systemFailed}>Failed:</span>{' '}
                {failedCount}
            </div>
        </div>
    )
}

export default Progress
