import React, { useState } from 'react'
import ReactDragListView from 'react-drag-listview'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

import { BLOCKS_LABELS } from '../../../../constants'

import AddBlockModal from './components/AddBlockModal/AddBlockModal'

import crossIcon from './i/cross.svg'
import dragIcon from './i/drag.svg'

import styles from './Blocks.module.scss'

const Blocks = ({ blocks, availableBlockTypes, onUpdateBlocks, onAddBlock }) => {
    const [isOpenedBlockModal, setIsOpenedBlockModal] = useState(false)

    return (
        <>
            <ReactDragListView
                onDragEnd={(fromIndex, toIndex) => {
                    if (toIndex === -1) return
                    const _blocks = cloneDeep(blocks)
                    const item = _blocks.splice(fromIndex, 1)[0]
                    _blocks.splice(toIndex, 0, item)
                    onUpdateBlocks(_blocks)
                }}
                nodeSelector="li.draggable-item"
                handleSelector="span.draggable-item__drag-point"
            >
                <div className={styles.title}>Blocks</div>
                <ul className={styles.blocks}>
                    {blocks.map(block => (
                        <li key={block.id} className={classNames('draggable-item', styles.item)}>
                            {BLOCKS_LABELS[block.type]}
                            <ul className={styles.actions}>
                                {blocks.length > 1 && (
                                    <>
                                        {/*<li*/}
                                        {/*    title={'Remove'}*/}
                                        {/*    className={styles.action}*/}
                                        {/*    onClick={() =>*/}
                                        {/*        onUpdateBlocks(prevState =>*/}
                                        {/*            prevState.filter(item => item.id !== block.id),*/}
                                        {/*        )*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    <img src={crossIcon} alt="remove" />*/}
                                        {/*</li>*/}
                                        <li className={styles.action} title={'Change position'}>
                                            <span
                                                className={classNames('draggable-item__drag-point', styles.dragPoint)}
                                            >
                                                <img src={dragIcon} alt="drag" />
                                            </span>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </li>
                    ))}
                    {!!availableBlockTypes.length && (
                        <li
                            className={classNames(styles.item, styles.isAddItem)}
                            onClick={() => setIsOpenedBlockModal(true)}
                        >
                            + Add
                        </li>
                    )}
                </ul>
            </ReactDragListView>

            {isOpenedBlockModal && (
                <AddBlockModal
                    blocks={availableBlockTypes.map(blockType => ({ value: blockType, label: blockType }))}
                    onClose={() => setIsOpenedBlockModal(false)}
                    onSubmit={blockType => onAddBlock(blockType)}
                />
            )}
        </>
    )
}

export default Blocks
