// Types
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'

// Actions
export const signIn = payload => ({
    type: SIGN_IN,
    payload,
})
export const signOut = () => ({
    type: SIGN_OUT,
})
