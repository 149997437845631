import React from 'react'

import Table from 'components/Table/Table'
import InputFilter from 'components/Table/components/Filters/InputFilter'
import SelectFilter from 'components/Table/components/Filters/SelectFilter'
import Toggle from 'components/Forms/Toggle/Toggle'
import Tags from 'components/Tags/Tags'
import TagsFilter from 'components/Table/components/Filters/TagsFilter'
import RoleMarks from 'components/Table/components/RoleMarks/RoleMarks'

import { convertToList, findTranslation } from 'utils/translations'
import { copyToClipboard } from 'utils/clipboard'
import { getSortKeys } from 'utils/table'
import { encodeOrDecodeJsonStructure, openUrlInNewTab } from 'utils/common'
import { getLanguageTagLocaleDetails, LANGUAGE_TAG_PREFIX } from 'utils/tag'

import SORT_ORDERS from 'constants/sortOrders'
import { PROJECT_MODES } from 'constants/projects'

import ActionsList from './components/ActionsList/ActionsList'

import iconCopy from 'images/icons/copy.svg'
import iconPdf from 'images/icons/pdf.png'
import iconConsole from 'images/icons/console.png'
import iconWorldFlag from 'images/icons/flags/world.svg'

const convertTranslation = translations => {
    if (!translations) return []
    return convertToList(translations.reduce((acc, value) => (acc = { ...acc, [value.languageCode]: value.text }), {}))
}

const getColumns = ({ methods, requestParams, payload }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.id}
                    type="number"
                    field="id"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: template => [
                {
                    image: iconCopy,
                    label: 'Copy ID to clipboard',
                    onClick: () => copyToClipboard(template.id),
                },
            ],
            getTitle: template => template.id,
            getValue: template => template.id,
            width: '7%',
            sort: {
                key: 'id',
                order: sort.key === 'id' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Name',
            headerFilter: (
                <InputFilter
                    initialValue={requestParams.name}
                    field="name"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: template => {
                const result = []

                if (template.mode === PROJECT_MODES.MULTIPLAYER) {
                    result.push({
                        image: iconConsole,
                        label: 'This is multiplayer template',
                    })
                }

                if (template.projectPdfUrl) {
                    result.push({
                        image: iconPdf,
                        label: 'This template has PDF file',
                        onClick: () => openUrlInNewTab(template.projectPdfUrl),
                    })
                }

                return result
            },
            getTitle: template => findTranslation(template.nameTranslation.translations, 'en', ''),
            getValue: template => findTranslation(template.nameTranslation.translations, 'en', ''),
            width: '14%',
        },
        {
            headerLabel: 'Enabled',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.isEnabled}
                    field="isEnabled"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        {
                            value: true,
                            label: 'Yes',
                        },
                        {
                            value: false,
                            label: 'No',
                        },
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: template => (template.enabled ? 'Yes' : 'No'),
            getValue: template => (
                <Toggle
                    value={template.enabled}
                    onChange={value =>
                        methods.updateTemplate({
                            ...template,
                            projectStructureJson: JSON.stringify(
                                encodeOrDecodeJsonStructure(template.projectStructureJson, 'encode'),
                            ),
                            name: convertTranslation(template.nameTranslation?.translations),
                            description: convertTranslation(template.descriptionTranslation?.translations),
                            enabled: value,
                        })
                    }
                />
            ),
            width: '8%',
        },
        {
            headerLabel: 'Premium',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.isPremium}
                    field="isPremium"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        {
                            value: true,
                            label: 'Yes',
                        },
                        {
                            value: false,
                            label: 'No',
                        },
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: template => (template.isPremium ? 'Yes' : 'No'),
            getValue: template => (template.isPremium ? 'Yes' : 'No'),
            width: '8%',
        },
        {
            headerLabel: 'Order number',
            getTitle: template => template.orderNumber,
            getValue: template => template.orderNumber,
            sort: {
                key: 'orderNumber,id',
                order: sort.key === 'orderNumber,id' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '8%',
        },
        {
            headerLabel: 'Uses',
            getTitle: template => template.usedTimes,
            getValue: template => template.usedTimes,
            width: '6%',
        },
        {
            headerLabel: 'Role & Lang',
            headerFilter: () => {
                const tags = requestParams.tags ? requestParams.tags.split(',') : []

                return (
                    <div>
                        <RoleMarks
                            style={{ marginBottom: 6 }}
                            roleTags={payload.tagsData.role.list}
                            activeTags={tags}
                            onChange={async tag => {
                                if (tags.includes(tag.name)) {
                                    const newTags = tags.filter(tagName => tagName !== tag.name)
                                    await methods.changeRequestParamsAndRefresh([
                                        {
                                            field: 'tags',
                                            value: newTags.length ? newTags.join(',') : null,
                                        },
                                    ])
                                } else {
                                    await methods.changeRequestParamsAndRefresh([
                                        {
                                            field: 'tags',
                                            value: requestParams.tags ? `${requestParams.tags},${tag.name}` : tag.name,
                                        },
                                    ])
                                }
                            }}
                        />
                        <SelectFilter
                            initialValue={requestParams.language}
                            isReturnOnlyOption
                            options={[
                                {
                                    value: null,
                                    label: 'All',
                                },
                                ...payload.tagsData.language.options.map(locale => ({
                                    value: locale.value,
                                    label: '',
                                    icon: locale.icon || iconWorldFlag,
                                })),
                            ]}
                            onChange={async tagName => {
                                const newTags = tags.filter(tag => !tag.startsWith(LANGUAGE_TAG_PREFIX))
                                if (!tagName) {
                                    await methods.changeRequestParamsAndRefresh([
                                        {
                                            field: 'tags',
                                            value: newTags.length ? newTags.join(',') : null,
                                        },
                                    ])
                                } else {
                                    await methods.changeRequestParamsAndRefresh([
                                        {
                                            field: 'tags',
                                            value: newTags.length ? `${newTags.join(',')},${tagName}` : tagName,
                                        },
                                    ])
                                }
                            }}
                        />
                    </div>
                )
            },
            getValue: template => {
                const locale = getLanguageTagLocaleDetails(template.languageTemplateTag)
                return (
                    <div>
                        <RoleMarks style={{ marginBottom: 6 }} roleTags={template.roleTemplateTag} />
                        <img
                            style={{ width: 32 }}
                            src={locale?.icon || iconWorldFlag}
                            title={locale?.engLabel || 'Any'}
                            alt=""
                        />
                    </div>
                )
            },
            width: '6%',
        },
        {
            headerLabel: 'Tags',
            headerFilter: (
                <TagsFilter
                    initialValue={requestParams.tags}
                    field="tags"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: template => `${template.tags.length} tags`,
            getValue: template => (
                <Tags
                    selectedTagsList={template.tags}
                    onUpdate={tags =>
                        methods.updateTemplate({
                            ...template,
                            projectStructureJson: JSON.stringify(
                                encodeOrDecodeJsonStructure(template.projectStructureJson, 'encode'),
                            ),
                            name: convertTranslation(template.nameTranslation?.translations),
                            description: convertTranslation(template.descriptionTranslation?.translations),
                            tags,
                        })
                    }
                    onRemove={({ id }) =>
                        methods.updateTemplate({
                            ...template,
                            projectStructureJson: JSON.stringify(
                                encodeOrDecodeJsonStructure(template.projectStructureJson, 'encode'),
                            ),
                            name: convertTranslation(template.nameTranslation?.translations),
                            description: convertTranslation(template.descriptionTranslation?.translations),
                            tags: template.tags.filter(el => el.id !== id),
                        })
                    }
                />
            ),
            width: '26%',
        },
        {
            headerLabel: 'Image',
            getValue: template => (template.imageUrl ? <img src={template.imageUrl} alt="" /> : null),
            width: '8%',
        },
        {
            headerLabel: '',
            getValue: template => <ActionsList template={template} methods={methods} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '8%',
        },
    ]
}

const TemplatesTable = ({ list, methods, requestParams, pagination, onExpand, onChangePage, isLoading, payload }) => (
    <Table
        columns={getColumns({ methods, requestParams, payload })}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default TemplatesTable
