import React from 'react'
import classNames from 'classnames'

import styles from './Marks.module.scss'

const Marks = ({ isSystem = false, isInterests = false }) => (
    <ul className={styles.marks}>
        {isSystem && (
            <li className={classNames(styles.mark, styles.isSystemMark)} title="This is a system tag">
                S
            </li>
        )}
        {isInterests && (
            <li className={classNames(styles.mark, styles.isInterestsMark)} title="This is a interests tag">
                I
            </li>
        )}
    </ul>
)

export default Marks
