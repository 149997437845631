import { USER_ROLES } from 'constants/user'

export const getUserName = ({ firstName, lastName, login } = {}, isOnlyInitials = false) => {
    const result = []
    if (firstName && firstName.length) {
        result.push(firstName)
        if (lastName && lastName.length) {
            result.push(lastName)
        }
    } else {
        if (!login) return ''
        result.push(login)
    }

    if (isOnlyInitials) {
        return result.reduce((acc, item) => (acc += item.charAt(0)), '')
    }
    return result.join(' ')
}

export const getAuthorName = ({ firstName, lastName } = {}, isOnlyInitials = false) => {
    const result = [firstName, lastName]
    if (isOnlyInitials) {
        return result.reduce((acc, item) => (acc += item.charAt(0)), '')
    }
    return result.join(' ')
}

export const getUserAvatar = ({ avatarUrl } = {}) => avatarUrl || null

export const isBusinessUserRole = user => {
    if (!user) return false
    if (user.fromAppSumo) return false
    return user.roleInProjectDto ? user.roleInProjectDto.userGroupInProjectDto.customId !== USER_ROLES.EDUCATION : false
}
export const isEducationUserRole = user => {
    if (!user) return false
    if (user.fromAppSumo) return false
    return user.roleInProjectDto ? user.roleInProjectDto.userGroupInProjectDto.customId === USER_ROLES.EDUCATION : false
}
