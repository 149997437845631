import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Upload from 'components/Forms/Upload/Upload'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import Select from 'components/Forms/Select/Select'
import JsonCodeBlock from 'components/Forms/CodeBlock/JSON/JSON'
import FormBlock from 'components/Forms/FormBlock'
import LocaleFormBlock, { CONTROL_TYPES } from 'components/Forms/components/LocaleFormBlock/LocaleFormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

import {
    fieldsValidator,
    getIsValueRule,
    getIsIntStringRule,
    getIsNotEmptyStringRule,
    getIsJsonStringRule,
} from 'utils/fieldsValidator'
import { encodeOrDecodeJsonStructure, setKeyToObject, removeKeyFromObject } from 'utils/common'
import {
    getLocaleDependentTextFieldsStateObject,
    addLocaleDependentTextFieldsToValidator,
    convertToList,
} from 'utils/translations'

import { DEFAULT_LOCALE } from 'constants/locales'
import { getBlocks, getBlockNameById } from 'constants/blocks'

const LOCALE_DEPENDENT_FIELDS = {
    NAME: {
        fieldName: 'name',
        translationsFieldKey: 'name',
    },
    DESCRIPTION: {
        fieldName: 'description',
        translationsFieldKey: 'description',
    },
}

const EditTemplateModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => {
    const { template, translations } = data

    const initialLocaleDependentFields = getLocaleDependentTextFieldsStateObject(LOCALE_DEPENDENT_FIELDS, translations)

    const [localeDependentFields, setLocaleDependentFields] = useState(initialLocaleDependentFields)

    const [activeNameLocale, setActiveNameLocale] = useState(DEFAULT_LOCALE)
    const [activeDescriptionLocale, setActiveDescriptionLocale] = useState(DEFAULT_LOCALE)

    const [isShowDescription, setIsShowDescription] = useState(!!translations.description)

    const [blockType, setBlockType] = useState({
        value: template.blockTypeId,
        label: getBlockNameById(template.blockTypeId) || '[Unknown block]',
    })

    const [isEnabled, setIsEnabled] = useState(template.enabled)

    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')

    const [orderNumber, setOrderNumber] = useState(template.orderNumber)

    const [structureJson, setStructureJson] = useState(template.structureJson)

    const [errors, setErrors] = useState({})

    const blocks = getBlocks()
    const blockTypesList = blocks.map(el => ({
        value: el.id,
        label: el.name,
    }))

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            addLocaleDependentTextFieldsToValidator(validator, {
                fields: LOCALE_DEPENDENT_FIELDS,
                values: localeDependentFields,
                excludeFields: {
                    [LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName]: !isShowDescription,
                },
            })
            validator.addFields([
                validator.createField('blockType', blockType, [getIsValueRule()]),
                validator.createField('orderNumber', String(orderNumber), [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsIntStringRule(),
                ]),
                validator.createField('structureJson', structureJson, [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsJsonStringRule(),
                ]),
            ])

            if (image) {
                validator.createField('image', imageUrl, [
                    getIsValueRule(),
                    getIsNotEmptyStringRule('File must be uploaded'),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const nameTransl = localeDependentFields[LOCALE_DEPENDENT_FIELDS.NAME.fieldName]
            const newNameTransl = convertToList(nameTransl)
            const descriptionTransl = localeDependentFields[LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName]
            const newDescriptionTransl = convertToList(descriptionTransl)

            await onSubmit({
                id: template.id,
                imageUrl: imageUrl.length ? imageUrl : template.imageUrl,
                isShowDescription,
                structureJson: JSON.stringify(encodeOrDecodeJsonStructure(JSON.parse(structureJson), 'encode')),
                enabled: isEnabled,
                blockTypeId: blockType.value,
                orderNumber: parseInt(orderNumber),
                name: newNameTransl,
                description: isShowDescription ? newDescriptionTransl : [],
            })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit template"
            subHeadText="Note: If the file field is left blank then will be applied to the old value"
            successText="Template updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <LocaleFormBlock
                label="Name"
                errors={errors}
                fields={localeDependentFields}
                fieldName={LOCALE_DEPENDENT_FIELDS.NAME.fieldName}
                activeTabName={activeNameLocale}
                onChangeActiveTabName={v => setActiveNameLocale(v)}
                onChange={data => {
                    setLocaleDependentFields(data.value)
                    setErrors(data.errors)
                }}
            />
            <FormBlock>
                <Toggle
                    value={isShowDescription}
                    label="Show description"
                    onChange={value => {
                        setIsShowDescription(value)
                        setErrors(removeKeyFromObject(errors, 'isShowDescription'))
                    }}
                />
            </FormBlock>
            {isShowDescription && (
                <LocaleFormBlock
                    label="Description"
                    controlType={CONTROL_TYPES.TEXTAREA}
                    errors={errors}
                    fields={localeDependentFields}
                    fieldName={LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName}
                    activeTabName={activeDescriptionLocale}
                    onChangeActiveTabName={v => setActiveDescriptionLocale(v)}
                    onChange={data => {
                        setLocaleDependentFields(data.value)
                        setErrors(data.errors)
                    }}
                />
            )}
            <br />
            <FormBlock>
                <Toggle
                    value={isEnabled}
                    label="Enabled"
                    onChange={value => {
                        setIsEnabled(value)
                        setErrors(removeKeyFromObject(errors, 'isEnabled'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Type">
                <Select
                    error={errors.blockType}
                    isDisabled={false}
                    value={blockType}
                    options={blockTypesList}
                    onChange={value => {
                        setBlockType(value)
                        setErrors(removeKeyFromObject(errors, 'blockType'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Image">
                <Upload
                    lastUrl={template.imageUrl}
                    value={image}
                    error={errors.image}
                    isUploaded={!!imageUrl}
                    onReset={() => {
                        setImageUrl('')
                        setImage(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'image', errorText))
                            return
                        }
                        setImageUrl(url)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                    onChange={file => {
                        setImageUrl('')
                        setImage(file)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Order number">
                <Input
                    type="number"
                    min={1}
                    value={orderNumber}
                    error={errors.orderNumber}
                    onChange={value => {
                        setOrderNumber(value)
                        setErrors(removeKeyFromObject(errors, 'orderNumber'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Template structure (JSON)">
                <JsonCodeBlock
                    value={structureJson}
                    error={errors.structureJson}
                    onChange={value => {
                        setStructureJson(value)
                        setErrors(removeKeyFromObject(errors, 'structureJson'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default EditTemplateModal
