import React, { useState } from 'react'
import { MaterialPicker } from 'react-color'
import OutsideClickHandler from 'react-outside-click-handler'

import Input from 'components/Forms/Input/Input'

import transparentBackgroundImage from 'images/transparentBackground.jpeg'

import './ColorPicker.scss'

const ColorPicker = ({ value, error, onChange }) => {
    const [isOpened, setIsOpened] = useState(false)

    return (
        <div className="common__color">
            <div className="common__color__input-box">
                <div
                    className="preview"
                    style={{
                        backgroundColor: value || 'transparent',
                        backgroundImage: value ? 'none' : `url(${transparentBackgroundImage})`,
                    }}
                    onClick={() => setIsOpened(true)}
                ></div>
                <Input
                    value={value}
                    error={error}
                    isReadOnly={true}
                    placeholder="Click to select"
                    isClearable={true}
                    onChange={() => onChange('')}
                    onClick={() => setIsOpened(true)}
                />
            </div>
            {isOpened && (
                <OutsideClickHandler onOutsideClick={() => setIsOpened(false)}>
                    <div className="common__color__picker">
                        <MaterialPicker color={value} onChangeComplete={color => onChange(color)} />
                    </div>
                </OutsideClickHandler>
            )}
        </div>
    )
}

export default ColorPicker
