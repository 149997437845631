import React, { useState, useEffect } from 'react'

import useAudioPlayer from 'hooks/useAudioPlayer'

import iconPause from 'images/icons/pause.svg'
import iconPlay from 'images/icons/play.svg'

import styles from './AudioButton.module.scss'

const AudioButton = ({ voiceId, voices }) => {
    const { onPlayAudio, playedUrl, onStop } = useAudioPlayer()

    const [url, setUrl] = useState(null)

    useEffect(() => {
        onStop()
        const voice = voices.find(v => v.voice_id === voiceId)
        if (voice) setUrl(voice.preview_url)
        else setUrl(null)
    }, [voiceId, voices])

    if (!url) return null

    return (
        <div className={styles.audioButton} onClick={() => onPlayAudio(url)}>
            <img src={playedUrl === url ? iconPause : iconPlay} alt="" />
        </div>
    )
}

export default AudioButton
