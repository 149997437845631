import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Tabs from 'components/Tabs/Tabs'

import ROUTES from 'constants/routes'

import TagsTab from './Tabs/Tags/Tags'
import SectionsTab from './Tabs/Sections/Sections'
import InteractyTemplatesTab from './Tabs/InteractyTemplates/InteractyTemplates'
import CommunityTemplatesTab from './Tabs/CommunityTemplates/CommunityTemplates'

import './TemplateGallery.scss'

class TemplateGallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <>
                <Tabs
                    isVisibleBottomLine={false}
                    list={[
                        {
                            to: `${ROUTES.TEMPLATE_GALLERY.path}/interacty-templates`,
                            label: 'Interacty templates',
                        },
                        {
                            to: `${ROUTES.TEMPLATE_GALLERY.path}/community-templates`,
                            label: 'Community templates',
                        },
                        {
                            to: `${ROUTES.TEMPLATE_GALLERY.path}/sections`,
                            label: 'Sections',
                        },
                        {
                            to: `${ROUTES.TEMPLATE_GALLERY.path}/tags`,
                            label: 'Tags',
                        },
                    ]}
                />

                <div className="divider" />

                <br />
                <br />

                <Switch>
                    <Route
                        exact
                        path={`${ROUTES.TEMPLATE_GALLERY.path}/interacty-templates`}
                        render={() => (
                            <InteractyTemplatesTab location={this.props.location} history={this.props.history} />
                        )}
                    />
                    <Route
                        exact
                        path={`${ROUTES.TEMPLATE_GALLERY.path}/community-templates`}
                        render={() => (
                            <CommunityTemplatesTab location={this.props.location} history={this.props.history} />
                        )}
                    />
                    <Route exact path={`${ROUTES.TEMPLATE_GALLERY.path}/tags`} render={() => <TagsTab />} />
                    <Route
                        exact
                        path={`${ROUTES.TEMPLATE_GALLERY.path}/sections`}
                        render={() => <SectionsTab location={this.props.location} history={this.props.history} />}
                    />
                    <Redirect
                        from="*"
                        to={{
                            pathname: ROUTES.ERROR.path,
                            state: {
                                errorCode: 404,
                            },
                        }}
                    />
                </Switch>
            </>
        )
    }
}

export default TemplateGallery
