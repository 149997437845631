import React from 'react'
import { Link } from 'react-router-dom'

import ROUTES from 'constants/routes'
import SORT_ORDERS from 'constants/sortOrders'

import Table from 'components/Table/Table'
import InputFilter from 'components/Table/components/Filters/InputFilter'
import Avatar from 'components/Avatar/Avatar'

import { copyToClipboard } from 'utils/clipboard'
import { getSortKeys } from 'utils/table'
import { getUserName } from 'utils/user'

import iconCopy from 'images/icons/copy.svg'

const getColumns = ({ methods, requestParams }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            headerFilter: (
                <InputFilter
                    field="id"
                    initialValue={requestParams.id}
                    type="number"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getMarks: organization => [
                {
                    image: iconCopy,
                    label: 'Copy ID to clipboard',
                    onClick: () => copyToClipboard(organization.id),
                },
            ],
            getTitle: organization => organization.id,
            getValue: organization => organization.id,
            width: '15%',
            sort: {
                key: 'id',
                order: sort.key === 'id' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Owner',
            getTitle: ({ owner }) => getUserName(owner),
            getValue: ({ owner }) => (
                <Link to={`${ROUTES.USERS.path}/${owner.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar user={owner} style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }} />
                    {getUserName(owner)}
                </Link>
            ),
            width: '25%',
        },
        {
            headerLabel: 'Name',
            getTitle: organization => organization.name,
            getValue: organization => (
                <>
                    {!!organization.logoURL?.length && (
                        <Avatar
                            url={organization.logoURL}
                            style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }}
                        />
                    )}
                    {organization.name}
                </>
            ),
            width: '30%',
        },
        {
            headerLabel: 'Members count',
            getTitle: organization => organization.membersCount,
            getValue: organization => organization.membersCount,
            width: '15%',
        },
        {
            headerLabel: 'Projects count',
            getTitle: organization => organization.projectsCount,
            getValue: organization => organization.projectsCount,
            width: '15%',
        },
    ]
}

const OrganizationsTable = ({ title, list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns({ methods, requestParams })}
        title={title}
        list={list}
        pagination={pagination}
        getRowClickHref={organization => `${ROUTES.ORGANIZATIONS.path}/${organization.id}`}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default OrganizationsTable
