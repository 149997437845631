import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const RemoveBankModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        onClose={() => onClose()}
        onAction={async () => onSubmit(data)}
        successText="Bank removed successfully!"
        headText="Please, confirm"
        noteText={`Are you sure you want to remove this bank?`}
        targetText={data.name}
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveBankModal
