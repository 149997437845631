import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const RemoveTagModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        isShowEnsure={data.isSystem}
        ensureText="This is system tag. You have to understand that this action has the potential to disrupt the operation of the application. Confirm that you are confident in your actions"
        onClose={() => onClose()}
        onAction={async () => onSubmit(data.id)}
        successText="Tag removed successfully!"
        headText="Please, confirm"
        noteText={`Are you sure you want to remove this${data.isSystem ? ' system' : ''} tag?`}
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveTagModal
