import React from 'react'

import styles from './DataItem.module.scss'

const DataItem = ({ title, value = null }) => (
    <li className={styles.dataItem}>
        <p className={styles.title}>{title}</p>
        <div className={styles.value}>{value}</div>
    </li>
)

export default DataItem
