import React from 'react'

import Table from 'components/Table/Table'
import Toggle from 'components/Forms/Toggle/Toggle'
import Tags from 'components/Tags/Tags'
import TagsFilter from 'components/Table/components/Filters/TagsFilter'
// import RoleMarks from 'components/Table/components/RoleMarks/RoleMarks'

import { findTranslation } from 'utils/translations'

import ButtonsGroup from './components/ButtonsGroup/ButtonsGroup'

import transparentBackgroundImage from 'images/transparentBackground.jpeg'

const getColumns = ({ methods, requestParams, payload }) => {
    return [
        {
            headerLabel: 'ID',
            getTitle: section => section.id,
            getValue: section => section.id,
            width: '8%',
        },
        {
            headerLabel: 'Name',
            getTitle: template => findTranslation(template.nameTranslation.translations, 'en', ''),
            getValue: template => findTranslation(template.nameTranslation.translations, 'en', ''),
            width: '14%',
        },
        {
            headerLabel: 'Color',
            getTitle: section => section.color,
            getValue: section => (
                <div
                    style={{
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '180px',
                        backgroundColor: section.color || 'transparent',
                        backgroundImage: section.color ? 'none' : `url(${transparentBackgroundImage})`,
                        width: 22,
                        height: 22,
                        borderRadius: 4,
                        border: '1px solid #D8D8D8',
                        boxSizing: 'border-box',
                    }}
                ></div>
            ),
            width: '5%',
        },
        {
            headerLabel: 'Enabled',
            getTitle: section => (section.enabled ? 'Yes' : 'No'),
            getValue: section => (
                <Toggle
                    value={section.enabled}
                    onChange={value =>
                        methods.updateSection({
                            ...section,
                            enabled: value,
                        })
                    }
                />
            ),
            width: '8%',
        },
        {
            headerLabel: 'Order number',
            getTitle: section => section.orderNumber,
            getValue: section => section.orderNumber,
            width: '8%',
        },
        // {
        //     headerLabel: 'Role',
        //     headerFilter: () => {
        //         const tags = requestParams.tags ? requestParams.tags.split(',') : []
        //
        //         return (
        //             <RoleMarks
        //                 style={{ marginBottom: 6 }}
        //                 roleTags={payload.tagsData.role.list}
        //                 activeTags={tags}
        //                 onChange={async tag => {
        //                     if (tags.includes(tag.name)) {
        //                         const newTags = tags.filter(tagName => tagName !== tag.name)
        //                         await methods.changeRequestParamsAndRefresh([
        //                             {
        //                                 field: 'tags',
        //                                 value: newTags.length ? newTags.join(',') : null,
        //                             },
        //                         ])
        //                     } else {
        //                         await methods.changeRequestParamsAndRefresh([
        //                             {
        //                                 field: 'tags',
        //                                 value: requestParams.tags ? `${requestParams.tags},${tag.name}` : tag.name,
        //                             },
        //                         ])
        //                     }
        //                 }}
        //             />
        //         )
        //     },
        //     getValue: section => <RoleMarks roleTags={section.tags} />,
        //     width: '6%',
        // },
        {
            headerLabel: 'Tags',
            headerFilter: (
                <TagsFilter
                    initialValue={requestParams.tags}
                    field="tags"
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: section => `${section.tags.length} tags`,
            getValue: section => (
                <Tags
                    isShowRoles
                    selectedTagsList={section.tags}
                    onUpdate={tags =>
                        methods.updateSection({
                            ...section,
                            tags,
                        })
                    }
                    onRemove={({ id }) =>
                        methods.updateSection({
                            ...section,
                            tags: section.tags.filter(tag => tag.id !== id),
                        })
                    }
                />
            ),
            width: '33%',
            // width: '27%',
        },
        {
            headerLabel: 'Image',
            getValue: section =>
                section.imageUrl ? <img src={section.imageUrl} alt="" title="Section image" /> : null,
            width: '10%',
        },
        {
            headerLabel: '',
            headerStyles: {
                justifyContent: 'flex-end',
            },
            getValue: section => <ButtonsGroup section={section} methods={methods} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '14%',
        },
    ]
}

const SectionsTable = ({
    title,
    list,
    methods,
    requestParams,
    pagination,
    onExpand,
    onChangePage,
    isLoading,
    payload,
}) => (
    <Table
        columns={getColumns({ methods, requestParams, payload })}
        title={title}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default SectionsTable
