import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { ResponsiveLine } from '@nivo/line'
import isInt from 'validator/lib/isInt'

import SORT_ORDERS from 'constants/sortOrders'

import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import Tooltip from './components/Tooltip/Tooltip'

import { PERIODS, PERIODS_DETAILS } from './constants'
import { getPeriodSelectOption } from './utils'

import styles from './DefaultChart.module.scss'

const DefaultChart = ({ initialPeriod = PERIODS.MONTH, initialCount = 12, method = () => {}, title }) => {
    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [chartData, setChartData] = useState(null)
    const [prevPeriod, setPrevPeriod] = useState(initialPeriod)
    const [period, setPeriod] = useState(initialPeriod)
    const [prevCount, setPrevCount] = useState(initialCount)
    const [count, setCount] = useState(initialCount)

    useEffect(() => {
        getChartData(false).finally(() => setIsReady(true))
    }, [])

    const getChartData = async (isShowSuccessToast = true) => {
        try {
            setIsLoading(true)
            await sendRequests()
            setPrevPeriod(period)
            setPrevCount(count)
            if (isShowSuccessToast) Toast(TOAST_TYPE.SUCCESS, { message: 'Chart data was updated' })
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR, { message: 'Cannot get data for chart' })
        } finally {
            setIsLoading(false)
        }
    }
    const updateChartData = data => {
        data.sort((a, b) => {
            const keyA = a.from,
                keyB = b.from
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
        })

        const result = []
        for (const chunk of data) {
            const xFrom = moment(chunk.from)
            const xTo = chunk.to >= moment().valueOf() ? moment().endOf('day') : moment(chunk.to)

            let x = `${xFrom.format('MMM D')} - ${xTo.format('MMM D')}`

            if (period === PERIODS.DAY) x = xFrom.format('MMM D')
            if (period === PERIODS.MONTH) x = xFrom.format('MMM, Y')
            if (period === PERIODS.YEAR) x = xFrom.format('Y')

            result.push({
                x,
                y: chunk.value,
            })
        }

        setChartData(result)
    }

    const sendRequests = () => {
        return new Promise((resolve, reject) => {
            try {
                let requestedCount = 0
                let requestsData = []
                while (requestedCount !== count) {
                    const from = moment()
                        .subtract(count - 1 - requestedCount, PERIODS_DETAILS[period].momentUnit)
                        .startOf(PERIODS_DETAILS[period].momentUnitOfTime)
                        .valueOf()
                    const to = moment()
                        .subtract(count - 1 - requestedCount, PERIODS_DETAILS[period].momentUnit)
                        .endOf(PERIODS_DETAILS[period].momentUnitOfTime)
                        .valueOf()

                    method({
                        sort: `created,${SORT_ORDERS.asc}`,
                        page: 0,
                        size: 1,
                        from,
                        to,
                    }).then(({ totalElements }) => {
                        requestsData.push({
                            from,
                            to,
                            value: totalElements,
                        })

                        if (requestsData.length === count) {
                            updateChartData(requestsData)
                            resolve()
                        }
                    })
                    requestedCount += 1
                }
            } catch (err) {
                reject(err)
            }
        })
    }

    return (
        <div className={styles.defaultChart}>
            <div className={styles.topBar}>
                {title && <div className={styles.defaultChartTitle}>{title}</div>}
                <ul className={styles.selectList}>
                    <li className={styles.periodSelect}>
                        <Select
                            value={getPeriodSelectOption(period)}
                            options={Object.values(PERIODS)
                                .filter(v => v !== period)
                                .map(v => getPeriodSelectOption(v))}
                            onChange={({ value }) => setPeriod(value)}
                        />
                    </li>
                    <li className={styles.countSelect}>
                        <Input
                            type="number"
                            min={1}
                            value={count}
                            onChange={value => {
                                if (!isInt(value)) return
                                setCount(parseInt(value))
                            }}
                        />
                    </li>
                    <li className={styles.buttonSelect}>
                        <Button
                            isDisabled={isLoading || (period === prevPeriod && count === prevCount)}
                            content="Apply"
                            variant="primary"
                            onClick={() => getChartData()}
                        />
                    </li>
                </ul>
            </div>

            <div className={styles.defaultChartContent}>
                {isReady ? (
                    <ResponsiveLine
                        data={[
                            {
                                id: 'default',
                                data: chartData,
                            },
                        ]}
                        margin={{ top: 10, right: 60, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
                        yFormat=" >-.2f"
                        axisBottom={{
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Date',
                            legendOffset: 36,
                            legendPosition: 'middle',
                        }}
                        axisLeft={{
                            orient: 'left',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Count',
                            legendOffset: -40,
                            legendPosition: 'middle',
                            format: e => Math.floor(e) === e && e,
                        }}
                        colors={'#2990FB'}
                        pointSize={10}
                        pointColor={'#fff'}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        tooltip={data => <Tooltip data={data} />}
                    />
                ) : (
                    <Preloader />
                )}
            </div>
        </div>
    )
}

export default DefaultChart
