import React from 'react'
import classNames from 'classnames'

import styles from './Wrapper.module.scss'

const Wrapper = ({ error = '', wrapperRef, children }) => (
    <div className={styles.wrapper} ref={wrapperRef}>
        <div className={classNames(styles.content, { [styles.isErrorContent]: !!error?.length })}>{children}</div>
        {!!error?.length && <p className={styles.error}>{error}</p>}
    </div>
)

export default Wrapper
