import React from 'react'

import Table from 'components/Table/Table'
import SelectFilter from 'components/Table/components/Filters/SelectFilter'
import Toggle from 'components/Forms/Toggle/Toggle'

import { getBlocks, getBlockNameById } from 'constants/blocks'
import SORT_ORDERS from 'constants/sortOrders'

import { getSortKeys } from 'utils/table'

import ButtonsGroup from './components/ButtonsGroup/ButtonsGroup'

const getColumns = ({ methods, requestParams }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            getTitle: template => template.id,
            getValue: template => template.id,
            width: '15%',
        },
        {
            headerLabel: 'Type',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.blockTypeId}
                    field="blockTypeId"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        ...getBlocks().map(el => ({
                            value: el.id,
                            label: el.name,
                        })),
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: template => getBlockNameById(template.blockTypeId) || template.blockTypeId,
            getValue: template => getBlockNameById(template.blockTypeId) || template.blockTypeId,
            width: '20%',
        },
        {
            headerLabel: 'Enabled',
            getTitle: template => (template.enabled ? 'Yes' : 'No'),
            getValue: template => (
                <Toggle
                    value={template.enabled}
                    onChange={value =>
                        methods.updateTemplate({
                            ...template,
                            enabled: value,
                        })
                    }
                />
            ),
            width: '15%',
        },
        {
            headerLabel: 'Order number',
            getTitle: template => template.orderNumber,
            getValue: template => template.orderNumber,
            sort: {
                key: 'orderNumber',
                order: sort.key === 'orderNumber' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '15%',
        },
        {
            headerLabel: 'Image',
            getValue: template => (template.imageUrl ? <img src={template.imageUrl} alt="" /> : null),
            width: '15%',
        },
        {
            headerLabel: '',
            headerStyles: {
                justifyContent: 'flex-end',
            },
            getValue: template => <ButtonsGroup template={template} methods={methods} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '20%',
        },
    ]
}

const TemplatesTable = ({ list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns({ methods, requestParams })}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default TemplatesTable
