import React from 'react'
import classNames from 'classnames'

import styles from './BlockWrapper.module.scss'

const BlockWrapper = ({ title, isHideBorders = false, children }) => (
    <div className={classNames(styles.blockWrapper, { [styles.isHideBorders]: isHideBorders })}>
        {title && <h4 className={styles.blockTitle}>{title}</h4>}
        <div className={styles.blockContent}>{children}</div>
    </div>
)

export default BlockWrapper
