import React from 'react'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'
import Checkbox from 'components/Forms/Checkbox/Checkbox'

import { getUniqueId } from 'utils/common'

import InputBlock from '../../../../../../../components/InputBlock/InputBlock'

import BlockWrapper from '../../BlockWrapper/BlockWrapper'

import Image from '../Image/Image'

import { MIN_QUESTIONS_COUNT, MAX_QUESTIONS_COUNT, MIN_ANSWERS_COUNT, MAX_ANSWERS_COUNT } from './constants'

import styles from './TriviaQuiz.module.scss'

const TriviaQuiz = ({ questions, results, onUpdate }) => (
    <BlockWrapper title="Trivia Quiz">
        <div className={styles.section}>
            <h4 className={styles.sectionTitle}>Questions</h4>
            <ul className={styles.items}>
                {questions.map((question, questionIndex) => (
                    <li key={question.id} className={styles.item}>
                        <div className={styles.questionMain}>
                            <Input
                                isWide
                                value={question.text}
                                onChange={v => onUpdate(`questions.[${questionIndex}].text`, v)}
                            />
                            {questions.length > MIN_QUESTIONS_COUNT && (
                                <div className={styles.buttonsBlock}>
                                    <Button
                                        content="Remove question"
                                        variant="danger"
                                        onClick={() => {
                                            const newQuestions = cloneDeep(questions)
                                            newQuestions.splice(questionIndex, 1)
                                            onUpdate('questions', newQuestions)
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <ul className={styles.answers}>
                            {question.answers.map((answer, answerIndex) => (
                                <li key={answer.id} className={styles.answer}>
                                    <div className={styles.answerNumber}>{answerIndex + 1}.</div>
                                    <Input
                                        isWide
                                        inputClassName={classNames({ [styles.isCorrectAnswer]: answer.isCorrect })}
                                        value={answer.text}
                                        onChange={v =>
                                            onUpdate(`questions.[${questionIndex}].answers[${answerIndex}].text`, v)
                                        }
                                    />
                                    {question.answers.length > MIN_ANSWERS_COUNT && (
                                        <div className={styles.buttonsBlock}>
                                            <Checkbox
                                                className={styles.buttonsCheckbox}
                                                value={answer.isCorrect}
                                                onChange={v => {
                                                    onUpdate(
                                                        `questions.[${questionIndex}].answers[${answerIndex}].isCorrect`,
                                                        v,
                                                    )
                                                }}
                                            />
                                            <Button
                                                content="Remove answer"
                                                variant="danger"
                                                onClick={() => {
                                                    const newQuestions = cloneDeep(questions)
                                                    newQuestions[questionIndex].answers.splice(answerIndex, 1)
                                                    onUpdate('questions', newQuestions)
                                                }}
                                            />
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                        {question.answers.length < MAX_ANSWERS_COUNT && (
                            <Button
                                content="+ Add answer"
                                variant="primary"
                                onClick={() => {
                                    const newQuestions = cloneDeep(questions)
                                    newQuestions[questionIndex].answers.push({ text: '', id: Date.now() })
                                    onUpdate('questions', newQuestions)
                                }}
                            />
                        )}
                    </li>
                ))}
            </ul>
            {questions.length < MAX_QUESTIONS_COUNT && (
                <Button
                    content="+ Add question"
                    variant="primary"
                    onClick={() => {
                        const newQuestions = cloneDeep(questions)
                        newQuestions.push({
                            text: '',
                            id: getUniqueId(),
                            answers: [
                                {
                                    text: '',
                                    id: getUniqueId(),
                                    isCorrect: false,
                                },
                                {
                                    text: '',
                                    id: getUniqueId(),
                                    isCorrect: false,
                                },
                            ],
                        })
                        onUpdate('questions', newQuestions)
                    }}
                />
            )}
        </div>
        <div className={styles.section}>
            <h4 className={styles.sectionTitle}>Results</h4>
            <ul className={styles.items}>
                {results.map((result, resultIndex) => (
                    <li key={result.id} className={styles.item}>
                        <div className={styles.resultMain}>
                            <InputBlock title="Image">
                                <Image
                                    title={null}
                                    isHideBorders
                                    imageUrl={result.imageUrl}
                                    onUpdate={v => onUpdate(`results.[${resultIndex}].imageUrl`, v)}
                                    isCanRefresh={false}
                                />
                            </InputBlock>
                            <InputBlock title="Title">
                                <Input
                                    isWide
                                    value={result.title}
                                    onChange={v => onUpdate(`results.[${resultIndex}].title`, v)}
                                />
                            </InputBlock>
                            <InputBlock title="Description">
                                <Input
                                    isWide
                                    value={result.description}
                                    onChange={v => onUpdate(`results.[${resultIndex}].description`, v)}
                                />
                            </InputBlock>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    </BlockWrapper>
)

export default TriviaQuiz
