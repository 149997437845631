import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const RemoveFolderModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        isShowEnsure
        ensureText="You should be aware that the audio files inside this folder will also be deleted. Confirm that you are confident in your actions"
        onClose={() => onClose()}
        onAction={async () => onSubmit(data)}
        successText="Folder removed successfully!"
        headText="Please, confirm"
        noteText={`Are you sure you want to remove this folder?`}
        targetText={data.name}
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveFolderModal
