import React, { useMemo, useState } from 'react'

import useAudioPlayer from 'hooks/useAudioPlayer'

import Button from 'components/Forms/Button/Button'
import Input from 'components/Forms/Input/Input'
import Preloader from 'components/Preloader/Preloader'

import iconDownload from './i/download.png'
import iconPlay from './i/play.svg'
import iconPause from './i/pause.svg'

import styles from './AudioList.module.scss'

const AudioList = ({ list, isLoading, onEdit, onRemove }) => {
    const { onPlayAudio, playedUrl } = useAudioPlayer()

    const [filter, setFilter] = useState('')

    const filteredList = useMemo(() => {
        if (!list || !list.length) return []
        return list.filter(({ name }) => name.toLowerCase().includes(filter.trim().toLowerCase()))
    }, [list, filter])

    if (isLoading) return <Preloader />

    return (
        <div className={styles.audios}>
            {list.length ? (
                <>
                    <div className={styles.filter}>
                        <Input
                            maxWidth="300px"
                            isClearable
                            placeholder="Name filter"
                            value={filter}
                            onChange={val => setFilter(val)}
                        />
                    </div>
                    <ul className={styles.audioList}>
                        {filteredList.map(item => (
                            <li key={item.id} className={styles.audio}>
                                <div className={styles.name}>{item.name}</div>
                                <ul className={styles.actions}>
                                    <li>
                                        <div className={styles.play} onClick={() => onPlayAudio(item.url)}>
                                            <img src={playedUrl === item.url ? iconPause : iconPlay} alt="" />
                                        </div>
                                    </li>
                                    <li>
                                        <a
                                            className={styles.downloadLink}
                                            href={item.url}
                                            download
                                            target="_blank"
                                            title="Download audio file"
                                        >
                                            <img className={styles.downloadIcon} src={iconDownload} alt="download" />
                                        </a>
                                    </li>
                                    <li>
                                        <Button
                                            content="Edit"
                                            variant="primary"
                                            isInverse
                                            onClick={() => onEdit(item)}
                                        />
                                    </li>
                                    <li>
                                        <Button
                                            content="Remove"
                                            variant="danger"
                                            isInverse
                                            onClick={() => onRemove(item)}
                                        />
                                    </li>
                                </ul>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <div className={styles.emptyList}>Audio files not found</div>
            )}
        </div>
    )
}

export default AudioList
