// Types
export const SET_USAGE = 'SET_USAGE'

// Actions
export const setUsage = ({ count, limit }) => ({
    type: SET_USAGE,
    payload: {
        count,
        limit,
    },
})
