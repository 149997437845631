import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

const CreateTagModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [name, setName] = useState('')

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('name', name, [getIsValueRule(), getIsNotEmptyStringRule()])])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const { existTagsNames } = data
            if (existTagsNames.includes(name)) {
                setErrors({
                    name: 'Name already exist',
                })
                return
            }

            await onSubmit(name)

            return true
        } catch (err) {
            if (err.response) {
                const {
                    response: { status },
                } = err
                if (status === 400) {
                    setErrors({
                        name: 'Invalid characters',
                    })
                    return
                }
            }
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }

    return (
        <FormModal
            headText="Create tag"
            successText="Tag created successfully!"
            actionText="Create"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Name">
                <Input
                    value={name}
                    error={errors.name}
                    onChange={value => {
                        setName(value)
                        setErrors(removeKeyFromObject(errors, 'name'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default CreateTagModal
