import React from 'react'
import classNames from 'classnames'

import iconClose from './i/close.png'

import styles from './Tongue.module.scss'

const Tongue = ({ title, isActive, icon, onSetActive }) => (
    <div
        title={title}
        style={{ backgroundImage: `url(${isActive ? iconClose : icon})` }}
        className={classNames(styles.tongue, { [styles.isActiveTongue]: isActive })}
        onClick={() => onSetActive(!isActive)}
    />
)

export default Tongue
