import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Tabs from 'components/Tabs/Tabs'

import ROUTES from 'constants/routes'

import TagsTab from './Tabs/Tags/Tags'

import './Authors.scss'

class Authors extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <>
                <Tabs
                    isVisibleBottomLine={false}
                    list={[
                        {
                            to: `${ROUTES.AUTHORS.path}/tags`,
                            label: 'Tags',
                        },
                    ]}
                />

                <div className="divider"></div>

                <br />
                <br />

                <Switch>
                    <Route
                        exact
                        path={`${ROUTES.AUTHORS.path}/tags`}
                        render={() => <TagsTab location={this.props.location} history={this.props.history} />}
                    />
                    <Redirect
                        from="*"
                        to={{
                            pathname: ROUTES.ERROR.path,
                            state: {
                                errorCode: 404,
                            },
                        }}
                    />
                </Switch>
            </>
        )
    }
}

export default Authors
