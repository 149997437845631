import React from 'react'

import Preloader from 'components/Preloader/Preloader'
import Input from 'components/Forms/Input/Input'
import Select from 'components/Forms/Select/Select'
import Toggle from 'components/Forms/Toggle/Toggle'
import FormBlock from 'components/Forms/FormBlock'
import Button from 'components/Forms/Button/Button'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'

import TASK_STATUSES from 'constants/taskStatuses'
import { capitalize } from 'utils/common'
import { createSelectOptions } from 'utils/createSelectOptions'

import './EmailsSending.scss'

import API from 'api'

class EmailsSendingPage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isReady: false,
            isModalOpen: false,
            isEmailsSending: false,
            emailsSendingStatuses: null,
            form: {
                ignoreAllParams: false,
                templateId: undefined,
                role: undefined,
                group: undefined,
                status: undefined,
                plan: undefined,
            },
            rolesOptions: {},
            groupsOptions: [],
            statusesOptions: [],
            plansOptions: [],
        }
    }

    async componentDidMount() {
        const [statuses, plans, roles] = await Promise.all([
            API.COMMON.GET_SUBSCRIPTION_STATUSES(),
            API.COMMON.GET_PRODUCTS(),
            API.USERS.GET_ROLES(),
        ])

        const statusesOptions = createSelectOptions(statuses, el => ({
            value: el,
            label: capitalize(el),
        }))
        const plansOptions = createSelectOptions(plans, el => ({
            value: el.id,
            label: el.name,
        }))
        const groupsOptions = createSelectOptions(roles, el => ({
            value: el.id,
            label: capitalize(el.groupTranslationDto.text),
        }))

        const rolesOptions = {}
        for (const group of roles) {
            rolesOptions[group.id] = createSelectOptions(group.userRoleInProjectDtos, el => ({
                value: el.id,
                label: capitalize(el.roleTranslationDto.text),
            }))
        }

        this.setState({
            isReady: true,
            rolesOptions,
            groupsOptions,
            statusesOptions,
            plansOptions,
        })
    }

    setFormFields = newFields => {
        this.setState(prevState => ({
            ...prevState,
            form: { ...prevState.form, ...newFields },
        }))
    }

    setGroupField = value => {
        this.setFormFields({ group: value, role: null })
    }

    setIgnoreAllParamsField = ignoreAllParams => {
        if (!ignoreAllParams) {
            this.setFormFields({ ignoreAllParams: false })
        } else {
            this.openModal()
        }
    }
    onConfirmModal = () => {
        this.setFormFields({ ignoreAllParams: true })
        this.closeModal()
    }

    openModal = () => {
        this.setState({ isModalOpen: true })
    }

    closeModal = () => {
        this.setState({ isModalOpen: false })
    }

    sendEmails = async () => {
        try {
            const { ignoreAllParams, templateId } = this.state

            this.setState({
                isEmailsSending: true,
                emailsSendingStatuses: null,
            })

            const response = await API.EMAILS.SEND_EMAILS({
                ignoreAllParams,
                templateId,
            })

            await this.checkSendingStatus(response.id)

            Toast(TOAST_TYPE.SUCCESS)
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState({
                isEmailsSending: false,
            })
        }
    }

    checkSendingStatus = async taskId => {
        const _response = await API.COMMON.GET_TASK_STATUS(taskId)
        this.setState({ emailsSendingStatuses: _response.statuses.reverse() })

        if (_response.statuses.some(item => item.status === TASK_STATUSES.DONE)) {
            return
        } else if (_response.statuses.some(item => item.status === TASK_STATUSES.FAILED)) {
            throw Error(_response.statuses)
        } else {
            await new Promise(resolve => setTimeout(resolve, 2000))
            await this.checkSendingStatus(taskId)
        }
    }

    render() {
        const {
            isReady,
            form,
            isEmailsSending,
            isModalOpen,
            emailsSendingStatuses,
            rolesOptions,
            groupsOptions,
            statusesOptions,
            plansOptions,
        } = this.state

        return (
            <>
                <SEO title="Emails sending" />

                {isReady ? (
                    <div className="emails-sending">
                        <div className="emails-sending__form">
                            <FormBlock
                                label={
                                    <span>
                                        Email templates ID (on{' '}
                                        <a href="https://www.sendinblue.com/" target="_blank" rel="noopener noreferrer">
                                            Sendinblue
                                        </a>
                                        )
                                    </span>
                                }
                            >
                                <Input
                                    type="number"
                                    value={form.templateId}
                                    onChange={val => this.setFormFields({ templateId: val })}
                                />
                            </FormBlock>
                            <div className="divider" />
                            <div>
                                <h3>Filter users</h3>
                                <ul className="emails-sending__form-filter">
                                    <li>
                                        <FormBlock label="Group">
                                            <Select
                                                isDisabled={true}
                                                value={form.group}
                                                options={groupsOptions}
                                                onChange={val => this.setGroupField(val)}
                                            />
                                        </FormBlock>
                                    </li>

                                    <li>
                                        <FormBlock label="Role">
                                            <Select
                                                value={form.role}
                                                isDisabled={true}
                                                // isDisabled={!form.group}
                                                options={form.group ? rolesOptions[form.group.value] : []}
                                                onChange={val => this.setFormFields({ role: val })}
                                            />
                                        </FormBlock>
                                    </li>

                                    <li>
                                        <FormBlock label="Plan">
                                            <Select
                                                value={form.plan}
                                                isDisabled={true}
                                                options={plansOptions}
                                                onChange={val => this.setFormFields({ plan: val })}
                                            />
                                        </FormBlock>
                                    </li>

                                    <li>
                                        <FormBlock label="Status">
                                            <Select
                                                value={form.status}
                                                isDisabled={true}
                                                options={statusesOptions}
                                                onChange={val => this.setFormFields({ status: val })}
                                            />
                                        </FormBlock>
                                    </li>
                                </ul>
                            </div>
                            <div className="divider" />
                            <FormBlock label="Send to absolutely all users (ignored: news subscription, if deactivated users, if blocked users)">
                                <Toggle
                                    value={form.ignoreAllParams}
                                    onChange={val => this.setIgnoreAllParamsField(val)}
                                />
                            </FormBlock>
                        </div>

                        <div className="emails-sending__actions">
                            <Button
                                content="Send emails"
                                isDisabled={isEmailsSending}
                                variant="primary"
                                onClick={this.sendEmails}
                            />
                        </div>
                        {emailsSendingStatuses && (
                            <div className="emails-sending__status-list">
                                <h3>Status:</h3>
                                {emailsSendingStatuses.map(item => (
                                    <div className="emails-sending__status-item" key={`${item.createdAt}${item.id}`}>
                                        <span>{item.createdAt}:</span>&nbsp;&nbsp;&nbsp;
                                        <span>{item.status}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <Preloader />
                )}
                {isModalOpen && (
                    <ConfirmationDialog
                        onClose={this.closeModal}
                        onAction={this.onConfirmModal}
                        headText="Are you sure you want to send to everyone?"
                    />
                )}
            </>
        )
    }
}

export default EmailsSendingPage
