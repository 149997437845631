import React from 'react'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

import Modal from 'components/Modal/Modal'

import { MODE } from '../../constants'

import Header from './components/Header/Header'
import QuestionsList from './components/QuestionsList/QuestionsList'
import Question from './components/Question/Question'
import AddButton from './components/AddButton/AddButton'
import { MAX_QUESTIONS_COUNT } from './components/Question/constants'
import { getDefaultQuestion } from './components/Question/utils'

import closeIcon from './i/close.svg'

import styles from './EditModal.module.scss'

const EditModal = ({
    data: { questions, name },
    title,
    onUpdate,
    onSave,
    isLoading,
    onChangeName,
    isValid,
    errors,
    onClose,
    mode,
}) => (
    <Modal onClose={onClose} isCloseOnOverlayClick={false}>
        <div className={styles.closeIcon} onClick={onClose}>
            <img src={closeIcon} alt="close" />
        </div>
        <div className={styles.editPlayground}>
            <Header
                mode={mode}
                errors={errors}
                title={title}
                elementsCount={questions.length}
                onSave={onSave}
                isLoading={isLoading}
                isValid={isValid}
                name={name}
                onChangeName={onChangeName}
            />
            {!!questions.length && (
                <QuestionsList>
                    {questions.map((question, i) => (
                        <Question
                            mode={mode}
                            key={i}
                            index={i}
                            data={{ question, questionsCount: questions.length }}
                            errors={errors.questions[i]}
                            onChange={v => {
                                const _questions = cloneDeep(questions)
                                _questions[i] = v
                                onUpdate(_questions)
                            }}
                            onRemove={() => {
                                const _questions = cloneDeep(questions)
                                const removed = _questions.splice(i, 1)
                                onUpdate(_questions, removed)
                            }}
                        />
                    ))}
                </QuestionsList>
            )}
            {mode !== MODE.VIEW && MAX_QUESTIONS_COUNT > questions.length && (
                <AddButton
                    text={'Add question'}
                    className={classNames(styles.addQuestionButton, {
                        [styles.addQuestionButtonMargin]: !questions.length,
                    })}
                    onClick={() => {
                        const _questions = cloneDeep(questions)
                        onUpdate([..._questions, getDefaultQuestion(questions.length)])
                    }}
                />
            )}
        </div>
    </Modal>
)

export default EditModal
