import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import ROUTES from 'constants/routes'

import DashboardLayout from 'layouts/Dashboard/Dashboard'
import AuthLayout from 'layouts/Auth/Auth'
import ErrorLayout from 'layouts/Error/Error'

export const ErrorRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            <ErrorLayout>
                <Component {...props} />
            </ErrorLayout>
        )}
    />
)
export const AuthRoute = ({ component: Component, isAuth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuth ? (
                <Redirect to={{ pathname: ROUTES.HOME.path }} />
            ) : (
                <AuthLayout>
                    <Component {...props} />
                </AuthLayout>
            )
        }
    />
)
export const DashboardRoute = ({ component: Component, isAuth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            !isAuth ? (
                <Redirect to={{ pathname: ROUTES.AUTH.path }} />
            ) : (
                <DashboardLayout>
                    <Component {...props} />
                </DashboardLayout>
            )
        }
    />
)
