import React, { useState } from 'react'
import classNames from 'classnames'

import './Checkbox.scss'

const Checkbox = ({ value = false, label = '', isDisabled = false, onChange = () => {}, className }) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className={classNames('common__checkbox', { [className]: !!className })}>
            <div
                className={classNames('box', { 'is-checked': value, 'is-disabled': isDisabled || isLoading })}
                onClick={async () => {
                    try {
                        setIsLoading(true)
                        await onChange(!value)
                    } catch (err) {
                    } finally {
                        setIsLoading(false)
                    }
                }}
            ></div>
            {!!label.length && <p>{label}</p>}
        </div>
    )
}

export default Checkbox
