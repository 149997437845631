import React from 'react'
import { Link } from 'react-router-dom'

import { invertColor } from 'utils/colors'

import './Breadcrumbs.scss'

const Breadcrumbs = ({ list = [] }) => (
    <div className="common__breadcrumbs">
        <ul>
            {list.map((el, i) => (
                <li key={i}>
                    <div className="label" onClick={() => (!!el.onClick ? el.onClick() : {})}>
                        {el.to ? <Link to={el.to}>{el.label}</Link> : el.label}
                    </div>
                    {el.marks && !!el.marks.length && (
                        <ul className="marks">
                            {el.marks.map(mark => (
                                <li
                                    key={mark.label}
                                    className={`mark${!!mark.onClick ? ' is-clickable' : ''}`}
                                    title={mark.title}
                                    onClick={() => (!!mark.onClick ? mark.onClick() : {})}
                                    style={{
                                        backgroundColor: mark.backgroundColor || '#3497FF',
                                        color: invertColor(mark.backgroundColor || '#3497FF'),
                                    }}
                                >
                                    {mark.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    </div>
)

export default Breadcrumbs
