import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHotkeys } from 'react-hotkeys-hook'

import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'
import FormBlock from 'components/Forms/FormBlock'
import SEO from 'components/SEO/SEO'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { signIn } from 'store/actions/user'

import API from 'api'

import { isUserHasAdminRole } from './utils'

import logo from './i/logo.svg'

import './Auth.scss'

const AuthPage = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([
                validator.createField('login', login, [getIsValueRule(), getIsNotEmptyStringRule()]),
                validator.createField('password', password, [getIsValueRule(), getIsNotEmptyStringRule()]),
            ])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const loginData = await API.AUTH.LOGIN({
                grant_type: 'password',
                username: login,
                password,
            })

            if (!isUserHasAdminRole(loginData.user)) {
                setErrors({
                    login: "Oops! You don't have admin permissions",
                })
                return
            }

            const userDetailsData = await API.AUTH.GET_MY_DETAILS()
            loginData.user = {
                ...loginData.user,
                ...userDetailsData,
            }
            dispatch(signIn(loginData))
        } catch (err) {
            console.error(err)
            setErrors({
                password: 'Oops! That login/password combination is not valid',
            })
        } finally {
            setIsLoading(false)
        }
    }

    useHotkeys('F1', () => {
        if (process.env.REACT_APP_BACKEND_URL !== 'https://api.dev.interacty.me') return

        setLogin('admin')
        setPassword('KRO8rBm4Ir')
    })

    return (
        <>
            <SEO title="Auth" />
            <div className="auth-box">
                <div className="auth-box__logo">
                    <img src={logo} alt="" />
                    <p>Admin panel</p>
                </div>
                <div className="auth-box__body">
                    <div className="auth-box__body__block">
                        <FormBlock label="Login">
                            <Input
                                value={login}
                                error={errors.login}
                                onChange={value => {
                                    setLogin(value)
                                    setErrors(removeKeyFromObject(errors, 'login'))
                                }}
                            />
                        </FormBlock>
                    </div>
                    <div className="auth-box__body__block">
                        <FormBlock label="Password">
                            <Input
                                type="password"
                                value={password}
                                error={errors.password}
                                onChange={value => {
                                    setPassword(value)
                                    setErrors(removeKeyFromObject(errors, 'password'))
                                }}
                            />
                        </FormBlock>
                    </div>
                </div>
                <div className="auth-box__footer">
                    <Button
                        loadingText="Authentication..."
                        content="Sign in"
                        variant="primary"
                        isInverse={true}
                        isWide={true}
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onClick={() => handleSubmit()}
                    />
                </div>
            </div>
        </>
    )
}

export default AuthPage
