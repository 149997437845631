import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const RemoveSectionModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        onClose={() => onClose()}
        onAction={async () => await onSubmit(data.id)}
        successText="Section removed successfully!"
        headText="Please, confirm"
        noteText="Are you sure you want to remove this section?"
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveSectionModal
