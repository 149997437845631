import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const RemoveTemplateModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        onClose={() => onClose()}
        onAction={async () => await onSubmit(data.id)}
        successText="Template removed successfully!"
        headText="Please, confirm"
        noteText="Are you sure you want to remove this template?"
        actionVariant="danger"
        actionText="Remove"
        cancelText="Cancel"
    />
)

export default RemoveTemplateModal
