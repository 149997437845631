import React from 'react'
import classNames from 'classnames'

import iconTranslator from 'images/icons/translator.png'

import styles from './TranslateButton.module.scss'

const TranslateButton = ({ isLoading, onClick, title }) => (
    <div
        title={title}
        className={classNames(styles.translateButton, { [styles.isLoading]: isLoading })}
        onClick={() => onClick()}
    >
        <img src={iconTranslator} alt="" />
    </div>
)

export default TranslateButton
