import React from 'react'
import moment from 'moment'

import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'
import TextArea from 'components/Forms/TextArea/TextArea'

import { copyToClipboard } from 'utils/clipboard'

import { getCountryByCode, getLanguageByCode } from 'utils/common'

import './InfoBlocks.scss'

const InfoBlocks = ({ userPublicProfile }) => (
    <ul className="user-public-profile-info-blocks">
        <li>
            {userPublicProfile.headerLogoUrl && (
                <div
                    className="user-public-profile-info-blocks__banner"
                    style={{ backgroundImage: `url(${userPublicProfile.headerLogoUrl})` }}
                ></div>
            )}
            <ul>
                <li>
                    <FormBlock label="First name">
                        <Input value={userPublicProfile.firstName || ''} isReadOnly={true} />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Last name">
                        <Input value={userPublicProfile.lastName || ''} isReadOnly={true} />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Country">
                        <Input
                            value={
                                getCountryByCode(userPublicProfile.countryCode) &&
                                getCountryByCode(userPublicProfile.countryCode).label
                            }
                            isReadOnly={true}
                        />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Language">
                        <Input
                            value={
                                getLanguageByCode(userPublicProfile.languageCode) &&
                                getLanguageByCode(userPublicProfile.languageCode).label
                            }
                            isReadOnly={true}
                        />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Created date">
                        <Input
                            value={moment(userPublicProfile.created).format('MMM D, YYYY (HH:mm)')}
                            isReadOnly={true}
                        />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Updated date">
                        <Input
                            value={moment(userPublicProfile.updated).format('MMM D, YYYY (HH:mm)')}
                            isReadOnly={true}
                        />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Title">
                        <TextArea rows={3} value={userPublicProfile.title} isReadOnly={true} />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Description">
                        <TextArea rows={3} value={userPublicProfile.description} isReadOnly={true} />
                    </FormBlock>
                </li>
                <li>
                    <FormBlock label="Email address">
                        <Input value={userPublicProfile.email} isReadOnly={true} />
                    </FormBlock>
                    <Button content="Copy" variant="primary" onClick={() => copyToClipboard(userPublicProfile.email)} />
                </li>
                <li>
                    <FormBlock label="Tags">
                        <ul className="user-public-profile-info-blocks__tags">
                            {userPublicProfile.tags.map(tag => (
                                <li key={tag.id} className="user-public-profile-info-blocks__tags__item">
                                    {tag.name}
                                </li>
                            ))}
                        </ul>
                    </FormBlock>
                </li>
            </ul>
        </li>
    </ul>
)

export default InfoBlocks
