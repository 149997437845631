import React from 'react'

import './UsersCounter.scss'

const UsersCounter = ({ items }) => (
    <div className="users-counter">
        <ul>
            {items.map((item, i) => (
                <li key={i}>
                    {item.label}: <span>{item.value}</span>
                </li>
            ))}
        </ul>
    </div>
)

export default UsersCounter
