import moment from 'moment'
import isInt from 'validator/lib/isInt'

import iconLocal from 'images/icons/social/local.svg'
import iconGithub from 'images/icons/social/github.png'
import iconGoogle from 'images/icons/social/google.svg'
import iconFacebook from 'images/icons/social/facebook.svg'

export const getUserActiveColor = isActive => {
    if (isActive) return '#65BB5A'
    return '#FA655C'
}

export const getUserTestColor = isTest => {
    if (isTest) return '#2990FB'
    return 'inherit'
}

export const getStatusColor = isExpired => {
    if (!isExpired) return '#65BB5A'
    else return '#FA655C'
}

export const getStatusText = (isExpired, expiresAt) => {
    if (isExpired) {
        return `expired ${moment(expiresAt).fromNow()}`
    }
    return 'active'
}

export const isValidProjectsCount = (v, allowEmpty = false) => {
    if (allowEmpty && v.length === 0) return true
    return isInt(v) && parseInt(v) >= 0
}

export const getProviderIcon = (provider, styles = {}) => {
    if (provider) {
        const _styles = {
            width: '18px',
            ...styles,
        }
        switch (provider) {
            case 'local':
                return <img style={_styles} src={iconLocal} alt="local" />
            case 'github':
                return <img style={_styles} src={iconGithub} alt="github" />
            case 'google':
                return <img style={_styles} src={iconGoogle} alt="google" />
            case 'facebook':
                return <img style={_styles} src={iconFacebook} alt="facebook" />
            default:
                break
        }
    }
    return null
}
