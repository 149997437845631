import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'

import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule, getIsStringNumberRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { EXCHANGE_CURRENCY_FROM, EXCHANGE_CURRENCY_TO } from '../../constants'

const EditExchangeRateModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [rate, setRate] = useState(data?.rate || 1)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            validator.addFields([
                validator.createField('rate', String(rate), [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsStringNumberRule(),
                ]),
            ])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit(Number(rate).toFixed(2))

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Update exchange rate"
            subHeadText={`${EXCHANGE_CURRENCY_FROM} 1 = ${EXCHANGE_CURRENCY_TO} ${Number(rate).toFixed(2)}`}
            successText="Exchange rate updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Exchange rate">
                <Input
                    type="number"
                    min={0.1}
                    step={0.01}
                    value={rate}
                    error={errors.rate}
                    onChange={value => {
                        setRate(value)
                        setErrors(removeKeyFromObject(errors, 'rate'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default EditExchangeRateModal
