import React, { useEffect, useState } from 'react'
import { cloneDeep, set } from 'lodash'

import Preloader from 'components/Preloader/Preloader'

import { PROJECT_MODES } from 'constants/projects'

import useProgress from './components/Progress/hooks/useProgress'
import Progress from './components/Progress/Progress'
import ProjectsMenu from './components/ProjectsMenu/ProjectsMenu'
import ProjectWorkplace from './components/ProjectWorkplace/ProjectWorkplace'

import { PUBLISH_DESTINATION } from './constants'

import styles from './SetupProjects.module.scss'
import { ca } from 'date-fns/locale'

const SetupProjects = ({ scenario, mainSettings, scenarioSettings, schema }) => {
    const [isPublishing, setIsPublishing] = useState(false)
    const [projects, setProjects] = useState([])
    const [activeProjectIndex, setActiveProjectIndex] = useState(0)
    const { totalCount, count, successCount, failedCount, onProgress, onProcessed } = useProgress(
        scenarioSettings.topics.length,
    )

    useEffect(() => {
        schema.methods
            .onGenerateProjects(mainSettings, scenarioSettings, { onProgress, onProcessed })
            .then(data => {
                const preparedData = data.map(main => ({
                    main,
                    system: {
                        publishSettings: {
                            [PUBLISH_DESTINATION.INTERACTY]: {
                                isActive: true,
                                isDisabled: false,
                                templateName: '',
                                templateDescription: '',
                                templateImageUrl: null,
                            },
                            [PUBLISH_DESTINATION.TEACHER_CONTENT]: {
                                isActive: false,
                                isDisabled: true,
                            },
                        },
                    },
                }))
                setProjects(preparedData)
            })
            .catch(err => console.error(err))
    }, [])

    const onUpdateProject = (path, value, projectIndex = activeProjectIndex) => {
        const _projects = cloneDeep(projects)
        set(_projects[projectIndex], path, value)
        setProjects(_projects)
    }

    const onPublish = async () => {
        setIsPublishing(true)
        try {
            await schema.methods.onPublishProject(projects, mainSettings)
        } catch (err) {
            console.error(err)
        } finally {
            setIsPublishing(false)
        }
    }

    return (
        <div className={styles.setupProjects}>
            {isPublishing ? (
                <Preloader />
            ) : (
                <>
                    {projects.length ? (
                        <div className={styles.projects}>
                            <ProjectsMenu
                                projects={projects}
                                activeProjectIndex={activeProjectIndex}
                                onChangeActiveProjectIndex={i => setActiveProjectIndex(i)}
                                onUpdate={onUpdateProject}
                                onPublish={onPublish}
                            />
                            <ProjectWorkplace
                                key={activeProjectIndex}
                                scenario={scenario}
                                mainSettings={mainSettings}
                                scenarioSettings={scenarioSettings}
                                schema={schema}
                                project={projects[activeProjectIndex]}
                                onUpdate={onUpdateProject}
                            />
                        </div>
                    ) : (
                        <>
                            <Preloader />
                            <Progress
                                count={count}
                                totalCount={totalCount}
                                successCount={successCount}
                                failedCount={failedCount}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default SetupProjects
