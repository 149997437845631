import React, { useState } from 'react'

const EmbeddedList = ({ list, maxFoldedLength = 3 }) => {
    const [collapsed, setCollapsed] = useState(false)

    return (
        <div className="list">
            <ul>
                {list.slice(0, collapsed ? list.length : maxFoldedLength).map((url, i) => (
                    <li key={i}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {url}
                        </a>
                    </li>
                ))}
            </ul>
            {list.length > maxFoldedLength && !collapsed && (
                <span onClick={() => setCollapsed(true)}>See all ({list.length})</span>
            )}
        </div>
    )
}

export default EmbeddedList
