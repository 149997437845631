import qs from 'qs'
import { signOut } from "../store/actions/user"

import HttpClient from './instance'
import store from 'store'

export const LOGIN = async (data = {}) => {
    const { credentials } = store.getState()

    return HttpClient({
        method: 'post',
        url: '/oauth/token',
        headers: {
            Authorization: `${credentials.type} ${window.btoa(`${credentials.username}:${credentials.password}`)}`,
        },
        data: qs.stringify(data),
    })
}

export const GET_MY_DETAILS = async () =>
    HttpClient({
        method: 'get',
        url: `/users/me`,
    })

export const LOGOUT = async () => {
    await HttpClient({
        method: 'post',
        url: '/logout',
        data: {},
    })
    store.dispatch(signOut())
}
