import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import SEO from 'components/SEO/SEO'

import ROUTES from 'constants/routes'

import './Error.scss'

const ErrorPage = ({ location: { state } }) => {
    const [errorCode, setErrorCode] = useState(null)

    useEffect(() => {
        if (state && state.errorCode) {
            setErrorCode(state.errorCode)
        }
    }, [state])

    const getErrorMessageByCode = code => {
        if (code === 404) return 'Page not found'
        return 'There was some error'
    }

    return (
        <>
            <SEO title="Error" />

            <div className="error-box">
                {errorCode ? <div className="error-box__code">{errorCode}</div> : null}
                <div className="error-box__content">
                    <div className="error-box__content__text">{getErrorMessageByCode(errorCode)}</div>
                    <div className="error-box__content__link">
                        <Link to={ROUTES.HOME.path}>Go home</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage
