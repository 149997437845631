import React from 'react'

import Button from 'components/Forms/Button/Button'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import styles from './TopicBlock.module.scss'

const TopicBlock = ({ text, onDelete }) => (
    <li className={styles.topicBlock}>
        <div className={styles.text}>{text}</div>
        <ul className={styles.actions}>
            <li className={styles.action}>
                <Button
                    content="Delete"
                    variant="danger"
                    onClick={async () => {
                        try {
                            await onDelete()
                        } catch (err) {
                            console.error(err)
                            Toast(TOAST_TYPE.ERROR)
                        }
                    }}
                />
            </li>
        </ul>
    </li>
)

export default TopicBlock
