import React, { useEffect, useState } from 'react'

import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import { FILE_TYPES, FILE_TYPES_FORMATS, FILE_TYPES_MAX_SIZES } from 'constants/fileTypes'

import { getFileDataUrl, uploadFile } from 'utils/file'

import iconDelete from './i/iconDelete.svg'
import iconNote from './i/note.png'

import './Upload.scss'

const Preview = ({ type, url, lastUrl, value }) => {
    if (!url) return null

    if (type === FILE_TYPES.IMAGE) {
        return <div className="image-preview" style={{ backgroundImage: `url(${url})` }} />
    }

    if (type === FILE_TYPES.AUDIO) {
        return <div className="image-preview image-preview--music" style={{ backgroundImage: `url(${iconNote})` }} />
    }

    if (type === FILE_TYPES.PDF_DOCUMENT) {
        if (!value && lastUrl) {
            return (
                <a
                    href={lastUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="image-preview image-preview--text image-preview--link"
                >
                    PDF
                </a>
            )
        }
        return (
            <div rel="noopener noreferrer" className="image-preview image-preview--text">
                PDF
            </div>
        )
    }

    return null
}

const Upload = ({
    lastUrl = null,
    value = null,
    isUploaded = null,
    error = '',
    notice = '',
    type = FILE_TYPES.IMAGE,
    isCanRemove = false,
    onReset = () => {},
    onUpload = () => {},
    onChange = () => {},
    isShowUpload = true,
    inputRef,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [previewUrl, setPreviewUrl] = useState(lastUrl)
    const [isRemoved, setIsRemoved] = useState(false)

    useEffect(() => {
        if (!value) return
        getFileDataUrl(value)
            .then(base64 => setPreviewUrl(base64))
            .catch(err => console.error(err))
    }, [value])

    useEffect(() => {
        setPreviewUrl(lastUrl)
    }, [lastUrl])

    const initFileUploading = async () => {
        switch (type) {
            case FILE_TYPES.PDF_DOCUMENT: {
                return await uploadFile(value, FILE_TYPES_MAX_SIZES[FILE_TYPES.PDF_DOCUMENT], FILE_TYPES_FORMATS[type])
            }
            case FILE_TYPES.AUDIO: {
                return await uploadFile(value, FILE_TYPES_MAX_SIZES[FILE_TYPES.AUDIO], FILE_TYPES_FORMATS[type])
            }
            default: {
                return await uploadFile(value)
            }
        }
    }

    const getPlaceholder = () => {
        if (!!lastUrl && !isRemoved) return 'For change drop file there, or click to select'
        return 'Drop file here, or click to select'
    }
    const getInputNotice = () => {
        if (notice) return notice
        if (isLoading) return <b>Uploading...</b>
        if (isUploaded) return <b>Uploaded</b>
        return null
    }
    const getPreviewUrl = () => !isRemoved && (previewUrl || lastUrl)

    return (
        <div className="common__upload">
            <Preview type={type} url={getPreviewUrl()} lastUrl={lastUrl} isRemoved={isRemoved} value={value} />
            <div className="file">
                <Input
                    isReadOnly={true}
                    isPaintedBackground={isUploaded}
                    placeholder={getPlaceholder()}
                    value={value ? value.name : ''}
                    error={error}
                    notice={getInputNotice()}
                />
                <input
                    ref={inputRef}
                    type="file"
                    accept={FILE_TYPES_FORMATS[type].join(',')}
                    title={value ? value.name : ''}
                    disabled={isUploaded}
                    onChange={evt => {
                        const file = evt.target.files.length ? evt.target.files[0] : null
                        evt.target.value = null
                        onChange(file)
                    }}
                />
            </div>

            {value && (
                <>
                    <Button
                        content={<img src={iconDelete} alt="delete" />}
                        variant="danger"
                        title="Remove"
                        isInverse={true}
                        isDisabled={isLoading}
                        onClick={() => {
                            setPreviewUrl(null)
                            onReset()
                        }}
                    />
                    {!isUploaded && isShowUpload && (
                        <Button
                            content="Upload"
                            variant="primary"
                            isInverse={true}
                            isDisabled={isLoading}
                            isLoading={isLoading}
                            onClick={async () => {
                                setIsLoading(true)
                                const result = await initFileUploading()
                                await onUpload(result)
                                setIsLoading(false)
                            }}
                        />
                    )}
                </>
            )}

            {!value && isCanRemove && !!lastUrl && !isRemoved && (
                <Button
                    content={<img src={iconDelete} alt="delete" />}
                    variant="danger"
                    title="Remove"
                    isInverse={true}
                    isDisabled={isLoading}
                    onClick={() => {
                        onUpload({ isSuccess: true, url: null })
                        setIsRemoved(true)
                    }}
                />
            )}
        </div>
    )
}

export default Upload
