import { SET_MODAL } from '../actions/modalManager'

const initialState = {
    name: null,
    payload: {},
}

const modalManager = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case SET_MODAL:
            state = {
                ...state,
                name: payload.name || null,
                payload: payload.payload || {},
            }
            break
        default:
            break
    }
    return state
}

export default modalManager
