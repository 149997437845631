import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import { fieldsValidator } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import InputBlock from '../../../../components/InputBlock/InputBlock'
import Voice from '../../../../components/Voice/Voice'

import { CONTROL_TYPES, CONTROLS } from '../../../../constants'

import TopicBlock from './components/TopicBlock/TopicBlock'

import styles from './Workplace.module.scss'

const Workplace = ({ isTopicsFilled, schema, mainSettings, onUpdateTopics, onUpdateData }) => {
    const [isLoading, setIsLoading] = useState(false)

    const [options, setOptions] = useState([])
    const [fields, setFields] = useState([])

    const [customTopic, setCustomTopic] = useState('')

    const [generatedTopics, setGeneratedTopics] = useState([])
    const [customTopics, setCustomTopics] = useState([])

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setOptions(schema.options.filter(option => (option.isVisible ? option.isVisible({ mainSettings }) : true)))
    }, [])
    useEffect(() => {
        let _fields = {}
        for (const option of options) {
            _fields = {
                ..._fields,
                ...option.controls.reduce(
                    (acc, control) => ({
                        ...acc,
                        [control.key]: control.defaultValue || null,
                    }),
                    {},
                ),
            }
        }
        setFields(_fields)
    }, [options])

    useEffect(() => {
        const validator = new fieldsValidator(false)

        for (const option of options) {
            for (const control of option.controls) {
                if (!!control.validationRules?.length) {
                    validator.addFields([
                        validator.createField(control.key, fields[control.key], control.validationRules),
                    ])
                }
            }
        }

        const { isHasErrors } = validator.validate()

        if (!isHasErrors) {
            onUpdateData(getPreparedData())
        } else {
            onUpdateData(null)
        }
    }, [fields])

    useEffect(() => {
        onUpdateTopics([...generatedTopics, ...customTopics])
    }, [generatedTopics, customTopics])

    const getPreparedData = (isForTopicGeneration = false) => {
        const data = {}
        for (const option of options) {
            if (!data[option.key]) data[option.key] = {}
            for (const control of option.controls) {
                if (isForTopicGeneration && !control.isUseForTopicGeneration) continue
                if (control.control === CONTROLS.SELECT) data[option.key][control.key] = fields[control.key].value
                if (control.control === CONTROLS.INPUT) {
                    if (!!control.controlParams && control.controlParams.type === CONTROL_TYPES.NUMBER) {
                        data[option.key][control.key] = Number(fields[control.key])
                    } else data[option.key][control.key] = fields[control.key]
                }
                if (control.control === CONTROLS.VOICE) data[option.key][control.key] = fields[control.key]
            }
        }
        return data
    }

    const onChangeOption = (key, value) => {
        setFields(prevState => ({
            ...prevState,
            [key]: value,
        }))
        setErrors(removeKeyFromObject(errors, key))
    }

    const onGenerateTopics = async (isOneTopic = false) => {
        try {
            setIsLoading(true)
            setErrors({})

            const validator = new fieldsValidator()

            for (const option of options) {
                for (const control of option.controls) {
                    if (control.isUseForTopicGeneration && !!control.validationRules?.length) {
                        validator.addFields([
                            validator.createField(control.key, fields[control.key], control.validationRules),
                        ])
                    }
                }
            }

            const { isHasErrors, errors } = validator.validate()

            if (isHasErrors) {
                setErrors(errors)
                return
            }

            if (isOneTopic) {
                const newGeneratedTopic = await schema.methods.onGetOneTopic(mainSettings, getPreparedData(true), [
                    ...generatedTopics,
                    ...customTopics,
                ])
                setGeneratedTopics([...generatedTopics, newGeneratedTopic])
            } else {
                const newGeneratedTopics = await schema.methods.onGetManyTopics(mainSettings, getPreparedData(true))
                setGeneratedTopics(newGeneratedTopics)
                setCustomTopics([])
            }
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classNames(styles.workplace, { [styles.isDisabled]: isLoading })}>
            <div className={styles.box}>
                {schema.options.map(option => {
                    const isVisible = option.isVisible ? option.isVisible({ mainSettings }) : true
                    if (!isVisible) return null

                    return (
                        <div key={option.key} className={styles.boxGroup}>
                            <div className={styles.boxGroupTitle}>{option.label}</div>
                            <ul className={styles.boxGroupList}>
                                {option.controls.map(control => {
                                    if (control.control === CONTROLS.SELECT) {
                                        let selectOptions = control.options
                                        const excludedOption = fields[control.excludeOptionFromKey]
                                        if (!!excludedOption) {
                                            selectOptions = selectOptions.filter(
                                                ({ value }) => value !== excludedOption.value,
                                            )
                                        }

                                        return (
                                            <InputBlock key={control.key} title={control.label}>
                                                <Select
                                                    {...(control.controlParams || {})}
                                                    error={errors[control.key]}
                                                    isPortal={false}
                                                    value={fields[control.key]}
                                                    options={selectOptions}
                                                    onChange={v => onChangeOption(control.key, v)}
                                                />
                                            </InputBlock>
                                        )
                                    }
                                    if (control.control === CONTROLS.INPUT) {
                                        return (
                                            <InputBlock key={control.key} title={control.label}>
                                                <Input
                                                    {...(control.controlParams || {})}
                                                    isWide
                                                    error={errors[control.key]}
                                                    value={fields[control.key]}
                                                    onChange={v => onChangeOption(control.key, v)}
                                                />
                                            </InputBlock>
                                        )
                                    }
                                    if (control.control === CONTROLS.VOICE) {
                                        return (
                                            <InputBlock key={control.key} title={control.label}>
                                                <Voice
                                                    {...(control.controlParams || {})}
                                                    error={errors[control.key]}
                                                    value={fields[control.key]}
                                                    onChange={v => onChangeOption(control.key, v)}
                                                />
                                            </InputBlock>
                                        )
                                    }
                                    return null
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
            <div className={styles.box}>
                <div className={styles.topicsHead}>
                    <div className={styles.topicsHeadTitle}>Topics</div>
                    <div className={styles.topicsHeadCounter}>
                        {generatedTopics.length + customTopics.length} / {mainSettings.numberOfProjects}
                    </div>
                </div>
                <div className={styles.topicsListWrapper}>
                    {!!customTopics.length && (
                        <div className={styles.topicBlock}>
                            <p className={styles.topicBlockTitle}>Custom topics:</p>
                            <ul className={styles.topicsList}>
                                {customTopics.map((topic, index) => (
                                    <TopicBlock
                                        key={topic}
                                        text={topic}
                                        onDelete={() => {
                                            const newCustomTopics = cloneDeep(customTopics)
                                            newCustomTopics.splice(index, 1)
                                            setCustomTopics(newCustomTopics)
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                    {!!generatedTopics.length && (
                        <div className={styles.topicBlock}>
                            <p className={styles.topicBlockTitle}>Generated topics:</p>
                            <ul className={styles.topicsList}>
                                {generatedTopics.map((topic, index) => (
                                    <TopicBlock
                                        key={`${index}_${topic}`}
                                        text={topic}
                                        onDelete={() => {
                                            const newTopics = cloneDeep(generatedTopics)
                                            newTopics.splice(index, 1)
                                            setGeneratedTopics(newTopics)
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className={styles.addTopic}>
                    <div className={styles.addTopicBlock}>
                        <Button
                            className={styles.generateAllButton}
                            content={`Clear and generate topic(s)`}
                            variant="primary"
                            onClick={onGenerateTopics}
                        />
                        <div className={styles.generateOneButtonWrapper}>
                            <Button
                                isDisabled={isTopicsFilled}
                                content="Generate one more topic"
                                variant="primary"
                                onClick={() => onGenerateTopics(true)}
                            />
                        </div>
                    </div>
                    <div className={styles.addTopicBlock}>
                        <Input
                            isDisabled={isTopicsFilled}
                            isWide
                            className={styles.boxInputAddTopic}
                            value={customTopic}
                            onChange={v => setCustomTopic(v)}
                        />
                        <Button
                            content="Add Topic"
                            isDisabled={customTopic.length < 1}
                            variant="primary"
                            onClick={() => {
                                setCustomTopics([...customTopics, customTopic])
                                setCustomTopic('')
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Workplace
