import { PAYOUTS_STATUSES } from 'constants/payouts'

export const getPayoutStatusColor = status => {
    if (status === PAYOUTS_STATUSES.COMPLETED || status === PAYOUTS_STATUSES.PAID) return '#65BB5A'
    if (status === PAYOUTS_STATUSES.PENDING) return '#8A8A8A'
    if (status === PAYOUTS_STATUSES.DECLINED || status === PAYOUTS_STATUSES.FAILED) return '#FA655C'
    return 'inherit'
}

export const isCanChangeStatus = status =>
    status === PAYOUTS_STATUSES.DECLINED || status === PAYOUTS_STATUSES.PENDING || status === PAYOUTS_STATUSES.FAILED
