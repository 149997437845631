import HttpClient from './instance'

export const GET_PUBLIC_AUDIO_FOLDERS = () =>
    HttpClient({
        method: 'get',
        url: '/folders/public',
        params: {
            category: 'AUDIO',
            size: 1000,
            page: 0,
            sort: 'name,asc',
        },
    })

export const UPLOAD_PUBLIC_AUDIO = (fileName, contentType, bytes, folderId = null) =>
    HttpClient({
        method: 'post',
        url: `/admin/media/audios`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileName,
            folderId,
            fileLength: bytes.length || bytes.byteLength,
        },
        data: bytes,
    })

export const UPDATE_PUBLIC_AUDIO_NAME = (id, fileName) =>
    HttpClient({
        method: 'patch',
        url: `/admin/media/audios/${id}`,
        params: {
            fileName,
        },
        data: {},
    })

export const UPDATE_PUBLIC_AUDIO = (id, fileName, contentType, bytes, folderId = null) =>
    HttpClient({
        method: 'patch',
        url: `/admin/media/audios/${id}`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileName,
            folderId,
            fileLength: bytes.length || bytes.byteLength,
        },
        data: bytes,
    })

export const CREATE_PUBLIC_AUDIO_FOLDER = name =>
    HttpClient({
        method: 'post',
        url: `/admin/folders/`,
        data: {
            name,
            category: 'AUDIO',
            parentId: null,
        },
    })

export const REMOVE_PUBLIC_AUDIO_FOLDER = folderId =>
    HttpClient({
        method: 'delete',
        url: `/admin/folders/${folderId}`,
    })

export const UPDATE_PUBLIC_AUDIO_FOLDER = (folderId, name) =>
    HttpClient({
        method: 'patch',
        url: `/admin/folders/${folderId}`,
        data: {
            name,
        },
    })

export const GET_PUBLIC_AUDIO = folderId =>
    HttpClient({
        method: 'get',
        url: `/media/audios/public`,
        params: {
            folderId,
            size: 1000,
            page: 0,
            sort: 'name,asc',
        },
    })

export const REMOVE_PUBLIC_AUDIO = audioId =>
    HttpClient({
        method: 'delete',
        url: `/admin/media/audios/${audioId}`,
    })
