import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import { LOCALES_DETAILS, getLocaleDetailsByCode } from 'constants/locales'

import { copyToClipboard } from 'utils/clipboard'

import Progress from 'components/Progress/Progress'
import Select from 'components/Forms/Select/Select'
import TextArea from 'components/Forms/TextArea/TextArea'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Preloader from 'components/Preloader/Preloader'

import iconCopy from 'images/icons/copy.svg'

import API from 'api'

import { setUsage } from 'store/actions/translator'

import iconTranslate from './i/translate.png'

import styles from './Translator.module.scss'

const TOAST_ERROR_TOAST_DATA = {
    title: 'Hmm...',
    message:
        'Looks like there some problems with DeepL servers. Translator can be unavailable, if this is really the case, try refreshing the page.',
    time: 8000,
}

const Translator = ({ rows = 20 }) => {
    const { usage } = useSelector(store => store.translator)
    const dispatch = useDispatch()

    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [localesList] = useState(() =>
        Object.values(LOCALES_DETAILS)
            .filter(locale => locale.isSupported)
            .map(locale => ({
                value: locale.code,
                label: locale.label,
            })),
    )
    const [sourceLocale, setSourceLocale] = useState(localesList[0])
    const [sourceText, setSourceText] = useState('')
    const [targetLocale, setTargetLocale] = useState(localesList[0])
    const [targetText, setTargetText] = useState('')

    const [lastTranslatedSourceLocale, setLastTranslatedSourceLocale] = useState(null)
    const [lastTranslatedTargetLocale, setLastTranslatedTargetLocale] = useState(null)
    const [lastTranslatedSourceText, setLastTranslatedSourceText] = useState(null)

    useEffect(() => {
        if (usage) {
            setIsReady(true)
            return
        }
        updateUsageInfo()
            .then(() => {
                setIsReady(true)
            })
            .catch(() => {})
    }, [])

    const updateUsageInfo = async () => {
        try {
            const response = await API.TRANSLATION.GET_DEEPL_USAGE_INFO()
            dispatch(
                setUsage({
                    count: response.character_count,
                    limit: response.character_limit,
                }),
            )
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR, TOAST_ERROR_TOAST_DATA)
            throw err
        }
    }

    const getTranslation = async () => {
        try {
            setIsLoading(true)
            const translatedText = await API.TRANSLATION.GET_DEEPL_TRANSLATE({
                sourceLocale: getLocaleDetailsByCode(sourceLocale.value).deeplSourceCode,
                sourceText,
                targetLocale: getLocaleDetailsByCode(targetLocale.value).deeplTargetCode,
            })
            setTargetText(translatedText)
            setLastTranslatedSourceLocale(sourceLocale)
            setLastTranslatedTargetLocale(targetLocale)
            setLastTranslatedSourceText(sourceText)
            updateUsageInfo().catch(() => {})
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR, TOAST_ERROR_TOAST_DATA)
        } finally {
            setIsLoading(false)
        }
    }

    const isCanTranslate = () => {
        if (isLoading) return false
        if (!sourceText.length) return false
        if (sourceLocale.value === targetLocale.value) return false
        if (lastTranslatedSourceLocale && lastTranslatedTargetLocale && lastTranslatedSourceText) {
            const isSameText = lastTranslatedSourceText === sourceText
            const isSameSourceLocale = sourceLocale.value === lastTranslatedSourceLocale.value
            const isSameTargetLocale = targetLocale.value === lastTranslatedTargetLocale.value

            return !isSameText || !isSameSourceLocale || !isSameTargetLocale
        }
        return true
    }

    return (
        <div className={styles.translator}>
            {isReady ? (
                <>
                    <Progress
                        title="DeepL API characters usage"
                        current={usage.count}
                        total={usage.limit}
                        units="characters"
                    />

                    <br />
                    <br />
                    <br />

                    <div className={styles.translatorWorkplace}>
                        <div className={styles.zone}>
                            <div className={styles.localesSelectContainer}>
                                <div className={styles.localeSelectBox}>
                                    <p className={styles.label}>Source locale</p>
                                    <div className={styles.selectWrapper}>
                                        <img src={LOCALES_DETAILS[sourceLocale.value].icon} alt={sourceLocale.value} />
                                        <Select
                                            isPortal={false}
                                            isDisabled={isLoading}
                                            value={sourceLocale}
                                            options={localesList}
                                            onChange={value => setSourceLocale(value)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.localeSelectBox}>
                                    <p className={styles.label}>Target locale</p>
                                    <div className={styles.selectWrapper}>
                                        <img src={LOCALES_DETAILS[targetLocale.value].icon} alt={targetLocale.value} />
                                        <Select
                                            isPortal={false}
                                            isDisabled={isLoading}
                                            value={targetLocale}
                                            options={localesList}
                                            onChange={value => setTargetLocale(value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <br />
                            <p className={styles.label}>Source text</p>
                            <TextArea
                                rows={rows}
                                isDisabled={isLoading}
                                value={sourceText}
                                placeholder="Type text there..."
                                onChange={value => setSourceText(value)}
                            />
                        </div>
                        <div className={styles.actions}>
                            <div
                                className={classNames({
                                    [styles.translateButton]: true,
                                    [styles.isDisabledTranslateButton]: !isCanTranslate(),
                                })}
                                onClick={() => getTranslation()}
                            >
                                <img src={iconTranslate} alt="translate" />
                            </div>
                        </div>
                        <div className={styles.zone}>
                            <p className={styles.label}>
                                <span>Target text</span>
                                {!!targetText.length && (
                                    <span
                                        title="Copy target text to clipboard"
                                        className={styles.labelIcon}
                                        onClick={() => copyToClipboard(targetText)}
                                    >
                                        <img src={iconCopy} alt="copy" />
                                    </span>
                                )}
                            </p>
                            <div className={styles.targetZoneContainer}>
                                <div className={styles.targetZone}>{targetText}</div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default Translator
