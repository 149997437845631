import HttpClient from './instance'

export const UPLOAD_AUDIO = ({ fileName, contentType, bytes, selectedOrganizationId }) =>
    HttpClient({
        method: 'post',
        url: `/media/audios`,
        headers: {
            'Content-Type': `${contentType}`,
        },
        params: {
            fileName,
            hidden: true,
            fileLength: bytes.length || bytes.byteLength,
            organizationId: selectedOrganizationId,
        },
        data: bytes,
        timeout: 30000,
    })
