import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import LocaleFormBlock from 'components/Forms/components/LocaleFormBlock/LocaleFormBlock'
import Select from 'components/Forms/Select/Select'
import Upload from 'components/Forms/Upload/Upload'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import {
    addLocaleDependentTextFieldsToValidator,
    convertToList,
    getLocaleDependentTextFieldsStateObject,
} from 'utils/translations'
import { removeKeyFromObject, setKeyToObject } from 'utils/common'

import LOCALES, { DEFAULT_LOCALE } from 'constants/locales'

const LOCALE_DEPENDENT_FIELDS = {
    FORMATTED_NAME: {
        fieldName: 'formattedName',
        translationsFieldKey: 'formattedName',
    },
}

const CreateTagModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [isSystem, setIsSystem] = useState(false)
    const [isInterests, setIsInterests] = useState(false)
    const [isChildren, setIsChildren] = useState(!!data.parentTag)
    const [name, setName] = useState('')
    const [parentTag, setParentTag] = useState(
        data.parentTag ? { value: data.parentTag.name, label: data.parentTag.name } : null,
    )

    const [icon, setIcon] = useState(null)
    const [iconUrl, setIconUrl] = useState('')

    const [localeDependentFields, setLocaleDependentFields] = useState(() =>
        getLocaleDependentTextFieldsStateObject(LOCALE_DEPENDENT_FIELDS),
    )
    const [activeFormattedNameLocale, setActiveFormattedNameLocale] = useState(DEFAULT_LOCALE)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            addLocaleDependentTextFieldsToValidator(validator, {
                fields: LOCALE_DEPENDENT_FIELDS,
                values: localeDependentFields,
                requiredLocales: [LOCALES.EN],
            })

            validator.addFields([validator.createField('name', name, [getIsValueRule(), getIsNotEmptyStringRule()])])

            if (isChildren) {
                validator.addFields([validator.createField('parentTag', parentTag, [getIsValueRule()])])
            }

            if (icon) {
                validator.addFields([
                    validator.createField('icon', iconUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            if (data.existingTags.map(tag => tag.name).includes(name)) {
                setErrors({
                    name: 'Name already exist',
                })
                return
            }

            await onSubmit({
                name,
                iconUrl: iconUrl || null,
                isSystem,
                isInterests,
                formattedName: convertToList(localeDependentFields[LOCALE_DEPENDENT_FIELDS.FORMATTED_NAME.fieldName]),
                parentTag: isChildren ? parentTag.value : null,
            })

            return true
        } catch (err) {
            if (err.response) {
                const {
                    response: { status },
                } = err
                if (status === 400) {
                    setErrors({
                        name: 'Invalid characters',
                    })
                    return
                }
            }
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }

    return (
        <FormModal
            headText="Create tag"
            successText="Tag created successfully!"
            actionText="Create"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Name">
                <Input
                    value={name}
                    error={errors.name}
                    onChange={value => {
                        setName(value)
                        setErrors(removeKeyFromObject(errors, 'name'))
                    }}
                />
            </FormBlock>
            <LocaleFormBlock
                label="Label"
                errors={errors}
                fields={localeDependentFields}
                requiredLocales={[LOCALES.EN]}
                fieldName={LOCALE_DEPENDENT_FIELDS.FORMATTED_NAME.fieldName}
                activeTabName={activeFormattedNameLocale}
                onChangeActiveTabName={v => setActiveFormattedNameLocale(v)}
                onChange={data => {
                    setLocaleDependentFields(data.value)
                    setErrors(data.errors)
                }}
            />
            <FormBlock label="Icon">
                <Upload
                    value={icon}
                    error={errors.icon}
                    isUploaded={!!iconUrl}
                    onReset={() => {
                        setIconUrl('')
                        setIcon(null)
                        setErrors(removeKeyFromObject(errors, 'icon'))
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'icon', errorText))
                            return
                        }
                        setIconUrl(url)
                        setErrors(removeKeyFromObject(errors, 'icon'))
                    }}
                    onChange={file => {
                        setIconUrl('')
                        setIcon(file)
                        setErrors(removeKeyFromObject(errors, 'icon'))
                    }}
                />
            </FormBlock>
            <FormBlock>
                <Toggle value={isInterests} label="Interests tag" onChange={value => setIsInterests(value)} />
            </FormBlock>
            <FormBlock>
                <Toggle value={isSystem} label="System tag" onChange={value => setIsSystem(value)} />
            </FormBlock>
            <FormBlock>
                <Toggle value={isChildren} label="Children tag" onChange={value => setIsChildren(value)} />
            </FormBlock>
            {isChildren && (
                <FormBlock label="Parent tag">
                    <Select
                        error={errors.parentTag}
                        value={parentTag}
                        options={data.existingTags
                            .filter(tag => !tag.parentTag)
                            .map(tag => ({ value: tag.name, label: tag.name }))}
                        onChange={value => {
                            setParentTag(value)
                            setErrors(removeKeyFromObject(errors, 'parentTag'))
                        }}
                    />
                </FormBlock>
            )}
        </FormModal>
    )
}

export default CreateTagModal
