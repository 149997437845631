import React from 'react'
import classNames from 'classnames'

import './TextArea.scss'

const TextArea = ({
    value = '',
    rows = 5,
    error = '',
    isPaintedBackground = false,
    placeholder = '',
    isDisabled = false,
    isReadOnly = false,
    notice,
    onChange = () => {},
    className,
    isWide = false,
}) => (
    <div
        className={classNames('common__text-area', { [className]: !!className })}
        style={{ width: isWide ? '100%' : 'auto' }}
    >
        <textarea
            className={classNames({
                'is-error': error && error.length,
                'is-painted-background': isPaintedBackground,
            })}
            disabled={isDisabled}
            readOnly={isReadOnly}
            placeholder={placeholder}
            rows={rows}
            value={value}
            onChange={evt => onChange(evt.target.value)}
        />
        {error && error.length ? <p className="error">{error}</p> : ''}
        {notice ? <p className="notice">{notice}</p> : ''}
    </div>
)

export default TextArea
