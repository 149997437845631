import React, { forwardRef } from 'react'
import moment from 'moment'

import Input from 'components/Forms/Input/Input'

const CustomInput = forwardRef(({ value, format, _placeholder, error, onClick, onClear, isClearable }, ref) => (
    <div className="common__date-picker__input" ref={ref}>
        <Input
            onClick={() => onClick()}
            error={error}
            placeholder={_placeholder}
            value={moment(new Date(value)).isValid() ? moment(new Date(value)).format(format) : ''}
            onChange={() => onClear()}
            isClearable={isClearable}
            isReadOnly={true}
        />
    </div>
))

export default CustomInput
