import { useState } from 'react'

const useProgress = totalCount => {
    const [topic, setTopic] = useState(null)
    const [count, setCount] = useState(0)
    const [successCount, setSuccessCount] = useState(0)
    const [failedCount, setFailedCount] = useState(0)

    const onProgress = topic => setTopic(topic)
    const onProcessed = isSuccess => {
        setCount(prevState => prevState + 1)
        if (isSuccess) setSuccessCount(prevState => prevState + 1)
        else setFailedCount(prevState => prevState + 1)
    }

    return {
        topic,
        totalCount,
        count,
        successCount,
        failedCount,

        onProgress,
        onProcessed,
    }
}

export default useProgress
