import {
    getIsMaxRule,
    getIsMinRule,
    getIsNotEmptyStringRule,
    getIsValueRule,
    isIntegerNumber,
} from 'utils/fieldsValidator'

import { LOCALES_DETAILS } from 'constants/locales'

import audioTasksApi from './api/audioTasks'

export const MIN_PROJECTS_COUNT = 1
export const MAX_PROJECTS_COUNT = 50

export const SCENARIOS = {
    AUDIO_TASKS: 'AUDIO_TASKS',
}
export const SCENARIOS_LABELS = {
    [SCENARIOS.AUDIO_TASKS]: 'Audio Tasks',
}

export const BLOCKS = {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    TRIVIA_QUIZ: 'TRIVIA_QUIZ',
    FLIP_CARDS: 'FLIP_CARDS',
}
export const BLOCKS_LABELS = {
    [BLOCKS.TEXT]: 'Text',
    [BLOCKS.IMAGE]: 'Image',
    [BLOCKS.TRIVIA_QUIZ]: 'Trivia Quiz',
    [BLOCKS.FLIP_CARDS]: 'Flip Cards',
}

export const DEFAULT_BLOCKS_BY_SCENARIOS = {
    [SCENARIOS.AUDIO_TASKS]: [BLOCKS.TEXT, BLOCKS.IMAGE, BLOCKS.TRIVIA_QUIZ, BLOCKS.FLIP_CARDS],
}

export const GPT_MODELS = {
    GPT_3_5_TURBO: 'gpt-3.5-turbo',
    GPT_4: 'gpt-4',
}

export const LEVELS = {
    BEGINNER: 'Beginner',
    INTERMEDIATE: 'Intermediate',
    ADVANCED: 'Advanced',
}

export const CONTROLS = {
    SELECT: 'SELECT',
    INPUT: 'INPUT',
    VOICE: 'VOICE',
}

export const CONTROL_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
}

export const FLIP_CARDS_BG_COLORS = [
    ['#8ab17d', '#f7b55f'],
    ['#efb366', '#82c06d'],
    ['#2a9d8f', '#faa35c'],
    ['#e9c46a', '#c6c865'],
    ['#287271', '#f68550'],
    ['#ee8959', '#207978'],
    ['#264653', '#f36744'],
    ['#f4a261', '#21a596'],
    ['#babb74', '#f4c95d'],
    ['#e76f51', '#1f4a5c'],
]

export const SCENARIO_SCHEMA = {
    [SCENARIOS.AUDIO_TASKS]: {
        methods: {
            onGetManyTopics: audioTasksApi.onGetManyTopics,
            onGetOneTopic: audioTasksApi.onGetOneTopic,
            onGenerateProjects: audioTasksApi.onGenerateProjects,
            onGenerateProjectJSON: audioTasksApi.onGenerateProjectJSON,
            onPublishProject: audioTasksApi.onPublishProject,
        },
        options: [
            {
                label: 'Main scenario settings',
                key: 'common',
                controls: [
                    {
                        key: 'learningLocaleCode',
                        label: 'Learning language',
                        defaultValue: null,
                        control: CONTROLS.SELECT,
                        options: Object.values(LOCALES_DETAILS).map(key => ({
                            value: key.code,
                            label: key.label,
                            icon: key.icon,
                        })),
                        excludeOptionFromKey: 'nativeLocaleCode',
                        isUseForTopicGeneration: true,
                        validationRules: [getIsValueRule()],
                    },
                    {
                        key: 'nativeLocaleCode',
                        label: 'Native language',
                        defaultValue: null,
                        control: CONTROLS.SELECT,
                        options: Object.values(LOCALES_DETAILS).map(key => ({
                            value: key.code,
                            label: key.label,
                            icon: key.icon,
                        })),
                        excludeOptionFromKey: 'learningLocaleCode',
                        validationRules: [getIsValueRule()],
                    },
                    {
                        key: 'theme',
                        label: 'Theme',
                        defaultValue: 'any',
                        control: CONTROLS.INPUT,
                        isUseForTopicGeneration: true,
                        validationRules: [getIsNotEmptyStringRule()],
                    },
                    {
                        key: 'level',
                        label: 'Level',
                        defaultValue: null,
                        control: CONTROLS.SELECT,
                        options: Object.keys(LEVELS).map(key => ({
                            value: key,
                            label: LEVELS[key],
                        })),
                        isUseForTopicGeneration: true,
                        validationRules: [getIsValueRule()],
                    },
                ],
            },
            {
                label: 'Quiz settings',
                key: 'quiz',
                controls: [
                    {
                        key: 'storyLength',
                        label: 'Approximate story words count',
                        defaultValue: '100',
                        control: CONTROLS.INPUT,
                        controlParams: {
                            type: CONTROL_TYPES.NUMBER,
                            min: 5,
                            max: 1000,
                        },
                        validationRules: [
                            getIsNotEmptyStringRule(),
                            isIntegerNumber(),
                            getIsMinRule(5),
                            getIsMaxRule(1000),
                        ],
                    },
                    {
                        key: 'voice',
                        label: 'Voice (default)',
                        defaultValue: null,
                        control: CONTROLS.VOICE,
                        validationRules: [getIsValueRule()],
                    },
                    {
                        key: 'questionsCount',
                        label: 'Questions count',
                        defaultValue: '5',
                        control: CONTROLS.INPUT,
                        controlParams: {
                            type: CONTROL_TYPES.NUMBER,
                            min: 1,
                            max: 50,
                        },
                        validationRules: [
                            getIsNotEmptyStringRule(),
                            isIntegerNumber(),
                            getIsMinRule(1),
                            getIsMaxRule(50),
                        ],
                    },
                ],
            },
            {
                isVisible: ({ mainSettings }) => mainSettings.blocks.includes(BLOCKS.FLIP_CARDS),
                label: 'Vocabulary settings',
                key: 'vocabulary',
                controls: [
                    {
                        key: 'cardsCount',
                        label: 'Cards count',
                        defaultValue: '5',
                        control: CONTROLS.INPUT,
                        controlParams: {
                            type: CONTROL_TYPES.NUMBER,
                            min: 1,
                            max: 100,
                        },
                        validationRules: [
                            getIsNotEmptyStringRule(),
                            isIntegerNumber(),
                            getIsMinRule(1),
                            getIsMaxRule(100),
                        ],
                    },
                ],
            },
        ],
    },
}
