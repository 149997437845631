import React from 'react'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

const ForcePayoutModal = ({ data = {}, onSubmit = () => {}, onClose = () => {} }) => (
    <ConfirmationDialog
        onClose={() => onClose()}
        onAction={async () => onSubmit(data)}
        successText="Payout successfully!"
        headText="Please, confirm"
        noteText="Are you sure you want to payout?"
        targetText={data.id}
        actionVariant="primary"
        actionText={`Payout ${data.amount / 100} ${data.currency}`}
        cancelText="Cancel"
    />
)

export default ForcePayoutModal
