import React from 'react'

import TextArea from 'components/Forms/TextArea/TextArea'

import Voice from '../../../../../../../components/Voice/Voice'
import InputBlock from '../../../../../../../components/InputBlock/InputBlock'

import BlockWrapper from '../../BlockWrapper/BlockWrapper'

const AudioStory = ({ story, voice, onUpdateStory, onUpdateVoice }) => (
    <BlockWrapper title="Audio story">
        <InputBlock title="Story">
            <TextArea isWide rows={20} value={story} onChange={v => onUpdateStory(v)} />
        </InputBlock>
        <InputBlock title="Voice">
            <Voice value={voice} onChange={v => onUpdateVoice(v)} />
        </InputBlock>
    </BlockWrapper>
)

export default AudioStory
