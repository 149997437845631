import React from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Preloader from 'components/Preloader/Preloader'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import EditProjectModal from 'components/Modal/EditProjectModal/EditProjectModal'
import SEO from 'components/SEO/SEO'

import API from 'api'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'

import ProjectsTable from './components/ProjectsTable/ProjectsTable'

import { TABLE_PARAMS, MODALS, MODAL_ACTIONS } from './constants'

class ProjectsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,

            projects: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },
            modal: {
                isOpen: false,
                isLoading: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getProjects()
        this.setState({
            isReady: true,
        })
    }

    openModal = async (name, payload = {}) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        if (this.state.modal.isLoading) return
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }
    modalAction = async (actionName, payload = {}) => {
        try {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: true,
                },
            }))

            switch (actionName) {
                case MODAL_ACTIONS.BLOCK_PROJECT: {
                    await API.PROJECTS.BLOCK_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.UNBLOCK_PROJECT: {
                    await API.PROJECTS.UNBLOCK_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                case MODAL_ACTIONS.REMOVE_PROJECT: {
                    await API.PROJECTS.REMOVE_PROJECT(payload.projectId)
                    await this.getProjects()
                    break
                }
                default:
                    break
            }
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isSuccess: true,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                modal: {
                    ...prevState.modal,
                    isLoading: false,
                },
            }))
        }
    }

    getProjects = async (isClear = true) => {
        try {
            const {
                projects: { requestParams },
            } = this.state

            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.PROJECTS.GET_PROJECTS(requestParams)
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    lastResponse: response,
                    list: isClear ? response.content : [...prevState.projects.list, ...response.content],
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                projects: {
                    ...prevState.projects,
                    isLoading: false,
                },
            }))
        }
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            projects: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await this.setState(prevState => ({
            projects: {
                ...prevState.projects,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getProjects(isClear)
    }

    render() {
        const { isReady, projects, modal } = this.state

        const isOpenedModal = name => {
            return modal.isOpen && modal.name === name
        }

        return isReady ? (
            <>
                <SEO title="Projects" />

                <ProjectsTable
                    title="Projects"
                    list={projects.list}
                    methods={{
                        openModal: this.openModal,
                        modalAction: this.modalAction,
                        changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                    }}
                    pagination={{
                        currentPage: projects.lastResponse.number,
                        totalPages: projects.lastResponse.totalPages,
                        perPageElements: projects.lastResponse.numberOfElements,
                        totalElements: projects.lastResponse.totalElements,
                    }}
                    requestParams={projects.requestParams}
                    onExpand={() =>
                        this.changeRequestParamsAndRefresh(
                            [
                                {
                                    field: 'page',
                                    value: projects.lastResponse.number + 1,
                                },
                            ],
                            false,
                        )
                    }
                    onChangePage={page =>
                        this.changeRequestParamsAndRefresh([
                            {
                                field: 'page',
                                value: page,
                            },
                        ])
                    }
                    isLoading={projects.isLoading}
                />
                {isOpenedModal(MODALS.CONFIRMATION) && (
                    <ConfirmationDialog
                        onClose={() => this.closeModal()}
                        onAction={() => modal.payload.onAction()}
                        {...modal.payload.data}
                    />
                )}

                {isOpenedModal(MODALS.EDIT_PROJECT) && (
                    <EditProjectModal
                        onClose={() => this.closeModal()}
                        onRefresh={async () => await this.getProjects()}
                        projectId={modal.payload.projectId}
                    />
                )}
            </>
        ) : (
            <Preloader />
        )
    }
}

export default ProjectsPage
