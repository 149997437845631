import React, { useState } from 'react'

import Button from 'components/Forms/Button/Button'

import useModalManager from 'hooks/useModalManager'

import iconEdit from 'images/icons/editWhite.svg'
import iconRubbish from 'images/icons/rubbishBinWhite.svg'
import iconView from 'images/icons/viewWhite.svg'

import { MODALS } from '../../../../constants'

import { getProjectStructureForPreview } from './utils'

const ButtonsGroup = ({ template, methods }) => {
    const { openModal, MODAL_NAMES } = useModalManager()
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <Button
                content={<img style={{ width: 18 }} src={iconView} alt="" />}
                styles={{
                    marginRight: 10,
                }}
                variant="primary"
                isInverse={true}
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={async () => {
                    openModal(MODAL_NAMES.PROJECT_PREVIEW, getProjectStructureForPreview(template.structureJson))
                }}
            />
            <Button
                content={<img style={{ width: 18 }} src={iconEdit} alt="" />}
                styles={{
                    marginRight: 10,
                }}
                variant="primary"
                isInverse={true}
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={async () => {
                    setIsLoading(true)
                    await methods.openModal(MODALS.EDIT_TEMPLATE, { template })
                    setIsLoading(false)
                }}
            />
            <Button
                content={<img style={{ width: 18 }} src={iconRubbish} alt="" />}
                variant="danger"
                isInverse={true}
                onClick={() => methods.openModal(MODALS.REMOVE_TEMPLATE, template)}
            />
        </>
    )
}

export default ButtonsGroup
