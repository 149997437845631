import React from 'react'

import SEO from 'components/SEO/SEO'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Button from 'components/Forms/Button/Button'

import ROUTES from 'constants/routes'

import API from 'api'

import FolderList from './components/FolderList/FolderList'
import AudioList from './components/AudioList/AudioList'
import CreateFolderModal from './components/CreateFolderModal/CreateFolderModal'
import RemoveFolderModal from './components/RemoveFolderModal/RemoveFolderModal'
import UploadAudioModal from './components/UploadAudioModal/UploadAudioModal'
import EditFolderNameModal from './components/EditFolderNameModal/EditFolderNameModal'
import RemoveAudioModal from './components/RemoveAudioModal/RemoveAudioModal'
import EditAudioModal from './components/EditAudioModal/EditAudioModal'

import { MODAL_TYPES } from './constants'

import styles from './Audio.module.scss'

class AudioPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            folders: {
                isLoading: false,
                list: [],
            },
            audios: {
                isLoading: false,
                list: [],
            },
            selectedFolderId: null,
            modal: {
                isOpen: false,
                name: '',
                payload: {},
            },
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getFolders()
        this.setState({
            isReady: true,
        })
    }

    getFolders = async () => {
        try {
            await this.setState(prevState => ({
                folders: {
                    ...prevState.folders,
                    isLoading: true,
                },
            }))

            const { content } = await API.FOLDERS.GET_PUBLIC_AUDIO_FOLDERS()

            await this.setState(prevState => ({
                folders: {
                    ...prevState.folders,
                    list: content,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            await this.setState(prevState => ({
                folders: {
                    ...prevState.folders,
                    isLoading: false,
                },
            }))
        }
    }
    getAudios = async () => {
        try {
            await this.setState(prevState => ({
                audios: {
                    ...prevState.audios,
                    isLoading: true,
                },
            }))

            const { selectedFolderId } = this.state
            const { content } = await API.FOLDERS.GET_PUBLIC_AUDIO(selectedFolderId)

            await this.setState(prevState => ({
                audios: {
                    ...prevState.audios,
                    list: content,
                },
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            await this.setState(prevState => ({
                audios: {
                    ...prevState.audios,
                    isLoading: false,
                },
            }))
        }
    }

    createFolder = async ({ name }) => {
        await API.FOLDERS.CREATE_PUBLIC_AUDIO_FOLDER(name)
        await this.getFolders()
    }
    updateFolder = async ({ id, name }) => {
        await API.FOLDERS.UPDATE_PUBLIC_AUDIO_FOLDER(id, name)
        await this.getFolders()
    }
    removeFolder = async ({ id }) => {
        await API.FOLDERS.REMOVE_PUBLIC_AUDIO_FOLDER(id)
        await this.getFolders()
    }
    removeAudio = async ({ id }) => {
        await API.FOLDERS.REMOVE_PUBLIC_AUDIO(id)
        await this.getAudios()
    }
    uploadAudio = async ({ name, type, bytes }) => {
        const { selectedFolderId } = this.state
        await API.FOLDERS.UPLOAD_PUBLIC_AUDIO(name, type, bytes, selectedFolderId)
        await this.getAudios()
    }
    editAudio = async ({ id, name, audio }) => {
        const { selectedFolderId } = this.state
        if (audio) await API.FOLDERS.UPDATE_PUBLIC_AUDIO(id, name, audio.type, audio.bytes, selectedFolderId)
        else await API.FOLDERS.UPDATE_PUBLIC_AUDIO_NAME(id, name)
        await this.getAudios()
    }

    setSelectedFolder = async ({ id }) => {
        await this.setState({ selectedFolderId: id })
        await this.getAudios()
    }
    clearSelectedFolder = async () => {
        await this.setState(prevState => ({
            selectedFolderId: null,
            audios: {
                ...prevState.audios,
                list: [],
            },
        }))
    }

    openModal = async (name, payload = null) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }

    render() {
        const { isReady, folders, audios, modal, selectedFolderId } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        const selectedFolder = folders.list.find(folder => folder.id === selectedFolderId)

        return (
            <>
                <SEO title="Assets / Audio" />

                {isReady ? (
                    <>
                        {selectedFolder ? (
                            <>
                                <div className={styles.folderBreadcrumbs}>
                                    <Breadcrumbs
                                        list={[
                                            {
                                                to: `${ROUTES.ASSETS.path}/audio`,
                                                onClick: () => this.clearSelectedFolder(),
                                                label: 'Folders',
                                            },
                                            {
                                                to: null,
                                                label: selectedFolder.name,
                                                marks: [
                                                    {
                                                        title: 'Change name for this folder',
                                                        label: 'Change name',
                                                        onClick: () =>
                                                            this.openModal(
                                                                MODAL_TYPES.EDIT_FOLDER_NAME,
                                                                selectedFolder,
                                                            ),
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                    <Button
                                        content="+ Upload audio"
                                        variant="primary"
                                        onClick={() => this.openModal(MODAL_TYPES.UPLOAD_AUDIO)}
                                    />
                                </div>
                                <AudioList
                                    isLoading={audios.isLoading}
                                    list={audios.list}
                                    onEdit={audio => this.openModal(MODAL_TYPES.EDIT_AUDIO, audio)}
                                    onRemove={audio => this.openModal(MODAL_TYPES.REMOVE_AUDIO, audio)}
                                />
                            </>
                        ) : (
                            <FolderList
                                isLoading={folders.isLoading}
                                list={folders.list}
                                onView={folder => this.setSelectedFolder(folder)}
                                onRemove={folder => this.openModal(MODAL_TYPES.REMOVE_FOLDER, folder)}
                                onCreate={() => this.openModal(MODAL_TYPES.CREATE_FOLDER)}
                            />
                        )}

                        {isOpenedModal(MODAL_TYPES.CREATE_FOLDER) && (
                            <CreateFolderModal onSubmit={this.createFolder} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODAL_TYPES.REMOVE_FOLDER) && (
                            <RemoveFolderModal
                                data={modal.payload}
                                onSubmit={this.removeFolder}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODAL_TYPES.UPLOAD_AUDIO) && (
                            <UploadAudioModal onSubmit={this.uploadAudio} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODAL_TYPES.EDIT_FOLDER_NAME) && (
                            <EditFolderNameModal
                                data={modal.payload}
                                onSubmit={this.updateFolder}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODAL_TYPES.REMOVE_AUDIO) && (
                            <RemoveAudioModal
                                data={modal.payload}
                                onSubmit={this.removeAudio}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODAL_TYPES.EDIT_AUDIO) && (
                            <EditAudioModal data={modal.payload} onSubmit={this.editAudio} onClose={this.closeModal} />
                        )}
                    </>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default AudioPage
