import React from 'react'

import useModalManager from 'hooks/useModalManager'

import ProjectViewerModal from 'components/Modal/ProjectViewerModal/ProjectViewerModal'

import { MODALS } from './constants'

const ModalManager = () => {
    const { modalManager, closeModal } = useModalManager()

    return (
        <>
            {modalManager.name === MODALS.PROJECT_PREVIEW && (
                <ProjectViewerModal onClose={closeModal} projectStructureJson={modalManager.payload} />
            )}
        </>
    )
}

export default ModalManager
