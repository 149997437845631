import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import FormBlock from 'components/Forms/FormBlock'
import DatePicker from 'components/Forms/DatePicker/DatePicker'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { STATUS } from 'constants/subscription'

const InternalChangeSubscriptionDataModal = ({ subscription, onSubmit = () => {}, onClose = () => {} }) => {
    const [expirationDate, setExpirationDate] = useState(subscription.expirationDate)
    const [status, setStatus] = useState({ label: subscription.status, value: subscription.status })

    const [errors, setErrors] = useState({})

    const statusFormattedList = Object.values(STATUS)
        .filter(el => el !== subscription.status)
        .map(el => ({
            label: el,
            value: el,
        }))

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([
                validator.createField('status', status, [getIsValueRule()]),
                validator.createField('expirationDate', expirationDate, [getIsValueRule()]),
            ])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit(subscription, { expirationDate: expirationDate.valueOf(), status: status.value }, true)

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Internal change subscription data"
            successText="Subscription data changed successfully!"
            actionText="Change"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Expiration date">
                <DatePicker
                    error={errors.expirationDate}
                    value={expirationDate}
                    format={'MMM D, YYYY (HH:mm)'}
                    timeFormat={'h:mm aa'}
                    isShowTimeSelect={true}
                    isShowTodayButton={true}
                    onChange={date => {
                        setExpirationDate(date)
                        setErrors(removeKeyFromObject(errors, 'expirationDate'))
                    }}
                    isClearable={false}
                />
            </FormBlock>
            <FormBlock label="Status">
                <Select
                    error={errors.status}
                    value={status}
                    options={statusFormattedList}
                    onChange={value => {
                        setStatus(value)
                        setErrors(removeKeyFromObject(errors, 'status'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default InternalChangeSubscriptionDataModal
