import React from 'react'
import classNames from 'classnames'

import editIcon from 'images/icons/editWhite.svg'

import iconAdd from './i/add.png'
import iconRemove from './i/remove.png'

import styles from './ListView.module.scss'

const ListView = ({
    tagsList = [],
    isShowEdit = true,
    isShowAdd = false,
    isShowRemove = true,
    onEdit = () => {},
    onRemove = () => {},
    onAdd = () => {},
    isDisabled,
}) => (
    <ul className={classNames(styles.tags, { [styles.isDisabledTags]: isDisabled })}>
        {isShowEdit && (
            <li className={classNames(styles.tag, styles.isEditTag)} title="Edit tags" onClick={() => onEdit()}>
                <img src={editIcon} alt="edit" />
            </li>
        )}
        {!!tagsList.length ? (
            tagsList.map((tag, i) => (
                <li
                    key={tag.id}
                    className={classNames(styles.tag, { [styles.isMarkedTag]: tag.isSystem || tag.isInterests })}
                >
                    {(tag.isSystem || tag.isInterests) && (
                        <ul className={styles.marksList}>
                            {tag.isSystem && (
                                <li className={classNames(styles.mark, styles.isSystemMark)} title="System tag" />
                            )}
                            {tag.isInterests && (
                                <li className={classNames(styles.mark, styles.isInterestsMark)} title="Interests tag" />
                            )}
                        </ul>
                    )}
                    {tag.name || `Tag ${i + 1}`}
                    {isShowRemove && (
                        <div className={styles.tagAction} title="Remove" onClick={() => onRemove(tag)}>
                            <img src={iconRemove} alt="remove" />
                        </div>
                    )}
                    {isShowAdd && (
                        <div className={styles.tagAction} title="Add" onClick={() => onAdd(tag)}>
                            <img src={iconAdd} alt="add" />
                        </div>
                    )}
                </li>
            ))
        ) : (
            <div className={styles.isEmptyList}>Not selected</div>
        )}
    </ul>
)

export default ListView
