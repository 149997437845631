import React, { useState } from 'react'

import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import Translator from 'components/Translator/Translator'
import JsonParseModal from 'components/Modal/JsonParseModal/JsonParseModal'
// import ImageUploaderModal from 'components/Modal/ImageUploaderModal/ImageUploaderModal'
import Button from 'components/Forms/Button/Button'

import SlidingPanel from './components/SlidingPanel/SlidingPanel'
import Actions from './components/Actions/Actions'
import ActionItem from './components/Actions/components/ActionItem/ActionItem'

import iconTranslator from 'images/icons/translator.png'

import styles from './Dashboard.module.scss'

const Dashboard = ({ children }) => {
    const [isVisibleTranslator, setIsVisibleTranslator] = useState(false)
    const [isVisibleJsonParser, setIsVisibleJsonParser] = useState(false)
    // const [isVisibleImageUploader, setIsVisibleImageUploader] = useState(false)

    return (
        <div className={styles.dashboard}>
            <Header />
            <div className={styles.dashboardWorkplace}>
                <Sidebar />
                <section id="dashboard-content" className={styles.content}>
                    <div className={styles.contentWorkplace}>{children}</div>
                </section>
            </div>

            <Actions>
                <ActionItem>
                    {/*<Button content="Image Uploader" variant="primary" onClick={() => setIsVisibleImageUploader(true)} isWide />*/}
                    {/*<br/>*/}
                    <Button
                        content="JSON Parser"
                        variant="primary"
                        onClick={() => setIsVisibleJsonParser(true)}
                        isWide
                    />
                </ActionItem>
            </Actions>

            <SlidingPanel
                isVisible={isVisibleTranslator}
                icon={iconTranslator}
                onChangeVisible={v => setIsVisibleTranslator(v)}
            >
                <Translator rows={5} />
            </SlidingPanel>

            {/*{isVisibleImageUploader && <ImageUploaderModal onClose={() => setIsVisibleImageUploader(false)} />}*/}
            {isVisibleJsonParser && <JsonParseModal onClose={() => setIsVisibleJsonParser(false)} />}
        </div>
    )
}

export default Dashboard
