export const STATUS = {
    INACTIVE: 'INACTIVE',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
}

export const BILLING_SYSTEM = {
    INTERNAL: 'INTERNAL',
    TWO_CHECKOUT: 'TWO_CHECKOUT',
    APPSUMO: 'APPSUMO',
    STRIPE: 'STRIPE',
    YOOKASSA: 'YOOKASSA',
}

export const ORDER_STATUS = {
    COMPLETE: 'COMPLETE',
    CANCELED: 'CANCELED',
}

export const TYPES = {
    GLOBAL: 'GLOBAL',
    PROJECT: 'PROJECT',
}

export const PRODUCT_LIST = {
    Trial: '6531GA9GB2',
    Free: '7D6DCC0440',
    Starter: '2444NE4LB5',
    StarterYear: '2444NE4LB5_YEAR',
    Basic: 'E8F83FBF8E',
    BasicYear: 'E8F83FBF8E_YEAR',
    Professional: '29D7A9C4F0',
    ProfessionalYear: '29D7A9C4F0_YEAR',
    ProfessionalPlus: '35D7A9C4DB',
    ProfessionalPlusYear: '35D7A9C4DB_YEAR',
    Enterprise: 'E721588D11',
    EnterpriseYear: 'E721588D11_YEAR',
    Organizations: '35D7A9C4D1',
    OrganizationsYear: '35D7A9C4D1_YEAR',
    OneTimeWhitelabel: 'NYII0AS0I4',
    OneTimeGamification: 'XOTEE7HTVX',
    OneTimeEvent: '3K0XQKIWLW',
}

export const FEATURES_TYPES = {
    REVIEWS_COUNT: 'REVIEWS_COUNT',
    WEBSITES: 'WEBSITES',
    PROJECTS_COUNT: 'PROJECTS_COUNT',
    PREMIUM_TEMPLATES_COUNT: 'PREMIUM_TEMPLATES_COUNT',
    LABEL_INTERACTY_EDUCATION: 'LABEL_INTERACTY_EDUCATION',
    GAMIFICATION: 'GAMIFICATION',
    NO_LOGO: 'NO_LOGO',
    NO_ADVERTISEMENT: 'NO_ADVERTISEMENT',
    FACEBOOK_PIXEL: 'FACEBOOK_PIXEL',
    GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
    LEAD_CONTACTS: 'LEAD_CONTACTS',
    BASIC_STATISTIC: 'BASIC_STATISTIC',
    FULL_STATISTICS: 'FULL_STATISTICS',
    LEAD_COLLECTION_FORM: 'LEAD_COLLECTION_FORM',
    PRIORITY_SUPPORT: 'PRIORITY_SUPPORT',
    CUSTOM_PROJECT_URL: 'CUSTOM_PROJECT_URL',
    PERSONAL_ONBOARDING: 'PERSONAL_ONBOARDING',
    SDK_INTEGRATION: 'SDK_INTEGRATION',
    FULL_CUSTOMIZATION: 'FULL_CUSTOMIZATION',
    TEAM: 'TEAM',
    CUSTOM_CSS_STYLES: 'CUSTOM_CSS_STYLES',
    INTEGRATIONS: 'INTEGRATIONS',
    CUSTOM_DOMAINS: 'CUSTOM_DOMAINS',
    STORAGE_SIZE: 'STORAGE_SIZE',
    MULTIPLAYER_PLAYERS: 'MULTIPLAYER_PLAYERS',
}
