import * as AUTH from './auth'
import * as BLOCKS_TEMPLATES from './blocksTemplates'
import * as COMMON from './common'
import * as EMAILS from './emails'
import * as PROJECTS from './projects'
import * as TEMPLATES from './templates'
import * as TRANSLATION from './translation'
import * as USERS from './users'
import * as ORGANIZATIONS from './organizations'
import * as FOLDERS from './folders'
import * as QUESTION_BANKS from './questionBanks'
import * as AUTHORS from './authors'
import * as CONTENT from './content'
import * as PAYOUTS from './payouts'
import * as PROJECT_GENERATOR from './projectGenerator'

export default {
    AUTH,
    BLOCKS_TEMPLATES,
    COMMON,
    EMAILS,
    PROJECTS,
    TEMPLATES,
    TRANSLATION,
    USERS,
    ORGANIZATIONS,
    FOLDERS,
    QUESTION_BANKS,
    AUTHORS,
    CONTENT,
    PAYOUTS,
    PROJECT_GENERATOR,
}
