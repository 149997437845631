import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description }) => (
    <Helmet>
        <title>{title ? `${title} | Interacty Admin` : 'Interacty Admin'}</title>
        <meta name="description" content={description || 'Admin panel for the Interacty platform'} />
    </Helmet>
)

export default SEO
