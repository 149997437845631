import React from 'react'
import classNames from 'classnames'

import styles from './Preloader.module.scss'

import preloaderImage from './i/preloader.gif'

const Preloader = ({ text, isFullScreen = false }) => (
    <div className={classNames(styles.preloader, { [styles.isFullScreenPreloader]: isFullScreen })}>
        <img className={styles.image} src={preloaderImage} alt="" />
        {text ? <p className={styles.text}>{text}</p> : null}
    </div>
)

export default Preloader
