import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `created,${SORT_ORDERS.desc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 30,
        getValue: v => Number(v),
    },
    login: {},
    id: {
        getValue: v => Number(v),
    },
    firstName: {},
    lastName: {},
    isPaidOnly: {
        getValue: v => v === 'true',
    },
    isActive: {
        getValue: v => v === 'true',
    },
    isTest: {
        getValue: v => v === 'true',
    },
    groupId: {
        getValue: v => Number(v),
    },
    roleId: {
        getValue: v => Number(v),
    },
    initialProvider: {},
    subscriptionPlanId: {
        getValue: v => Number(v),
    },
    numberOfProjects: {
        getValue: v => Number(v),
    },
    numberOfPublishedProjects: {
        getValue: v => Number(v),
    },
    from: {
        getValue: v => Number(v),
    },
    to: {
        getValue: v => Number(v),
    },
}

export const PAID_ONLY_PLAN_VALUE = 'PAID_ONLY'
