import React from 'react'

import Modal from 'components/Modal/Modal'

import DataItem from './components/DataItem/DataItem'

import { getFeatureValue } from './components/utils'

import styles from './FeaturesModal.module.scss'

const FeaturesModal = ({ product, onClose = () => {} }) => (
    <Modal onClose={onClose} isShowCross>
        <div className={styles.featuresModal}>
            <div className={styles.head}>
                {product.name} ({product.productCode})
            </div>
            <ul className={styles.list}>
                {product.featureProducts.map(feature => (
                    <li key={feature.id} className={styles.item}>
                        <h5 className={styles.title}>{feature.featureType}</h5>
                        <ul className={styles.rows}>
                            <DataItem title="Feature ID" value={feature.id} />
                            <DataItem title="Feature type" value={feature.featureType} />
                            <DataItem title="Feature name" value={feature.name} />
                            <DataItem title="Product code" value={feature.productCode} />
                            <DataItem title="Value" value={getFeatureValue(feature)} />
                        </ul>
                    </li>
                ))}
            </ul>
        </div>
    </Modal>
)

export default FeaturesModal
