import React from 'react'
import classNames from 'classnames'

import styles from './FormTabs.module.scss'

const FormTabs = ({ items = [], activeValue, onChangeTab }) => (
    <ul className={styles.formTabs}>
        {items.map(item => (
            <li
                key={item.value}
                title={item.title}
                className={classNames(styles.tabItem, {
                    [styles.tabItemRequired]: item.isRequired,
                    [styles.tabItemActive]: item.value === activeValue,
                    [styles.tabItemEmpty]: !item.isFilled,
                    [styles.tabItemError]: item.isError,
                })}
                onClick={() => onChangeTab(item.value)}
            >
                {item.label}
            </li>
        ))}
    </ul>
)

export default FormTabs
