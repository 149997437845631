import React from 'react'

import Table from 'components/Table/Table'
import Button from 'components/Forms/Button/Button'
import SelectFilter from 'components/Table/components/Filters/SelectFilter'

import { copyToClipboard } from 'utils/clipboard'
import { getSortKeys } from 'utils/table'
import { getPublicTemplateSubjectLabel, getPublicTemplateGradeLabel } from 'utils/projects'

import SORT_ORDERS from 'constants/sortOrders'

import iconCopy from 'images/icons/copy.svg'
import iconEye from 'images/icons/eyeWhite.svg'

import { MODALS } from '../../constants'

const getColumns = ({ methods, requestParams }) => {
    const sort = getSortKeys(requestParams)

    return [
        {
            headerLabel: 'ID',
            getMarks: content => [
                {
                    image: iconCopy,
                    label: 'Copy ID to clipboard',
                    onClick: () => copyToClipboard(content.id),
                },
            ],
            getTitle: content => content.id,
            getValue: content => content.id,
            width: '20%',
            sort: {
                key: 'id',
                order: sort.key === 'id' ? sort.order : SORT_ORDERS.undefined,
            },
        },
        {
            headerLabel: 'Approved',
            headerFilter: (
                <SelectFilter
                    initialValue={requestParams.approved}
                    field="approved"
                    options={[
                        {
                            value: null,
                            label: 'All',
                        },
                        {
                            value: true,
                            label: 'Yes',
                        },
                        {
                            value: false,
                            label: 'No',
                        },
                    ]}
                    onChange={methods.changeRequestParamsAndRefresh}
                />
            ),
            getTitle: template => (template.isInGallery ? 'Yes' : 'No'),
            getValue: template => (template.isInGallery ? 'Yes' : 'No'),
            width: '7%',
        },
        {
            headerLabel: 'Title',
            getTitle: content => content.title,
            getValue: content => content.title,
            width: '10%',
        },
        {
            headerLabel: 'Description',
            getTitle: content => content.description,
            getValue: content => content.description,
            width: '20%',
        },
        {
            headerLabel: 'Subject',
            getTitle: content => getPublicTemplateSubjectLabel(content.subject),
            getValue: content => getPublicTemplateSubjectLabel(content.subject),
            width: '10%',
        },
        {
            headerLabel: 'Grade',
            getTitle: content => getPublicTemplateGradeLabel(content.grade),
            getValue: content => getPublicTemplateGradeLabel(content.grade),
            width: '10%',
        },
        {
            headerLabel: 'Image',
            getValue: content => (content.previewImageUrl ? <img src={content.previewImageUrl} alt="" /> : null),
            width: '10%',
        },
        {
            valueStyles: { justifyContent: 'flex-end' },
            getValue: content => (
                <>
                    <Button
                        content={<img src={iconEye} alt="" />}
                        variant="primary"
                        isInverse={true}
                        onClick={() => window.open(content.contentUrl, '_blank')}
                        styles={{ padding: 0, marginRight: 10, width: 36 }}
                    />
                    {content.isInGallery ? (
                        <Button
                            content="Hide"
                            title="Hide from gallery"
                            variant="danger"
                            isInverse={true}
                            onClick={() => methods.openModal(MODALS.DENY_CONTENT, { content })}
                        />
                    ) : (
                        <Button
                            content="Publish"
                            title="Publish in gallery"
                            variant="primary"
                            isInverse={true}
                            onClick={() => methods.openModal(MODALS.APPROVE_CONTENT, { content })}
                        />
                    )}
                </>
            ),
            width: '13%',
        },
    ]
}

const UserContentTable = ({ list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns({ methods, requestParams })}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key, order }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sort', value: `${key},${order}` }])
        }}
        isLoading={isLoading}
    />
)

export default UserContentTable
