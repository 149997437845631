import React, { useState, useEffect } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import FormModal from 'components/Modal/FormModal/FormModal'
import FormBlock from 'components/Forms/FormBlock'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import JsonCodeBlock from 'components/Forms/CodeBlock/JSON/JSON'

import Preloader from 'components/Preloader/Preloader'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule, getIsJsonStringRule } from 'utils/fieldsValidator'
import { encodeOrDecodeJsonStructure, removeKeyFromObject } from 'utils/common'

import API from 'api'

import { PROJECTS_STATUSES } from 'constants/projects'

const EditProjectModal = ({ projectId, onRefresh = () => {}, onClose = () => {} }) => {
    const [isReady, setIsReady] = useState(false)

    const [project, setProject] = useState(false)

    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [projectStructureJson, setProjectStructureJson] = useState('')

    const [isPublishRepublish, setIsPublishRepublish] = useState(false)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        API.PROJECTS.GET_PROJECT(projectId).then(project => {
            setProject(project)

            setName(project.name)
            setTitle(project.title)
            setDescription(project.description)
            setIsPublishRepublish(project.projectStatus === PROJECTS_STATUSES.PUBLISHED)
            setProjectStructureJson(JSON.stringify(project.projectStructureJson))

            setIsReady(true)
        })
    }, [])

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            validator.addFields([
                validator.createField('projectStructureJson', projectStructureJson, [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsJsonStringRule(),
                ]),
            ])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await API.PROJECTS.UPDATE_PROJECT(project.projectId, {
                ...project,
                name,
                title,
                description,
                projectStructureJson: encodeOrDecodeJsonStructure(JSON.parse(projectStructureJson), 'encode'),
            })

            if (isPublishRepublish) {
                await API.PROJECTS.PUBLISH_PROJECT(project.projectId)
            }

            await onRefresh()

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit project"
            successText="Project updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            {isReady ? (
                <>
                    <FormBlock label="Name">
                        <Input
                            value={name}
                            error={errors.name}
                            onChange={value => {
                                setName(value)
                                setErrors(removeKeyFromObject(errors, 'name'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Project structure (JSON)">
                        <JsonCodeBlock
                            value={projectStructureJson}
                            error={errors.projectStructureJson}
                            onChange={value => {
                                setProjectStructureJson(value)
                                setErrors(removeKeyFromObject(errors, 'projectStructureJson'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Title">
                        <Input
                            value={title}
                            error={errors.title}
                            onChange={value => {
                                setTitle(value)
                                setErrors(removeKeyFromObject(errors, 'title'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Description">
                        <Input
                            value={description}
                            error={errors.description}
                            onChange={value => {
                                setDescription(value)
                                setErrors(removeKeyFromObject(errors, 'description'))
                            }}
                        />
                    </FormBlock>
                    <br />
                    <FormBlock>
                        <Toggle
                            value={isPublishRepublish}
                            label={project.projectStatus === PROJECTS_STATUSES.PUBLISHED ? 'Republish' : 'Publish'}
                            onChange={value => {
                                setIsPublishRepublish(value)
                                setErrors(removeKeyFromObject(errors, 'isPublishRepublish'))
                            }}
                        />
                    </FormBlock>
                </>
            ) : (
                <Preloader />
            )}
        </FormModal>
    )
}

export default EditProjectModal
