import { CURRENCIES } from 'constants/currencies'

export const getFormattedPrice = (price, currency) => {
    if (price === undefined || price === null) return null
    if (currency === CURRENCIES.USD) return `${getCurrencySymbol(CURRENCIES.USD)}${price.toFixed(2)}`
    else if (currency === CURRENCIES.RUB) return `${price.toFixed(2)} ${getCurrencySymbol(CURRENCIES.RUB)}`
    return `${price.toFixed(2)} ${currency}`
}

export const getCurrencySymbol = currency => {
    if (currency === CURRENCIES.USD) return '$'
    if (currency === CURRENCIES.RUB) return '₽'
    return null
}

export const getMonthlyFormattedPrice = (price, currency, billingCycle) => {
    if (billingCycle === -1) return getFormattedPrice(price, currency)
    if (billingCycle === 1) return getFormattedPrice(price, currency)
    if (billingCycle === 12) return getFormattedPrice(price / 12, currency)
    return null
}

export const getYearlyFormattedPrice = (price, currency, billingCycle) => {
    if (billingCycle === -1) return getFormattedPrice(price, currency)
    if (billingCycle === 1) return getFormattedPrice(price * 12, currency)
    if (billingCycle === 12) return getFormattedPrice(price, currency)
    return price
}

export const getMonthlyPrice = (price, billingCycle) => {
    if (billingCycle === -1) return price
    if (billingCycle === 1) return price
    if (billingCycle === 12) return price / 12
    return null
}

export const getYearlyPrice = (price, billingCycle) => {
    if (billingCycle === -1) return price
    if (billingCycle === 1) return price * 12
    if (billingCycle === 12) return price
    return price
}
