import React, { useEffect, useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Upload from 'components/Forms/Upload/Upload'
import Input from 'components/Forms/Input/Input'
import Toggle from 'components/Forms/Toggle/Toggle'
import FormBlock from 'components/Forms/FormBlock'
import JsonCodeBlock from 'components/Forms/CodeBlock/JSON/JSON'
import Tags from 'components/Tags/Tags'
import LocaleFormBlock, { CONTROL_TYPES } from 'components/Forms/components/LocaleFormBlock/LocaleFormBlock'
import FormBlockInner from 'components/Forms/FormBlockInner'
import Button from 'components/Forms/Button/Button'
import Select from 'components/Forms/Select/Select'

import FormModal from 'components/Modal/FormModal/FormModal'

import {
    fieldsValidator,
    getIsValueRule,
    getIsIntStringRule,
    getIsNotEmptyStringRule,
    getIsJsonStringRule,
} from 'utils/fieldsValidator'
import {
    encodeOrDecodeJsonStructure,
    setKeyToObject,
    removeKeyFromObject,
    removeFromArrayByIndex,
    moveInArray,
    replaceArrayElementByIndex,
} from 'utils/common'
import {
    getLocaleDependentTextFieldsStateObject,
    addLocaleDependentTextFieldsToValidator,
    convertToList,
} from 'utils/translations'
import { getSpecialTagsData, getOptionByTag, getTagByOption } from 'utils/tag'

import { DEFAULT_LOCALE } from 'constants/locales'
import { FILE_TYPES } from 'constants/fileTypes'
import { PROJECT_MODES } from 'constants/projects'

const LOCALE_DEPENDENT_FIELDS = {
    NAME: {
        fieldName: 'name',
        translationsFieldKey: 'nameTranslation',
    },
    DESCRIPTION: {
        fieldName: 'description',
        translationsFieldKey: 'descriptionTranslation',
    },
}

const EditTemplateModal = ({ template, onSubmit = () => {}, onClose = () => {} }) => {
    const [isLoading, setIsLoading] = useState(true)

    const [activeNameLocale, setActiveNameLocale] = useState(DEFAULT_LOCALE)
    const [activeDescriptionLocale, setActiveDescriptionLocale] = useState(DEFAULT_LOCALE)

    const initialLocaleDependentFields = getLocaleDependentTextFieldsStateObject(LOCALE_DEPENDENT_FIELDS, template)

    const [localeDependentFields, setLocaleDependentFields] = useState(initialLocaleDependentFields)

    const [isShowDescription, setIsShowDescription] = useState(!!template.descriptionTranslation)

    const [isEnabled, setIsEnabled] = useState(template.enabled)

    const [image, setImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')

    const [projectPdf, setProjectPdf] = useState(null)
    const [projectPdfUrl, setProjectPdfUrl] = useState('')

    const [isPremium, setIsPremium] = useState(template.isPremium)

    const [tagsData, setTagsData] = useState({})

    const [language, setLanguage] = useState(null)
    const [role, setRole] = useState(null)
    const [tags, setTags] = useState(template.tags)

    const [orderNumber, setOrderNumber] = useState(template.orderNumber)

    const [structureJson, setStructureJson] = useState(JSON.stringify(template.projectStructureJson))

    const [isMultiplayerMode] = useState(template.mode === PROJECT_MODES.MULTIPLAYER)
    const [demoImages, setDemoImages] = useState(Array(template.demoImages ? template.demoImages.length : 1).fill(null))
    const [demoImagesUrls, setDemoImagesUrls] = useState(template.demoImages || [''])

    const [errors, setErrors] = useState({})

    useEffect(() => {
        getSpecialTagsData()
            .then(data => {
                setTagsData(data)
                setLanguage(getOptionByTag(data.language.options, template.languageTemplateTag))
                if (template.roleTemplateTag?.length === 1) {
                    setRole(getOptionByTag(data.role.options, template.roleTemplateTag[0]))
                } else if (template.roleTemplateTag?.length > 1) {
                    setRole(data.role.options[0])
                }
                setIsLoading(false)
            })
            .catch(err => console.error(err))
    }, [])

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            addLocaleDependentTextFieldsToValidator(validator, {
                fields: LOCALE_DEPENDENT_FIELDS,
                values: localeDependentFields,
                excludeFields: {
                    [LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName]: !isShowDescription,
                },
            })
            validator.addFields([
                validator.createField('orderNumber', String(orderNumber), [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsIntStringRule(),
                ]),
                validator.createField('structureJson', structureJson, [
                    getIsValueRule(),
                    getIsNotEmptyStringRule(),
                    getIsJsonStringRule(),
                ]),
                validator.createField('language', language, [getIsValueRule()]),
                validator.createField('role', role, [getIsValueRule()]),
            ])

            if (image) {
                validator.addFields([
                    validator.createField('image', imageUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }

            if (isMultiplayerMode) {
                demoImagesUrls.forEach((demoImageUrl, index) => {
                    validator.addFields([
                        validator.createField(`demoImages[${index}]`, demoImageUrl, [
                            getIsValueRule(),
                            getIsNotEmptyStringRule(
                                !!demoImages[index] ? 'File must be uploaded' : 'Field is required',
                            ),
                        ]),
                    ])
                })
            }

            if (projectPdf) {
                validator.addFields([
                    validator.createField('projectPdf', projectPdfUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            const nameTransl = localeDependentFields[LOCALE_DEPENDENT_FIELDS.NAME.fieldName]
            const newNameTransl = convertToList(nameTransl)
            const descriptionTransl = localeDependentFields[LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName]
            const newDescriptionTransl = convertToList(descriptionTransl)
            await onSubmit({
                id: template.id,
                projectStructureJson: JSON.stringify(encodeOrDecodeJsonStructure(JSON.parse(structureJson), 'encode')),
                isPremium,
                imageUrl: imageUrl || template.imageUrl,
                orderNumber: parseInt(orderNumber),
                enabled: isEnabled,
                projectType: {
                    id: template.projectType.id,
                },
                projectPdfUrl: projectPdfUrl === null ? null : projectPdfUrl || template.projectPdfUrl,
                name: newNameTransl,
                description: isShowDescription ? newDescriptionTransl : [],
                tags,
                mode: template.mode,
                demoImages: isMultiplayerMode ? demoImagesUrls : null,
                roleTemplateTag: getTagByOption(tagsData.role.list, role, {
                    fallbackValue: tagsData.role.list,
                    isWrapInArray: true,
                }),
                languageTemplateTag: getTagByOption(tagsData.language.list, language),
            })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit template"
            subHeadText="Note: If the file field is left blank then will be applied to the old value"
            successText="Template updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
            isLoading={isLoading}
        >
            <LocaleFormBlock
                label="Name"
                errors={errors}
                fields={localeDependentFields}
                fieldName={LOCALE_DEPENDENT_FIELDS.NAME.fieldName}
                activeTabName={activeNameLocale}
                onChangeActiveTabName={v => setActiveNameLocale(v)}
                onChange={data => {
                    setLocaleDependentFields(data.value)
                    setErrors(data.errors)
                }}
            />
            <FormBlock>
                <Toggle
                    value={isShowDescription}
                    label="Show description"
                    onChange={value => {
                        setIsShowDescription(value)
                        setErrors(removeKeyFromObject(errors, 'isShowDescription'))
                    }}
                />
            </FormBlock>
            {isShowDescription && (
                <LocaleFormBlock
                    label="Description"
                    controlType={CONTROL_TYPES.TEXTAREA}
                    errors={errors}
                    fields={localeDependentFields}
                    fieldName={LOCALE_DEPENDENT_FIELDS.DESCRIPTION.fieldName}
                    activeTabName={activeDescriptionLocale}
                    onChangeActiveTabName={v => setActiveDescriptionLocale(v)}
                    onChange={data => {
                        setLocaleDependentFields(data.value)
                        setErrors(data.errors)
                    }}
                />
            )}
            <br />
            <FormBlock>
                <Toggle
                    value={isEnabled}
                    label="Enabled"
                    onChange={value => {
                        setIsEnabled(value)
                        setErrors(removeKeyFromObject(errors, 'isEnabled'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Image">
                <Upload
                    lastUrl={template.imageUrl}
                    value={image}
                    error={errors.image}
                    isUploaded={!!imageUrl}
                    onReset={() => {
                        setImageUrl('')
                        setImage(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'image', errorText))
                            return
                        }
                        setImageUrl(url)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                    onChange={file => {
                        setImageUrl('')
                        setImage(file)
                        setErrors(removeKeyFromObject(errors, 'image'))
                    }}
                />
            </FormBlock>
            <FormBlock>
                <Toggle
                    value={isPremium}
                    label="Premium template"
                    onChange={value => {
                        setIsPremium(value)
                        setErrors(removeKeyFromObject(errors, 'isPremium'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Order number">
                <Input
                    type="number"
                    min={1}
                    value={orderNumber}
                    error={errors.orderNumber}
                    onChange={value => {
                        setOrderNumber(value)
                        setErrors(removeKeyFromObject(errors, 'orderNumber'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Language">
                <Select
                    error={errors.language}
                    value={language}
                    options={tagsData.language?.options}
                    onChange={value => {
                        setLanguage(value)
                        setErrors(removeKeyFromObject(errors, 'language'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Users Role">
                <Select
                    error={errors.role}
                    value={role}
                    options={tagsData.role?.options}
                    onChange={value => {
                        setRole(value)
                        setErrors(removeKeyFromObject(errors, 'role'))
                    }}
                />
            </FormBlock>
            <FormBlock label="Tags">
                <Tags
                    isForceSuccessClose={true}
                    isEnsureRemove={false}
                    selectedTagsList={tags}
                    onUpdate={tags => setTags(tags)}
                    onRemove={({ id }) => setTags(tags.filter(tag => tag.id !== id))}
                />
            </FormBlock>
            <FormBlock label="Template structure (JSON)">
                <JsonCodeBlock
                    value={structureJson}
                    error={errors.structureJson}
                    onChange={value => {
                        setStructureJson(value)
                        setErrors(removeKeyFromObject(errors, 'structureJson'))
                    }}
                />
            </FormBlock>
            <FormBlock>
                <Toggle
                    value={template.mode === PROJECT_MODES.MULTIPLAYER}
                    label="Multiplayer mode"
                    isDisabled
                    onChange={() => {}}
                />
            </FormBlock>
            {isMultiplayerMode && (
                <FormBlock label="Multiplayer Demo Images">
                    {demoImages.map((demoImage, index) => (
                        <FormBlockInner
                            key={index}
                            onRemove={() => {
                                setDemoImagesUrls(removeFromArrayByIndex(demoImagesUrls, index))
                                setDemoImages(removeFromArrayByIndex(demoImages, index))
                                setErrors(removeKeyFromObject(errors, 'demoImages'))
                            }}
                            onUp={() => {
                                setDemoImagesUrls(moveInArray(demoImagesUrls, index, index - 1))
                                setDemoImages(moveInArray(demoImages, index, index - 1))
                                setErrors(removeKeyFromObject(errors, 'demoImages'))
                            }}
                            onDown={() => {
                                setDemoImagesUrls(moveInArray(demoImagesUrls, index, index + 1))
                                setDemoImages(moveInArray(demoImages, index, index + 1))
                                setErrors(removeKeyFromObject(errors, 'demoImages'))
                            }}
                            isShowRemove={demoImages.length > 1}
                            isShowUp={index !== 0}
                            isShowDown={index !== demoImages.length - 1}
                        >
                            <Upload
                                value={demoImage}
                                error={errors.demoImages?.[index]}
                                lastUrl={demoImagesUrls[index]}
                                isUploaded={!!demoImages[index] && !!demoImagesUrls[index]}
                                onReset={() => {
                                    setDemoImagesUrls(replaceArrayElementByIndex(demoImagesUrls, index, ''))
                                    setDemoImages(replaceArrayElementByIndex(demoImages, index, null))
                                }}
                                onUpload={({ isSuccess, url, errorText }) => {
                                    if (!isSuccess) {
                                        setErrors(prevState =>
                                            setKeyToObject(prevState, `demoImages[${index}]`, errorText),
                                        )
                                        return
                                    }
                                    setDemoImagesUrls(prevState => replaceArrayElementByIndex(prevState, index, url))
                                    setErrors(prevState => removeKeyFromObject(prevState, `demoImages[${index}]`))
                                }}
                                onChange={file => {
                                    setDemoImagesUrls(replaceArrayElementByIndex(demoImagesUrls, index, ''))
                                    setDemoImages(replaceArrayElementByIndex(demoImages, index, file))
                                    setErrors(removeKeyFromObject(errors, `demoImages[${index}]`))
                                }}
                            />
                        </FormBlockInner>
                    ))}
                    <Button
                        content="+ Add Image"
                        title="+ Add Image"
                        onClick={() => {
                            setDemoImagesUrls([...demoImagesUrls, ''])
                            setDemoImages([...demoImages, null])
                        }}
                    />
                </FormBlock>
            )}
            <FormBlock label="PDF">
                <Upload
                    isCanRemove
                    lastUrl={template.projectPdfUrl}
                    type={FILE_TYPES.PDF_DOCUMENT}
                    value={projectPdf}
                    error={errors.projectPdf}
                    isUploaded={!!projectPdfUrl}
                    onReset={() => {
                        setProjectPdfUrl('')
                        setProjectPdf(null)
                    }}
                    onUpload={({ isSuccess, url, errorText }) => {
                        if (!isSuccess) {
                            setErrors(setKeyToObject(errors, 'projectPdf', errorText))
                            return
                        }
                        setProjectPdfUrl(url)
                        setErrors(removeKeyFromObject(errors, 'projectPdf'))
                    }}
                    onChange={file => {
                        setProjectPdfUrl('')
                        setProjectPdf(file)
                        setErrors(removeKeyFromObject(errors, 'projectPdf'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default EditTemplateModal
