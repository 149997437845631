export const getItem = (key, options = {}) => {
    const { isJsonParse = true, fallbackValue = null } = options

    const item = localStorage.getItem(key)
    return item ? (isJsonParse ? JSON.parse(item) : item) : fallbackValue
}

export const setItem = (key, value, options = {}) => {
    const { isJsonStringify = true } = options

    localStorage.setItem(key, isJsonStringify ? JSON.stringify(value) : value)
}

export const removeItem = key => localStorage.removeItem(key)
