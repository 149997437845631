import React, { useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

const EditFolderNameModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const [name, setName] = useState(data.name)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('name', name, [getIsValueRule(), getIsNotEmptyStringRule()])])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit({ ...data, name: name.trim() })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit folder name"
            successText="Folder name updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            <FormBlock label="Name">
                <Input
                    value={name}
                    error={errors.name}
                    onChange={value => {
                        setName(value)
                        setErrors(removeKeyFromObject(errors, 'name'))
                    }}
                />
            </FormBlock>
        </FormModal>
    )
}

export default EditFolderNameModal
