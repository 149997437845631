import React, { useEffect, useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Toggle from 'components/Forms/Toggle/Toggle'
import Checkbox from 'components/Forms/Checkbox/Checkbox'
import Preloader from 'components/Preloader/Preloader'
import LocaleFormBlock from 'components/Forms/components/LocaleFormBlock/LocaleFormBlock'
import Select from 'components/Forms/Select/Select'
import Upload from 'components/Forms/Upload/Upload'

import { fieldsValidator, getIsValueRule, getIsNotEmptyStringRule } from 'utils/fieldsValidator'
import { removeKeyFromObject, setKeyToObject } from 'utils/common'
import {
    addLocaleDependentTextFieldsToValidator,
    convertToList,
    getLocaleDependentTextFieldsStateObject,
    getTranslation,
} from 'utils/translations'

import LOCALES, { DEFAULT_LOCALE } from 'constants/locales'

const LOCALE_DEPENDENT_FIELDS = {
    FORMATTED_NAME: {
        fieldName: 'formattedName',
        translationsFieldKey: 'formattedName',
    },
}

const EditTagModal = ({ data, onSubmit = () => {}, onClose = () => {} }) => {
    const { tag } = data

    const [isConfirm, setIsConfirm] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isChildren, setIsChildren] = useState(!!tag.parentTag)

    const [icon, setIcon] = useState(null)
    const [iconUrl, setIconUrl] = useState('')

    const [isSystem, setIsSystem] = useState(tag.isSystem)
    const [isInterests, setIsInterests] = useState(tag.isInterests)
    const [name, setName] = useState(tag.name)
    const [parentTag, setParentTag] = useState(tag.parentTag ? { value: tag.parentTag, label: tag.parentTag } : null)

    const [localeDependentFields, setLocaleDependentFields] = useState({})
    const [activeFormattedNameLocale, setActiveFormattedNameLocale] = useState(DEFAULT_LOCALE)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        getTranslation(tag.formattedNameTranslationId)
            .then(response => {
                setLocaleDependentFields(
                    getLocaleDependentTextFieldsStateObject(LOCALE_DEPENDENT_FIELDS, { formattedName: response }),
                )
                setIsLoading(false)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            addLocaleDependentTextFieldsToValidator(validator, {
                fields: LOCALE_DEPENDENT_FIELDS,
                values: localeDependentFields,
                requiredLocales: [LOCALES.EN],
            })
            validator.addFields([validator.createField('name', name, [getIsValueRule(), getIsNotEmptyStringRule()])])

            if (isChildren) {
                validator.addFields([validator.createField('parentTag', parentTag, [getIsValueRule()])])
            }

            if (icon) {
                validator.addFields([
                    validator.createField('icon', iconUrl, [
                        getIsValueRule(),
                        getIsNotEmptyStringRule('File must be uploaded'),
                    ]),
                ])
            }

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            if (tag.name !== name && data.existingTags.map(tag => tag.name).includes(name)) {
                setErrors({
                    name: 'Name already exist',
                })
                return
            }

            await onSubmit(tag.id, {
                name,
                iconUrl: iconUrl === null ? null : iconUrl || tag.iconUrl,
                isSystem,
                isInterests,
                formattedName: convertToList(localeDependentFields[LOCALE_DEPENDENT_FIELDS.FORMATTED_NAME.fieldName]),
                parentTag: isChildren ? parentTag.value : null,
            })

            return true
        } catch (err) {
            if (err.response) {
                const {
                    response: { status },
                } = err
                if (status === 400) {
                    setErrors({
                        name: 'Invalid characters',
                    })
                }
            }
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Edit tag"
            successText="Tag updated successfully!"
            actionText="Update"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
            isDisabledAction={tag.isSystem && !isConfirm}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <FormBlock label="Name">
                        <Input
                            value={name}
                            error={errors.name}
                            onChange={value => {
                                setName(value)
                                setErrors(removeKeyFromObject(errors, 'name'))
                            }}
                        />
                    </FormBlock>
                    <LocaleFormBlock
                        label="Label"
                        errors={errors}
                        fields={localeDependentFields}
                        requiredLocales={[LOCALES.EN]}
                        fieldName={LOCALE_DEPENDENT_FIELDS.FORMATTED_NAME.fieldName}
                        activeTabName={activeFormattedNameLocale}
                        onChangeActiveTabName={v => setActiveFormattedNameLocale(v)}
                        onChange={data => {
                            setLocaleDependentFields(data.value)
                            setErrors(data.errors)
                        }}
                    />
                    <FormBlock label="Icon">
                        <Upload
                            isCanRemove
                            lastUrl={tag.iconUrl}
                            value={icon}
                            error={errors.icon}
                            isUploaded={!!iconUrl}
                            onReset={() => {
                                setIconUrl('')
                                setIcon(null)
                                setErrors(removeKeyFromObject(errors, 'icon'))
                            }}
                            onUpload={({ isSuccess, url, errorText }) => {
                                if (!isSuccess) {
                                    setErrors(setKeyToObject(errors, 'icon', errorText))
                                    return
                                }
                                setIconUrl(url)
                                setErrors(removeKeyFromObject(errors, 'icon'))
                            }}
                            onChange={file => {
                                setIconUrl('')
                                setIcon(file)
                                setErrors(removeKeyFromObject(errors, 'icon'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock>
                        <Toggle value={isInterests} label="Interests tag" onChange={value => setIsInterests(value)} />
                    </FormBlock>
                    <FormBlock>
                        <Toggle value={isSystem} label="System tag" onChange={value => setIsSystem(value)} />
                    </FormBlock>
                    <FormBlock>
                        <Toggle value={isChildren} label="Children tag" onChange={value => setIsChildren(value)} />
                    </FormBlock>
                    {isChildren && (
                        <FormBlock label="Parent tag">
                            <Select
                                error={errors.parentTag}
                                value={parentTag}
                                options={data.existingTags
                                    .filter(tag => !tag.parentTag)
                                    .map(tag => ({ value: tag.name, label: tag.name }))}
                                onChange={value => {
                                    setParentTag(value)
                                    setErrors(removeKeyFromObject(errors, 'parentTag'))
                                }}
                            />
                        </FormBlock>
                    )}

                    {tag.isSystem && (
                        <>
                            <br />
                            <FormBlock>
                                <Checkbox
                                    value={isConfirm}
                                    label="This is system tag. You have to understand that this action has the potential to disrupt the operation of the application. Confirm that you are confident in your actions"
                                    onChange={value => setIsConfirm(value)}
                                />
                            </FormBlock>
                        </>
                    )}
                </>
            )}
        </FormModal>
    )
}

export default EditTagModal
