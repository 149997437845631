import { GENERATE_IMAGE, GENERATE_SPEECH } from 'api/projectGenerator'

import { getBlobFromBase64, uploadFile } from 'utils/file'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

export const onGenerateImage = async text =>
    `data:image/png;base64,${await GENERATE_IMAGE(`${text}. Exclude living characters, leave only inanimate objects.`)}`

export const onGenerateSpeech = async (text, { voiceId } = {}) => await GENERATE_SPEECH(text, { voiceId })

export const getImageUrl = async image => {
    if (!image) return null
    if (image.startsWith('data:image')) {
        const imageBlob = getBlobFromBase64(image)
        const imageResult = await uploadFile(imageBlob)
        if (!imageResult.isSuccess) {
            Toast(TOAST_TYPE.ERROR, { title: "Can't upload image to server" })
            return
        }
        return imageResult.url
    }
    return image
}
