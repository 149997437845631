export const PROJECTS_STATUSES = {
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT',
    DELETED: 'DELETED',
    TEMPLATE: 'TEMPLATE',
}
export const PROJECTS_BLOCK_STATUSES = {
    FREE: 'FREE',
    BLOCKED: 'BLOCKED',
}

export const PROJECT_MODES = {
    SINGLE: 'SINGLE',
    MULTIPLAYER: 'MULTIPLAYER',
}

export const PUBLIC_TEMPLATE_SUBJECTS = [
    {
        value: 'ART_AND_MUSIC',
        label: 'Arts & Music',
    },
    {
        value: 'ENGLISH_LANGUAGE_ARTS',
        label: 'English Language Arts',
    },
    {
        value: 'WORLD_LANGUAGE',
        label: 'World Language',
    },
    {
        value: 'MATH',
        label: 'Math',
    },
    {
        value: 'SCIENCE',
        label: 'Science',
    },
    {
        value: 'SOCIAL_STUDIES_HISTORY',
        label: 'Social Studies - History',
    },
    {
        value: 'SPECIALTY',
        label: 'Specialty',
    },
    {
        value: 'HOLIDAYS_SEASONAL',
        label: 'Holidays / Seasonal',
    },
]

export const PUBLIC_TEMPLATE_GRADES = [
    {
        value: 'PREK',
        label: 'PreK',
    },
    {
        value: 'K',
        label: 'K',
    },
    {
        value: '1ST',
        label: '1st',
    },
    {
        value: '2ND',
        label: '2nd',
    },
    {
        value: '3RD',
        label: '3rd',
    },
    {
        value: '4TH',
        label: '4th',
    },
    {
        value: '5TH',
        label: '5th',
    },
    {
        value: '6TH',
        label: '6th',
    },
    {
        value: '7TH',
        label: '7th',
    },
    {
        value: '8TH',
        label: '8th',
    },
    {
        value: '9TH',
        label: '9th',
    },
    {
        value: '10TH',
        label: '10th',
    },
    {
        value: '11TH',
        label: '11th',
    },
    {
        value: '12TH',
        label: '12th',
    },
    {
        value: 'NOT_GRADE_SPECIFIC',
        label: 'Not Grade Specific',
    },
    {
        value: 'HIGHER_EDUCATION',
        label: 'Higher Education',
    },
    {
        value: 'ADULT_EDUCATION',
        label: 'Adult Education',
    },
    {
        value: 'HOMESCHOOL',
        label: 'Homeschool',
    },
    {
        value: 'STAFF',
        label: 'Staff',
    },
]
