import React from 'react'

import styles from './DataItem.module.scss'

const DataItem = ({ title, titleStyles = {}, value = null, valueStyles = {}, valueIcon = null }) => (
    <li className={styles.dataItem}>
        <p style={titleStyles} className={styles.title}>
            {title}
        </p>
        <div style={valueStyles} className={styles.value}>
            {value}
            {valueIcon}
        </div>
    </li>
)

export default DataItem
