import React, { useEffect, useState } from 'react'

import RemixViewer from 'components/RemixViewer/RemixViewer'
import Preloader from 'components/Preloader/Preloader'

import TopBar from './components/TopBar/TopBar'
import Sharing from './components/Sharing/Sharing'

import AudioTasks from './views/AudioTasks/AudioTasks'

import { SCENARIOS } from '../../../../constants'

import { PROJECT_MODES } from './constants'

import styles from './ProjectWorkplace.module.scss'

const ProjectWorkplace = ({ scenario, mainSettings, schema, project, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState(PROJECT_MODES.EDIT)
    const [json, setJson] = useState(null)

    useEffect(() => {
        setJson(null)
        if (mode === PROJECT_MODES.PREVIEW) generateJson()
    }, [mode])

    const generateJson = async () => {
        try {
            setIsLoading(true)
            setJson(await schema.methods.onGenerateProjectJSON({ project, mainSettings }))
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.projectWorkplace}>
            <TopBar
                isDisabled={isLoading}
                topic={project.main.topic}
                mode={mode}
                onChangeMode={v => setMode(v)}
                onEditTopic={v => onUpdate('main.topic', v)}
            />
            <div className={styles.content}>
                {isLoading ? (
                    <Preloader />
                ) : (
                    <>
                        {mode === PROJECT_MODES.EDIT && (
                            <>
                                {scenario === SCENARIOS.AUDIO_TASKS && (
                                    <AudioTasks project={project} onUpdate={onUpdate} />
                                )}
                            </>
                        )}
                        {mode === PROJECT_MODES.PREVIEW && <RemixViewer projectStructureJson={json} />}
                        {mode === PROJECT_MODES.SHARING && <Sharing project={project} onUpdate={onUpdate} />}
                    </>
                )}
            </div>
        </div>
    )
}

export default ProjectWorkplace
