import React from 'react'
import { cloneDeep } from 'lodash'

import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import { getUniqueId } from 'utils/common'

import BlockWrapper from '../../BlockWrapper/BlockWrapper'

import { MIN_PAIRS_COUNT, MAX_PAIRS_COUNT } from './constants'

import styles from './FlipCards.module.scss'

const FlipCards = ({ items, title = 'Flip Cards', onUpdate, onAdd }) => (
    <BlockWrapper title={title}>
        <ul className={styles.items}>
            {items.map((item, index) => (
                <li key={item.id} className={styles.item}>
                    <Input isWide value={item.learning} onChange={v => onUpdate(`[${index}].learning`, v)} />
                    <span className={styles.itemDivider}>-</span>
                    <Input isWide value={item.native} onChange={v => onUpdate(`[${index}].native`, v)} />
                    {items.length > MIN_PAIRS_COUNT && (
                        <div className={styles.buttonsBlock}>
                            <Button
                                content="Remove"
                                variant="danger"
                                onClick={() => {
                                    const _items = cloneDeep(items)
                                    _items.splice(index, 1)
                                    onUpdate('', _items)
                                }}
                            />
                        </div>
                    )}
                </li>
            ))}
        </ul>
        {items.length < MAX_PAIRS_COUNT && <Button content="+ Add pair" variant="primary" onClick={() => onAdd()} />}
    </BlockWrapper>
)

export default FlipCards
