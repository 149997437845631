import React, { useState, useEffect } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Select from 'components/Forms/Select/Select'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Preloader from 'components/Preloader/Preloader'

import { fieldsValidator, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import API from 'api'

const ChangeSubscriptionModal = ({ subscription, userId, onSubmit = () => {}, onClose = () => {} }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [products, setProducts] = useState([])

    useEffect(() => {
        setIsLoading(true)
        API.COMMON.GET_PRODUCTS(userId)
            .then(data => {
                setProducts(
                    data
                        .filter(el => el.productCode !== subscription.product.productCode)
                        .map(el => ({
                            label: el.name,
                            value: el.productCode,
                        })),
                )
            })
            .catch(err => console.error(err))
            .finally(() => setIsLoading(false))
    }, [])

    const [product, setProduct] = useState(null)

    const [errors, setErrors] = useState({})

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('product', product, [getIsValueRule()])])

            const { isHasErrors, errors } = validator.validate()
            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit(subscription.id, product.value)

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <FormModal
            headText="Change subscription"
            successText="Subscription changed successfully!"
            actionText="Change"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <FormBlock label="Subscription">
                    <Select
                        error={errors.product}
                        value={product}
                        options={products}
                        onChange={value => {
                            setProduct(value)
                            setErrors(removeKeyFromObject(errors, 'product'))
                        }}
                    />
                </FormBlock>
            )}
        </FormModal>
    )
}

export default ChangeSubscriptionModal
