import React, { useRef, useEffect } from 'react'
import { useCodeMirror } from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'

import { jsBeautify } from 'utils/beautify'

import useResize from 'hooks/useResize'

import Wrapper from '../components/Wrapper/Wrapper'

const extensions = [json()]

const JsonCodeBlock = ({ value, error = '', onChange, minHeight = '100px', maxHeight = '500px' }) => {
    const wrapperRef = useRef()
    const editorRef = useRef()
    const { setContainer } = useCodeMirror({
        container: editorRef.current,
        extensions,
        value: jsBeautify(value),
        minHeight,
        maxHeight,
        onChange: v => onChange(v),
        theme: 'light',
    })

    const { height } = useResize(wrapperRef, 0)

    const getScrollableElement = () => {
        if (!editorRef.current) return null
        const scrolledElements = editorRef.current.getElementsByClassName('cm-scroller')
        if (!scrolledElements?.length) return null
        return scrolledElements[0]
    }

    useEffect(() => {
        const scrollableElement = getScrollableElement()
        if (!scrollableElement) return
        scrollableElement.scrollTop = 0
    }, [height])

    useEffect(() => {
        if (editorRef.current) {
            setContainer(editorRef.current)
        }
    }, [editorRef.current])

    return (
        <Wrapper error={error} wrapperRef={wrapperRef}>
            <div ref={editorRef} />
        </Wrapper>
    )
}

export default JsonCodeBlock
