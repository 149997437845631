import classNames from 'classnames'

import { getUniqueGroups } from './utils'

import styles from './Filters.module.scss'

const Filters = ({ products, onClickGroup, groups }) => (
    <div className={styles.filters}>
        <div className={styles.filterBox}>
            <p className={styles.title}>Groups:</p>
            <ul className={styles.list}>
                {getUniqueGroups(products).map(group => (
                    <li
                        key={group}
                        className={classNames(styles.item, { [styles.isActiveItem]: groups.includes(group) })}
                        onClick={() => onClickGroup(group)}
                    >
                        {group}
                    </li>
                ))}
            </ul>
        </div>
    </div>
)

export default Filters
