import React from 'react'

import SEO from 'components/SEO/SEO'
import Translator from 'components/Translator/Translator'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

class TranslatorPage extends React.Component {
    render() {
        return (
            <>
                <SEO title="Translator" />
                <PageTopbar title="Translator" />
                <Translator />
            </>
        )
    }
}

export default TranslatorPage
