import classNames from 'classnames'

import styles from './CompactView.module.scss'

const CompactView = ({ tagsList, onEdit = () => {}, isDisabled }) => (
    <div
        onClick={() => onEdit()}
        className={classNames(styles.compactView, { [styles.isDisabledCompactView]: isDisabled })}
    >
        {tagsList.length} selected
    </div>
)

export default CompactView
