import React, { useState, useRef } from 'react'
import debounce from 'lodash/debounce'

import Input from 'components/Forms/Input/Input'

const InputFilter = ({
    initialValue = '',
    field,
    type = 'text',
    waitMs = 1500,
    trim = true,
    validateLength = true,
    minLength = 2,
    customChange = null,
    onChange = () => {},
}) => {
    const [value, setValue] = useState(initialValue)
    const [error, setError] = useState(null)
    const debouncedRequest = useRef(debounce(async v => await validateAndSend(v), waitMs)).current

    async function validateAndSend(v) {
        if (validateLength) {
            if (type !== 'number' && v.length > 0 && v.length < minLength) {
                setError(`Min symbols: ${minLength}`)
                return
            }
        }

        const data = [
            {
                field,
                value: v.length ? (trim ? v.trim() : v) : null,
            },
        ]

        if (customChange) {
            setError(customChange(data))
            return
        }

        setError(null)
        await onChange(data)
    }

    return (
        <Input
            value={value}
            error={error}
            type={type}
            onChange={v => {
                setValue(v)
                setError(null)
                debouncedRequest(v)
            }}
        />
    )
}

export default InputFilter
