import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `orderNumber,${SORT_ORDERS.asc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 20,
        getValue: v => Number(v),
    },
}

export const MODALS = {
    DENY_CONTENT: 'DENY_CONTENT',
    CHANGE_ORDER_NUMBER: 'CHANGE_ORDER_NUMBER',
}
