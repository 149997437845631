import SORT_ORDERS from 'constants/sortOrders'

export const TABLE_PARAMS = {
    sort: {
        default: `created,id,${SORT_ORDERS.asc}`,
    },
    page: {
        default: 0,
        getValue: v => Number(v),
    },
    size: {
        default: 50,
        getValue: v => Number(v),
    },
    approved: {
        getValue: v => v === 'true',
    },
}

export const MODALS = {
    APPROVE_CONTENT: 'APPROVE_CONTENT',
    DENY_CONTENT: 'DENY_CONTENT',
}
