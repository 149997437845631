import HttpClient from './instance'

export const GET_PAYOUTS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/public-profile/payouts`,
        params,
    })

export const GET_USER_PAYOUTS = async (profileId, params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/public-profile/${profileId}/payouts`,
        params,
    })

export const GET_EXCHANGE_RATE = (currencyFrom, currencyTo) =>
    HttpClient({
        method: 'get',
        url: `/admin/public-profile/payouts/exchange-rate`,
        params: {
            currencyFrom,
            currencyTo,
        },
    })

export const UPDATE_EXCHANGE_RATE = ({ currencyFrom, currencyTo, rate }) =>
    HttpClient({
        method: 'post',
        url: `/admin/public-profile/payouts/exchange-rate`,
        data: {
            currencyFrom,
            currencyTo,
            rate,
        },
    })

export const CHANGE_STATUS = (payoutId, status) =>
    HttpClient({
        method: 'patch',
        url: `/admin/public-profile/payouts/${payoutId}/change-status`,
        data: {
            status,
        },
    })

export const FORCE_PAYOUT = payoutId =>
    HttpClient({
        method: 'post',
        url: `/admin/public-profile/payouts/${payoutId}/manual-payout`,
        data: {},
    })
