import qs from 'qs'
import { cloneDeep } from 'lodash'

export const getSortKeys = params => {
    const lastIndex = params.sort.lastIndexOf(',')
    return {
        key: params.sort.substring(0, lastIndex),
        order: params.sort.substring(lastIndex + 1),
    }
}

export const getTableParams = ({ location }, allowedParams) => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const params = {}
    for (const [key, data] of Object.entries(allowedParams)) {
        if (!searchParams[key]) {
            if (typeof data.default !== 'undefined') params[key] = data.default
            continue
        }
        params[key] = data.getValue ? data.getValue(searchParams[key]) : searchParams[key]
    }

    return params
}

export const getRefreshedParams = (currentParams = {}, updatedParams = []) => {
    const requestParams = cloneDeep(currentParams)

    updatedParams.forEach(({ field, value }) => {
        if (value === null || value === undefined || value === '') {
            delete requestParams[field]
        } else {
            requestParams[field] = value
        }
    })

    return requestParams
}

export const setTableParams = ({ history, location }, params, isClear = true) => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    const paramsToAdd = {}
    for (const [key, value] of Object.entries(params)) {
        paramsToAdd[key] = value
    }

    history.replace({
        pathname: location.pathname,
        search: qs.stringify(isClear ? paramsToAdd : { ...searchParams, ...paramsToAdd }),
    })
}
