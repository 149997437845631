import HttpClient from './instance'

export const GET_TEMPLATES = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/templates',
        params: {
            ...params,
            projectTypeId: process.env.REACT_APP_PROJECT_TYPE_ID,
        },
    })
export const GET_TEMPLATE = async id =>
    HttpClient({
        method: 'get',
        url: `/admin/templates/${id}`,
    })
export const CREATE_TEMPLATE = async data =>
    HttpClient({
        method: 'post',
        url: '/admin/templates',
        data: {
            ...data,
            authorId: null,
        },
    })
export const UPDATE_TEMPLATE = async (id, data = {}) =>
    HttpClient({
        method: 'put',
        url: `/admin/templates/${id}`,
        data: {
            ...data,
            authorId: null,
        },
    })
export const REMOVE_PROJECT = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/templates/${id}`,
    })
export const GET_TAGS = async () =>
    HttpClient({
        method: 'get',
        url: `/admin/templates/tags`,
    })
export const CREATE_TAG = async data =>
    HttpClient({
        method: 'post',
        url: `/admin/templates/tags`,
        data: {
            id: null,
            ...data,
        },
    })

export const REMOVE_TAG = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/templates/tags/${id}`,
    })
export const UPDATE_TAG = async (id, data) =>
    HttpClient({
        method: 'put',
        url: `/admin/templates/tags/${id}`,
        data: {
            id,
            ...data,
        },
    })
export const GET_SECTIONS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/templates/sections`,
        params,
    })
export const CREATE_SECTION = async (data = {}) =>
    HttpClient({
        method: 'post',
        url: `/admin/templates/sections`,
        data,
    })
export const REMOVE_SECTION = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/templates/sections/${id}`,
    })
export const UPDATE_SECTION = async data =>
    HttpClient({
        method: 'put',
        url: `/admin/templates/sections/${data.id}`,
        data,
    })
