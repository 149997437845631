import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import Table from 'components/Table/Table'
import Avatar from 'components/Avatar/Avatar'

import SORT_ORDERS from 'constants/sortOrders'
import ROUTES from 'constants/routes'

import { getUserName } from 'utils/user'

import EmbeddedList from './components/EmbeddedList/EmbeddedList'
import ActionsList from './components/ActionsList/ActionsList'

const SORT_KEYS = {
    BY_DATE: 'BY_DATE',
    BY_EMBED_COUNT: 'BY_EMBED_COUNT',
    BY_USERS: 'BY_USERS',
}

const getColumns = ({ requestParams }) => {
    const sort = {
        key: requestParams.sortParam,
        order: SORT_ORDERS.desc,
    }

    return [
        {
            headerLabel: 'Project name',
            getTitle: project => project.name,
            getValue: project => project.name,
            width: '15%',
        },
        {
            headerLabel: 'Created by',
            getTitle: project => getUserName(project.user),
            getValue: project => (
                <>
                    <Link
                        to={`${ROUTES.USERS.path}/${project.user.id}`}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Avatar user={project.user} style={{ fontSize: 10, width: 22, height: 22, marginRight: 5 }} />
                        {getUserName(project.user)}
                    </Link>
                </>
            ),
            width: '15%',
        },
        {
            headerLabel: 'Published at',
            getTitle: project => moment(project.published).format('MMM D, YYYY (HH:mm)'),
            getValue: project => moment(project.published).format('MMM D, YYYY (HH:mm)'),
            sort: {
                key: SORT_KEYS.BY_DATE,
                order: sort.key === SORT_KEYS.BY_DATE ? sort.order : SORT_ORDERS.undefined,
            },
            width: '20%',
        },
        {
            headerLabel: 'Embedded to',
            getTitle: project => `${project.embeddedList.filter(url => !!url).length} url's`,
            getValue: project => <EmbeddedList list={project.embeddedList.filter(url => !!url)} />,
            sort: {
                key: 'BY_EMBED_COUNT',
                order: sort.key === 'BY_EMBED_COUNT' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '25%',
        },
        {
            headerLabel: 'Users',
            getTitle: project => project.uniqueUsersCount,
            getValue: project => project.uniqueUsersCount,
            sort: {
                key: 'BY_USERS',
                order: sort.key === 'BY_USERS' ? sort.order : SORT_ORDERS.undefined,
            },
            width: '10%',
        },
        {
            headerLabel: 'Preview',
            getValue: project => (project.previewUrl ? <img src={project.previewUrl} alt="preview" /> : null),
            width: '10%',
        },
        {
            headerLabel: '',
            getValue: project => <ActionsList project={project} />,
            valueStyles: {
                justifyContent: 'flex-end',
            },
            width: '5%',
        },
    ]
}

const ProjectsTable = ({ title, list, methods, requestParams, pagination, onExpand, onChangePage, isLoading }) => (
    <Table
        columns={getColumns({ requestParams })}
        title={title}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        onChangeSort={({ key }) => {
            methods.changeRequestParamsAndRefresh([{ field: 'sortParam', value: key }])
        }}
        isLoading={isLoading}
    />
)

export default ProjectsTable
