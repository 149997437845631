import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'

import Select from 'components/Forms/Select/Select'

const getInitialSelected = (initialValue, options) => {
    if (typeof initialValue === 'undefined') return options[0]
    const option = options.find(option => option.value === initialValue)
    return option || options[0]
}

const SelectFilter = ({
    field,
    isReturnOnlyOption = false,
    initialValue,
    options = [],
    onChange,
    placeholder,
    isDisabled = false,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState(options)
    const [selected, setSelected] = useState(() => getInitialSelected(initialValue, options))

    useEffect(() => {
        if (isEqual(options, items)) return
        setItems(options)
        setSelected(options.length ? options[0] : null)
    }, [options])

    return (
        <Select
            isDisabled={isDisabled || isLoading}
            value={selected}
            options={items}
            placeholder={placeholder}
            onChange={async value => {
                setIsLoading(true)
                setSelected(value)
                if (isReturnOnlyOption) {
                    await onChange(value.value)
                } else {
                    await onChange([
                        {
                            field,
                            value: value.value,
                        },
                    ])
                }
                setIsLoading(false)
            }}
        />
    )
}

export default SelectFilter
