import HttpClient from './instance'

export const UPLOAD_FILE = async (fileName, contentType, bytes) =>
    HttpClient({
        method: 'post',
        headers: {
            'Content-Type': `${contentType}; charset=UTF-8`,
            'Content-Disposition': `attachment; filename=${fileName}`,
        },
        url: `/admin/media/upload`,
        params: {
            fileLength: bytes.length || bytes.byteLength,
        },
        data: bytes,
    })

export const GET_PROJECTS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/projects`,
        params,
    })

export const GET_PROJECT = async projectId =>
    HttpClient({
        method: 'get',
        url: `/projects/${projectId}`,
    })

export const SAVE_PROJECT = async (data = {}) =>
    await HttpClient({
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `/projects`,
        data,
    })

export const UPDATE_PROJECT = async (projectId, data = {}) =>
    HttpClient({
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        url: `/projects/${projectId}`,
        data,
    })
export const PUBLISH_PROJECT = async id =>
    HttpClient({
        method: 'patch',
        url: `/projects/${id}/publish`,
    })

export const GET_NEW_EMBEDDED_PROJECTS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/projects/new-embedded',
        params,
    })

export const GET_USER_PROJECTS = async (userId, params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/users/${userId}/projects`,
        params,
    })

export const REMOVE_PROJECT = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/projects/${id}`,
    })

export const BLOCK_PROJECT = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/projects/${id}/block`,
        data: {},
    })

export const UNBLOCK_PROJECT = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/projects/${id}/unblock`,
        data: {},
    })

export const BLOCK_ALL_USER_PROJECTS = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/projects/block`,
        data: {},
    })

export const UNBLOCK_ALL_USER_PROJECTS = async id =>
    HttpClient({
        method: 'patch',
        url: `/admin/users/${id}/projects/unblock`,
        data: {},
    })
