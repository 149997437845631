import React from 'react'

import SEO from 'components/SEO/SEO'
import Button from 'components/Forms/Button/Button'

import InfoBlocks from './components/InfoBlocks/InfoBlocks'
import UserContent from './components/UserContent/UserContent'

import { isPublicProfile } from './utils'

import styles from './PublicProfile.module.scss'
import { useHistory, useLocation } from 'react-router-dom'

const PublicProfile = ({ userPublicProfile, userVisibleName }) => {
    const location = useLocation()
    const history = useHistory()

    return (
        <>
            <SEO title={`Users / ${userVisibleName} / Public profile`} />
            <div className={styles.topbar}>
                <h2 className={styles.title}>Info</h2>
                {isPublicProfile(userPublicProfile) && (
                    <div className={styles.buttons}>
                        <Button
                            content="View profile"
                            variant="primary"
                            onClick={() =>
                                window.open(
                                    `${process.env.REACT_APP_SSR_URL}/authors/${userPublicProfile.id}`,
                                    '_blank',
                                )
                            }
                        />
                    </div>
                )}
            </div>
            <InfoBlocks userPublicProfile={userPublicProfile} />
            <br />
            <br />
            <h2 className={styles.title}>User content</h2>
            <UserContent authorId={userPublicProfile.id} location={location} history={history} />
        </>
    )
}

export default PublicProfile
