import { sample, range } from 'lodash'

import { DEFAULT_ANSWER_TEXT } from './components/Answer/constants'

import { DEFAULT_QUESTION_ANSWER_COUNT, DEFAULT_QUESTION_TEXT } from './constants'

export const getDefaultQuestion = questionCount => ({
    answers: range(1, DEFAULT_QUESTION_ANSWER_COUNT + 1).map(item => `${DEFAULT_ANSWER_TEXT} ${item}`),
    correctAnswersIndexes: [sample(range(0, DEFAULT_QUESTION_ANSWER_COUNT))],
    value: `${DEFAULT_QUESTION_TEXT} ${questionCount + 1}`,
})
