import React from 'react'
import moment from 'moment/moment'

import API from 'api'

import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import SEO from 'components/SEO/SEO'
import Button from 'components/Forms/Button/Button'

import { getRefreshedParams, getTableParams, setTableParams } from 'utils/table'

import PageTopbar from 'layouts/Dashboard/components/PageTopbar/PageTopbar'

import PayoutsTable from './components/PayoutsTable/PayoutsTable'
import EditExchangeRateModal from './components/EditExchangeRateModal/EditExchangeRateModal'
import ChangePayoutStatusModal from './components/ChangePayoutStatusModal/ChangePayoutStatusModal'
import ForcePayoutModal from './components/ForcePayoutModal/ForcePayoutModal'

import { TABLE_PARAMS, MODALS, EXCHANGE_CURRENCY_FROM, EXCHANGE_CURRENCY_TO } from './constants'

import styles from './Payouts.module.scss'

class PayoutsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            payouts: {
                isLoading: false,
                lastResponse: null,
                requestParams: getTableParams(this.props, TABLE_PARAMS),
                list: [],
            },
            totalPayoutsSum: 0,
            exchangeRate: null,
            modal: {
                isOpen: false,
                name: '',
                payload: null,
            },
        }
    }

    async componentDidMount() {
        await Promise.all([this.getPayouts(), this.getExchangeRate()])
        this.setState({
            isReady: true,
        })
    }

    getPayouts = async (isClear = true) => {
        try {
            const {
                payouts: { requestParams },
            } = this.state

            this.setState(prevState => ({
                payouts: {
                    ...prevState.payouts,
                    isLoading: true,
                },
            }))

            setTableParams(this.props, requestParams)

            const response = await API.PAYOUTS.GET_PAYOUTS(requestParams)
            this.setState(prevState => ({
                isReady: true,
                payouts: {
                    ...prevState.payouts,
                    lastResponse: response.payouts,
                    list: isClear ? response.payouts.content : [...prevState.payouts.list, ...response.payouts.content],
                },
                totalPayoutsSum: response.totalPayoutsSum,
            }))
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        } finally {
            this.setState(prevState => ({
                payouts: {
                    ...prevState.payouts,
                    isLoading: false,
                },
            }))
        }
    }

    getExchangeRate = async () => {
        try {
            const response = await API.PAYOUTS.GET_EXCHANGE_RATE(EXCHANGE_CURRENCY_FROM, EXCHANGE_CURRENCY_TO)

            this.setState({
                exchangeRate: response,
            })
        } catch (err) {
            console.error(err)
        }
    }
    updateExchangeRate = async rate => {
        const response = await API.PAYOUTS.UPDATE_EXCHANGE_RATE({
            currencyFrom: EXCHANGE_CURRENCY_FROM,
            currencyTo: EXCHANGE_CURRENCY_TO,
            rate,
        })
        this.setState({
            exchangeRate: response,
        })
    }

    changePayoutStatus = async ({ id }, status) => {
        await API.PAYOUTS.CHANGE_STATUS(id, status)
        await this.getPayouts()
    }
    forcePayout = async ({ id }) => {
        await API.PAYOUTS.FORCE_PAYOUT(id)
        await this.getPayouts()
    }

    changeRequestParamsAndRefresh = async (data = [], isClear = true) => {
        const {
            payouts: { requestParams },
        } = this.state

        if (data.find(item => item.field !== 'page')) {
            data.push({
                field: 'page',
                value: 0,
            })
        }

        await this.setState(prevState => ({
            payouts: {
                ...prevState.payouts,
                requestParams: getRefreshedParams(requestParams, data),
            },
        }))

        await this.getPayouts(isClear)
    }

    openModal = async (name, payload = null) => {
        await this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }

    closeModal = async () => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: false,
            },
        }))
    }

    render() {
        const { isReady, payouts, exchangeRate, modal } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title="Payouts" />

                {!isReady ? (
                    <Preloader />
                ) : (
                    <>
                        <PageTopbar title="Payouts">
                            <div className={styles.exchangeRateBox}>
                                {!!exchangeRate && (
                                    <div className={styles.exchangeRate}>
                                        <div className={styles.exchangeRateValue}>
                                            {exchangeRate.currencyFrom} 1 = {exchangeRate.currencyTo}{' '}
                                            {exchangeRate.rate}
                                        </div>
                                        <div className={styles.exchangeRateDate}>
                                            {moment(exchangeRate.createdAt).format('MMM D, YYYY (HH:mm)')}
                                        </div>
                                    </div>
                                )}
                                {!exchangeRate ? (
                                    <Button
                                        content="Create exchange rate"
                                        variant="primary"
                                        onClick={() => this.openModal(MODALS.EDIT_EXCHANGE_RATE)}
                                    />
                                ) : (
                                    <Button
                                        content="Update exchange rate"
                                        variant="primary"
                                        onClick={() => this.openModal(MODALS.EDIT_EXCHANGE_RATE, exchangeRate)}
                                    />
                                )}
                            </div>
                        </PageTopbar>
                        <PayoutsTable
                            list={payouts.list}
                            isHasExchangeRate={!!exchangeRate}
                            methods={{
                                openModal: this.openModal,
                                changeRequestParamsAndRefresh: this.changeRequestParamsAndRefresh,
                            }}
                            pagination={{
                                currentPage: payouts.lastResponse.number,
                                totalPages: payouts.lastResponse.totalPages,
                                perPageElements: payouts.lastResponse.numberOfElements,
                                totalElements: payouts.lastResponse.totalElements,
                            }}
                            requestParams={payouts.requestParams}
                            onExpand={() =>
                                this.changeRequestParamsAndRefresh(
                                    [
                                        {
                                            field: 'page',
                                            value: payouts.lastResponse.number + 1,
                                        },
                                    ],
                                    false,
                                )
                            }
                            onChangePage={page =>
                                this.changeRequestParamsAndRefresh([
                                    {
                                        field: 'page',
                                        value: page,
                                    },
                                ])
                            }
                            isLoading={payouts.isLoading}
                        />

                        {isOpenedModal(MODALS.EDIT_EXCHANGE_RATE) && (
                            <EditExchangeRateModal
                                data={modal.payload}
                                onSubmit={this.updateExchangeRate}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.CHANGE_PAYOUT_STATUS) && (
                            <ChangePayoutStatusModal
                                data={modal.payload}
                                onSubmit={this.changePayoutStatus}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.FORCE_PAYOUT) && (
                            <ForcePayoutModal
                                data={modal.payload}
                                onSubmit={this.forcePayout}
                                onClose={this.closeModal}
                            />
                        )}
                    </>
                )}
            </>
        )
    }
}

export default PayoutsPage
