import HttpClient from './instance'

export const GET_TEMPLATES = async (blockTypeId, params = {}) =>
    HttpClient({
        method: 'get',
        url: `/admin/templates/blocks${blockTypeId ? `/${blockTypeId}` : ''}`,
        params,
    })

export const CREATE_TEMPLATE = async (data = {}) =>
    HttpClient({
        method: 'post',
        url: '/admin/templates/blocks',
        data,
    })

export const UPDATE_TEMPLATE = async (data = {}) =>
    HttpClient({
        method: 'put',
        url: `/admin/templates/blocks/${data.id}`,
        data,
    })

export const REMOVE_TEMPLATE = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/templates/blocks/${id}`,
    })
