import React, { useMemo, useState, useRef } from 'react'
import classNames from 'classnames'

import Button from 'components/Forms/Button/Button'
import Input from 'components/Forms/Input/Input'
import Upload from 'components/Forms/Upload/Upload'

import { FILE_TYPES } from 'constants/fileTypes'

import styles from './BanksList.module.scss'

const BanksList = ({ list, onOpen, onRemove, onNew, onParse, isLoading }) => {
    const uploadRef = useRef(null)

    const [filter, setFilter] = useState('')

    const filteredList = useMemo(() => {
        if (!list || !list.length) return []
        return list.filter(({ name }) => name.toLowerCase().includes(filter.trim().toLowerCase()))
    }, [list, filter])

    return (
        <div className={classNames(styles.folders, { [styles.isLoadingFolders]: isLoading })}>
            <h2 className={styles.title}>
                Default banks
                <div className={styles.titleButtons}>
                    <Button content="+ Create bank" variant="primary" onClick={() => onNew()} />
                    <Button
                        content="Upload CSV / XLSX"
                        variant="primary"
                        isInverse
                        onClick={() => uploadRef.current.click()}
                    />
                </div>
                <div className={styles.hidden}>
                    <Upload inputRef={uploadRef} type={FILE_TYPES.QUESTION_BANKS} onChange={file => onParse(file)} />
                </div>
            </h2>
            {list.length ? (
                <>
                    <div className={styles.filter}>
                        <Input
                            maxWidth="300px"
                            isClearable
                            placeholder="Name filter"
                            value={filter}
                            onChange={val => setFilter(val)}
                        />
                    </div>
                    {!!filteredList.length ? (
                        <ul className={styles.folderList}>
                            {filteredList.map(item => (
                                <li key={item.bankId} className={styles.folder}>
                                    <div className={styles.name}>{item.name}</div>
                                    <ul className={styles.actions}>
                                        <li>
                                            <Button
                                                content="Edit"
                                                variant="primary"
                                                isInverse={true}
                                                onClick={() => onOpen(item)}
                                            />
                                        </li>
                                        <li>
                                            <Button
                                                content="Remove"
                                                variant="danger"
                                                isInverse={true}
                                                onClick={() => onRemove(item)}
                                            />
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.emptyList}>Nothing was found for name "{filter}"</div>
                    )}
                </>
            ) : (
                <div className={styles.emptyList}>Banks not found</div>
            )}
        </div>
    )
}

export default BanksList
