import React, { useState } from 'react'

import Button from 'components/Forms/Button/Button'

import { MODALS } from '../../../../constants'

const ButtonsGroup = ({ section, methods }) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <Button
                content="Edit"
                styles={{
                    marginRight: 10,
                }}
                variant="primary"
                isInverse={true}
                isDisabled={isLoading}
                isLoading={isLoading}
                onClick={async () => {
                    setIsLoading(true)
                    await methods.openModal(MODALS.EDIT_SECTION, { section })
                    setIsLoading(false)
                }}
            />
            <Button
                content="Remove"
                variant="danger"
                isInverse={true}
                onClick={() => methods.openModal(MODALS.REMOVE_SECTION, section)}
            />
        </>
    )
}

export default ButtonsGroup
