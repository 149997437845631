import { PUBLIC_TEMPLATE_SUBJECTS, PUBLIC_TEMPLATE_GRADES } from 'constants/projects'

export const getPublicTemplateSubjectLabel = value => {
    const res = PUBLIC_TEMPLATE_SUBJECTS.find(item => item.value === value)
    if (res) return res.label
    return value
}

export const getPublicTemplateGradeLabel = value => {
    const res = PUBLIC_TEMPLATE_GRADES.find(item => item.value === value)
    if (res) return res.label
    return value
}
