import React, { useEffect, useState } from 'react'

import Marks from './components/Marks/Marks'
import Actions from './components/Actions/Actions'

import styles from './TagsList.module.scss'

const isHasMatch = (item, substring) => !!item.toLowerCase().match(new RegExp(substring.toLowerCase(), 'g'))

const TagsList = ({ list, nameFilter, openModal }) => {
    const [structuredList, setStructuredList] = useState([])

    useEffect(() => {
        const data = list
            .filter(tag => !tag.parentTag)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(tag => {
                let isVisible = isHasMatch(tag.name, nameFilter)

                const children = list
                    .filter(
                        childrenTag => childrenTag.parentTag === tag.name && isHasMatch(childrenTag.name, nameFilter),
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))

                if (!isVisible && children.length) isVisible = true

                return {
                    isVisible,
                    ...tag,
                    children,
                }
            })
        setStructuredList(data.filter(tag => tag.isVisible))
    }, [nameFilter, list])

    if (!list.length) return null

    return (
        <>
            <ul className={styles.tagsList}>
                {structuredList.map(tag => (
                    <li className={styles.tag} key={tag.id}>
                        <div className={styles.tagContent}>
                            <Marks isSystem={tag.isSystem} isInterests={tag.isInterests} />
                            <div className={styles.main}>
                                <div className={styles.info}>
                                    {!!tag.iconUrl && (
                                        <div
                                            className={styles.icon}
                                            style={{ backgroundImage: `url(${tag.iconUrl})` }}
                                        />
                                    )}
                                    <div className={styles.mainName}>{tag.name}</div>
                                </div>
                                <Actions tag={tag} openModal={openModal} isCanAdd />
                            </div>
                            {!!tag.children.length && (
                                <div className={styles.children}>
                                    <ul className={styles.childrenTagsList}>
                                        {tag.children.map(childrenTag => (
                                            <li className={styles.childrenTag} key={childrenTag.id}>
                                                <Marks isSystem={tag.isSystem} isInterests={tag.isInterests} />
                                                <div className={styles.main}>
                                                    <div className={styles.info}>
                                                        {!!childrenTag.iconUrl && (
                                                            <div
                                                                className={styles.icon}
                                                                style={{
                                                                    backgroundImage: `url(${childrenTag.iconUrl})`,
                                                                }}
                                                            />
                                                        )}
                                                        <div className={styles.mainName}>{childrenTag.name}</div>
                                                    </div>
                                                    <Actions tag={childrenTag} openModal={openModal} />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default TagsList
