import React, { useEffect, useState } from 'react'

import Select from 'components/Forms/Select/Select'
import Button from 'components/Forms/Button/Button'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'

import { fieldsValidator, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { SCENARIOS, SCENARIOS_LABELS } from '../../constants'

import InputBlock from '../../components/InputBlock/InputBlock'

import styles from './ChooseScenario.module.scss'

const ChooseScenario = ({ isDisabled, onAccept }) => {
    const [scenario, setScenario] = useState(null)

    const [errors, setErrors] = useState({})

    const onSubmit = () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()
            validator.addFields([validator.createField('scenario', scenario, [getIsValueRule()])])

            const { isHasErrors, errors } = validator.validate()

            if (isHasErrors) {
                setErrors(errors)
                return
            }

            onAccept(scenario.value)
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            throw err
        }
    }

    return (
        <div className={styles.chooseScenario}>
            <InputBlock title="Scenario">
                <Select
                    error={errors.scenario}
                    isPortal={false}
                    value={scenario}
                    options={Object.keys(SCENARIOS).map(key => ({
                        value: key,
                        label: SCENARIOS_LABELS[key],
                    }))}
                    onChange={v => {
                        setScenario(v)
                        setErrors(removeKeyFromObject(errors, 'scenario'))
                    }}
                />
            </InputBlock>
            {!isDisabled && (
                <Button variant="primary" onClick={onSubmit}>
                    Next
                </Button>
            )}
        </div>
    )
}

export default ChooseScenario
