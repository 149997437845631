import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

import Preloader from 'components/Preloader/Preloader'
import Modal from 'components/Modal/Modal'

import ROUTES from 'constants/routes'

import { getUserName } from 'utils/user'

import './UsersModal.scss'

const UsersModal = ({
    title,
    note,
    modalEndpoint,
    modalEndpointRequestParams,
    modalDate,
    getValue,
    onClose = () => {},
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [lastResponse, setLastResponse] = useState(null)
    const [list, setList] = useState([])

    async function getUsers(offsetPages = 0) {
        if (isLoading) return
        setIsLoading(true)
        const response = await modalEndpoint({ ...modalEndpointRequestParams, page: offsetPages })
        setList([...list, ...response.content])
        setLastResponse(response)
        setIsLoading(false)
    }
    useEffect(() => {
        getUsers()
    }, [])

    return (
        <Modal onClose={onClose}>
            <div className="users-modal">
                <div className="close" onClick={() => onClose()}></div>
                <div className="title">{title}</div>
                <div className="note">{note}</div>

                <ul className="list">
                    {list.map(user => {
                        return (
                            <li key={user.id} className="item">
                                <p>
                                    <Link to={`${ROUTES.USERS.path}/${user.id}`}>{getUserName(user)}</Link>
                                </p>
                                <div>
                                    {getValue(user)}
                                    {modalDate && <div className="date">{modalDate(user)}</div>}
                                </div>
                            </li>
                        )
                    })}
                </ul>
                {!lastResponse ? <Preloader /> : null}
                {lastResponse && isLoading ? <div className="loader"></div> : null}
                {!isLoading && lastResponse && !lastResponse.last ? (
                    <VisibilitySensor onChange={isVisible => isVisible && getUsers(lastResponse.number + 1)}>
                        <div className="visibility-checker"></div>
                    </VisibilitySensor>
                ) : null}
            </div>
        </Modal>
    )
}

export default UsersModal
