import React, { useEffect, useState } from 'react'

import axios from 'axios'

import Step from './components/Step/Step'

import ChooseScenario from './steps/ChooseScenario/ChooseScenario'
import MainSettings from './steps/MainSettings/MainSettings'
import SetupScenario from './steps/SetupScenario/SetupScenario'
import SetupProjects from './steps/SetupProjects/SetupProjects'

import { SCENARIO_SCHEMA } from './constants'

import styles from './Generator.module.scss'

const Generator = () => {
    const [currentStepNumber, setCurrentStepNumber] = useState(1)

    const [scenario, setScenario] = useState(null)
    const [mainSettings, setMainSettings] = useState(null)
    const [scenarioSettings, setScenarioSettings] = useState(null)

    // https://github.com/aws/serverless-application-model/issues/717
    // https://repost.aws/questions/QUzc-7Oa5XQCCAt1fefpGuxw/fetch-blocked-by-cors-it-does-not-have-http-ok-status

    useEffect(() => {
        fetch('https://teachercontent.com/api.php?_d=products', {
            headers: {
                Authorization: `Basic ${window.btoa('anton.chachkov@quantumsoft.ru:YTS133U02qF67KA9o260908074cf2790')}`,
            },
        })
            // axios({
            //     method: 'get',
            //     url: 'https://teachercontent.com/api.php?_d=products',
            //     // withCredentials: true,
            //     headers: {
            //         'Authorization': `Basic ${window.btoa('anton.chachkov@quantumsoft.ru:YTS133U02qF67KA9o260908074cf2790')}`,
            //         'Content-type': 'application/json',
            //         'Accept': 'application/json',
            //     },
            // })
            .then(response => {
                console.log('products:', response)
            })
            .catch(err => {
                console.error(err)
            })

        fetch('https://teachercontent.com/api.php?_d=features', {
            headers: {
                Authorization: `Basic ${window.btoa('anton.chachkov@quantumsoft.ru:YTS133U02qF67KA9o260908074cf2790')}`,
            },
        })
            .then(response => {
                console.log('features:', response)
            })
            .catch(err => {
                console.error(err)
            })

        fetch('https://teachercontent.com/api.php?_d=categories', {
            headers: {
                Authorization: `Basic ${window.btoa('anton.chachkov@quantumsoft.ru:YTS133U02qF67KA9o260908074cf2790')}`,
            },
        })
            .then(response => {
                console.log('categories:', response)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    useEffect(() => {
        setCurrentStepNumber(1)
        if (scenario) setCurrentStepNumber(2)
        if (mainSettings) setCurrentStepNumber(3)
        if (scenarioSettings) setCurrentStepNumber(4)
    }, [scenario, mainSettings, scenarioSettings])

    return (
        <div className={styles.generator}>
            <Step number={1} title="Choose scenario" isDisabled={!!scenario}>
                <ChooseScenario onAccept={v => setScenario(v)} isDisabled={!!scenario} />
            </Step>
            {currentStepNumber > 1 && (
                <Step number={2} title="Main settings" isDisabled={!!mainSettings}>
                    <MainSettings scenario={scenario} onAccept={v => setMainSettings(v)} isDisabled={!!mainSettings} />
                </Step>
            )}
            {currentStepNumber > 2 && (
                <Step number={3} title="Setup scenario" isDisabled={!!scenarioSettings}>
                    <SetupScenario
                        schema={SCENARIO_SCHEMA[scenario]}
                        mainSettings={mainSettings}
                        onAccept={v => setScenarioSettings(v)}
                        isDisabled={!!scenarioSettings}
                    />
                </Step>
            )}
            {currentStepNumber > 3 && (
                <Step number={4} title="Setup projects">
                    <SetupProjects
                        schema={SCENARIO_SCHEMA[scenario]}
                        scenario={scenario}
                        mainSettings={mainSettings}
                        scenarioSettings={scenarioSettings}
                    />
                </Step>
            )}
        </div>
    )
}

export default Generator
