export const PRODUCT_GROUPS = {
    BUSINESS: 'BUSINESS',
    EDUCATION: 'EDUCATION',
    APPSUMO: 'APPSUMO',
}

export const PRODUCT_CODES = {
    ENTERPRISE_MONTHLY: 'E721588D11',
    ENTERPRISE_YEARLY: 'E721588D11_YEAR',
}
