import React from 'react'
import ReactDatepicker from 'react-datepicker'
import moment from 'moment'

import './DatePicker.scss'

import CustomInput from './components/CustomInput/CustomInput'

const DateRangePicker = ({
    valueStart = null,
    valueEnd = null,
    onChangeStart = () => {},
    onChangeEnd = () => {},
    isClearableStart = true,
    isClearableEnd = true,
    format = 'MMM D, YYYY',
    placeholderStart = 'From',
    placeholderEnd = 'To',
    errorStart,
    errorEnd,
    timeFormat = 'HH:mm',
    minDate,
    maxDate,
    startPopperPlacement = 'auto',
    endPopperPlacement = 'auto',
}) => (
    <div className="common__date-range-picker">
        <ReactDatepicker
            popperPlacement={startPopperPlacement}
            showTimeSelect={false}
            selected={valueStart}
            onChange={date => onChangeStart(moment(date).startOf('day').valueOf())}
            dateFormat="yyyy-MM-dd HH:mm"
            timeFormat={timeFormat}
            endDate={valueEnd}
            minDate={minDate}
            maxDate={valueEnd ? valueEnd : maxDate}
            selectsStart
            customInput={
                <CustomInput
                    format={format}
                    _placeholder={placeholderStart}
                    error={errorStart}
                    onClear={() => onChangeStart(null)}
                    isClearable={isClearableStart}
                />
            }
        />
        <span className="delimer">-</span>
        <ReactDatepicker
            popperPlacement={endPopperPlacement}
            showTimeSelect={false}
            selected={valueEnd}
            onChange={date => onChangeEnd(moment(date).endOf('day').valueOf())}
            dateFormat="yyyy-MM-dd HH:mm"
            timeFormat={timeFormat}
            startDate={valueStart}
            minDate={valueStart ? valueStart : minDate}
            maxDate={maxDate}
            selectsEnd
            customInput={
                <CustomInput
                    format={format}
                    _placeholder={placeholderEnd}
                    error={errorEnd}
                    onClear={() => onChangeEnd(null)}
                    isClearable={isClearableEnd}
                />
            }
        />
    </div>
)

export default DateRangePicker
