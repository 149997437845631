import { combineReducers } from 'redux'

import user from './user'
import translator from './translator'
import modalManager from './modalManager'

export default combineReducers({
    credentials: () => ({
        type: 'Basic',
        username: 'admin-client',
        password: 'H6t~hvqMjajJ+X43nzjRS_&[?SCU0"',
    }),
    user,
    translator,
    modalManager,
})
