export const MODE = {
    CREATE: 'CREATE',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

export const TITLE = {
    [MODE.CREATE]: 'Review questions',
    [MODE.EDIT]: 'Edit questions',
    [MODE.VIEW]: 'View questions',
}
