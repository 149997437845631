import React from 'react'
import classNames from 'classnames'

import { PROJECT_MODES } from '../../constants'

import EditIcon from './svg/Edit'
import EyeIcon from './svg/Eye'
import SharingIcon from './svg/Sharing'

import styles from './TopBar.module.scss'

const TopBar = ({ topic, mode, onChangeMode, onEditTopic, isDisabled }) => (
    <div className={classNames(styles.topBar, { [styles.isDisabledTopBar]: isDisabled })}>
        <div className={styles.topic}>
            <input className={styles.topicInput} value={topic} onChange={evt => onEditTopic(evt.target.value)} />
        </div>
        <ul className={styles.modeList}>
            <li
                className={classNames(styles.mode, { [styles.isActiveMode]: mode === PROJECT_MODES.EDIT })}
                onClick={() => onChangeMode(PROJECT_MODES.EDIT)}
            >
                <EditIcon color={mode === PROJECT_MODES.EDIT ? '#fff' : '#555'} />
            </li>
            <li
                className={classNames(styles.mode, { [styles.isActiveMode]: mode === PROJECT_MODES.PREVIEW })}
                onClick={() => onChangeMode(PROJECT_MODES.PREVIEW)}
            >
                <EyeIcon color={mode === PROJECT_MODES.PREVIEW ? '#fff' : '#555'} />
            </li>
            <li
                className={classNames(styles.mode, { [styles.isActiveMode]: mode === PROJECT_MODES.SHARING })}
                onClick={() => onChangeMode(PROJECT_MODES.SHARING)}
            >
                <SharingIcon color={mode === PROJECT_MODES.SHARING ? '#fff' : '#555'} />
            </li>
        </ul>
    </div>
)

export default TopBar
