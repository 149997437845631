import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import Button from 'components/Forms/Button/Button'

import doneIcon from './icons/done.svg'

import styles from './Step.module.scss'

const Step = ({ number, title, isDisabled, children }) => {
    const [isMinimize, setIsMinimize] = useState(false)

    useEffect(() => {
        if (!isDisabled) return
        setIsMinimize(true)
    }, [isDisabled])

    return (
        <div className={styles.step}>
            <div className={styles.head}>
                <div className={styles.leftSide}>
                    <div className={styles.number}>{number}</div>
                    <div className={styles.title}>{title}</div>
                    {isDisabled && (
                        <div className={styles.icon}>
                            <img src={doneIcon} alt="done" />
                        </div>
                    )}
                </div>
                {!!isDisabled && (
                    <div className={styles.rightSide}>
                        <Button
                            content={isMinimize ? 'Show details' : 'Hide details'}
                            variant="primary"
                            onClick={() => setIsMinimize(prevState => !prevState)}
                        />
                    </div>
                )}
            </div>
            <div
                className={classNames(styles.content, {
                    [styles.isDisabled]: isDisabled,
                    [styles.isMinimizeContent]: isMinimize,
                })}
            >
                {children}
            </div>
        </div>
    )
}

export default Step
