import React from 'react'
import classNames from 'classnames'

import Input from 'components/Forms/Input/Input'
import Button from 'components/Forms/Button/Button'

import { MODE } from '../../../../constants'

import styles from './Header.module.scss'

const Header = ({ title, elementsCount, isValid, onSave, isLoading, name, onChangeName, errors, mode }) => (
    <div className={styles.header}>
        <div className={styles.box}>
            <div className={styles.texts}>
                <div className={styles.title}>{title}</div>
                <div className={styles.number}>
                    {'Number of questions'}: {elementsCount}
                </div>
            </div>
            {mode !== MODE.VIEW && (
                <div className={styles.actions}>
                    <div className={classNames(styles.notice, { [styles.noticeError]: !isValid })}>
                        {isValid ? 'It’s good to go!' : errors.global.text || 'Correct errors before proceeding'}
                    </div>
                    <Button
                        content={mode === MODE.CREATE ? 'Create' : 'Save'}
                        variant="primary"
                        onClick={onSave}
                        isInverse
                        isDisabled={!isValid || isLoading}
                    />
                </div>
            )}
        </div>
        {mode !== MODE.VIEW && (
            <div className={styles.name}>
                <Input
                    placeholder={'Bank name'}
                    value={name}
                    error={errors.name?.text}
                    onChange={v => onChangeName(v)}
                    className={styles.textInput}
                    maxLength={80}
                />
            </div>
        )}
    </div>
)

export default Header
