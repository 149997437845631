export const ABC = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
]

export const DEFAULT_ANSWER_TEXT = 'Answer'

export const MIN_ANSWERS_COUNT = 2
export const MAX_ANSWERS_COUNT = 10
