export const PERIODS = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
}

export const PERIODS_DETAILS = {
    [PERIODS.DAY]: {
        momentUnit: 'days',
        momentUnitOfTime: 'day',
    },
    [PERIODS.WEEK]: {
        momentUnit: 'weeks',
        momentUnitOfTime: 'week',
    },
    [PERIODS.MONTH]: {
        momentUnit: 'month',
        momentUnitOfTime: 'month',
    },
    [PERIODS.YEAR]: {
        momentUnit: 'year',
        momentUnitOfTime: 'year',
    },
}
