import React, { useState } from 'react'
import moment from 'moment'

import Button from 'components/Forms/Button/Button'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'

import { BILLING_SYSTEM, FEATURES_TYPES } from 'constants/subscription'
import { PRODUCT_CODES } from 'constants/products'
import { isBusinessUserRole } from 'utils/user'

import { LOCK_ENTERPRISE_PLAN, UNLOCK_ENTERPRISE_PLAN } from 'api/users'

import editIcon from 'images/icons/edit.svg'

import { MODALS } from '../../constants'

import ChangeSubscriptionExpirationDateModal from './components/ChangeSubscriptionExpirationDateModal/ChangeSubscriptionExpirationDateModal'
import DataItem from './components/DataItem/DataItem'

import { getExpirationColor, getStatusColor, isCanManageRecurring, isCanManageExpirationDate } from './utils'

import twocheckoutLogo from './i/2checkout.png'
import stripeLogo from './i/stripe.png'

import './SubscriptionDetails.scss'

const SubscriptionDetails = ({ user, title, subscription, methods, isHideChangeSubscription = false }) => {
    const [isOpenChangeSubscriptionExpirationDateModal, setIsOpenChangeSubscriptionExpirationDateModal] =
        useState(false)
    const [isOpenChangeRecurringModal, setIsOpenChangeRecurringModal] = useState(false)

    const getFeatureValue = feature => {
        if (feature.hasOption) {
            if (feature.productOptionValue === -1) return 'Unlimited'
            return feature.productOptionValue
        }
        return feature.enabled ? 'Yes' : 'No'
    }

    const getPlanLimit = constraint => {
        if (constraint.planLimit === -1) return 'Unlimited'
        return constraint.planLimit
    }
    const getCustomLimit = constraint => {
        if (constraint.customPlanLimit === -1) return 'Unlimited'
        if (constraint.customPlanLimit === 0) return 'Unset'
        return constraint.customPlanLimit
    }

    const isEnterpriseMonthlyEnabled = () => user.enterpriseProductRule.isEnterpriseMonthlyEnabled
    const isEnterpriseYearlyEnabled = () => user.enterpriseProductRule.isEnterpriseYearlyEnabled

    return (
        <>
            <div className="current-subscription">
                <h3>
                    <p className="current-subscription__title">
                        {title}
                        {subscription && (
                            <>
                                {subscription.billingSystem === BILLING_SYSTEM.STRIPE && (
                                    <img className="img-small" src={stripeLogo} alt="stripe" />
                                )}
                                {subscription.billingSystem === BILLING_SYSTEM.TWO_CHECKOUT && (
                                    <img src={twocheckoutLogo} alt="2checkout" />
                                )}
                            </>
                        )}
                    </p>
                    {subscription && (
                        <div className="current-subscription__buttons">
                            {isBusinessUserRole(user) && (
                                <>
                                    <Button
                                        content={
                                            isEnterpriseMonthlyEnabled()
                                                ? 'Monthly Enterprise enabled'
                                                : 'Monthly Enterprise disabled'
                                        }
                                        variant="primary"
                                        onClick={() =>
                                            methods.openModal(MODALS.CONFIRMATION, {
                                                onAction: async () => {
                                                    if (isEnterpriseMonthlyEnabled()) {
                                                        await LOCK_ENTERPRISE_PLAN({
                                                            userId: user.id,
                                                            productCode: PRODUCT_CODES.ENTERPRISE_MONTHLY,
                                                        })
                                                    } else {
                                                        await UNLOCK_ENTERPRISE_PLAN({
                                                            userId: user.id,
                                                            productCode: PRODUCT_CODES.ENTERPRISE_MONTHLY,
                                                        })
                                                    }
                                                    await methods.updateUser()
                                                },
                                                noteText: `Are you sure you want to ${
                                                    isEnterpriseMonthlyEnabled() ? 'lock' : 'unlock'
                                                } opportunity to buy Enterprise plan for this user?`,
                                                actionVariant: isEnterpriseMonthlyEnabled() ? 'danger' : 'primary',
                                                actionText: isEnterpriseMonthlyEnabled() ? 'Lock' : 'Unlock',
                                            })
                                        }
                                    />
                                    <Button
                                        content={
                                            isEnterpriseYearlyEnabled()
                                                ? 'Yearly Enterprise enabled'
                                                : 'Yearly Enterprise disabled'
                                        }
                                        variant="primary"
                                        onClick={() =>
                                            methods.openModal(MODALS.CONFIRMATION, {
                                                onAction: async () => {
                                                    if (isEnterpriseYearlyEnabled()) {
                                                        await LOCK_ENTERPRISE_PLAN({
                                                            userId: user.id,
                                                            productCode: PRODUCT_CODES.ENTERPRISE_YEARLY,
                                                        })
                                                    } else {
                                                        await UNLOCK_ENTERPRISE_PLAN({
                                                            userId: user.id,
                                                            productCode: PRODUCT_CODES.ENTERPRISE_YEARLY,
                                                        })
                                                    }
                                                    await methods.updateUser()
                                                },
                                                noteText: `Are you sure you want to ${
                                                    isEnterpriseYearlyEnabled() ? 'lock' : 'unlock'
                                                } opportunity to buy Enterprise plan for this user?`,
                                                actionVariant: isEnterpriseYearlyEnabled() ? 'danger' : 'primary',
                                                actionText: isEnterpriseYearlyEnabled() ? 'Lock' : 'Unlock',
                                            })
                                        }
                                    />
                                </>
                            )}
                            {subscription.billingSystem === BILLING_SYSTEM.INTERNAL ||
                                (subscription.isTest && (
                                    <Button
                                        content="Internal change subscription data (TEST ONLY)"
                                        variant="primary"
                                        onClick={() =>
                                            methods.openModal(MODALS.INTERNAL_CHANGE_SUBSCRIPTION_DATA, subscription)
                                        }
                                    />
                                ))}
                            {!isHideChangeSubscription && (
                                <Button
                                    content="Change subscription"
                                    variant="primary"
                                    isInverse
                                    onClick={() => methods.openModal(MODALS.CHANGE_SUBSCRIPTION, subscription)}
                                />
                            )}
                        </div>
                    )}
                </h3>
                <ul className="data">
                    <li>
                        <h4>Info</h4>
                        {subscription ? (
                            <ul>
                                <DataItem title="ID" value={subscription.id} />
                                <DataItem
                                    title="Status"
                                    value={subscription.status}
                                    valueStyles={{ color: getStatusColor(subscription.status === 'ACTIVE') }}
                                />
                                <DataItem
                                    title="Expiration"
                                    value={subscription.expired ? 'Expired' : 'Active'}
                                    valueStyles={{ color: getExpirationColor(subscription.expired) }}
                                />
                                <DataItem title="Billing system" value={subscription.billingSystem} />
                                <DataItem title="Type" value={subscription.type} />
                                <DataItem title="Test" value={subscription.isTest ? 'Yes' : 'No'} />
                                <DataItem title="Trial" value={subscription.isTrial ? 'Yes' : 'No'} />
                                <DataItem
                                    title="Subscription reference"
                                    value={
                                        subscription.subscriptionReference ? subscription.subscriptionReference : 'No'
                                    }
                                />
                                <DataItem
                                    title="Last order reference"
                                    value={subscription.lastOrderReference ? subscription.lastOrderReference : 'No'}
                                />
                                <DataItem
                                    title="Recurring"
                                    value={subscription.recurringEnabled ? 'Yes' : 'No'}
                                    valueIcon={
                                        isCanManageRecurring(subscription) && (
                                            <img
                                                src={editIcon}
                                                alt=""
                                                onClick={() => setIsOpenChangeRecurringModal(true)}
                                            />
                                        )
                                    }
                                />
                                {subscription.started && (
                                    <DataItem
                                        title="Start date"
                                        value={moment(subscription.started).format('MMM D, YYYY (HH:mm)')}
                                    />
                                )}
                                {subscription.expirationDate && (
                                    <DataItem
                                        title="Expiration date"
                                        value={moment(subscription.expirationDate).format('MMM D, YYYY (HH:mm)')}
                                        valueIcon={
                                            isCanManageExpirationDate(subscription) && (
                                                <img
                                                    src={editIcon}
                                                    alt=""
                                                    onClick={() => setIsOpenChangeSubscriptionExpirationDateModal(true)}
                                                />
                                            )
                                        }
                                    />
                                )}
                                <DataItem
                                    title="Purchase date"
                                    value={moment(subscription.purchaseDate).format('MMM D, YYYY (HH:mm)')}
                                />
                                <DataItem
                                    title="Constraint next renewal date"
                                    value={moment(subscription.constraintNextRenewalDate).format('MMM D, YYYY (HH:mm)')}
                                />
                            </ul>
                        ) : (
                            <div className="empty">Not found</div>
                        )}
                    </li>
                    <li>
                        <h4>Product</h4>
                        {subscription && subscription.product ? (
                            <ul>
                                <DataItem title="Name" value={subscription.product.name} />
                                <DataItem title="Billing cycle type" value={subscription.product.billingCycleType} />
                                <DataItem title="Code" value={subscription.product.productCode} />
                                <DataItem title="ID" value={subscription.product.productId} />
                                <DataItem
                                    title="Purchase price"
                                    value={`${subscription.product.purchasePrice} ${subscription.product.currency}`}
                                />
                            </ul>
                        ) : (
                            <div className="empty">Not found</div>
                        )}
                    </li>
                    <li>
                        <h4>Last payment</h4>
                        {subscription && subscription.lastPayment ? (
                            <ul>
                                <DataItem title="Test" value={subscription.lastPayment.isTest ? 'Yes' : 'No'} />
                                <DataItem title="Price" value={subscription.lastPayment.price} />
                                <DataItem title="Currency" value={subscription.lastPayment.currency} />
                                <DataItem
                                    title="Promo code"
                                    value={subscription.lastPayment.promoCode ? 'Yes' : 'No'}
                                />
                                <DataItem title="Total price" value={subscription.lastPayment.total} />
                                <DataItem title="Payment method" value={subscription.lastPayment.paymentMethod} />
                                <DataItem title="Card last digits" value={subscription.lastPayment.cardLastDigits} />
                                <DataItem
                                    title="Sale date"
                                    value={moment(subscription.lastPayment.saleDate).format('MMM D, YYYY (HH:mm)')}
                                />
                                <DataItem title="Order flow" value={subscription.lastPayment.orderFlow} />
                                <DataItem title="Order status" value={subscription.lastPayment.orderStatus} />
                            </ul>
                        ) : (
                            <div className="empty">Not found</div>
                        )}
                    </li>
                </ul>
                <ul className="data full-width-style">
                    <li>
                        <h4>Features</h4>
                        {subscription && subscription.product ? (
                            <ul>
                                {subscription.product.featureProducts.map(feature => (
                                    <li key={feature.id}>
                                        <h5>{feature.featureType}</h5>
                                        <ul>
                                            <DataItem title="Feature ID" value={feature.id} />
                                            <DataItem title="Feature type" value={feature.featureType} />
                                            <DataItem title="Feature name" value={feature.name} />
                                            <DataItem title="Product code" value={feature.productCode} />
                                            <DataItem title="Value" value={getFeatureValue(feature)} />
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="empty">Not found</div>
                        )}
                    </li>
                    <li>
                        <h4>Constraints</h4>
                        {subscription ? (
                            <ul>
                                {subscription.constraints.map(constraint => (
                                    <li key={constraint.id}>
                                        <h5>{constraint.featureProduct.featureType}</h5>
                                        <ul>
                                            <DataItem title="Constraint ID" value={constraint.id} />
                                            <DataItem title="Feature ID" value={constraint.featureProduct.id} />
                                            <DataItem
                                                title="Feature type"
                                                value={constraint.featureProduct.featureType}
                                            />
                                            <DataItem title="Feature name" value={constraint.featureProduct.name} />
                                            <DataItem
                                                title="Product code"
                                                value={constraint.featureProduct.productCode}
                                            />
                                            <DataItem
                                                title="Refreshable"
                                                value={constraint.refreshable ? 'Yes' : 'No'}
                                            />
                                            <DataItem title="Current value" value={constraint.currentValue} />
                                            <DataItem title="Plan limit" value={getPlanLimit(constraint)} />
                                            <DataItem title="Custom plan limit" value={getCustomLimit(constraint)} />
                                            {constraint.featureProduct.featureType === FEATURES_TYPES.TEAM && (
                                                <Button
                                                    content="Update"
                                                    variant="primary"
                                                    isInverse
                                                    onClick={() =>
                                                        methods.openModal(MODALS.UPDATE_CONSTRAINT, {
                                                            subscription,
                                                            constraint,
                                                        })
                                                    }
                                                />
                                            )}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="empty">Not found</div>
                        )}
                    </li>
                </ul>
            </div>

            {isOpenChangeSubscriptionExpirationDateModal && (
                <ChangeSubscriptionExpirationDateModal
                    data={{
                        expirationDate: subscription.expirationDate,
                        subscriptionReference: subscription.subscriptionReference,
                    }}
                    onSubmit={date => methods.updateSubscription(subscription, { expirationDate: date })}
                    onClose={() => setIsOpenChangeSubscriptionExpirationDateModal(false)}
                />
            )}
            {isOpenChangeRecurringModal && (
                <ConfirmationDialog
                    onClose={() => setIsOpenChangeRecurringModal(false)}
                    onAction={() =>
                        methods.updateSubscription(subscription, { recurringEnabled: !subscription.recurringEnabled })
                    }
                    helpText={
                        'Note: If the user account is linked to "2Checkout" service, after changes may be a slight delay in displaying in the GUI'
                    }
                    noteText={`Are you sure you want to ${
                        subscription.recurringEnabled ? 'disable' : 'enable'
                    } subscription recurring for this user?`}
                    actionVariant={subscription.recurringEnabled ? 'danger' : 'primary'}
                    actionText={subscription.recurringEnabled ? 'Disable' : 'Enable'}
                />
            )}
        </>
    )
}

export default SubscriptionDetails
