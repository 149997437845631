import React from 'react'
import moment from 'moment'

import Table from 'components/Table/Table'

import { BILLING_SYSTEM, ORDER_STATUS } from 'constants/subscription'

import iconRefund from 'images/icons/refund.svg'
import iconCopy from 'images/icons/copy.svg'

import { getFormattedPrice } from 'utils/payments'
import { copyToClipboard } from 'utils/clipboard'

import { MODALS, MODAL_ACTIONS } from '../../constants'

import styles from './BillingHistoryTable.module.scss'

const EditButton = ({ onClick }) => (
    <img onClick={onClick} src={iconRefund} alt="edit" title="Refund" className={styles.editIcon} />
)

const getColumns = ({ methods }) => [
    {
        headerLabel: 'Date',
        getTitle: payment => moment(payment.saleDate).format('MMM D, YYYY (HH:mm)'),
        getValue: payment => moment(payment.saleDate).format('MMM D, YYYY (HH:mm)'),
        width: '10%',
    },
    {
        headerLabel: 'External Order Reference',
        getMarks: payment => [
            {
                image: iconCopy,
                label: 'Copy to clipboard',
                onClick: () => copyToClipboard(payment.externalOrderReference),
            },
        ],
        getTitle: payment => payment.externalOrderReference,
        getValue: payment => payment.externalOrderReference,
        width: '20%',
    },
    {
        headerLabel: 'Product',
        getTitle: payment => `${payment.product.name} (${payment.product.productCode})`,
        getValue: payment => `${payment.product.name} (${payment.product.productCode})`,
        width: '25%',
    },
    {
        headerLabel: 'Payment method',
        getValue: payment => (
            <>
                {payment.billingSystem}
                <br />
                {payment.paymentMethod ? `${payment.paymentMethod}` : null}
                {payment.cardLastDigits ? ` **** ${payment.cardLastDigits}` : null}
            </>
        ),
        width: '20%',
    },
    {
        headerLabel: 'Status',
        getTitle: payment => payment.orderStatus,
        getValue: payment => payment.orderStatus,
        width: '15%',
    },
    {
        headerLabel: 'Total',
        getTitle: payment => getFormattedPrice(payment.price, payment.currency),
        getValue: payment => (
            <>
                {getFormattedPrice(payment.price, payment.currency)}
                {payment.billingSystem === BILLING_SYSTEM.YOOKASSA && payment.orderStatus === ORDER_STATUS.COMPLETE && (
                    <EditButton
                        onClick={() =>
                            methods.openModal(MODALS.CONFIRMATION, {
                                data: {
                                    noteText: 'Are you sure you want to refund this payment?',
                                    actionVariant: 'primary',
                                    actionText: 'Refund',
                                    successText: 'Payment is refund successfully!',
                                },
                                onAction: () =>
                                    methods.modalAction(MODAL_ACTIONS.REFUND_PAYMENT, {
                                        ...payment,
                                        paymentId: payment.id,
                                    }),
                            })
                        }
                    />
                )}
            </>
        ),
        width: '10%',
    },
]

const BillingHistoryTable = ({ title, list, pagination, onExpand, onChangePage, isLoading, methods }) => (
    <Table
        columns={getColumns({ methods })}
        title={title}
        list={list}
        pagination={pagination}
        onExpand={onExpand}
        onChangePage={onChangePage}
        isLoading={isLoading}
    />
)

export default BillingHistoryTable
