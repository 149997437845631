import React from 'react'
import classNames from 'classnames'

import { getFormattedNumber } from 'utils/numbers'

import { THRESHOLD_DANGER_STATUS } from './constants'

import styles from './Progress.module.scss'

const Progress = ({ title, current, total, units }) => {
    const filledPercentage = (current * 100) / total
    const roundedFilledPercentage = Math.round(filledPercentage * 10) / 10

    const isDanger = filledPercentage >= THRESHOLD_DANGER_STATUS

    return (
        <div className={styles.progress}>
            <div className={styles.info}>
                <div className={styles.title}>{title}</div>
                <div className={styles.values}>
                    {getFormattedNumber(current)} of {getFormattedNumber(total)}
                    {!!units && ` ${units}`}
                </div>
            </div>
            <div className={styles.line}>
                <div
                    className={classNames({
                        [styles.lineFiller]: true,
                        [styles.isDanger]: isDanger,
                    })}
                    style={{ minWidth: `${filledPercentage}%` }}
                >
                    {roundedFilledPercentage > 100 ? 100 : roundedFilledPercentage}%
                </div>
            </div>
        </div>
    )
}

export default Progress
