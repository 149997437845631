import HttpClient from './instance'

export const GET_AUTHORS_TAGS = async () =>
    HttpClient({
        method: 'get',
        url: `/public-content-tags`,
        params: {
            size: 1000,
            page: 0,
            sort: 'name,asc',
        },
    })

export const CREATE_AUTHORS_TAG = async name =>
    HttpClient({
        method: 'post',
        url: `/admin/public-content-tags`,
        data: {
            name,
        },
    })

export const UPDATE_AUTHORS_TAG = async (id, name) =>
    HttpClient({
        method: 'put',
        url: `/admin/public-content-tags/${id}`,
        data: {
            name,
        },
    })

export const REMOVE_AUTHORS_TAG = async id =>
    HttpClient({
        method: 'delete',
        url: `/admin/public-content-tags/${id}`,
        data: {},
    })
