import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { removeItem } from 'utils/localStorage'

import reducer from './reducers'

// Remove legacy localStorage keys
removeItem('token')
removeItem('scope')
removeItem('user')
removeItem('userDetails')
removeItem('userData')

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(reducer, composeEnhancers(applyMiddleware(thunkMiddleware)))
