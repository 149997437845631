import React, { useEffect, useState } from 'react'

import { GET_PRODUCTS, UPDATE_PRODUCT_RUB_PRICE } from 'api/products'

import Preloader from 'components/Preloader/Preloader'
import SEO from 'components/SEO/SEO'

import useModal from 'hooks/useModal'

import Filters from './components/Filters/Filters'
import ProductsTable from './components/ProductsTable/ProductsTable'
import FeaturesModal from './components/FeaturesModal/FeaturesModal'
import UpdatePriceModal from './components/UpdatePriceModal/UpdatePriceModal'

import { getUniqueGroups } from './utils'
import { MODALS } from './constants'

import styles from './Products.module.scss'
import { CURRENCIES } from '../../constants/currencies'

const Products = () => {
    const [isReady, setIsReady] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [products, setProducts] = useState([])

    const { modalName, modalPayload, onOpenModal, onCloseModal } = useModal()
    const [groups, setGroups] = useState([])

    const [selectedGroups, setSelectedGroups] = useState([])

    useEffect(() => {
        updateProducts()
            .then(() => setIsReady(true))
            .catch(err => console.error(err))
    }, [])

    const updateProducts = async () => {
        setIsLoading(true)
        try {
            const list = await GET_PRODUCTS()
            setProducts(list)
            setGroups(getUniqueGroups(list))
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const updatePrice = async (product, currency, newPrice) => {
        if (currency === CURRENCIES.RUB) {
            await UPDATE_PRODUCT_RUB_PRICE(product.id, { productCode: product.productCode, newPrice })
        }
        await updateProducts()
    }

    return (
        <div className={styles.products}>
            <SEO title="Products" />
            {isReady ? (
                <>
                    <Filters
                        products={products}
                        onClickGroup={group =>
                            setSelectedGroups(prevState => {
                                if (prevState.includes(group)) return prevState.filter(v => v !== group)
                                return [...prevState, group]
                            })
                        }
                        groups={selectedGroups}
                    />
                    <br />
                    <br />
                    <ul className={styles.tables}>
                        {(selectedGroups.length ? selectedGroups : groups).map(group => (
                            <li key={group} className={styles.table}>
                                <ProductsTable
                                    title={group}
                                    list={products.filter(product => product.group === group)}
                                    methods={{
                                        onOpenModal,
                                    }}
                                    isLoading={isLoading}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <Preloader />
            )}

            {modalName === MODALS.FEATURES && <FeaturesModal product={modalPayload} onClose={onCloseModal} />}
            {modalName === MODALS.EDIT_PRICE && (
                <UpdatePriceModal
                    product={modalPayload}
                    currency={CURRENCIES.RUB}
                    onClose={onCloseModal}
                    onSubmit={updatePrice}
                />
            )}
        </div>
    )
}

export default Products
