import React from 'react'

import Button from 'components/Forms/Button/Button'

import editIcon from 'images/icons/editWhite.svg'
import rubbishBinIcon from 'images/icons/rubbishBinWhite.svg'
import plusIcon from 'images/icons/plusWhite.svg'

import { MODALS } from '../../../../constants'

import styles from './Actions.module.scss'

const Actions = ({ tag, openModal, isCanAdd = false }) => (
    <ul className={styles.actions}>
        {isCanAdd && (
            <li className={styles.action}>
                <Button
                    title="Add"
                    className={styles.button}
                    variant="primary"
                    isInverse={true}
                    onClick={() => openModal(MODALS.CREATE_TAG, { parentTag: tag })}
                >
                    <img className={styles.icon} src={plusIcon} alt="edit" />
                </Button>
            </li>
        )}
        <li className={styles.action}>
            <Button
                title="Edit"
                className={styles.button}
                variant="primary"
                isInverse={true}
                onClick={() => openModal(MODALS.EDIT_TAG, tag)}
            >
                <img className={styles.icon} src={editIcon} alt="edit" />
            </Button>
        </li>
        <li className={styles.action}>
            <Button
                title="Remove"
                className={styles.button}
                variant="danger"
                isInverse={true}
                onClick={() => openModal(MODALS.REMOVE_TAG, tag)}
            >
                <img className={styles.icon} src={rubbishBinIcon} alt="remove" />
            </Button>
        </li>
    </ul>
)

export default Actions
