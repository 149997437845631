import React from 'react'

import styles from './PageTopbar.module.scss'

const PageTopbar = ({ title, children }) => (
    <div className={styles.pageTopbar}>
        <h1 className={styles.pageTitle}>{title}</h1>
        {children && <div className={styles.pageActions}>{children}</div>}
    </div>
)

export default PageTopbar
