import React from 'react'
import classNames from 'classnames'

import './Button.scss'

import loaderIcon from './i/loader.svg'

const availableVariants = ['primary', 'secondary', 'danger']

const Button = ({
    content,
    title,
    loadingText = '',
    variant = 'primary',
    styles = {},
    isInverse = false,
    isDisabled = false,
    isLoading = false,
    isWide = false,
    onClick = () => {},
    onMouseDownEvt = () => {},
    className,
    children,
}) => (
    <button
        className={classNames({
            common__button: true,
            'is-wide': isWide,
            'is-loading': isLoading,
            'is-inverse': isInverse,
            [`${availableVariants.includes(variant) ? variant : availableVariants[0]}`]: true,
            [className]: !!className,
        })}
        title={title}
        data-loading-text={loadingText}
        disabled={isDisabled}
        style={styles}
        onClick={() => onClick()}
        onMouseDown={onMouseDownEvt}
    >
        {content || null}
        {children || null}
        {isLoading && (
            <div className="loader">
                {!!loadingText.length && <p>{loadingText}</p>}
                <img src={loaderIcon} alt="" />
            </div>
        )}
    </button>
)

export default Button
