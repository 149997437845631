// Types
export const SET_MODAL = 'SET_MODAL'

// Actions
export const setModal = (name, payload) => {
    return {
        type: SET_MODAL,
        payload: {
            name,
            payload,
        },
    }
}
