import React from 'react'
import classNames from 'classnames'

import SEO from 'components/SEO/SEO'
import Input from 'components/Forms/Input/Input'
import Preloader from 'components/Preloader/Preloader'
import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Button from 'components/Forms/Button/Button'

import API from 'api'

import RemoveTagModal from './components/RemoveTagModal/RemoveTagModal'
import CreateTagModal from './components/CreateTagModal/CreateTagModal'
import EditTagModal from './components/EditTagModal/EditTagModal'
import TagsList from './components/TagsList/TagsList'

import { MODALS, TABS } from './constants'

import styles from './Tabs.module.scss'

class TagsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            nameFilter: '',
            initialTags: [],
            tags: [],
            modal: {
                isOpen: false,
                name: '',
                payload: {},
            },
            activeTab: TABS.ALL,
        }
        this.baseState = this.state
    }

    async componentDidMount() {
        await this.getTags()
        this.setState({
            isReady: true,
            initialTags: this.state.tags,
        })
    }

    getTags = async () => {
        try {
            const tags = await API.TEMPLATES.GET_TAGS()

            await this.setState({
                tags: tags.reverse(),
            })
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
        }
    }

    openModal = async (name, payload = null) => {
        this.setState(prevState => ({
            modal: {
                ...prevState.modal,
                isOpen: true,
                name,
                payload,
            },
        }))
    }
    closeModal = async () => {
        this.setState({
            modal: {
                ...this.baseState.modal,
                isOpen: false,
            },
        })
    }

    createTag = async tag => {
        await API.TEMPLATES.CREATE_TAG(tag)
        await this.getTags()
    }
    removeTag = async id => {
        await API.TEMPLATES.REMOVE_TAG(id)
        await this.getTags()
    }
    editTag = async (id, data) => {
        await API.TEMPLATES.UPDATE_TAG(id, data)
        await this.getTags()
    }

    onChangeTab = tab => {
        const { initialTags } = this.state
        if (tab === TABS.ALL) {
            this.setState({
                activeTab: TABS.ALL,
                tags: initialTags,
            })
        }
        if (tab === TABS.SYSTEM) {
            this.setState({
                activeTab: TABS.SYSTEM,
                tags: initialTags.filter(tag => tag.isSystem),
            })
        }
        if (tab === TABS.INTERESTS) {
            this.setState({
                activeTab: TABS.INTERESTS,
                tags: initialTags.filter(tag => tag.isInterests),
            })
        }
    }

    render() {
        const { isReady, tags, modal, nameFilter, activeTab } = this.state

        const isOpenedModal = name => modal.isOpen && modal.name === name

        return (
            <>
                <SEO title="Template gallery / Tags" />
                {isReady ? (
                    <div className="tags">
                        <div className="top-bar">
                            <div className="top-bar--left">
                                <Input
                                    maxWidth="300px"
                                    isClearable
                                    placeholder="Name filter"
                                    value={nameFilter}
                                    onChange={val => this.setState({ nameFilter: val })}
                                />
                            </div>
                            <div className="top-bar--right">
                                <Button
                                    content="+ Create tag"
                                    variant="primary"
                                    onClick={() => this.openModal(MODALS.CREATE_TAG)}
                                />
                            </div>
                        </div>
                        <ul className={styles.tabs}>
                            <li
                                onClick={() => this.onChangeTab(TABS.ALL)}
                                className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.ALL })}
                            >
                                All
                            </li>
                            <li
                                onClick={() => this.onChangeTab(TABS.SYSTEM)}
                                className={classNames(styles.tab, { [styles.isActiveTab]: activeTab === TABS.SYSTEM })}
                            >
                                System
                            </li>
                            <li
                                onClick={() => this.onChangeTab(TABS.INTERESTS)}
                                className={classNames(styles.tab, {
                                    [styles.isActiveTab]: activeTab === TABS.INTERESTS,
                                })}
                            >
                                Interests
                            </li>
                        </ul>
                        <br />
                        <TagsList list={tags} nameFilter={nameFilter} openModal={this.openModal} />

                        {isOpenedModal(MODALS.REMOVE_TAG) && (
                            <RemoveTagModal data={modal.payload} onSubmit={this.removeTag} onClose={this.closeModal} />
                        )}
                        {isOpenedModal(MODALS.CREATE_TAG) && (
                            <CreateTagModal
                                data={{
                                    existingTags: tags,
                                    parentTag: modal.payload?.parentTag || null,
                                }}
                                onSubmit={this.createTag}
                                onClose={this.closeModal}
                            />
                        )}
                        {isOpenedModal(MODALS.EDIT_TAG) && (
                            <EditTagModal
                                data={{
                                    tag: modal.payload,
                                    existingTags: tags,
                                }}
                                onSubmit={this.editTag}
                                onClose={this.closeModal}
                            />
                        )}
                    </div>
                ) : (
                    <Preloader />
                )}
            </>
        )
    }
}

export default TagsPage
