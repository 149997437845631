import React, { useEffect, useState } from 'react'

import Toast, { TOAST_TYPE } from 'components/Toast/Toast'
import Input from 'components/Forms/Input/Input'
import FormBlock from 'components/Forms/FormBlock'
import FormModal from 'components/Modal/FormModal/FormModal'
import Toggle from 'components/Forms/Toggle/Toggle'
import Select from 'components/Forms/Select/Select'
import Preloader from 'components/Preloader/Preloader'

import { fieldsValidator, getIsEmailRule, getIsNotEmptyStringRule, getIsValueRule } from 'utils/fieldsValidator'
import { removeKeyFromObject } from 'utils/common'

import { USER_ROLES } from 'constants/user'
import { isEuVersion } from 'constants/env'

import API from 'api'

const CreateNewUser = ({ onSubmit, onClose = () => {} }) => {
    const [isLoading, setIsLoading] = useState(false)

    const [roles, setRoles] = useState([])
    const [products, setProducts] = useState([])

    const [login, setLogin] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [newsSubscription, setNewsSubscription] = useState(true)
    const [country, setCountry] = useState('')

    const [productCode, setProductCode] = useState(null)
    const [group, setGroup] = useState(null)
    const [role, setRole] = useState(null)

    const [errors, setErrors] = useState({})

    useEffect(() => {
        setIsLoading(true)
        getInitialData()
            .then(() => {})
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    const getInitialData = async () => {
        const [_products, _roles] = await Promise.all([API.COMMON.GET_PRODUCTS(), API.USERS.GET_ROLES()])

        setRoles(_roles)
        setProducts(_products)

        // Auto EDU group selection
        if (isEuVersion) {
            const educationGroup = _roles.find(item => item.customId === USER_ROLES.EDUCATION)
            if (educationGroup) {
                setGroup({
                    value: educationGroup.id,
                    label: educationGroup.groupTranslationDto.text,
                    payload: educationGroup,
                })
            }
        }
    }

    const handleSubmit = async () => {
        try {
            setErrors({})

            const validator = new fieldsValidator()

            validator.addFields([
                validator.createField('login', login, [getIsValueRule(), getIsNotEmptyStringRule(), getIsEmailRule()]),
                validator.createField('productCode', productCode, [getIsValueRule()]),
                validator.createField('group', group, [getIsValueRule()]),
                validator.createField('role', role, [getIsValueRule()]),
            ])

            const { isHasErrors, errors } = validator.validate()

            if (isHasErrors) {
                setErrors(errors)
                return
            }

            await onSubmit({
                login,
                firstName,
                lastName,
                productCode: productCode.value,
                newsSubscription,
                company,
                country,
                roleInProjectId: role.value,
            })

            return true
        } catch (err) {
            console.error(err)
            Toast(TOAST_TYPE.ERROR)
            if (err.response) {
                const {
                    response: { status, data },
                } = err
                if (status === 400) {
                    if (data.kind.code === 5) {
                        if (data.message === 'Email is not valid') {
                            setErrors(prevState => ({
                                ...prevState,
                                login: 'Email is not valid',
                            }))
                            return
                        }
                        if (data.message === 'User with supplied credentials already exists') {
                            setErrors(prevState => ({
                                ...prevState,
                                login: 'User with this email already exist',
                            }))
                            return
                        }
                        if (data.message === 'Bad request') {
                            if (data.exception_data?.login === 'Email is not valid') {
                                setErrors(prevState => ({
                                    ...prevState,
                                    login: 'Email is not valid',
                                }))
                                return
                            }
                        }
                    }
                }
            }
            throw err
        }
    }

    return (
        <FormModal
            headText="Create new user"
            successText="User created successfully!"
            actionText="Create"
            onAction={() => handleSubmit()}
            onClose={() => onClose()}
        >
            {isLoading ? (
                <Preloader />
            ) : (
                <>
                    <FormBlock label="Login">
                        <Input
                            value={login}
                            error={errors.login}
                            onChange={value => {
                                setLogin(value)
                                setErrors(removeKeyFromObject(errors, 'login'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="First Name">
                        <Input
                            value={firstName}
                            error={errors.firstName}
                            onChange={value => {
                                setFirstName(value)
                                setErrors(removeKeyFromObject(errors, 'firstName'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Last Name">
                        <Input
                            value={lastName}
                            error={errors.lastName}
                            onChange={value => {
                                setLastName(value)
                                setErrors(removeKeyFromObject(errors, 'lastName'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Company">
                        <Input
                            value={company}
                            error={errors.company}
                            onChange={value => {
                                setCompany(value)
                                setErrors(removeKeyFromObject(errors, 'company'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Country">
                        <Input
                            value={country}
                            error={errors.country}
                            onChange={value => {
                                setCountry(value)
                                setErrors(removeKeyFromObject(errors, 'country'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock label="Group">
                        <Select
                            value={group}
                            error={errors.group}
                            isDisabled={isEuVersion}
                            options={roles.map(item => ({
                                value: item.id,
                                label: item.groupTranslationDto.text,
                                payload: item,
                            }))}
                            onChange={value => {
                                setRole(null)
                                setProductCode(null)
                                setGroup(value)
                                setErrors(removeKeyFromObject(errors, 'group'))
                            }}
                        />
                    </FormBlock>
                    {!!group && (
                        <FormBlock label="Role">
                            <Select
                                value={role}
                                error={errors.role}
                                options={group.payload.userRoleInProjectDtos.map(item => ({
                                    value: item.id,
                                    label: item.roleTranslationDto.text,
                                }))}
                                onChange={value => {
                                    setRole(value)
                                    setErrors(removeKeyFromObject(errors, 'role'))
                                }}
                            />
                        </FormBlock>
                    )}
                    <FormBlock label="Product Code">
                        <Select
                            isDisabled={!group}
                            value={productCode}
                            error={errors.productCode}
                            options={products
                                .filter(
                                    item =>
                                        item.group ===
                                        (group?.payload.customId === USER_ROLES.EDUCATION
                                            ? USER_ROLES.EDUCATION
                                            : USER_ROLES.BUSINESS),
                                )
                                .map(item => ({ value: item.productCode, label: item.name }))}
                            onChange={value => {
                                setProductCode(value)
                                setErrors(removeKeyFromObject(errors, 'productCode'))
                            }}
                        />
                    </FormBlock>
                    <FormBlock>
                        <Toggle
                            value={newsSubscription}
                            label="News subscription"
                            onChange={value => {
                                setNewsSubscription(value)
                                setErrors(removeKeyFromObject(errors, 'newsSubscription'))
                            }}
                        />
                    </FormBlock>
                </>
            )}
        </FormModal>
    )
}

export default CreateNewUser
