import HttpClient from './instance'

export const GET_ORGANIZATIONS = async (params = {}) =>
    HttpClient({
        method: 'get',
        url: '/admin/organizations',
        params,
    })

export const GET_ORGANIZATION = async id =>
    HttpClient({
        method: 'get',
        url: `/admin/organizations/${id}`,
        params: {
            membersApprovedOnly: false,
        },
    })
